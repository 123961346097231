<template>
  <!-- Checkbox Buttons Group -->
  <div class="adm-checkbox-buttons-group">
    <adm-button-group>
      <adm-button
        v-for="(option, key) in options"
        :key="option.value"
        type="ghost"
        size="medium"
        :class="{
          'adm-checkbox-buttons-group--next-is-selected' : isNextOptionSelected(key),
          'is-hover-disabled-for-active-button' : isOnlyActive(option.value)
        }"
        :color="selected.includes(option.value) ? 'blue' : 'dark'"
        @click="toggleOption(option)"
      >
        {{ option.label }}
      </adm-button>
    </adm-button-group>
  </div>
  <!-- /Checkbox Buttons Group -->
</template>

<script>
  import AdmButtonGroup from '@/views/_components/button/AdmButtonGroup'
  import AdmButton from '@/views/_components/button/AdmButton'

  export default {
    name: 'AdmCheckboxButtonsGroup',
    components: {
      AdmButton,
      AdmButtonGroup
    },
    props: {
      options: {
        type: Array,
        default: () => []
      },
      value: {
        type: [Array, String],
        default: () => []
      },
      atLeastOneNeedsToBeSelected: {
        type: Boolean,
        default: false
      }
    },

    data () {
      return {
        selected: this.value
      }
    },

    methods: {
      toggleOption (option) {
        if (typeof this.selected === 'string') {
          this.selected = option.value
        } else {
          if (this.selected.includes(option.value)) {
            if (this.atLeastOneNeedsToBeSelected && this.selected.length === 1) {
              return
            }
            this.selected = this.selected.filter(item => item !== option.value)
          } else {
            this.selected.push(option.value)
          }
        }

        this.$emit('input', this.selected )
      },
      isNextOptionSelected (key) {
        if (!this.options.hasOwnProperty(key + 1)) {
          return false
        }
        return this.selected.includes(this.options[key + 1].value)
      },

      isOnlyActive (value) {
        return !!(this.selected.length === 1 && this.selected.includes(value));
      }
    }
  };
</script>

<style lang="scss">
.adm-checkbox-buttons-group {
  &--next-is-selected.adm-button {
    border-right: 1px solid transparent;
  }
  .adm-button.is-hover-disabled-for-active-button {
    pointer-events: none;
  }

  .adm-button__size__medium {
    flex-grow: 1;
  }
}
</style>
