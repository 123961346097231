const getDefaultState = () => {
  return {
    showContinueButton: false,
    isDataChanged: false,
    showMobileNavigation: true,
  }
}

const state = getDefaultState()

const mutations = {
  setShowContinueButton (state, payload) {
    state.showContinueButton = payload
  },

  setIsDataChanged (state, payload) {
    state.isDataChanged = payload
  },

  toggleMobileNavigation (state, payload) {
    state.showMobileNavigation = payload
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
