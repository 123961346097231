const moment = require('moment')

if (window.language === 'sr-Latn') {
  require('moment/locale/sr')
}

if (window.language === 'pt-BR') {
  require('moment/locale/pt-br')
}

if (window.language === 'ru-RU') {
  require('moment/locale/ru')
}

if (window.language === 'th') {
  require('moment/locale/th')
}

if (window.language === 'fr') {
  require('moment/locale/fr')
}

if (window.language === 'de') {
  require('moment/locale/de')
}

if (window.language === 'cs') {
  require('moment/locale/cs')
}

if (window.language === 'es') {
  require('moment/locale/es')
}

export default moment
