export default [
  {
    path: 'features',
    name: 'features',
    meta: {
      title: 'features_&_integrations',
      requiresAuth: true,
      requiredPermissions: 'edit_settings',
    },
    component: () => import(/* webpackChunkName: "features&integrations" */'@/views/Features&Integrations/Features&IntegrationsPage/Features&IntegrationsPage.vue').then(value => value.default)
  },
  {
    path: 'features/webhooks',
    meta: {
      title: 'webhooks',
      breadcrumb: 'webhooks',
      feature: 'webhooks',
      requiredPermissions: 'edit_settings'
    },
    name: 'configure-webhooks',
    component: () => import(/* webpackChunkName: "features&integrations" */'@/views/Features&Integrations/Features/Webhooks/Webhooks.vue').then(value => value.default)
  },
  {
    path: 'features/smtp',
    meta: {
      title: "smtp_email_feature_name",
      breadcrumb: 'smtp_email_feature_name',
      feature: 'smtp_email',
      requiredPermissions: 'edit_settings'
    },
    name: 'configure-smtp',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Features/SmtpEmail/SmtpEmail.vue').then(value => value.default)
  },
  {
    path: 'features/domain',
    meta: {
      breadcrumb: 'configure_domain',
      feature: 'custom_domain',
      requiredPermissions: 'edit_settings'
    },
    name: 'configure-domain',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Features/CustomDomain/CustomDomain.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/facebook-pixel',
    meta: {
      title: 'facebook_pixel_feature_name',
      breadcrumb: 'facebook_pixel_feature_name',
      feature: 'facebook_pixel',
      requiredPermissions: 'edit_settings'
    },
    name: 'facebook-pixel-integration',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/FacebookPixel/FacebookPixel.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/google-analytics',
    meta: {
      title: 'google_analytics_feature_name',
      breadcrumb: 'google_analytics_feature_name',
      feature: 'google_analytics',
      requiredPermissions: 'edit_settings'
    },
    name: 'google-analytics-integration',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/GoogleAnalytics/GoogleAnalytics.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/google-tag-manager',
    meta: {
      title: 'google_tag_manager_feature_name',
      breadcrumb: 'google_tag_manager_feature_name',
      feature: 'google_tag_manager',
      requiredPermissions: 'edit_settings'
    },
    name: 'google-tag-manager-integration',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/GoogleTagManager/GoogleTagManager.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/zapier',
    meta: {
      title: 'zapier_feature_name',
      breadcrumb: 'zapier_feature_name',
      feature: 'zapier',
      requiredPermissions: 'edit_settings'
    },
    name: 'zapier-integrations',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/Zapier/Zapier.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/mailchimp',
    meta: {
      title: 'mailchimp_integration_feature_name',
      breadcrumb: 'mailchimp_integration_feature_name',
      feature: 'mailchimp_integration',
      requiredPermissions: 'edit_settings'
    },
    name: 'mailchimp-integration',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/Mailchimp/Mailchimp.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/reserve-with-google',
    meta: {
      title: 'reserve_with_google_feature_name',
      breadcrumb: 'reserve_with_google_feature_name',
      feature: 'reserve_with_google',
      requiredPermissions: 'edit_settings'
    },
    name: 'reserve-with-google',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/ReserveWithGoogle/ReserveWithGoogle.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/google-calendar',
    meta: {
      title: 'google_calendar_feature_name',
      breadcrumb: 'google_calendar_feature_name',
      feature: 'google_calendar',
      requiredPermissions: 'edit_settings'
    },
    name: 'google-calendar-integration',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/GoogleCalendar/GoogleCalendar.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/sendfox',
    meta: {
      title: "sendfox_integration_feature_name",
      breadcrumb: 'sendfox_integration_feature_name',
      feature: 'sendfox_integration',
      requiredPermissions: 'edit_settings'
    },
    name: 'sendfox-integration',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/SendFox/SendFox.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/acumba',
    meta: {
      breadcrumb: 'Acumba',
      feature: 'acumba',
      requiredPermissions: 'edit_settings'
    },
    name: 'acumba-integration',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/Acumba/Acumba.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/apple-calendar',
    meta: {
      breadcrumb: 'apple_calendar_feature_name',
      feature: 'apple_calendar',
      requiredPermissions: 'edit_settings'
    },
    name: 'configure-apple-calendar',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/AppleCalendar/AppleCalendar.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/outlook-calendar',
    meta: {
      title: 'outlook_calendar_feature_name',
      breadcrumb: 'outlook_calendar_feature_name',
      feature: 'outlook_calendar',
      requiredPermissions: 'edit_settings'
    },
    name: 'outlook-calendar-integration',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/OutlookCalendar/OutlookCalendar.vue').then(value => value.default)
  },
  {
    path: 'features/integrations/zoom',
    meta: {
      title: 'zoom_integration_feature_name',
      breadcrumb: 'zoom_integration_feature_name',
      feature: 'zoom_integration',
      requiredPermissions: 'edit_settings'
    },
    name: 'zoom-integration',
    component: () => import(/* webpackChunkName: "settings" */ '@/views/Features&Integrations/Integrations/Zoom/Zoom.vue').then(value => value.default)
  },
]
