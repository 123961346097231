<template>

  <!-- Price Details -->
  <div class="price-details">

    <!-- Sidebar Title -->
    <h6 class="price-details__title">
      <a @click="hidePriceDetails">
        <i class="el-icon-back" /> {{ $t('price_details') }}
      </a>
    </h6>
    <!-- /Sidebar Title -->

    <!-- Customers -->
    <div class="customers-label">
      {{ $t('customers') }}
    </div>
    <!-- /Customers -->

    <!-- Details -->
    <template v-if="appointmentProp.bookings && appointmentProp.bookings.length > 0">
      <div v-for="booking in appointmentProp.bookings" :key="booking.customerId" class="customer-section">

        <!-- General Info -->
        <div class="customer__general-info">

          <!-- Customer Name & Service Price -->
          <div class="customer__general-info__row">
            <div class="customer__name overflow-ellipsis pr-4">
              {{ booking.firstName }} {{ booking.lastName }}
            </div>
          </div>
          <!-- /Customer Name & Service Price -->

          <!-- Service Price -->
          <div class="customer__general-info__row">
            <div class="overflow-ellipsis pr-4">
              {{ $t('service') }}
            </div>
            <div>
              {{ formatPrice(booking.price) }}
            </div>
          </div>
          <!-- /Additional Persons -->

          <!-- Additional Persons -->
          <div v-if="booking.additionalPersons > 0" class="customer__general-info__row">
            <div class="overflow-ellipsis pr-4">
              +{{ booking.additionalPersons }} {{ $t('people_l') }}
            </div>
            <div>
              {{ formatPrice(booking.price * (booking.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1)) }}
            </div>
          </div>
          <!-- /Additional Persons -->

        </div>
        <!-- /General Info -->

        <!-- Booking Extras -->
        <div v-if="booking.extras.length > 0" class="customer__extras">
          <div v-for="extra in booking.extras" :key="extra.id" class="customer__extras__extra">

            <!-- Extra Name -->
            <div class="customer__extras__extra__name overflow-ellipsis mr-4">
              {{ extra.name }}
            </div>
            <!-- /Extra Name -->

            <!-- Quantity -->
            <adm-tag
              v-if="extra.quantity > 1"
              class="mr-4"
            >
              x {{ extra.quantity * (1 + booking.additionalPersons) }}
            </adm-tag>
            <!-- /Quantity -->

            <!-- Price -->
            <div class="customer__extras__extra__price">
              {{
                formatPrice(extra.price * extra.quantity * (extra.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1))
              }}
            </div>
            <!-- /Price -->

          </div>
        </div>
        <!-- /Booking Extras -->

        <!-- Booking Taxes -->
        <div class="customer__taxes">
          <div class="overflow-ellipsis">
            {{ $t('taxes') }}
          </div>
          <div class="customer__taxes__price">
            {{ formatPrice(calculatePriceDetailsTax(booking, appointmentProp)) }}
          </div>
        </div>
        <!-- /Booking Taxes -->

        <!-- Booking Deposit -->
        <div v-if="booking.deposit" class="customer__total">
          <div class="overflow-ellipsis">
            {{ $t('deposit_amount') }}
          </div>
          <div class="customer__deposit__price">
            {{ formatPrice(booking.deposit) }}
          </div>
        </div>
        <!-- /Booking Deposit -->

        <!-- Price Per Booking -->
        <div v-if="booking.recurringData">
          <div class="customer__recurring">
            <div class="overflow-ellipsis">
              {{ $t('price_per_appointment') }}
            </div>
            <div class="bold">
              {{ formatPrice(getCustomerTotal(booking)) }}
            </div>
          </div>
          <adm-badge>x{{ booking.recurringData.bookings.length }} {{ $t('recurring_appointments') }}</adm-badge>
        </div>
        <!-- /Price Per Booking -->

        <!-- Booking SubTotal -->
        <div v-if="booking.discount" class="customer__total">
          <div class="overflow-ellipsis">
            {{ $t('subtotal') }}
          </div>
          <div class="bold">
            {{ formatPrice(getCustomerTotalWithRecurring(booking)) }}
          </div>
        </div>
        <!-- /Booking SubTotal -->

        <!-- Discount -->
        <div v-if="booking.discount" class="customer__total">
          <div class="overflow-ellipsis">
            {{ $t('discount') }}
          </div>
          <div class="bold">
            - {{ formatPrice(getDiscountForBooking(booking)) }}
          </div>
        </div>
        <!-- /Discount -->

        <!-- Booking Total -->
        <div class="customer__total">
          <div class="overflow-ellipsis">
            {{ $t('total') }}
          </div>
          <div class="bold">
            {{ formatPrice(getCustomerTotalWithRecurring(booking) - getDiscountForBooking(booking)) }}
          </div>
        </div>
        <!-- /Booking Total -->

        <!-- Appointment First Payment -->
        <div v-if="booking.recurringData && isPaymentForFirstBookingOnly()" class="customer__total">
          <div>
            {{ $t('first_payment') }}
          </div>
          <div class="bold">
            {{ formatPrice(getFirstPaymentForBooking(booking)) }}
          </div>
        </div>
        <!-- /Appointment First Payment -->

      </div>
    </template>
    <!-- /Details -->

    <!-- Appointment Total Price -->
    <div class="price-details__total-price">
      <div class="description semi-bold">
        {{ $t('appointment_total_price') }}
      </div>
      <div class="price semi-bold">
        {{ getTotalPrice(appointmentProp) }}
      </div>
    </div>
    <!-- /Appointment Total Price -->

  </div>
  <!-- /Price Details -->

</template>

<script>
import AppointmentUtils from '@/utils/appointment'
import mixinPrice from '@/mixins/common/price'
import mixinTax from '@/mixins/common/tax'
import AdmTag from '@/views/_components/tag/AdmTag'
import AdmBadge from '@/views/_components/badge/AdmBadge.vue'

export default {
  name: 'PriceDetails',
  components: { AdmBadge, AdmTag },
  mixins: [
    mixinPrice,
    mixinTax,
  ],

  props: {
    appointmentProp: {
      type: Object,
      default: () => ({}),
      required: true
    },
    hidePriceDetails: {
      type: Function,
      default: () => {}
    },
  },

  methods: {
    getCustomerTotal (booking) {
      let customerTax = 0

      if (booking.bookingId && booking.taxData) {
        customerTax = booking.taxData.taxDisplaySettings === 'exclusive' ? this.calculatePriceDetailsTax(booking, this.appointmentProp) : 0
      } else {
        customerTax = this.$store.state.settings.payments.taxDisplaySettings === 'exclusive' ? this.calculatePriceDetailsTax(booking, this.appointmentProp) : 0
      }

      return booking.price * (booking.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1)
        + booking.extras.reduce((result, extra) => result + (extra.price * extra.quantity * (extra.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1)), 0)
        + customerTax
    },

    getDiscountForBooking (booking) {
      return AppointmentUtils.getDiscountForBooking(booking, this.appointmentProp.service)
    },

    getCustomerTotalWithRecurring (booking) {
      let price = this.getCustomerTotal(booking)
      if (booking.recurringData && booking.recurringData.bookings.length > 1) {
        price *= booking.recurringData.bookings.length
      }

      return price
    },

    getTotalPrice (appointment) {
      let totalPrice = AppointmentUtils.calculateAppointmentPrice(appointment)

      if (this.appointmentProp.id) {
        return this.formatPrice(totalPrice > 0 ? totalPrice : 0)
      }

      if (this.$store.state.settings.payments.taxDisplaySettings === 'exclusive') {
        for (let i = 0; i < this.appointmentProp.bookings.length; i++) {
          let booking = this.appointmentProp.bookings[i]
          let bookingPrice = AppointmentUtils.getPriceForBookingWithDiscount(booking)
          let taxPrice = this.calculatePriceDetailsTax(this.appointmentProp.bookings[i], this.appointmentProp)

          if ((bookingPrice + taxPrice) < 0) {
              continue
          }
          if (booking.recurringData && booking.recurringData.bookings.length > 1) {
            taxPrice *= booking.recurringData.bookings.length
          }
          totalPrice += taxPrice
        }
      }

      return this.formatPrice(totalPrice > 0 ? totalPrice : 0)
    },

    isPaymentForFirstBookingOnly () {
      return AppointmentUtils.isPaymentForFirstBookingOnly(this.appointmentProp.service)
    },

    getFirstPaymentForBooking (booking) {
      let priceWithDiscount = AppointmentUtils.getPriceForBookingWithDiscount(booking)
      if (this.$store.state.settings.payments.taxDisplaySettings === 'inclusive') {
          return priceWithDiscount
      }
      return priceWithDiscount + this.calculatePriceDetailsTax(booking, this.appointmentProp)
    }
  }
}
</script>

<style lang="scss" scoped>
.price-details {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin-bottom: 12px;

  @include phone-down {
    position: fixed;
    top: 56px;
    bottom: 66px;
    height: auto;
    left: 0;
    right: 0;
    padding: 20px;
    background-color: #fff;
    z-index: 3;
    overflow: auto;
  }

  &__title {
    margin-bottom: 25px;

    a {
      font-size: 18px;
      color: $shade-900;
    }
  }

  .customers-label {
    font-size: 12px;
    color: $shade-600;
    margin-bottom: 5px;
  }

  .customer-section {
    margin-bottom: 20px;

    .customer {
      &__general-info {
        border-bottom: 1px dashed $shade-300;
        padding: 2px 0;

        &__row {
          display: flex;
          justify-content: space-between;
        }
      }

      &__name {
        font-weight: 600;
      }

      &__extras {
        border-bottom: 2px solid $shade-300;

        &__extra {
          display: flex;
          align-items: center;
          padding: 8px 0;

          &__name {
            color: $shade-600;
          }

          &__price {
            margin: 0 0 0 auto;
          }
        }
      }

      &__taxes {
        padding: 8px 0 0 0;
        display: flex;
        justify-content: space-between;
      }

      &__recurring {
        padding-top: 8px;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        justify-content: space-between;
      }

      &__total {
        padding-top: 8px;
        display: flex;
        justify-content: space-between;
      }
    }
  }

  &__total-price {
    display: flex;
    justify-content: space-between;
    padding: 10px 12px;
    border-radius: 5px;
    background-color: var(--primary-300);
    margin: auto 0 0 0;

    .price {
      color: var(--primary-900);
    }
  }
}
</style>
