<template>

  <!-- Popover -->
  <div class="adm-popover-reference">
    <el-popover
      ref="elementPopover"
      v-bind="{...$attrs, ...$props}"
      :title="titleWithoutAttribute ? titleWithoutAttribute : title"
      :popper-class="'adm-popover' + (popperClass ? ' ' + popperClass : popperClass)"
      :width="width"
      :arrow-offset="arrowOffset"
      @show="$emit('show')"
      @after-enter="$emit('after-enter')"
      @hide="$emit('hide')"
      @after-leave="$emit('after-leave')"
    >

      <!-- Content -->
      <div>
        <adm-button
          v-if="showClose"
          class="adm-popover__close"
          size="micro"
          type="borderless"
          color="dark"
          icon-start="close"
          icon-only
          @click="$emit('close')"
        />
        <slot />
      </div>
      <!-- /Content -->

      <!-- Reference -->
      <slot slot="reference" name="reference" />
      <!-- /Reference -->

    </el-popover>
  </div>
  <!-- /Popover -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'

export default {
  name: 'AdmPopover',

  components: {
    AdmButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    titleWithoutAttribute: {
      type: String,
      default: ''
    },
    popperClass: {
      type: String,
      default: ''
    },
    trigger: {
      type: String,
      default: 'click'
    },
    showClose: {
      type: Boolean,
      default: false
    },
    visibleArrow: {
      type: Boolean,
      default: true
    },
    width: {
      type: Number,
      default: null
    },
    arrowOffset: {
      type: Number,
      default: 0
    },
  }
}
</script>

<style lang="scss">
// Popover
.adm-popover {
  border-radius: 8px;
  filter: drop-shadow(0px 11px 16px rgba(0, 13, 39, 0.1)) drop-shadow(0px 2px 2px rgba(0, 20, 60, 0.17));
  padding: 16px;
  font-size: 14px;
  line-height: 20px;
  color: $shade-800;

  // Title
  .el-popover__title {
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
  }

  // Close
  &__close {
    position: absolute;
    top: 4px;
    right: 4px;
  }
}
</style>
