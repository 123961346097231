export default {
  data () {
    return {
      // TODO - Change statuses to be string instead of integer
      appointmentStatuses: [
        {
          value: 1,
          stringValue: 'approved',
          class: 'approved',
          icon: 'approved',
          label: this.$t('approved'),
          color: this.$colors['green-900']
        },
        {
          value: 2,
          stringValue: 'pending',
          class: 'pending',
          icon: 'pending-status',
          label: this.$t('pending'),
          color: this.$colors['yellow-900']
        },
        {
          value: 3,
          stringValue: 'canceled',
          class: 'canceled',
          icon: 'cancelled',
          label: this.$t('canceled'),
          color: this.$colors['shade-800']
        },
        {
          value: 4,
          stringValue: 'rejected',
          class: 'rejected',
          icon: 'rejected',
          label: this.$t('rejected'),
          color: this.$colors['red-900']
        },
        {
          value: 5,
          stringValue: 'no_show',
          class: 'no_show',
          icon: 'no-show',
          label: this.$t('no_show'),
          color: this.$colors['shade-500']
        }
      ],
      paymentStatuses: [
        {
          value: 1,
          class: 'paid',
          label: this.$t('paid')
        },
        {
          value: 2,
          class: 'pending',
          label: this.$t('pending')
        }
      ],
      invoiceStatuses: [
        {
          value: 1,
          class: 'paid',
          label: this.$t('paid')

        },
        {
          value: 2,
          class: 'pending',
          label: this.$t('pending')
        },
        {
          value: 3,
          class: 'archived',
          label: this.$t('archived')
        }
      ],
      smsNotificationsStatuses: [
        {
          value: 'prepared',
          class: 'prepared',
          label: this.$t('prepared')

        },
        {
          value: 'delivered',
          class: 'delivered',
          label: this.$t('delivered')
        },
        {
          value: 'undelivered',
          class: 'undelivered',
          label: this.$t('undelivered')
        },
        {
          value: 'failed',
          class: 'failed',
          label: this.$t('failed')
        }
      ],
      ratingReviewStatuses: [
        {
          value: 1,
          stringValue: 'approved',
          label: this.$t('approved'),
        },
        {
          value: 2,
          stringValue: 'pending',
          label: this.$t('pending'),
        },
        {
          value: 3,
          stringValue: 'rejected',
          label: this.$t('rejected'),
        }
      ]
    }
  }
}
