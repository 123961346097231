
const getDefaultState = () => {
  return {
    isSurveyVisible: false,
    showSurveyButton: false,
    progress: 0,
    currentStep: 0,
    showSurvey: false,
  }
}
const state = getDefaultState()

const mutations = {
  setSurveyOpenOrClosed(state, payload) {
    state.isSurveyVisible = payload
  },

  incrementStep () {
    state.currentStep ++;
  },

  decrementStep () {
    if(state.currentStep > 0) {
      state.currentStep --;
    }
  },

  setShowSurvey (state, payload) {
    state.showSurvey = payload
  },

  hideSurveyButton () {
    state.showSurvey = false
  }
}

export default {
  namespaced: true,
  state,
  mutations
}
