import EmailUnsubscribe from '@/views/EmailUnsubscribe/EmailUnsubscribe'

export default [
  {
    path: '/unsubscribe',
    name: 'unsubscribe',
    component: EmailUnsubscribe,
    meta: {
      title: 'unsubscribe',
      shared: true,
    }
  },
  {
    path: '/remote/login',
    name: 'impersonate',
    meta: {
      title: 'login',
      shared: true,
    },
    component: () => import(/* webpackChunkName: "impersonate" */ '@/views/Authentication/Impersonate/Impersonate').then(value => value.default)
  }
]
