<template>
  <div
    :class="[
      'main-nav-bar-mobile__more-item',
      {
        'active': isActive(),
        'is-red': red,
        'is-disabled': disabled
      }
    ]"
  >
    <router-link
      :to="{ name: routeName }"
      @click.native.stop="$emit('drawer-item-clicked', action)"
    >
      <i v-if="icon" :class="'tz-' + icon" />
      <span>{{ label }}</span>
    </router-link>
  </div>
  <!-- /Main Navigation Item -->

</template>

<script>
export default {
  name: 'AdmDrawerItemMobile',

  props: {
    label: {
      type: [String, Function],
      required: true
    },
    routeName: {
      type: String,
      default: null
    },
    icon: {
      type: String,
      default: null
    },
    action: {
      type: Function,
      default: () => {}
    },
    red: Boolean,
    disabled: Boolean
  },

  methods: {
    isActive () {
      if (this.routeName && this.routeName === this.$route.name) {
        return true
      }

      return this.$route.path.split('/')[1] === this.routeName
    },
  }
}
</script>

<style lang="scss">
.main-nav-bar-mobile__more-item {
  a {
    width: 100%;
    height: 48px;
    display: flex;
    align-items: center;
    text-decoration: none;

    span {
      color: $shade-900;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
      margin-left: 10px;
      border-bottom: 1px solid $shade-300;
    }

    i:before {
      font-size: 20px;
      color: $shade-800;
    }
  }

  &.active {
    span, i:before {
      color: var(--primary-900);
    }
  }

  &.is-red {
    span, i:before {
      color: $red-900;
    }
  }

  &.is-disabled {
    span, i:before {
      color: $shade-600;
    }
  }
}
</style>
