var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adm-input-wrapper",style:({ maxWidth: _vm.maxWidth })},[_c('el-input',_vm._b({ref:"elInput",class:[
      _vm.type === 'textarea' ? 'adm-textarea' : 'adm-input',
      _vm.size ? (_vm.type === 'textarea' ? 'adm-textarea' : 'adm-input') + '__size__' + _vm.size : '',
      {
        'is-icon-start': _vm.iconStart || _vm.$slots.prefix,
        'is-icon-end': _vm.iconEnd || _vm.$slots.suffix,
      }
    ],attrs:{"id":_vm.id,"autocomplete":_vm.autocomplete},on:{"blur":(e) => _vm.$emit('blur', e),"focus":(e) => _vm.$emit('focus', e),"change":(eventValue) => _vm.$emit('change', eventValue),"input":(eventValue) => _vm.$emit('input', eventValue)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'el-input',{..._vm.$attrs, ..._vm.$props},false),[(_vm.$slots.prefix || _vm.iconStart)?_c('template',{slot:"prefix"},[_vm._t("prefix",function(){return [_c('i',{class:'tz-' + _vm.iconStart})]})],2):_vm._e(),(_vm.$slots.suffix || _vm.iconEnd)?_c('template',{slot:"suffix"},[_vm._t("suffix",function(){return [(_vm.iconHandler)?_c('i',{class:['tz-' + _vm.iconEnd, 'with-handler' ],on:{"click":() => _vm.iconHandler()}}):_c('i',{class:'tz-' + _vm.iconEnd})]})],2):_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }