export default [
  {
    path: 'customers',
    name: 'customers',
    meta: {
      title: 'customers',
      requiresAuth: true,
      requiredPermissions: 'read_customer',
    },
    component: () => import(/* webpackChunkName: "customers" */ '@/views/Customers/CustomersPage/CustomersPage').then(value => value.default)
  },
  {
    path: 'customers/:customerId(\\d+)',
    name: 'customers-edit',
    meta: {
      title: 'customers',
      requiresAuth: true,
      requiredPermissions: 'edit_customer',
    },
    component: () => import(/* webpackChunkName: "customers-manage" */ '@/views/Customers/ManageCustomer/EditCustomer.vue').then(value => value.default)
  }
]
