<template>

  <!-- Design System Date Picker -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Input
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
      description="Basic date picker measured by 'day'."
      code-description="The measurement is determined by the type attribute. You can enable quick options by creating a picker-options object with shortcuts property. The disabled date is set by disabledDate, which is a function."
    >

      <!-- Preview -->
      <template #preview>
        <adm-date-picker
          v-model="basicUsage.first"
          max-width="200px"
        />
        <adm-date-picker
          v-model="basicUsage.second"
          class="ml-16"
          :picker-options="basicUsagePickerOptions"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getDatePickerCode({ 'v-model': 'datepicker' }) }}
        <br>
        {{ getDatePickerCode({ 'v-model': 'datepicker', 'picker-options': 'pickerOptions' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Other measurements -->
    <design-system-element-block
      title="Other measurements"
      description="You can choose week, month, year or multiple dates by extending the standard date picker component."
    >

      <!-- Preview -->
      <template #preview>
        <adm-date-picker
          v-model="otherMeasurements.week"
          type="week"
          format="Week WW"
          max-width="200px"
          placeholder="Week"
        />
        <adm-date-picker
          v-model="otherMeasurements.month"
          class="ml-16"
          type="month"
          placeholder="Month"
          max-width="200px"
        />
        <adm-date-picker
          v-model="otherMeasurements.year"
          class="ml-16"
          type="year"
          placeholder="Year"
          max-width="200px"
        />
        <adm-date-picker
          v-model="otherMeasurements.dates"
          class="ml-16"
          type="dates"
          placeholder="Dates"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getDatePickerCode({ 'v-model': 'datepicker', type: 'week', format: 'Week WW', placeholder: 'week' }) }}
        <br>
        {{ getDatePickerCode({ 'v-model': 'datepicker', type: 'month', placeholder: 'Month' }) }}
        <br>
        {{ getDatePickerCode({ 'v-model': 'datepicker', type: 'year', placeholder: 'Year' }) }}
        <br>
        {{ getDatePickerCode({ 'v-model': 'datepicker', type: 'dates', placeholder: 'Dates' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Other measurements -->

    <!-- Date range -->
    <design-system-element-block
      title="Date range"
      description="Picking a date range is supported."
      code-description="When in range mode, the left and right panels are linked by default. If you want the two panels to switch current months independently, you can use the unlink-panels attribute."
    >

      <!-- Preview -->
      <template #preview>
        <adm-date-picker
          v-model="dateRange.first"
          type="daterange"
          max-width="300px"
        />
        <adm-date-picker
          v-model="dateRange.second"
          class="ml-16"
          type="daterange"
          :picker-options="dateRangePickerOptions"
          max-width="300px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getDatePickerCode({ 'v-model': 'datepicker', type: 'daterange' }) }}
        <br>
        {{ getDatePickerCode({ 'v-model': 'datepicker', type: 'daterange', 'picker-options': 'pickerOptions' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Date range -->

    <!-- Month range -->
    <design-system-element-block
      title="Month range"
      description="Picking a date range is supported."
      code-description="When in range mode, the left and right panels are linked by default. If you want the two panels to switch current years independently, you can use the unlink-panels attribute."
    >

      <!-- Preview -->
      <template #preview>
        <adm-date-picker
          v-model="monthRange.first"
          type="monthrange"
          max-width="300px"
        />
        <adm-date-picker
          v-model="monthRange.second"
          class="ml-16"
          type="monthrange"
          :picker-options="monthRangePickerOptions"
          max-width="300px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getDatePickerCode({ 'v-model': 'monthrange', type: 'daterange' }) }}
        <br>
        {{ getDatePickerCode({ 'v-model': 'monthrange', type: 'daterange', 'picker-options': 'pickerOptions' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Month range -->

    <!-- Date Picker Attributes -->
    <design-system-documentation-table :table-data="tableData" />
    <!-- /Date Picker Attributes -->

    <!-- Formats -->
    <design-system-documentation-table
      :table-data="tableFormats"
      type="formats"
      title="Formats"
    >
      <span>
        Use format to control displayed text's format in the input box. Use value-format to control binding value's format.
      </span>
      <adm-alert class="mt-8" title="Pay attention to capitalization" type="default" />
    </design-system-documentation-table>
    <!-- /Formats -->

    <!-- Picker Options Attributes -->
    <design-system-documentation-table :table-data="tableDataPickerOptions" title="Picker options" />
    <!-- /Picker Options Attributes -->

    <!-- Shortcuts Attributes -->
    <design-system-documentation-table :table-data="tableDataShortcuts" title="Shortcuts" />
    <!-- /Shortcuts Attributes -->

    <!-- Date Picker Methods -->
    <design-system-documentation-table :table-data="tableDataMethods" type="events" title="Methods" />
    <!-- /Date Picker Methods -->

    <!-- Date Picker Events -->
    <design-system-documentation-table :table-data="tableDataEvents" type="events" title="Events" />
    <!-- /Date Picker Events -->

  </div>
  <!-- /Design System Date Picker -->

</template>

<script>
import AdmDatePicker from '@/views/_components/datePicker/AdmDatePicker'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'
import AdmAlert from '@/views/_components/alert/AdmAlert'

export default {
  name: 'DesignSystemDatePicker',

  components: {
    AdmAlert,
    AdmDatePicker,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: {
        first: '',
        second: '',
      },
      otherMeasurements: {
        week: '',
        month: '',
        year: '',
        dates: null
      },
      dateRange: {
        first: '',
        second: '',
      },
      monthRange: {
        first: '',
        second: '',
      },
      basicUsagePickerOptions: {
        disabledDate (time) {
          return time.getTime() > Date.now()
        },
        shortcuts: [{
          text: 'Today',
          onClick (picker) {
            picker.$emit('pick', new Date())
          }
        }, {
          text: 'Yesterday',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24)
            picker.$emit('pick', date)
          }
        }, {
          text: 'A week ago',
          onClick (picker) {
            const date = new Date()
            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', date)
          }
        }]
      },
      dateRangePickerOptions: {
        shortcuts: [{
          text: 'Last week',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last month',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last 3 months',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      monthRangePickerOptions: {
        shortcuts: [{
          text: 'This month',
          onClick (picker) {
            picker.$emit('pick', [new Date(), new Date()])
          }
        }, {
          text: 'This year',
          onClick (picker) {
            const end = new Date()
            const start = new Date(new Date().getFullYear(), 0)
            picker.$emit('pick', [start, end])
          }
        }, {
          text: 'Last 6 months',
          onClick (picker) {
            const end = new Date()
            const start = new Date()
            start.setMonth(start.getMonth() - 6)
            picker.$emit('pick', [start, end])
          }
        }]
      },
      tableFormats: [
        {
          format: 'yyyy',
          meaning: 'year',
          note: '-',
          example: '2017',
        },
        {
          format: 'M',
          meaning: 'month',
          note: 'no leading 0',
          example: '1',
        },
        {
          format: 'MM',
          meaning: 'month',
          note: '',
          example: '01',
        },
        {
          format: 'MMM',
          meaning: 'month',
          note: '',
          example: 'Jan',
        },
        {
          format: 'MMMM',
          meaning: 'month',
          note: '',
          example: 'January',
        },
        {
          format: 'W',
          meaning: 'week',
          note: 'only for week picker\'s format; no leading 0',
          example: '1',
        },
        {
          format: 'WW',
          meaning: 'week',
          note: 'only for week picker\'s format',
          example: '01',
        },
        {
          format: 'd',
          meaning: 'day',
          note: 'no leading 0',
          example: '2',
        },
        {
          format: 'dd',
          meaning: 'day',
          note: '-',
          example: '02',
        },
        {
          format: 'H',
          meaning: 'hour',
          note: '24-hour clock; no leading 0',
          example: '3',
        },
        {
          format: 'HH',
          meaning: 'hour',
          note: '24-hour clock',
          example: '03',
        },
        {
          format: 'h',
          meaning: 'hour',
          note: '12-hour clock; must be used with A or a; no leading 0',
          example: '3',
        },
        {
          format: 'hh',
          meaning: 'hour',
          note: '12-hour clock; must be used with A or a',
          example: '03',
        },
        {
          format: 'm',
          meaning: 'minute',
          note: 'no leading 0',
          example: '4',
        },
        {
          format: 'mm',
          meaning: 'minute',
          note: '-',
          example: '04',
        },
        {
          format: 's',
          meaning: 'second',
          note: 'no leading 0',
          example: '5',
        },
        {
          format: 'ss',
          meaning: 'second',
          note: '-',
          example: '05',
        },
        {
          format: 'A',
          meaning: 'AM/PM',
          note: 'only for format, uppercased',
          example: 'AM',
        },
        {
          format: 'a',
          meaning: 'am/pm',
          note: 'only for format, lowercased',
          example: 'am',
        },
        {
          format: 'timestamp',
          meaning: 'JS timestamp',
          note: 'only for value-format; binding value will be a number',
          example: '1483326245000',
        },
        {
          format: '[MM]',
          meaning: 'No escape characters',
          note: 'To escape characters, wrap them in square brackets (e.g. [A] [MM])',
          example: 'MM',
        },
      ],
      tableData: [
        {
          attribute: 'value / v-model',
          description: 'binding value',
          type: 'date(DatePicker) / array(DateRangePicker)',
          values: '-',
          default: '-',
        },
        {
          attribute: 'readonly',
          description: 'whether DatePicker is read only',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'disabled',
          description: 'whether input is disabled',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'size',
          description: 'size of Input',
          type: 'string',
          values: 'default / medium / small',
          default: 'default',
        },
        {
          attribute: 'type',
          description: 'type of the picker',
          type: 'string',
          values: 'year / month / date / dates / datetime / week / datetimerange / daterange / monthrange',
          default: 'date',
        },
        {
          attribute: 'clearable',
          description: 'whether to show clear button',
          type: 'boolean',
          values: 'true / false',
          default: 'true',
        },
        {
          attribute: 'placeholder',
          description: 'placeholder in non-range mode',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'start-placeholder',
          description: 'placeholder for the start date in range mode',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'end-placeholder',
          description: 'placeholder for the end date in range mode',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'format',
          description: 'format of the displayed value in the input box',
          type: 'string',
          values: 'see date formats',
          default: 'yyyy-MM-dd',
        },
        {
          attribute: 'align',
          description: 'alignment',
          type: 'string',
          values: 'left / center / right',
          default: 'left',
        },
        {
          attribute: 'popper-class',
          description: 'custom class name for DatePicker\'s dropdown',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'picker-options',
          description: 'additional options, check the table below',
          type: 'object',
          values: '-',
          default: '{}',
        },
        {
          attribute: 'range-separator',
          description: 'range separator',
          type: 'string',
          values: '-',
          default: '\'-\'',
        },
        {
          attribute: 'default-time',
          description: 'optional, the time value to use when selecting date range',
          type: 'string[]',
          values: 'Array with length 2, each item is a string like 12:00:00. The first item for the start date and then second item for the end date.',
          default: '-',
        },
        {
          attribute: 'value-format',
          description: 'optional, format of binding value. If not specified, the binding value will be a Date object',
          type: 'string',
          values: 'see date formats',
          default: 'yyyy-MM-dd',
        },
        {
          attribute: 'name',
          description: 'same as name in native input',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'unlink-panels',
          description: 'unlink two date-panels in range-picker',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'validate-event',
          description: 'whether to trigger form validation',
          type: 'boolean',
          values: 'true / false',
          default: 'true',
        },
      ],
      tableDataPickerOptions: [
        {
          attribute: 'shortcuts',
          description: 'a { text, onClick } object array to set shortcut options, check the table below',
          type: 'object[]',
          values: '-',
          default: '-',
        },
        {
          attribute: 'disabledDate',
          description: 'a function determining if a date is disabled with that date as its parameter. Should return a Boolean.',
          type: 'function',
          values: '-',
          default: '-',
        },
        {
          attribute: 'cellClassName',
          description: 'set custom className',
          type: 'Function(Date)',
          values: '-',
          default: '-',
        },
        {
          attribute: 'firstDayOfWeek',
          description: 'first day of week',
          type: 'Number',
          values: '1 to 7',
          default: '7',
        },
        {
          attribute: 'onPick',
          description: ' callback that triggers when the selected date is changed. Only for daterange and datetimerange.',
          type: 'Function({ maxDate, minDate })',
          values: '-',
          default: '-',
        },
      ],
      tableDataShortcuts: [
        {
          attribute: 'text',
          description: 'title of the shortcut',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'onClick',
          description: 'callback function, triggers when the shortcut is clicked, with the vm as its parameter. You can change the picker value by emitting the pick event. Example: vm.$emit(\'pick\', new Date()).',
          type: 'function',
          values: '-',
          default: '-',
        },
      ],
      tableDataMethods: [
        {
          name: 'focus',
          description: 'focus the Input component',
          parameters: '-'
        },
      ],
      tableDataEvents: [
        {
          name: 'change',
          description: 'triggers when user confirms the value',
          parameters: 'component\'s binding value'
        },
        {
          name: 'blur',
          description: 'triggers when Input blurs',
          parameters: 'component instance'
        },
        {
          name: 'focus',
          description: 'triggers when Input focuses',
          parameters: 'component instance'
        },
      ],
    }
  },
}
</script>

