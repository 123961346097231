const getDefaultState = () => {
  return {
    isExpanded: true
  }
}

const state = getDefaultState()

const getters = {}

const mutations = {
  toggleMenu (state) {
    state.isExpanded = !state.isExpanded
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
