<template>

  <!-- Design System Input Message -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Message
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
      description="Displays at the top, and disappears after 3 seconds."
      class="design-buttons-wrap"
    >

      <!-- Preview -->
      <template #preview>
        <adm-button color="grey" @click="showMessage({ message: 'This is a message.', showClose: true, duration: 400000400})">
          Show message
        </adm-button>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Types -->
    <design-system-element-block
      title="Types"
      description="Used to show the feedback of Success, Warning, Info and Error activities."
      class="design-buttons-wrap"
    >

      <!-- Preview -->
      <template #preview>
        <adm-button
          color="grey"
          @click="showMessage({ message: 'Congrats, this is a success message.', type: 'success', showClose: true, duration: 9999999 })"
        >
          Success
        </adm-button>
        <adm-button
          color="grey"
          @click="showMessage({ message: 'Warning, this is a warning message.', type: 'warning', showClose: true })"
        >
          Warning
        </adm-button>
        <adm-button color="grey" @click="showMessage({ message: 'This is a message.', showClose: true })">
          Info
        </adm-button>
        <adm-button
          color="grey"
          @click="showMessage({ message: 'Oops, this is a error message.', type: 'error', showClose: true })"
        >
          Error
        </adm-button>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Types -->

    <!-- Input Number Attributes -->
    <design-system-documentation-table :table-data="tableData" />
    <!-- /Input Number Attributes -->

  </div>
  <!-- /Design System Input Message -->

</template>

<script>
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'
import AdmButton from '@/views/_components/button/AdmButton'

export default {
  name: 'DesignSystemInputCurrency',

  components: {
    AdmButton,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: 0,
      tableData: []
    }
  },

  methods: {
    showMessage (messageOptions) {
      this.$message(messageOptions)
    }
  }
}
</script>
<style lang="scss">
.design-buttons-wrap {
  .adm-button {
    margin-left: 12px;
  }
}
</style>
