<template>

  <!-- Design System Select -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Select
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
      code-description="v-model is the value of adm-option that is currently selected."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select
          v-model="basicUsage"
          max-width="200px"
        >
          <adm-option
            v-for="item in defaultOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Sizes -->
    <design-system-element-block
      title="Sizes"
      description="Add size attribute to change the size of Select. In addition to the default size, there are two other options: medium and small."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select
          v-model="sizes.default"
          max-width="200px"
        >
          <adm-option
            v-for="item in defaultOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </adm-select>
        <adm-select
          v-model="sizes.medium"
          class="ml-16"
          size="medium"
          max-width="200px"
        >
          <adm-option
            v-for="item in defaultOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </adm-select>
        <adm-select
          v-model="sizes.small"
          class="ml-16"
          size="small"
          max-width="200px"
        >
          <adm-option
            v-for="item in defaultOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Sizes -->

    <!-- Select with icon -->
    <design-system-element-block
      title="Select with icon"
      description="Add an icon to indicate select type."
      code-description="To add icons in Input, you can simply use icon-start attribute."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select
          v-model="selectWithIcon"
          icon-start="user"
          max-width="200px"
        >
          <adm-option
            v-for="item in defaultOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Select with icon -->

    <!-- Disabled option -->
    <design-system-element-block
      title="Disabled option"
      code-description="Set the value of disabled in adm-option to true to disable this option."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select
          v-model="disabledOption"
          max-width="200px"
        >
          <adm-option
            v-for="item in disabledOptionOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          />
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Disabled option -->

    <!-- Disabled select -->
    <design-system-element-block
      title="Disabled select"
      description="Disable the whole component."
      code-description="Set disabled of adm-select to make it disabled."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select
          v-model="disabledSelect"
          max-width="200px"
          :disabled="true"
          placeholder="Select something"
        >
          <adm-option
            v-for="item in defaultOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          />
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Disabled select -->

    <!-- Clearable single select -->
    <design-system-element-block
      title="Clearable single select"
      description="You can clear Select using a clear icon."
      code-description="Set clearable attribute for adm-select and a clear icon will appear. Note that clearable is only for single select."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select
          v-model="clearableSingleSelect"
          max-width="200px"
          :clearable="true"
        >
          <adm-option
            v-for="item in defaultOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          />
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Clearable single select -->

    <!-- Basic multiple select -->
    <design-system-element-block
      title="Basic multiple select"
      description="Multiple select uses tags to display selected options."
      code-description="Set multiple attribute for adm-select to enable multiple mode. In this case, the value of v-model will be an array of selected options. By default the selected options will be displayed as Tags. You can collapse them to a text by using collapse-tags attribute."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select
          v-model="basicMultipleSelect"
          max-width="200px"
          :multiple="true"
        >
          <adm-option
            v-for="item in defaultOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          />
        </adm-select>
        <adm-select
          v-model="basicMultipleSelectCollapse"
          class="ml-16"
          max-width="200px"
          :multiple="true"
          :collapse-tags="false"
        >
          <adm-option
            v-for="item in defaultOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
            :disabled="item.disabled"
          />
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic multiple select -->

    <!-- Custom template -->
    <design-system-element-block
      title="Custom template"
      description="You can customize HTML templates for options."
      code-description="Insert customized HTML templates into the slot of adm-option."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select
          v-model="customTemplate"
          max-width="200px"
        >
          <adm-option
            v-for="item in customTemplateOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
            <span>{{ item.label }}</span>
            <span style="margin-left: auto; color: #8492a6; font-size: 13px">{{ item.value }}</span>
          </adm-option>
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Custom template -->

    <!-- Grouping -->
    <design-system-element-block
      title="Grouping"
      description="Display options in groups."
      code-description="Use adm-option-group to group the options, and its label attribute stands for the name of the group."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select v-model="grouping" max-width="200px" :filterable="true">
          <adm-option-group
            v-for="group in groupOptions"
            :key="group.label"
            :label="group.label"
          >
            <el-option
              v-for="item in group.options"
              :key="item.value"
              class="adm-select-option"
              :label="item.label"
              :value="item.value"
            />
          </adm-option-group>
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Grouping -->

    <!-- Option filtering -->
    <design-system-element-block
      title="Option filtering"
      description="You can filter options for your desired ones."
      code-description="Adding filterable to adm-select enables filtering. By default, Select will find all the options whose label attribute contains the input value. If you prefer other filtering strategies, you can pass the filter-method. filter-method is a Function that gets called when the input value changes, and its parameter is the current input value."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select
          v-model="optionFiltering"
          :filterable="true"
          max-width="200px"
        >
          <adm-option
            v-for="item in defaultOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Option filtering -->

    <!-- Remote search -->
    <design-system-element-block
      title="Remote search"
      description="Enter keywords and search data from server."
      code-description="Set the value of filterable and remote with true to enable remote search, and you should pass the remote-method. remote-method is a Function that gets called when the input value changes, and its parameter is the current input value. Note that if adm-option is rendered with the v-for directive, you should add the key attribute for adm-option. Its value needs to be unique, such as item.value in the following example."
    >

      <!-- Preview -->
      <template #preview>
        <adm-select
          v-model="remoteSearch"
          multiple
          filterable
          remote
          reserve-keyword
          placeholder="Please enter a keyword"
          :remote-method="remoteMethod"
          :loading="remoteSearchLoading"
          max-width="300px"
        >
          <adm-option
            v-for="item in remoteSearchOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </adm-select>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Remote search -->

    <!-- Info -->
    <adm-alert
      type="positive"
      title="If the binding value of Select is an object, make sure to assign value-key as its unique identity key name."
      class="mb-16"
    />
    <!-- /Info -->

    <!-- Select Attributes -->
    <design-system-documentation-table :table-data="tableData" title="Select attributes" />
    <!-- /Select Attributes -->

    <!-- Select Methods -->
    <design-system-documentation-table :table-data="tableDataMethods" type="events" title="Methods" />
    <!-- /Select Methods -->

    <!-- Select Events -->
    <design-system-documentation-table :table-data="tableDataEvents" type="events" title="Select events" />
    <!-- /Select Events -->

    <!-- Option Group Attributes -->
    <design-system-documentation-table :table-data="optionGroupAttributes" title="Option group attributes" />
    <!-- /Option Group Attributes -->

  </div>
  <!-- /Design System Select -->

</template>

<script>
import AdmOption from '@/views/_components/select/AdmOption'
import AdmSelect from '@/views/_components/select/AdmSelect'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'
import AdmOptionGroup from '@/views/_components/select/AdmOptionGroup'
import AdmAlert from '@/views/_components/alert/AdmAlert'

export default {
  name: 'DesignSystemSelect',

  components: {
    AdmAlert,
    AdmOptionGroup,
    AdmOption,
    AdmSelect,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: '',
      sizes: {
        default: '',
        medium: '',
        small: ''
      },
      selectWithIcon: '',
      disabledOption: '',
      disabledSelect: '',
      clearableSingleSelect: '',
      basicMultipleSelect: [],
      customTemplate: '',
      basicMultipleSelectCollapse: [],
      grouping: '',
      optionFiltering: '',
      remoteSearch: '',
      remoteSearchOptions: [],
      remoteSearchList: [],
      remoteSearchLoading: false,
      defaultOptions: [
        {
          value: 1,
          label: 'Option 1'
        },
        {
          value: 2,
          label: 'Option 2'
        },
        {
          value: 3,
          label: 'Option 3'
        },
        {
          value: 4,
          label: 'Option 4'
        },
        {
          value: 5,
          label: 'Option 5'
        }
      ],
      disabledOptionOptions: [
        {
          value: 1,
          label: 'Option 1',
          disabled: false
        },
        {
          value: 2,
          label: 'Option 2',
          disabled: true
        },
        {
          value: 3,
          label: 'Option 3',
          disabled: false
        },
        {
          value: 4,
          label: 'Option 4',
          disabled: false
        },
        {
          value: 5,
          label: 'Option 5',
          disabled: false
        }
      ],
      customTemplateOptions: [
        {
          value: 'Beijing',
          label: 'Beijing'
        },
        {
          value: 'Shanghai',
          label: 'Shanghai'
        },
        {
          value: 'Nanjing',
          label: 'Nanjing'
        },
        {
          value: 'Chengdu',
          label: 'Chengdu'
        },
        {
          value: 'Shenzhen',
          label: 'Shenzhen'
        },
        {
          value: 'Guangzhou',
          label: 'Guangzhou'
        }
      ],
      states: [
        'Alabama', 'Alaska', 'Arizona',
        'Arkansas', 'California', 'Colorado',
        'Connecticut', 'Delaware', 'Florida',
        'Georgia', 'Hawaii', 'Idaho', 'Illinois',
        'Indiana', 'Iowa', 'Kansas', 'Kentucky',
        'Louisiana', 'Maine', 'Maryland',
        'Massachusetts', 'Michigan', 'Minnesota',
        'Mississippi', 'Missouri', 'Montana',
        'Nebraska', 'Nevada', 'New Hampshire',
        'New Jersey', 'New Mexico', 'New York',
        'North Carolina', 'North Dakota', 'Ohio',
        'Oklahoma', 'Oregon', 'Pennsylvania',
        'Rhode Island', 'South Carolina',
        'South Dakota', 'Tennessee', 'Texas',
        'Utah', 'Vermont', 'Virginia',
        'Washington', 'West Virginia', 'Wisconsin',
        'Wyoming'
      ],
      groupOptions: [
        {
          label: 'Popular cities',
          options: [
            {
              value: 'Shanghai',
              label: 'Shanghai'
            },
            {
              value: 'Beijing',
              label: 'Beijing'
            }
          ]
        },
        {
          label: 'City name',
          options: [
            {
              value: 'Chengdu',
              label: 'Chengdu'
            },
            {
              value: 'Shenzhen',
              label: 'Shenzhen'
            },
            {
              value: 'Guangzhou',
              label: 'Guangzhou'
            },
            {
              value: 'Dalian',
              label: 'Dalian'
            }
          ]
        }
      ],
      tableData: [
        {
          attribute: 'value / v-model',
          description: 'binding value',
          type: 'boolean / string / number / array / object',
          values: '-',
          default: '-',
        },
        {
          attribute: 'multiple',
          description: 'whether multiple-select is activated',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'disabled',
          description: 'whether Select is disabled',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'value-key',
          description: 'unique identity key name for value, required when value is an object',
          type: 'string',
          values: '-',
          default: 'value',
        },
        {
          attribute: 'size',
          description: 'size of Input',
          type: 'string',
          values: 'default / medium / small',
          default: '-',
        },
        {
          attribute: 'clearable',
          description: 'whether select can be cleared',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'collapse-tags',
          description: 'whether to collapse tags to a text when multiple selecting',
          type: 'boolean',
          values: '-',
          default: 'true',
        },
        {
          attribute: 'multiple-limit',
          description: 'maximum number of options user can select when multiple is true. No limit when set to 0',
          type: 'number',
          values: '-',
          default: '0',
        },
        {
          attribute: 'name',
          description: 'the name attribute of select input',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'autocomplete',
          description: 'the autocomplete attribute of select input',
          type: 'string',
          values: '-',
          default: 'off',
        },
        {
          attribute: 'placeholder',
          description: 'placeholder',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'filterable',
          description: 'whether Select is filterable. When used with adm-option-group use el-option component with class "adm-select-option" instead of adm-option component.',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'allow-create',
          description: 'whether creating new items is allowed. To use this, filterable must be true',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'filter-method',
          description: 'custom filter method',
          type: 'function',
          values: '-',
          default: '-',
        },
        {
          attribute: 'remote',
          description: 'whether options are loaded from server',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'remote-method',
          description: 'custom remote search method',
          type: 'function',
          values: '-',
          default: '-',
        },
        {
          attribute: 'loading',
          description: 'whether Select is loading data from server',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'no-match-text',
          description: 'displayed text when no data matches the filtering query, you can also use slot empty',
          type: 'string',
          values: '-',
          default: 'No matching data',
        },
        {
          attribute: 'no-data-text',
          description: 'displayed text when there is no options, you can also use slot empty',
          type: 'string',
          values: '-',
          default: 'No data',
        },
        {
          attribute: 'popper-class',
          description: 'custom class name for Select\'s dropdown',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'reserve-keyword',
          description: 'when multiple and filter is true, whether to reserve current keyword after selecting an option',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'default-first-option',
          description: 'select first matching option on enter key. Use with filterable or remote',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'popper-append-to-body',
          description: 'whether to append the popper menu to body. If the positioning of the popper is wrong, you can try to set this prop to false',
          type: 'boolean',
          values: '-',
          default: 'true',
        },
        {
          attribute: 'automatic-dropdown',
          description: 'for non-filterable Select, this prop decides if the option menu pops up when the input is focused',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'icon-start',
          description: 'start icon',
          type: 'string',
          values: 'all available icons',
          default: '-',
        },
        {
          attribute: 'icon-color',
          description: 'start icon',
          type: 'string',
          values: 'all available icons',
          default: '-',
        },
      ],
      tableDataMethods: [
        {
          name: 'focus',
          description: 'focus the Input component',
          parameters: '-'
        },
        {
          name: 'blur',
          description: 'blur the Input component, and hide the dropdown',
          parameters: '-'
        },
      ],
      tableDataEvents: [
        {
          name: 'change',
          description: 'triggers when the selected value changes',
          parameters: 'current selected value'
        },
        {
          name: 'visible-change',
          description: 'triggers when the dropdown appears/disappears',
          parameters: 'true when it appears, and false otherwise'
        },
        {
          name: 'remove-tag',
          description: 'triggers when a tag is removed in multiple mode',
          parameters: 'removed tag value'
        },
        {
          name: 'clear',
          description: 'triggers when the clear icon is clicked in a clearable Select',
          parameters: '-'
        },
        {
          name: 'blur',
          description: 'triggers when Input blurs',
          parameters: '(event: Event)'
        },
        {
          name: 'focus',
          description: 'triggers when Input focuses',
          parameters: '(event: Event)'
        },
      ],
      optionGroupAttributes: [
        {
          attribute: 'label',
          description: 'name of the group',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'disabled',
          description: 'whether to disable all options in this group',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
      ],
    }
  },

  mounted () {
    this.remoteSearchList = this.states.map(item => {
      return { value: `value:${item}`, label: `label:${item}` }
    })
  },

  methods: {
    remoteMethod (query) {
      if (query !== '') {
        this.remoteSearchLoading = true
        setTimeout(() => {
          this.remoteSearchLoading = false
          this.remoteSearchOptions = this.remoteSearchList.filter(item => {
            return item.label.toLowerCase()
              .indexOf(query.toLowerCase()) > -1
          })
        }, 200)
      } else {
        this.options = []
      }
    }
  }
}
</script>

