<template>

  <!-- Design System Pagination -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Pagination
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
    >

      <!-- Preview -->
      <template #preview>
        <div style="position: relative;margin-top: 50px">
          <adm-pagination
            :loading="false"
            :current-page="2"
            :total="20"
          />
        </div>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{
          getPaginationCode(
            { 'loading': false, 'current-page': 2, 'total': 20},
          )
        }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Modal Attributes -->
    <design-system-documentation-table :table-data="tableDataAttributes" />
    <!-- /Modal Attributes -->

  </div>
  <!-- /Design System Pagination -->

</template>

<script>
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'
import AdmPagination from '@/views/_components/pagination/AdmPagination'

export default {
  name: 'DesignSystemPagination',

  components: {
    AdmPagination,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: 0,
      tableDataAttributes: [
        {
          attribute: 'loading',
          description: 'should sceleton loading be presented',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'current-page',
          description: 'Current page',
          type: 'Number',
          values: '-',
          default: '1',
        },
        {
          attribute: 'total',
          description: 'Total number of items',
          type: 'Number',
          values: '-',
          default: '1',
        },
        {
          attribute: 'total-per-page',
          description: 'Total per page',
          type: 'Number',
          values: '-',
          default: '8',
        },
        {
          attribute: 'padding-top',
          description: 'Padding top',
          type: 'Number',
          values: '-',
          default: '16',
        },
        {
          attribute: 'padding-bottom',
          description: 'Padding bottom',
          type: 'Number',
          values: '-',
          default: '16',
        },
        {
          attribute: 'padding-left',
          description: 'Padding left',
          type: 'Number',
          values: '-',
          default: '16',
        },
        {
          attribute: 'padding-right',
          description: 'Padding right',
          type: 'Number',
          values: '-',
          default: '16',
        },
      ],
    }
  },
}
</script>
