<template>

  <!-- Main Navigation Item -->
  <guide-menu-wrapper
    :popover-name="routeName"
    :description="guide.description"
    :title="guide.title"
    :is-proceed-presented="true"
    :is-skip-presented="true"
  >
    <adm-tooltip
      :content="name"
      :disabled="disableTooltip"
      placement="right"
      :open-delay="250"
    >
      <div
        :class="{
          'active': isActive(),
          'active-popover-border': isPopoverOpened
        }"
        class="main-nav-bar__item"
      >
        <router-link
          :to="{ name: routeName }"
        >
          <div v-html="icon" />
          <span>{{ name }}</span>
        </router-link>
      </div>
    </adm-tooltip>
  </guide-menu-wrapper>
  <!-- /Main Navigation Item -->

</template>

<script>
import GuideMenuWrapper from '@/views/Guide/GuideMenuWrapper'
import AdmTooltip from '@/views/_components/tooltip/AdmTooltip'

export default {
  name: 'MainNavigationItem',
  components: { AdmTooltip, GuideMenuWrapper },
  props: {
    disableTooltip: {
      type: Boolean,
      default: false
    },
    name: {
      type: String,
      required: true
    },
    routeName: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    guide: {
      type: Object,
      required: true
    }
  },

  computed: {
    isPopoverOpened () {
      return this.$store.getters['guide/isPopoverOpened'](this.routeName)
    },
  },

  methods: {
    isActive () {
      if (this.routeName === this.$route.name) {
        return true
      }

      return this.$route.path.split('/')[1] === this.routeName
    },
  }
}
</script>

<style lang="scss">
.main-nav-bar__item {
  display: flex;
  height: 48px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:focus {
    outline: none;
    border: none;
  }

  a {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    flex-direction: column;
    text-align: center;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.07);
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.1);

    path.customize-fill {
      fill: $adm-white;
      opacity: 1;
    }
  }

  span {
    margin-top: 2px;
    display: none;
    color: $adm-white;
    opacity: 0.85;
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
  }

  svg {
    margin: 0 auto;

    path.customize-fill {
      fill: $adm-white;
      opacity: 0.55;
    }
  }
}
</style>
