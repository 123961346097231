const getDefaultState = () => {
  return {
    data: [],
    validationErrors: {},
    isValidationNeeded: false,
    customFields: [],
    usedRecipients: [],
  }
}

const state = getDefaultState()

const getters = {
  getNotifications: (state) => {
    return state.data
  },

  getUsedRecipients: (state) => {
    return state.usedRecipients
  },
}

const mutations = {
  setNotifications (state, payload) {
    state.data = payload
  },

  setCustomFields (state, payload) {
    state.customFields = payload
  },

  setUsedRecipients (state, payload) {
    state.usedRecipients = payload
  },

  addUsedRecipients (state, payload) {
    state.usedRecipients = [...state.usedRecipients, ...payload]
  },

  updateNotification (state, payload) {
    const index = state.data.findIndex(notification => notification.id === payload.id)
    if (payload && payload.name && state.data[index].name.trim() === '') {
      state.validationErrors = {}
    }
    state.data[index] = payload
  },

  addValidationError (state, payload) {
    state.isValidationNeeded = true
    state.validationErrors[payload.id] = payload.error
  },

  resetValidationCheck (state) {
    state.isValidationNeeded = false
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
