<template>
  <button
    class="helpscoutBeaconButton flex-center justify-center"
    @click="toggleBeacon"
  >
    <svg v-if="!beaconVisible" width="24" height="22" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.347 20.871l-.003-.05c0 .017.001.034.003.05zm-.243-4.278a2 2 0 0 1 .513-1.455c1.11-1.226 1.383-2.212 1.383-4.74C22 5.782 18.046 2 13.125 2h-2.25C5.954 2 2 5.78 2 10.399c0 4.675 4.01 8.626 8.875 8.626h2.25c.834 0 1.606-.207 3.212-.798a2 2 0 0 1 1.575.083l2.355 1.161-.163-2.878zM10.875 0h2.25C19.13 0 24 4.656 24 10.399c0 2.6-.25 4.257-1.9 6.08l.243 4.279c.072.845-.807 1.471-1.633 1.162l-3.682-1.816c-1.212.446-2.527.921-3.903.921h-2.25C4.869 21.025 0 16.142 0 10.4 0 4.656 4.869 0 10.875 0z" fill="#FFF" />
    </svg>

    <svg v-else width="14" height="14" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.707.293a.999.999 0 0 0-1.414 0L7 5.586 1.707.293A.999.999 0 1 0 .293 1.707L5.586 7 .293 12.293a.999.999 0 1 0 1.414 1.414L7 8.414l5.293 5.293a.997.997 0 0 0 1.414 0 .999.999 0 0 0 0-1.414L8.414 7l5.293-5.293a.999.999 0 0 0 0-1.414" fill="#FFF" fill-rule="evenodd" />
    </svg>
  </button>
</template>

<script>
export default {
  name: 'HelpScoutBeacon',

  data () {
    return {
      beaconVisible: false,
    }
  },

  created () {
    this.initHelpScoutBeacon()
  },

  methods: {
    initHelpScoutBeacon () {
      !function (e, t, n) {
        function a () {
          const e = t.getElementsByTagName('script')[0], n = t.createElement('script')
          n.type = 'text/javascript', n.async = !0, n.src = 'https://beacon-v2.helpscout.net', e.parentNode.insertBefore(n, e)
        }

        if (e.Beacon = n = function (t, n, a) {
          e.Beacon.readyQueue.push({
            method: t,
            options: n,
            data: a
          })
        }, n.readyQueue = [], 'complete' === t.readyState) return a()
        e.attachEvent ? e.attachEvent('onload', a) : e.addEventListener('load', a, !1)
      }(window, document, window.Beacon || function () {})

      let beaconId = '1f0f41f8-7f5d-4392-80c4-dd0cb6c7f0c5'
      if (window.language === 'sr-Latn') {
        beaconId = '5f2d6079-c3d3-4af6-8c01-5851f7345d1f'
      }
      window.Beacon('init', beaconId)
      window.Beacon('config', { display: { style: 'manual' } })
      window.Beacon('on', 'open', () => { this.beaconVisible = true })
      window.Beacon('on', 'close', () => { this.beaconVisible = false })
      window.Beacon('session-data', {
        'Plan': 'plan_'+this.$store.getters['settings/getSettingByName']('activePlan', 'general')
      })
    },

    toggleBeacon () {
      Beacon('toggle')
    }
  }
}
</script>

<style lang="scss">
  #beacon-container {
    & .BeaconContainer {
      bottom: 80px;
      background-color: $adm-white;
      padding-bottom: 88px;

      @include tablet-down {
        bottom: 0;
        right: 0;
        top: 56px;
        height: calc(100vh - 56px);
        max-height: calc(100vh - 56px);
      }

      @include phone-down {
        top: 88px;
        bottom: 0;
        height: calc(100vh - 88px);
        max-height: calc(100vh - 88px);

        iframe {
          padding-bottom: 90px;
        }
      }
    }

    @include phone-up {
      .c-BeaconCloseButton {
        top: 6px;
        right: 8px;
        z-index: 1;

        & > div {
          padding: 0;
          background-color: transparent;
        }

        &__label {
          display: none;
        }

        &__iconClose {
          height: 34px;
          width: 34px;
          position: unset;
        }
      }
    }
  }

  .helpscoutBeaconButton {
    border-radius: 50%;
    width: 60px;
    height: 60px;
    background-color: var(--primary-900);
    position: fixed;
    bottom: 10px;
    right: 20px;
    z-index: 2;

    &:hover {
      background-color: var(--primary-1000);
      box-shadow: rgba(0, 0, 0, 0.06) 0 0 0 30px inset;
    }

    @include tablet-down {
      display: none;
    }
  }
</style>
