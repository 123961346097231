<template>

  <!-- Design System Dropdown -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Dropdown
    </h2>
    <!-- /Title -->

    <!-- Basic Usage -->
    <design-system-element-block
      title="Basic usage"
      description="Click on the dropdown menu to unfold it for more actions."
      code-description="The triggering element is rendered by the default slot, and the dropdown part is rendered by the slot named dropdown. By default, dropdown list shows when you hover on the triggering element without having to click it."
    >

      <!-- Preview -->
      <template #preview>
        <adm-dropdown>
          <adm-button size="medium" type="borderless" icon-end="arrow-down">
            Dropdown
          </adm-button>
          <adm-dropdown-menu slot="dropdown">
            <adm-dropdown-item>
              Action 1
            </adm-dropdown-item>
            <adm-dropdown-item :red="true">
              Action 2
            </adm-dropdown-item>
            <adm-dropdown-item icon="notes">
              Action 3
            </adm-dropdown-item>
            <adm-dropdown-item icon="trash" :red="true">
              Action 4
            </adm-dropdown-item>
            <adm-dropdown-item icon="arrow-circle-up" :blue="true">
              Action 5
            </adm-dropdown-item>
            <adm-dropdown-item :disabled="true">
              Action 6
            </adm-dropdown-item>
            <adm-dropdown-item :divided="true">
              Action 7
            </adm-dropdown-item>
            <adm-dropdown-item :divided-dashed="true">
              Action 8
            </adm-dropdown-item>
          </adm-dropdown-menu>
        </adm-dropdown>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getBasicUsageCode() }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic Usage -->

    <!-- Triggering Element -->
    <design-system-element-block
      title="Triggering element"
      description="Use the group button to trigger the dropdown list."
      code-description="Use group-button to split the triggering element into a button group with the left button being a normal button and right one the actual triggering target."
    >

      <!-- Preview -->
      <template #preview>
        <adm-dropdown :group-button="true" size="medium" @click="handleClick">
          Dropdown
          <adm-dropdown-menu slot="dropdown">
            <adm-dropdown-item>
              Action 1
            </adm-dropdown-item>
            <adm-dropdown-item :red="true">
              Action 2
            </adm-dropdown-item>
            <adm-dropdown-item icon="notes">
              Action 3
            </adm-dropdown-item>
            <adm-dropdown-item icon="trash" :red="true">
              Action 4
            </adm-dropdown-item>
            <adm-dropdown-item icon="arrow-circle-up" :blue="true">
              Action 5
            </adm-dropdown-item>
            <adm-dropdown-item :disabled="true">
              Action 6
            </adm-dropdown-item>
            <adm-dropdown-item :divided="true">
              Action 7
            </adm-dropdown-item>
            <adm-dropdown-item :divided-dashed="true">
              Action 8
            </adm-dropdown-item>
          </adm-dropdown-menu>
        </adm-dropdown>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getTriggeringElementCode() }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Triggering Element -->

    <!-- How To Trigger -->
    <design-system-element-block
      title="How to trigger"
      description="Click the triggering element or hover on it."
      code-description="Use the attribute trigger. By default, it is click."
    >

      <!-- Preview -->
      <template #preview>
        <adm-dropdown>
          <adm-button size="medium" color="grey" icon-end="arrow-down">
            Dropdown
          </adm-button>
          <adm-dropdown-menu slot="dropdown">
            <adm-dropdown-item>
              Action 1
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 2
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 3
            </adm-dropdown-item>
          </adm-dropdown-menu>
        </adm-dropdown>
        <adm-dropdown class="ml-12" trigger="hover">
          <adm-button size="medium" color="grey" icon-end="arrow-down">
            Dropdown
          </adm-button>
          <adm-dropdown-menu slot="dropdown">
            <adm-dropdown-item>
              Action 1
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 2
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 3
            </adm-dropdown-item>
          </adm-dropdown-menu>
        </adm-dropdown>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getHowToTriggerCodeClick() }}
        <br>
        {{ getHowToTriggerCodeHover() }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /How To Trigger -->

    <!-- Menu Hiding Behavior -->
    <design-system-element-block
      title="Menu hiding behavior"
      description="Use hide-on-click to define if menu closes on clicking."
      code-description="By default menu will close when you click on menu items, and it can be turned off by setting hide-on-click to false."
    >

      <!-- Preview -->
      <template #preview>
        <adm-dropdown :hide-on-click="false">
          <adm-button size="medium" color="grey" icon-end="arrow-down">
            Dropdown
          </adm-button>
          <adm-dropdown-menu slot="dropdown">
            <adm-dropdown-item>
              Action 1
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 2
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 3
            </adm-dropdown-item>
          </adm-dropdown-menu>
        </adm-dropdown>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getMenuHidingBehaviorCode() }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Menu Hiding Behavior -->

    <!-- Command Event -->
    <design-system-element-block
      title="Command event"
      description="Clicking each dropdown item fires an event whose parameter is assigned by each item."
    >

      <!-- Preview -->
      <template #preview>
        <adm-dropdown @command="handleCommand">
          <adm-button size="medium" type="borderless" icon-end="arrow-down">
            Dropdown
          </adm-button>
          <adm-dropdown-menu slot="dropdown">
            <adm-dropdown-item command="a">
              Action 1
            </adm-dropdown-item>
            <adm-dropdown-item command="b" :red="true">
              Action 2
            </adm-dropdown-item>
            <adm-dropdown-item command="c" icon="notes">
              Action 3
            </adm-dropdown-item>
            <adm-dropdown-item command="d" icon="trash" :red="true">
              Action 4
            </adm-dropdown-item>
            <adm-dropdown-item command="e" :disabled="true">
              Action 5
            </adm-dropdown-item>
            <adm-dropdown-item command="f" :divided="true">
              Action 6
            </adm-dropdown-item>
            <adm-dropdown-item command="g" :divided-dashed="true">
              Action 7
            </adm-dropdown-item>
          </adm-dropdown-menu>
        </adm-dropdown>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getCommandEventCode() }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Command Event -->

    <!-- Sizes -->
    <design-system-element-block
      title="Sizes"
      description="Besides default size, Dropdown component provides three additional sizes for you to choose among different scenarios. Only available when group-button is true."
      code-description="Use attribute size to set additional sizes with medium, small or mini."
    >

      <!-- Preview -->
      <template #preview>
        <adm-dropdown :group-button="true" size="default">
          Dropdown
          <adm-dropdown-menu slot="dropdown">
            <adm-dropdown-item>
              Action 1
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 2
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 3
            </adm-dropdown-item>
          </adm-dropdown-menu>
        </adm-dropdown>
        <adm-dropdown class="ml-12" :group-button="true" size="medium">
          Dropdown
          <adm-dropdown-menu slot="dropdown">
            <adm-dropdown-item>
              Action 1
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 2
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 3
            </adm-dropdown-item>
          </adm-dropdown-menu>
        </adm-dropdown>
        <adm-dropdown class="ml-12" :group-button="true" size="small">
          Dropdown
          <adm-dropdown-menu slot="dropdown">
            <adm-dropdown-item>
              Action 1
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 2
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 3
            </adm-dropdown-item>
          </adm-dropdown-menu>
        </adm-dropdown>
        <adm-dropdown class="ml-12" :group-button="true" size="mini">
          Dropdown
          <adm-dropdown-menu slot="dropdown">
            <adm-dropdown-item>
              Action 1
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 2
            </adm-dropdown-item>
            <adm-dropdown-item>
              Action 3
            </adm-dropdown-item>
          </adm-dropdown-menu>
        </adm-dropdown>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSizeCode() }}
        <br>
        {{ getSizeCode('medium') }}
        <br>
        {{ getSizeCode('small') }}
        <br>
        {{ getSizeCode('mini') }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Sizes -->

    <!-- Dropdown Attributes -->
    <design-system-documentation-table title="Dropdown Attributes" :table-data="tableDataAttributes" />
    <!-- /Dropdown Attributes -->

    <!-- Dropdown Slots -->
    <design-system-documentation-table title="Dropdown Slots" :table-data="tableDataSlots" type="slots" />
    <!-- /Dropdown Slots -->

    <!-- Dropdown Events -->
    <design-system-documentation-table title="Dropdown Events" :table-data="tableDataEvents" type="events" />
    <!-- /Dropdown Events -->

    <!-- Dropdown Menu Items Attributes -->
    <design-system-documentation-table title="Dropdown Menu Items Attributes" :table-data="tableDataItemAttributes" />
    <!-- /Dropdown Menu Items Attributes -->

  </div>
  <!-- /Design System Button -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmDropdown from '@/views/_components/dropdown/AdmDropdown'
import AdmDropdownItem from '@/views/_components/dropdown/AdmDropdownItem'
import AdmDropdownMenu from '@/views/_components/dropdown/AdmDropdownMenu'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'

export default {
  name: 'DesignSystemDropdown',

  components: {
    AdmButton,
    AdmDropdown,
    AdmDropdownItem,
    AdmDropdownMenu,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      tableDataAttributes: [
        {
          attribute: 'trigger',
          description: 'how to trigger (only works when group-button is false)',
          type: 'string',
          values: 'click / hover',
          default: 'click',
        },
        {
          attribute: 'group-button',
          description: 'whether a button group is displayed',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'size',
          description: 'menu button size, refer to Button Component, only works when group-button is true',
          type: 'string',
          values: 'default / medium / small / mini',
          default: 'default',
        },
        {
          attribute: 'color',
          description: 'menu button color, refer to Button Component, only works when group-button is true',
          type: 'string',
          values: 'blue / grey',
          default: 'blue',
        },
        {
          attribute: 'icon-start',
          description: 'menu button icon, refer to Button Component, only works when group-button is true',
          type: 'string',
          values: 'all available icons',
          default: '-',
        },
        {
          attribute: 'placement',
          description: 'placement of pop menu',
          type: 'string',
          values: 'top / top-start / top-end / bottom / bottom-start / bottom-end',
          default: 'bottom-end',
        },
        {
          attribute: 'hide-on-click',
          description: 'whether to hide menu after clicking menu-item',
          type: 'boolean',
          values: 'true / false',
          default: 'true',
        },
      ],
      tableDataSlots: [
        {
          name: '-',
          description: 'content of Dropdown. Notice: Must be a valid html dom element (ex. <span>, <button> etc.) or adm-component, to attach the trigger listener'
        },
        {
          name: 'dropdown',
          description: 'content of the Dropdown Menu, usually a <adm-dropdown-menu> element',
        }
      ],
      tableDataEvents: [
        {
          name: 'click',
          description: 'if group-button is true, triggers when left button is clicked',
          parameters: '-'
        },
        {
          name: 'command',
          description: 'triggers when a dropdown item is clicked',
          parameters: 'the command dispatched from the dropdown item'
        },
        {
          name: 'visible-change',
          description: 'triggers when the dropdown appears/disappears',
          parameters: 'true when it appears, and false otherwise'
        }
      ],
      tableDataItemAttributes: [
        {
          attribute: 'command',
          description: 'a command to be dispatched to Dropdown\'s command callback',
          type: 'string / number / object',
          values: '-',
          default: '-',
        },
        {
          attribute: 'icon',
          description: 'menu item icon',
          type: 'string',
          values: 'all available icons',
          default: '-',
        },
        {
          attribute: 'disabled',
          description: 'whether the item is disabled',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'divided',
          description: 'whether a divider is displayed',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'divided-dashed',
          description: 'whether a divider is displayed with dashed border',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'red',
          description: 'whether the item is red',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'is-active',
          description: 'whether the item is active',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'is-with-active-icon',
          description: 'Whether the active item has check icon. Works only when is-active is true.',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
      ],
    }
  },

  methods: {
    handleClick () {
      alert('button click')
    },

    handleCommand (command) {
      this.$message({ message: 'click on item ' + command, showClose: true })
    },

    getBasicUsageCode () {
      return this.getDropdownCode(
        null,
        [
          { text: 'Action 1' },
          { text: 'Action 2', props: { red: true } },
          { text: 'Action 3', props: { icon: 'notes' } },
          { text: 'Action 4', props: { icon: 'trash', red: true } },
          { text: 'Action 5', props: { icon: 'arrow-circle-up', blue: true } },
          { text: 'Action 6', props: { disabled: true } },
          { text: 'Action 7', props: { divided: true } },
          { text: 'Action 8', props: { 'divided-dashed': true } }
        ],
        this.getButtonCode({ size: 'medium', type: 'borderless', 'icon-end': 'arrow-down' }, 'Dropdown')
      )
    },

    getTriggeringElementCode () {
      return this.getDropdownCode(
        { 'group-button': true, size: 'medium', '@click': 'handleClick' },
        [
          { text: 'Action 1' },
          { text: 'Action 2', props: { red: true } },
          { text: 'Action 3', props: { icon: 'notes' } },
          { text: 'Action 4', props: { icon: 'trash', red: true } },
          { text: 'Action 5', props: { icon: 'arrow-circle-up', blue: true } },
          { text: 'Action 6', props: { disabled: true } },
          { text: 'Action 7', props: { divided: true } },
          { text: 'Action 8', props: { 'divided-dashed': true } }
        ]
      )
    },

    getHowToTriggerCodeClick () {
      return this.getDropdownCode(
        {},
        [
          { text: 'Action 1' },
          { text: 'Action 2' },
          { text: 'Action 3' },
        ],
        this.getButtonCode({ size: 'medium', color: 'grey', 'icon-end': 'arrow-down' }, 'Dropdown')
      )
    },

    getHowToTriggerCodeHover () {
      return this.getDropdownCode(
        { trigger: 'hover' },
        [
          { text: 'Action 1' },
          { text: 'Action 2' },
          { text: 'Action 3' },
        ],
        this.getButtonCode({ size: 'medium', color: 'grey', 'icon-end': 'arrow-down' }, 'Dropdown')
      )
    },

    getMenuHidingBehaviorCode () {
      return this.getDropdownCode(
        { 'hide-on-click': false },
        [
          { text: 'Action 1' },
          { text: 'Action 2' },
          { text: 'Action 3' },
        ],
        this.getButtonCode({ size: 'medium', color: 'grey', 'icon-end': 'arrow-down' }, 'Dropdown')
      )
    },

    getCommandEventCode () {
      return this.getDropdownCode(
        { '@command': 'handleCommand' },
        [
          { text: 'Action 1', props: { command: 'a' } },
          { text: 'Action 2', props: { command: 'b', red: true } },
          { text: 'Action 3', props: { command: 'c', icon: 'notes' } },
          { text: 'Action 4', props: { command: 'd', icon: 'trash', red: true } },
          { text: 'Action 5', props: { command: 'e', disabled: true } },
          { text: 'Action 6', props: { command: 'f', divided: true } },
          { text: 'Action 7', props: { command: 'g', 'divided-dashed': true } }
        ],
        this.getButtonCode({ size: 'medium', type: 'borderless', 'icon-end': 'arrow-down' }, 'Dropdown')
      )
    },

    getSizeCode (size = null) {
      return this.getDropdownCode(
        { 'group-button': true, size: size },
        [
          { text: 'Action 1' },
          { text: 'Action 2' },
          { text: 'Action 3' },
        ]
      )
    }
  }
}
</script>
