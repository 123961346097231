<template>
  <div
    class="social-media-images-card"
    :class="{'social-media-images-card--selected': selected}"
    @click="$emit('click')"
  >
    <!-- Social Card Image -->
    <div class="social-media-images-card__image-container">
      <img :src="imageSrc" alt="">
    </div>
    <!-- /Social Card Image -->

    <!-- Social Card Bottom -->
    <div class="social-media-images-card__bottom">
      <adm-button
        size="micro"
        type="borderless"
        icon-start="trash"
        :color="selected ? 'light' : 'dark'"
        @click.stop="$emit('delete')"
      />
    </div>
    <!-- /Social Card Bottom -->
  </div>
</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton.vue'

export default {
  name: 'SocialMediaImageCard',
  components: { AdmButton },
  props: {
    imageSrc: {
      required: true,
      type: String,
    },
    selected: {
      required: false,
      type: Boolean,
      default: false,
    }
  },
}
</script>

<style lang="scss">
.social-media-images-card {
  border: 1px solid $shade-300;
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1));
  border-radius: 4px;
  width: 138px;
  cursor: pointer;

  &:hover:not(.social-media-images-card--selected) {
    border-color: $shade-400;
  }

  &--selected {
    border-color: var(--primary-900);
    filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.1));

    .social-media-images-card__bottom {
      background-color: var(--primary-900);
      transition: background-color 300ms linear;
      color: $white;
    }
  }

  &__image-container {
    font-size: 0;
    line-height: 0;

    img {
      object-fit: cover;
      width: 100%;
      height: 170px;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 8px;
    height: 48px;
  }
}
</style>
