<template>

  <!-- Radio Group -->
  <div class="adm-radio-group-wrapper">
    <el-radio-group
      v-model="model"
      v-bind="$props"
      :class="`adm-radio-group-wrapper__${ display }`"
      @input="(eventValue) => $emit('input', eventValue)"
      @change="(eventLabel) => $emit('change', eventLabel)"
    >
      <slot />
    </el-radio-group>
  </div>
  <!-- /Radio Group -->

</template>

<script>
export default {
  name: 'AdmRadioGroup',

  props: {
    value: {
      type: [String, Number],
      required: true
    },
    display: {
      type: String,
      default: 'block',
      validator (value) {
        return ['flex', 'block'].indexOf(value) !== -1
      }
    },
    disabled: Boolean
  },

  data () {
    return {
      selfModel: ''
    }
  },

  computed: {
    model: {
      get () {
        return this.value !== undefined ? this.value : this.selfModel
      },
      set (val) {
        this.selfModel = val
      }
    }
  },
}
</script>

<style lang="scss">
.adm-radio-group-wrapper {
  .el-radio-group {
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
  }

  &__block {
    flex-direction: column;
  }
}
</style>

