export default {
  methods: {
    redirectToAddNewPage (routeName) {
      this.$router.push({ name: routeName })
    },

    routeExists (routeName) {
      return this.$router.resolve({ name: routeName })?.resolved?.matched?.length > 0
    },

    bookAppointmentRoute () {
      const whoCanBook = this.$store.state.settings.customize.accountSettings

      if (whoCanBook === 'registered_and_guest' && this.$store.getters['user/isLoggedIn'] === false) return 'checkout'

      if (whoCanBook === 'registered_only' && this.$store.getters['user/isLoggedIn'] === false) return 'login'

      return 'booking'
    }
  }
}
