<template>
  <div
    class="skeleton-element"
    :style="{
      height: height ? height + 'px' : null,
      width: width ? width + 'px' : null,
      margin: margin ? margin + 'px' : null,
      marginTop: marginTop ? marginTop + 'px' : null,
      marginRight: marginRight ? marginRight + 'px' : null,
      marginBottom: marginBottom ? marginBottom + 'px' : null,
      marginLeft: marginLeft ? marginLeft + 'px' : null,
      borderRadius: borderRadius + 'px'
    }"
  />
</template>

<script>
export default {
  name: 'AdmSkeletonElement',

  props: {
    height: {
      type: Number,
      default: 0
    },
    width: {
      type: Number,
      default: 0
    },
    margin: {
      type: Number,
      default: null
    },
    marginTop: {
      type: Number,
      default: 0
    },
    marginRight: {
      type: Number,
      default: 0
    },
    marginBottom: {
      type: Number,
      default: 0
    },
    marginLeft: {
      type: Number,
      default: 0
    },
    borderRadius: {
      type: Number,
      default: 5
    }
  }
}
</script>

<style lang="scss">
$loader-gradient: (rgba(255, 255, 255, 0) 30%, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0) 80%);

@keyframes shimmer {
  0% {
    left: -50%;
  }
  100% {
    left: 150%;
  }
}

.skeleton-element,
.calendar-loading .v-calendar-daily__day-interval {
  display: flex;
  width: 100%;
  box-shadow: unset;
  background-color: $shade-200;
  border-radius: 5px;
  border: none !important;
  position: relative;
  overflow: hidden;

  &.el-input, &.el-select, &.el-input-number, &.phone-input, &.service-color {
    display: block;
    height: 40px;
  }

  &.el-input--medium, &.el-select--medium {
    display: block;
    height: 36px;
  }

  &.avatar-uploader {
    min-height: 200px;
    border-radius: 50%;
  }

  &.el-textarea {
    min-height: 75px;
  }

  * {
    visibility: hidden;
    line-height: 0;
  }

  &.phone-input, &.el-date-editor {
    * {
      display: none;
    }
  }

  &:before {
    content: '';
    height: 100%;
    width: 100%;
    -webkit-animation: shimmer 1s infinite ease-out; /* Safari 4+ */
    -moz-animation: shimmer 1s infinite ease-out; /* Fx 5+ */
    -o-animation: shimmer 1s infinite ease-out; /* Opera 12+ */
    animation: shimmer 1s infinite ease-out;
    background: -moz-linear-gradient(to right, $loader-gradient); /* FF3.6-15 */
    background: -webkit-linear-gradient(to right, $loader-gradient); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right, $loader-gradient); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
}

.skeleton-element-datepicker {
  div {
    background-color: unset !important;
    cursor: not-allowed;

    span {
      box-shadow: unset;
      background-color: $shade-200;
      border-radius: 5px;
      position: relative;
      overflow: hidden;
      font-size: 0;
      line-height: 0;
      margin: 0;

      &:before {
        content: '';
        height: 100%;
        width: 100%;
        -webkit-animation: shimmer 1s infinite ease-out; /* Safari 4+ */
        -moz-animation: shimmer 1s infinite ease-out; /* Fx 5+ */
        -o-animation: shimmer 1s infinite ease-out; /* Opera 12+ */
        animation: shimmer 1s infinite ease-out;
        background: -moz-linear-gradient(to right, $loader-gradient); /* FF3.6-15 */
        background: -webkit-linear-gradient(to right, $loader-gradient); /* Chrome10-25,Safari5.1-6 */
        background: linear-gradient(to right, $loader-gradient); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
      }
    }
  }
}
</style>
