export default function getCustomLabel (label, defaultLabel, store) {
  let customizeObject = store.getters['settings/getSettingByName']('labelChanges', 'customize');
  let languagePublic = store.getters['settings/getDefaultLanguagePublic']();
  if (!customizeObject || !customizeObject[languagePublic]) {
      return defaultLabel
  }

  if (!customizeObject[languagePublic][label]) {
    return defaultLabel
  }

  return customizeObject[languagePublic][label];
}
