<template>
  <!-- Date & Time Pickers -->
  <div class="share-datetime-section">
    <!-- Date Picker -->
    <adm-form-item
      v-if="$store.state.shareBookingForm.selectedDateOption === 'daterange'"
      :label="$t('date_range')"
      class="mb-0"
    >
      <adm-date-picker
        ref="datePicker"
        :value="$store.state.shareBookingForm.queryParams.dateRange"
        type="daterange"
        :format="datePickerFormat"
        :picker-options="pickerOptions"
        :append-to-body="false"
        size="small"
        :placeholder="$t('select_appointment_date')"
        value-format="yyyy-MM-dd"
        @focus="onDatePickerFocus"
        @input="selectDate"
      />
    </adm-form-item>
    <!-- /Date Picker -->

    <div v-else>
      <time-slot-item
        v-for="(timeslot, index) in $store.state.shareBookingForm.queryParams.timeslots"
        :key="index"
        :index="index"
        :is-delete-option-visible="$store.state.shareBookingForm.queryParams.timeslots.length > 1"
        @remove="removeTimeSlotItem(index)"
      />

      <adm-button
        class="share-datetime-section__add-date"
        size="mini"
        icon-start="plus"
        type="borderless"
        @click="addTimeSlotItem()"
      >
        {{ $t('add_date') }}
      </adm-button>
    </div>
  </div>
  <!-- /Date & Time Pickers -->

</template>

<script>
import AdmDatePicker from '@/views/_components/datePicker/AdmDatePicker'
import AdmFormItem from '@/views/_components/form/AdmFormItem'
import mixinDateTime from '@/mixins/common/datetime'
import mixinDuration from '@/mixins/common/duration'
import TimeSlotItem
  from '@/views/Dashboard/ShareBookingFormDialog/EntitiesSection/DateTimeSection/TimeSlotItem/TimeSlotItem.vue'
import AdmButton from '@/views/_components/button/AdmButton.vue'
import { getAbortController, isRequestCanceled } from '@/utils/api'

export default {
  name: 'DateTimeSection',

  components: {
    AdmButton,
    TimeSlotItem,
    AdmDatePicker,
    AdmFormItem,
  },

  mixins: [
    mixinDateTime,
    mixinDuration
  ],

  data: function () {
    return {
      loadingTimeSlots: false,
      cancelToken: getAbortController(),
      slots: {},
      isDatePickerOpenedOnce: false,
      datePickerCurrentViewChanged: false,
      periodBookingAdvance: this.$store.state.settings.general.periodBookingAdvance,
      minimumTimeBeforeBooking: this.$store.state.settings.general.minimumTimeBeforeBooking,
    }
  },

  computed: {
    pickerOptions () {
      const $this = this

      return {
        disabledDate (date) {
          if (
            $this.$moment().isAfter($this.$moment(date), 'day') ||
            $this.$moment(date) > $this.$moment().add($this.periodBookingAdvance, 'd') ||
            $this.$moment(date) < $this.$moment().add($this.minimumTimeBeforeBooking - 86400, 's')
          ) {
            return true
          }
        },
        firstDayOfWeek: this.getElementFirstDayOfWeek(),
        cellClassName () {
          return $this.loadingTimeSlots ? 'skeleton-element-datepicker' : ''
        }
      }
    },
  },

  beforeDestroy () {
    this.cancelToken.abort('Request canceled')
  },

  methods: {
    selectDate (dateRange) {
      this.$store.commit('shareBookingForm/setQueryParams', {
        entityType: 'dateRange', value: dateRange
      })
    },

    addTimeSlotItem () {
      let timeslots = [...this.$store.state.shareBookingForm.queryParams.timeslots]
      timeslots.push({date: null, time: null})

      this.$store.commit('shareBookingForm/setQueryParams', {
        entityType: 'timeslots', value: timeslots
      })
    },

    removeTimeSlotItem (index) {
      let timeslots = [...this.$store.state.shareBookingForm.queryParams.timeslots]

      timeslots.splice(index, 1)

      this.$store.commit('shareBookingForm/setQueryParams', {
        entityType: 'timeslots', value: timeslots
      })
    },

    onDatePickerFocus () {
      this.$nextTick(_ => {
        this.isDatePickerOpenedOnce = true
      })

      this.loadTimeSlots()
    },

    async loadTimeSlots () {
      // Abort previous request if not finished
      if (this.loadingTimeSlots) {
        this.loadingTimeSlots = false
      }

      this.loadingTimeSlots = true
      try {
        const requestParams = {
          serviceCategory: this.$store.state.shareBookingForm.queryParams.serviceCategory?.id,
          service: this.$store.state.shareBookingForm.queryParams.service?.id,
        }
        const response = await this.$http.get('/api/v1/booking-links/time-before-and-advanced-booking',
          {
            params: requestParams,
            signal: this.cancelToken.signal,
          },
        )

        this.periodBookingAdvance = response.data.periodBookingAdvance
        this.minimumTimeBeforeBooking = response.data.minimumTimeBeforeBooking

        this.loadingTimeSlots = false
      } catch (e) {
        if (isRequestCanceled(e)) return

        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        this.loadingTimeSlots = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.share-datetime-section {

  &__add-date {
    margin-top: 4px;
  }
}
</style>
