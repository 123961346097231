<template>

  <!-- Guide Main Menu Popover -->
  <el-popover
    popper-class="guide__item__popover"
    :title="title"
    width="416"
    trigger="manual"
    :value="isPopoverOpened"
    :placement="placement"
  >
    <!-- Guide Main Menu Popover Description -->
    <p class="guide__item__popover__description">
      {{ description }}
    </p>

    <p v-if="isProceedPresented" class="guide__item__popover__description guide__item__popover__description--proceed">
      {{ isWrapperForIcon ? $t('click_on_this_icon_to_proceed') : $t('click_on_button_to_proceed') }}
    </p>
    <!-- /Guide Main Menu Popover Description -->

    <!-- Guide Main Menu Popover Skip Button -->
    <div v-if="isSkipPresented" class="guide__item__popover__actions">
      <adm-button
        icon-start="book-outline"
        size="medium"
        type="borderless"
        color="dark"
        @click="skipTutorial"
      >
        {{ $t('skip_this_tutorial') }}
      </adm-button>
    </div>
    <!-- /Guide Main Menu Popover Skip Button -->

    <slot slot="reference" />
  </el-popover>
  <!-- /Guide Main Menu Popover -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import { getAbortController } from '@/utils/api'

export default {
  name: "GuideMenuWrapper",
  components: { AdmButton },
  props: {
    title: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    popoverName: {
      type: String,
      required: true
    },
    isProceedPresented: {
      type: Boolean,
      required: true
    },
    placement: {
      type: String,
      required: false,
      default: 'right-start'
    },
    isSkipPresented: {
      type: Boolean,
      required: true
    },
    isWrapperForIcon: {
      type: Boolean,
      default: true,
    }
  },
  data () {
    return {
      isPopoverOpenedActive: false,
    }
  },
  computed: {
    isPopoverOpened () {
      return this.isPopoverOpenedActive && this.$store.getters['guide/isPopoverOpened'](this.popoverName)
    },
  },

  mounted () {
    this.$nextTick(function () {
      this.isPopoverOpenedActive = true
    })
  },

  methods: {
    skipTutorial () {
      this.$store.dispatch('guide/skipCurrentGuide')
    }
  },
}
</script>

<style lang="scss">
.active-popover-border {
  border: 2px solid #00edfc;

  &.adm-button__size__small {
    padding: 0 14px;
  }

  &--rounded {
    border-radius: 5px;
  }
}
.active-popover {
  box-shadow: 0 0 0 2px #00edfc;

  &--rounded {
    border-radius: 50%;
  }
}

.guide__item {

  &__popover {
    padding: 0;

    .el-popover {
      color: $shade-900;

      &__title {
        display: inline-block;
        font-weight: 600;
        font-size: 18px;
        padding: 24px 28px 0;
        word-break: break-word;
        text-align: left;
      }
    }

    &__description {
      word-break: normal;
      text-align: left;
      padding-left: 28px;
      padding-right: 28px;
      padding-bottom: 12px;
      &--proceed {
        font-weight: 500;
      }
    }

    &__close-button {
      display: inline-block;
      position: absolute;
      right: 10px;
      top: 6px;
    }

    &--smaller {
      .el-popover {
        &__title {
          padding-left: 16px;
          padding-top: 12px;
          margin-bottom: 8px;
          font-size: 16px;
        }
      }
      .guide__item__popover__description {
        padding-left: 16px;
        padding-right: 20px;
      }
    }

    &__actions {
      margin-top: 16px;
      padding: 10px 16px;
      border-top: 1px solid $shade-300;
    }
  }
}
</style>
