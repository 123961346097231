<template>
  <div class="customer-card-recurring">
    <adm-switch
      :value="bookingProp.isRecurringEnabledForBooking"
      :class="{'customer-card-recurring__form-item-enabled': bookingProp.isRecurringEnabledForBooking}"
      :label="$t('enable_recurring_appointments')"
      @change="toggleRecurringBooking"
    />

    <div v-if="bookingProp.isRecurringEnabledForBooking" class="customer-card-recurring__container">

      <template v-if="!isRecurringBookingListPresented">
        <!-- Frequency Selection -->
        <one-row-form-item
          class="customer-card-recurring__container__frequency"
          :class="{'customer-card-recurring__container__frequency--no-recurrence': bookingProp.recurringData.selectedRecurrenceOption === 'no_recurrence'}"
        >
          <template #label>
            {{ $t('recurrence_frequency') }}
          </template>

          <template #input>
            <adm-checkbox-buttons-group
              :options="getRecurrenceOptions()"
              :value="bookingProp.recurringData.selectedRecurrenceOption"
              :at-least-one-needs-to-be-selected="true"
              @input="selectRecurrenceOption"
            />
          </template>
        </one-row-form-item>
        <!-- /Frequency Selection -->

        <template v-if="bookingProp.recurringData.selectedRecurrenceOption !== 'no_recurrence'">
          <!-- Repeat Every -->
          <div class="customer-card-recurring__container__times">
            <adm-form-item
              :label="$tc('repeat_this_appointment_every', bookingProp.recurringData.occurrenceInterval)"
              class="customer-card-recurring__container__times__form-item"
            >
              <adm-input-number
                size="small"
                :value="bookingProp.recurringData.occurrenceInterval"
                :min="1"
                :max="50"
                @input="changeOccurrenceIntervalOption"
              />
            </adm-form-item>

            <span v-if="bookingProp.recurringData.selectedRecurrenceOption === 'daily'">{{ $tc('day_plural', bookingProp.recurringData.occurrenceInterval) }}</span>
            <span v-if="bookingProp.recurringData.selectedRecurrenceOption === 'weekly'">{{ $tc('week_plural_recurring', bookingProp.recurringData.occurrenceInterval) }}</span>
            <span v-if="bookingProp.recurringData.selectedRecurrenceOption === 'monthly'">{{ $tc('month_plural', bookingProp.recurringData.occurrenceInterval) }}</span>
          </div>
          <!-- /Repeat Every -->

          <!-- Days Of Week -->
          <div v-if="bookingProp.recurringData.selectedRecurrenceOption === 'weekly'" class="customer-card-recurring__container__week-repeat">
            <div class="customer-card-recurring__container__week-repeat__frequency">
              <div
                v-for="recurrenceOption in getDaysOfWeek()"
                :key="recurrenceOption.index"
                class="customer-card-recurring__container__week-repeat__frequency__option"
                :class="{ 'selected': bookingProp.recurringData.occurrenceOnWeekly.includes(recurrenceOption.index), 'disabled': !recurrenceOption.available }"
                @click="recurrenceOption.available ? selectRecurringWeekDays(recurrenceOption.index) : null"
              >
                {{ recurrenceOption.value }}
              </div>
            </div>
          </div>
          <!-- /Days Of Week -->

          <!-- Repeat On For Month -->
          <div v-if="bookingProp.recurringData.selectedRecurrenceOption === 'monthly'" class="customer-card-recurring__container__month-repeat">
            <adm-radio
              :value="bookingProp.recurringData.occurrenceOnMonthly"
              label="specific_date"
              @change="selectOccurrenceOnMonthly('specific_date')"
            >
              {{ $t('repeat_on') + ' ' + getDayOfMonth() + ' ' + $t('each_month') }}
            </adm-radio>
            <adm-radio
              :value="bookingProp.recurringData.occurrenceOnMonthly"
              label="last_day"
              @change="selectOccurrenceOnMonthly('last_day')"
            >
              {{ $t('repeat_on_every') + ' ' + getLastOfMonthLabel() }}
            </adm-radio>
          </div>
          <!-- /Repeat On For Month -->

          <!-- End After -->
          <div class="customer-card-recurring__container__times">
            <adm-form-item
              :label="$t('end_after')"
              class="customer-card-recurring__container__times__form-item"
            >
              <adm-input-number
                :value="bookingProp.recurringData.endOption.value ? bookingProp.recurringData.endOption.value : 1"
                size="small"
                :min="appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.min === 0 ? 1 : appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.min"
                :max="appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.max"
                @change="setEndOptionValue"
              />
            </adm-form-item>

            {{ $t('occurrences') }}
          </div>
          <!-- /End After -->

          <!-- Limits Description -->
          <div class="customer-card-recurring__container__limits">
            <div v-if="!isRecurringLimitFixed()">
              {{ $t('recurring_appointments_limit') }}
            </div>
            <div v-if="!isRecurringLimitFixed()" class="customer-card-recurring__container__limits__values">
              <div class="customer-card-recurring__container__limits__values__item">
                {{ $t('min') }}
                <span class="customer-card-recurring__container__limits__values__item__value">
                  {{ appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.min }}
                </span>
              </div>
              <div class="customer-card-recurring__container__limits__values__item">
                {{ $t('max') }}
                <span class="customer-card-recurring__container__limits__values__item__value">
                  {{ appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.max }}
                </span>
              </div>
            </div>
            <div v-if="isRecurringLimitFixed()">
              {{ $t('you_need_to_book') }} {{ appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.min }} {{ $t('recurring_appointment') }}
            </div>
          </div>
          <!-- /Limits Description -->
        </template>
      </template>
      <div
        v-if="isRecurringBookingListPresented"
        class="customer-card-recurring__container__list"
        :class="{'customer-card-recurring__container__list--collapsed': isRecurringBookingListCollapsed}"
      >
        <div
          class="customer-card-recurring__container__list__info"
          :class="{'customer-card-recurring__container__list__info--collapsed': isRecurringBookingListCollapsed}"
        >
          <div class="customer-card-recurring__container__list__info__repeat">
            <adm-icon class="customer-card-recurring__container__list__info__repeat__icon" icon="repeat" />
            <adm-tag>
              {{ bookingProp.recurringData.bookings.length }} {{ $t('appointments') }} {{ $t('total') }}
            </adm-tag>
          </div>
          <a v-if="!isRecurringBookingListCollapsed" @click.prevent="isRecurringBookingListCollapsed = true">
            {{ $t('show_less') }}
          </a>
          <a v-else @click.prevent="isRecurringBookingListCollapsed = false">
            {{ $t('show_more') }}
          </a>
        </div>

        <!-- Recurring List -->
        <transition-group
          tag="div"
          name="recurring-list-transition"
          class="customer-card-recurring__container__list__wrapper"
          :class="{'customer-card-recurring__container__list__wrapper--hidden': isRecurringBookingListCollapsed}"
        >
          <template v-for="(recurringBooking, index) in bookingProp.recurringData.bookings">
            <recurring-item-card
              :key="`recurring_${index}`"
              :is-first-booking="index === 0"
              :booking="recurringBooking"
              :is-min-limit-reached="isMinLimitReached()"
              :appointment-prop="appointmentProp"
              @delete="deleteBooking"
            />
          </template>
        </transition-group>
        <!-- /Recurring List -->
      </div>

      <template v-if="bookingProp.recurringData.selectedRecurrenceOption !== 'no_recurrence'">
        <!-- Recurring Actions -->
        <div class="customer-card-recurring__container__generate">
          <adm-button
            v-if="!isRecurringBookingListPresented"
            size="mini"
            :loading="loadingRecurringList"
            @click="generateRecurringBookingsList()"
          >
            {{ $t('create_appointment_list') }}
          </adm-button>

          <adm-button
            v-if="isRecurringBookingListPresented && !isRecurringBookingListCollapsed"
            size="mini"
            color="grey"
            @click="backToRecurringList()"
          >
            {{ $t('back_to_recurring_settings') }}
          </adm-button>
        </div>
        <!-- /Recurring Actions -->

        <adm-alert
          v-if="!isRecurringBookingListPresented"
          class="mt-16" type="warning"
          :title="$t('make_sure_to_create_recurring_list')"
        />
      </template>
    </div>
  </div>
</template>

<script>
import AdmSwitch from '@/views/_components/switch/AdmSwitch.vue'
import OneRowFormItem from '@/views/_components/oneRowFormItem/OneRowFormItem.vue'
import AdmCheckboxButtonsGroup from '@/views/_components/checkbox/AdmCheckboxButtonsGroup.vue'
import AdmInputNumber from '@/views/_components/input/AdmInputNumber.vue'
import AdmFormItem from '@/views/_components/form/AdmFormItem.vue'
import AdmButton from '@/views/_components/button/AdmButton.vue'
import datetime from '@/mixins/common/datetime'
import duration from '@/mixins/common/duration'
import AdmIcon from '@/views/_components/icon/AdmIcon.vue'
import AdmTag from '@/views/_components/tag/AdmTag.vue'
import AdmRadio from '@/views/_components/radio/AdmRadio.vue'
import RecurringItemCard from './RecurringItemCard/RecurringItemCard.vue'
import AdmAlert from '../../../../../../_components/alert/AdmAlert.vue'

export default {
  name: 'Recurring',
  components: {
    AdmAlert,
    RecurringItemCard,
    AdmRadio,
    AdmTag,
    AdmIcon,
    AdmButton,
    AdmFormItem,
    AdmInputNumber,
    AdmCheckboxButtonsGroup,
    OneRowFormItem,
    AdmSwitch
  },
  mixins: [
    datetime,
    duration
  ],
  props: {
    appointmentProp: {
      type: Object,
      required: true,
      default: () => ({})
    },
    bookingProp: {
      type: Object,
      required: true,
      default: () => ({})
    },
  },
  data () {
    return {
      loadingRecurringList: false,
      isRecurringBookingListPresented: false,
      isRecurringBookingListCollapsed: false,
    }
  },

  methods: {
    isRecurringLimitFixed () {
      return this.appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.min
        === this.appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.max
    },

    toggleRecurringBooking (val) {
      this.$set(this.bookingProp, 'isRecurringEnabledForBooking', val)

      this.isRecurringBookingListPresented = false
      let defaultData = null

      if (val) {
        defaultData = {
          selectedRecurrenceOption: null, // null, daily, weekly, monthly
          occurrenceInterval: 1,
          occurrenceOnWeekly: [], // days of week
          occurrenceOnMonthly: 'specific_date',
          endOption: {
            type: 'occurrence',
            value: 1,
          },
          bookings: [],
        }
      }
      this.$set(this.bookingProp, 'recurringData', defaultData)

      if (val) {
        this.selectRecurringWeekDays((new Date(this.appointmentProp.startDateTime)).getDay())
        if (this.appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.min > 1) {
          this.setEndOptionValue(this.appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.min)
        }
      }
    },

    isMinLimitReached () {
      return this.bookingProp.recurringData.bookings.length
        <= this.appointmentProp.service.recurringOptions.limitNumberOfRecurringAppointments.min
    },

    getRecurrenceOptions () {
      let recurringOptions = this.appointmentProp.service.recurringOptions
      let recurrenceOptions = []

      if (recurringOptions.limitNumberOfRecurringAppointments.min === 0) {
        recurrenceOptions.push({
          value: 'no_recurrence',
          label: this.$t('no_recurrence')
        })
      }

      if (recurringOptions.frequency.includes('daily')) {
        recurrenceOptions.push({
          value: 'daily',
          label: this.$t(`daily`)
        })
      }

      if (recurringOptions.frequency.includes('weekly')) {
        recurrenceOptions.push({
          value: 'weekly',
          label: this.$t(`weekly`)
        })
      }

      if (recurringOptions.frequency.includes('monthly')) {
        recurrenceOptions.push({
          value: 'monthly',
          label: this.$t(`monthly`)
        })
      }

      if (!this.bookingProp.recurringData.selectedRecurrenceOption) {
        this.selectRecurrenceOption(recurrenceOptions[0].value)
      }

      return recurrenceOptions
    },

    selectRecurrenceOption (recurrenceValue) {
      if (this.bookingProp.recurringData.selectedRecurrenceOption !== recurrenceValue) {
        this.changeOccurrenceIntervalOption(1)
      }

      this.$set(this.bookingProp.recurringData, 'selectedRecurrenceOption', recurrenceValue)
    },

    changeOccurrenceIntervalOption (val) {
      this.$set(this.bookingProp.recurringData, 'occurrenceInterval', val)
    },

    setEndOptionValue (val) {
      this.$set(this.bookingProp.recurringData.endOption, 'value', val)
    },

    selectOccurrenceOnMonthly (val) {
      this.$set(this.bookingProp.recurringData, 'occurrenceOnMonthly', val)
    },

    getDayOfMonth() {
      return this.$moment(this.appointmentProp.startDateTime).format('Do')
    },

    getLastOfMonthLabel () {
      let selectedDate = this.$moment(this.appointmentProp.startDateTime).toDate()

      const getWeekNumOfMonthOfDate = (
        selectedDate
      ) => {
        const firstDayOfMonth = new Date(selectedDate.getFullYear(), selectedDate.getMonth(), 1)
        let counter = 1
        while (firstDayOfMonth.getDate() !== selectedDate.getDate()) {
          if (selectedDate.getDay() === firstDayOfMonth.getDay()) {
            counter++
          }
          firstDayOfMonth.setDate(firstDayOfMonth.getDate() + 1);
        }

        return counter
      }
      let label = ''
      switch (getWeekNumOfMonthOfDate(selectedDate)) {
        case 1:
          label = this.$t('first')
          break
        case 2:
          label = this.$t('second')
          break
        case 3:
          label = this.$t('third')
          break
        case 4:
          label = this.$t('fourth')
          break
        default:
          label = this.$t('last')
      }

      return `${label} ${this.$moment(this.appointmentProp.startDateTime).format('dddd')}`
    },

    getDaysOfWeek () {
      const moment = this.$moment
      const firstDayOfWeek = this.getMomentFirstDayOfWeek();
      const threeLetterDaysOfWeek = moment.weekdaysShort();
      let selectedDate = moment(this.appointmentProp.startDate)
      selectedDate.subtract(selectedDate.day(), 'days')

      // Use map to create an array of objects containing day information
      const shortDaysOfWeekFormatted = threeLetterDaysOfWeek.map((day, index) => {
        const currentDate = selectedDate.clone().add(index, 'days');
        const available = this.$store.state.manageAppointment.slotsDates.includes(currentDate.format('YYYY-MM-DD'));

        return { index, value: day, available };
      });

      // Move the first day of the week to the front of the array
      const firstPart = shortDaysOfWeekFormatted.slice(0, firstDayOfWeek);
      shortDaysOfWeekFormatted.splice(0, firstDayOfWeek);
      shortDaysOfWeekFormatted.push(...firstPart);

      return shortDaysOfWeekFormatted;
    },

    selectRecurringWeekDays (selectedOption) {
      let occurrenceOn = JSON.parse(JSON.stringify(this.bookingProp.recurringData.occurrenceOnWeekly))
      if (occurrenceOn.includes(selectedOption)) {
        occurrenceOn = occurrenceOn.filter(val => val !== selectedOption)
        if (occurrenceOn.length === 0) {
          return
        }
      } else {
        occurrenceOn.push(selectedOption)
      }

      this.$set(this.bookingProp.recurringData, 'occurrenceOnWeekly', occurrenceOn)
    },

    generateRecurringBookingsList () {
      this.loadingRecurringList = true
      const recurringData = this.bookingProp.recurringData

      let extras = []
      if (this.bookingProp.extras) {
        this.bookingProp.extras.forEach(extraVal => {
          extras.push(JSON.stringify(extraVal))
        })
      }

      this.$http.get(
        '/api/v1/appointments/booking/recurring',
        {
          params: {
            additionalPersons: this.bookingProp.additionalPersons,
            employee: this.appointmentProp.employee.id,
            extras: extras,
            location: this.appointmentProp.location ? this.appointmentProp.location.id : null,
            selectedDate: this.appointmentProp.startDate,
            selectedTime: this.appointmentProp.startTime,
            service: this.appointmentProp.service.id,
            selectedSlot: this.appointmentProp.selectedSlot,
            recurringData: JSON.stringify({
              selectedRecurrenceOption: recurringData.selectedRecurrenceOption,
              occurrenceInterval: recurringData.occurrenceInterval,
              occurrenceOnWeekly: recurringData.occurrenceOnWeekly,
              occurrenceOnMonthly: recurringData.occurrenceOnMonthly,
              endOptionType: recurringData.endOption.type,
              endOptionValue: recurringData.endOption.value,
            })
          }
        }
      ).then(response => {
        this.$set(this.bookingProp.recurringData, 'bookings', response.data.bookings)
      }).finally(() => {
        this.loadingRecurringList = false
        this.isRecurringBookingListPresented = true
      })
    },

    deleteBooking (booking) {
      let bookings = JSON.parse(JSON.stringify(this.bookingProp.recurringData.bookings))
      let index = bookings.findIndex(obj => obj.selectedDate === booking.selectedDate && obj.selectedTime === booking.selectedTime)

      bookings.splice(index, 1)

      this.$set(this.bookingProp.recurringData, 'bookings', bookings)
    },

    backToRecurringList () {
      this.isRecurringBookingListPresented = false
      this.$set(this.bookingProp.recurringData, 'bookings', [])
    }
  }
}
</script>

<style lang="scss" scoped>
// Recurring
.customer-card-recurring {
  border-top: 1px solid $shade-300;
  padding: 0.75rem 1.25rem;
  gap: 16px;

  &__form-item-enabled {
    margin-bottom: 16px;
  }

  &__container {
    padding: 12px;
    border-radius: 8px;
    background-color: var(--primary-200);

    &__frequency {
      padding-top: 0;
      border-bottom: 1px solid $shade-250;
      align-items: center;

      &--no-recurrence {
        border-bottom: none;
        padding-bottom: 0;
      }
    }

    &__times {
      display: flex;
      align-items: center;
      gap: 8px;
      font-weight: 500;
      margin-bottom: 16px;
      padding-top: 16px;

      &__form-item {
        margin-bottom: 0;

        ::v-deep {
          .adm-input-number-wrapper {
            min-width: 84px;
            max-width: 100px;
          }
        }

        ::v-deep > div {
          display: flex;
          align-items: center;
        }
      }
    }

    &__week-repeat {
      border-bottom: 1px solid $metal;
      margin-bottom: 18px;

      @media (max-width: 380px) {
        margin-bottom: 8px;
      }

      &__frequency {
        display: flex;
        flex-wrap: wrap;

        @include phone-down {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          gap: 8px;
          margin-bottom: 16px;
        }

        &__option {
          margin-bottom: 16px;
          padding: 12px 16px;
          font-size: 14px;
          box-shadow: 0 1px 1px rgba(0, 0, 0, 0.13), 0 2px 4px rgba(0, 0, 0, 0.04);
          border-radius: 6px;
          margin-right: 8px;
          cursor: pointer;
          user-select: none;
          text-align: center;
          background-color: $white;

          &.selected {
            background-color: var(--primary-500);
          }

          @include phone-down {
            margin-right: 0;
            margin-bottom: 0;
          }

          &:hover:not(.selected):not(.disabled) {
            background-color: $shade-250;
            box-shadow: 0 1px 1px rgba(0, 0, 0, 0.13), 0 2px 4px rgba(0, 0, 0, 0.04);
          }

          &.disabled {
            cursor: not-allowed;
            background-color: $shade-250;
            color: $shade-700;
          }
        }
      }
    }

    &__month-repeat {
      padding-bottom: 16px;
      border-bottom: 1px solid $shade-250;
      margin-bottom: 16px;
    }

    &__limits {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 16px;
      background-color: var(--primary-400);
      border-radius: 8px;
      padding: 8px 8px 8px 16px;
      font-weight: 500;

      &__values {
        display: flex;
        font-weight: 400;
        text-transform: uppercase;

        &__item {
          display: flex;
          align-items: center;
          border-radius: 8px;
          color: $shade-600;
          font-weight: 500;
          background-color: $white;
          padding: 4px 4px 4px 8px;

          &:not(:last-child) {
            margin-left: 4px;
            margin-right: 8px;
          }

          &__value {
            font-weight: 600;
            font-size: 22px;
            line-height: 28px;
            margin-left: 8px;
            color: $shade-900;
          }
        }
      }
    }

    &__generate {
      display: flex;
      justify-content: flex-end;
    }

    &__list {
      background-color: var(--primary-200);
      margin-bottom: 16px;

      &--collapsed {
        margin-bottom: 0;
      }

      &__info {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid $shade-250;
        padding-bottom: 16px;
        margin-bottom: 12px;

        &--collapsed {
          border-bottom: none;
          margin-bottom: 0;
          padding-bottom: 0;
        }

        &__repeat {
          display: flex;
          align-items: center;
          text-transform: lowercase;

          &__icon {
            margin-right: 8px;
          }
        }

        a {
          font-weight: 500;
          font-size: 13px;
          line-height: 20px;
          color: var(--primary-900);
        }
      }

      &__wrapper {
        background-color: $white;
        padding: 8px 12px;

        &--hidden {
          display: none;
        }
      }
    }
  }

  .recurring-list-transition {
    backface-visibility: hidden;
    z-index: 1;

    &-enter-active, &-leave-active {
      transition: all 0.5s ease;
    }

    &-enter,
    &-leave-to {
      opacity: 0;
      transform: translateX(30px);
    }
  }
}
</style>
