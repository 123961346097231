const getDefaultState = () => {
  return {
    isGuideStepsOpened: false,
    popover: {
      generalSettings: false,
      generalSettingsTab: false,
      generalSettingsModal: false,
      generalSettingsSaveButton: false,
      paymentSettings: false,
      paymentSettingsModal: false,
      paymentSettingsTab: false,
      paymentSettingsSaveButton: false,
      services: false,
      servicesModal: false,
      servicesAddButton: false,
      servicesSaveButton: false,
      locations: false,
      locationsModal: false,
      locationsAddButton: false,
      locationsSaveButton: false,
      employees: false,
      employeesModal: false,
      employeesAddEdit: false,
      employeesAssignedServiceTab: false,
      employeesWorkingHoursTab: false,
      employeesSaveButton: false,
      customize: false,
      customizeLookAndFeelTab: false,
      customizeModal: false,
      customizeSaveButton: false,
      bookingWebsite: false,
      bookingWebsiteButton: false,
    },
    newFeaturesGuide: {

    },
    stepSuccess: {
      opened: false,
      currentStep: '',
    }
  }
}

const state = getDefaultState()

const getters = {
  isPopoverOpened: state => (page) => {
    if (page === 'settings') {
      return state.popover.generalSettings || state.popover.paymentSettings
    }

    return state.popover[page]
  },
  getActivePopoverName: state => {
    let activePopoverName = null
    Object.keys(state.popover).forEach(function (key) {
      if (state.popover[key] === true) {
        activePopoverName = key
      }
    })

    return activePopoverName
  },
}

const mutations = {
  openGuideSteps (state) {
    state.isGuideStepsOpened = true
  },

  closeGuideSteps (state) {
    state.isGuideStepsOpened = false
  },

  openItemPopover (state, page) {
    state.popover[page] = true
  },

  closeItemPopover (state, page) {
    if (!page) {
      state.popover = getDefaultState().popover

      return
    }
    if (page === 'settings') {
      state.popover.generalSettings = false
      state.popover.paymentSettings = false

      return
    }

    state.popover[page] = false
  },

  setStepSuccessData (stage, payload) {
    state.stepSuccess = payload
  },

  resetState (state) {
    Object.assign(state, getDefaultState())
  }
}

const actions = {
  openSuccessModal (context, { currentStep }) {
    context.commit('setStepSuccessData', {
      opened: true,
      currentStep: currentStep,
    })
    context.commit('closeItemPopover')
  },
  closeSuccessModal (context) {
    context.commit('setStepSuccessData', {
      opened: false,
      currentStep: '',
    })
  },
  skipCurrentGuide (context) {
    context.commit('closeItemPopover')
    context.commit('openGuideSteps')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
