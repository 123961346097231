export default [
  {
    path: 'account-settings',
    name: 'account-settings',
    redirect: { name: 'account-settings-personal-info' },
    meta: {
      title: 'account_settings',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "account-settings" */ '@/views/AccountSettings/AccountSettingsPage').then(value => value.default),
    children: [
      {
        path: 'personal-info',
        name: 'account-settings-personal-info',
        meta: {
          title: 'personal_info',
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "account-settings-personal-info" */ '@/views/AccountSettings/PersonalInfo/AccountSettingsPersonalInfo').then(value => value.default)
      },
      {
        path: 'change-password',
        name: 'account-settings-change-password',
        meta: {
          title: 'change_password',
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "account-settings-change-password" */ '@/views/AccountSettings/ChangePassword/AccountSettingsChangePassword').then(value => value.default)
      },
      {
        path: 'integrations',
        name: 'account-settings-integrations',
        meta: {
          title: 'integrations',
          requiresAuth: true
        },
        component: () => import(/* webpackChunkName: "account-settings-integrations" */ '@/views/AccountSettings/Integrations/Integrations').then(value => value.default)
      },
      {
        path: 'integrations/zoom',
        name: 'account-settings-integrations-zoom',
        meta: {
          title: 'integrations_zoom',
          requiresAuth: true,
          feature: 'zoom_integration',
        },
        component: () => import(/* webpackChunkName: "account-settings-integrations" */ '@/views/AccountSettings/Integrations/Zoom/Zoom').then(value => value.default)
      },
      {
        path: 'integrations/google-calendar',
        name: 'account-settings-integrations-google-calendar',
        meta: {
          title: 'integrations_google',
          requiresAuth: true,
          feature: 'google_calendar',
        },
        component: () => import(/* webpackChunkName: "account-settings-integrations" */ '@/views/AccountSettings/Integrations/GoogleCalendar/GoogleCalendar').then(value => value.default)
      },
      {
        path: 'integrations/outlook-calendar',
        name: 'account-settings-integrations-outlook-calendar',
        meta: {
          title: 'integrations_outlook',
          requiresAuth: true,
          feature: 'outlook_calendar',
        },
        component: () => import(/* webpackChunkName: "account-settings-integrations" */ '@/views/AccountSettings/Integrations/OutlookCalendar/OutlookCalendar').then(value => value.default)
      },
      {
        path: 'integrations/apple-calendar',
        name: 'account-settings-integrations-apple-calendar',
        meta: {
          title: 'integrations_apple',
          requiresAuth: true,
          feature: 'apple_calendar',
        },
        component: () => import(/* webpackChunkName: "account-settings-integrations" */ '@/views/AccountSettings/Integrations/AppleCalendar/AppleCalendar').then(value => value.default)
      },
    ]
  }
]
