import axios from 'axios'
import Cookies from 'js-cookie'
import { getAuthorization } from '@/utils/api'

const baseURL = process.env.VUE_APP_API_URL

const httpClient = axios.create({
  baseURL
})

// Add a request interceptor
httpClient.interceptors.request.use(function (config) {
  config.headers.Authorization = getAuthorization()

  return config
})

httpClient.interceptors.response.use(
  function (response) {
    return response
  }, function (error) {
    if (error.response && error.response.status === 401) {
      Cookies.remove('access_token')
      localStorage.removeItem('access_token')

      window.location.href = window.location.protocol + "//" + window.location.hostname + (window.location.port ? ':' + window.location.port: '')

      return false
    }

    return Promise.reject(error)
  }
)

export function getDashboardImageLink (folder, fileNameWithExtension) {
  return `${baseURL}/api/v1/settings/dashboard/image/${folder}/${fileNameWithExtension}`
}

export function getDashboardLogoLink (type) {
  return `${baseURL}/api/v1/settings/dashboard/logo/${type}`
}

export function getPublicFileLink (uuid, size = 'medium', nocache = false) {
  return `${baseURL}/api/v1/public/media/${uuid}?size=${size}`
}

export default httpClient
