<template>

  <!-- Design System Checkbox -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Checkbox
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
      description="Checkbox can be used alone to switch between two states."
    >

      <!-- Preview -->
      <template #preview>
        <adm-checkbox
          v-model="basicUsage"
        >
          Option
        </adm-checkbox>

        <adm-checkbox
          v-model="basicUsage"
          caption-position="left"
          class="ml-16"
        >
          Option
        </adm-checkbox>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Disabled -->
    <design-system-element-block
      title="Disabled State"
      description="Disabled state for checkbox."
    >

      <!-- Preview -->
      <template #preview>
        <adm-checkbox
          v-model="disabled"
          disabled
        >
          Option
        </adm-checkbox>

        <adm-checkbox
          class="ml-16"
          disabled
        >
          Option
        </adm-checkbox>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Disabled -->

    <!-- Description -->
    <design-system-element-block
      title="Description"
      description="Checkbox with description"
    >

      <!-- Preview -->
      <template #preview>
        <adm-checkbox
          v-model="description"
          description="Checkbox with description"
        >
          Option
        </adm-checkbox>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Description -->

    <!-- Indeterminate -->
    <design-system-element-block
      title="Indeterminate"
      description="The indeterminate property can help you to achieve a 'check all' effect."
    >

      <!-- Preview -->
      <template #preview>
        <div>
          <!-- Bulk Actions -->
          <adm-checkbox
            :indeterminate="checkData.indeterminate"
            :value="checkData.allChecked"
            class="mb-12"
            @change="handleCheckAll(options, checkData)"
          >
            Check all
          </adm-checkbox>
          <!-- /Bulk Actions -->

          <div class="flex">
            <adm-checkbox
              v-for="option in options"
              :key="option.key"
              v-model="option.checked"
              class="mr-12"
              @change="handleCheckSingle(options, checkData)"
            >
              {{ option.label }}
            </adm-checkbox>
          </div>
        </div>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Indeterminate -->

    <!-- Checkbox Attributes -->
    <design-system-documentation-table :table-data="tableData" title="Checkbox attributes" />
    <!-- /Checkbox Attributes -->

    <!-- Checkbox Events -->
    <design-system-documentation-table :table-data="tableDataEvents" type="events" title="Checkbox events" />
    <!-- /Checkbox Events -->

  </div>
  <!-- /Design System Checkbox -->

</template>

<script>
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import AdmCheckbox from '@/views/_components/checkbox/AdmCheckbox'
import mixinCheckbox from '@/mixins/component/checkbox'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'

export default {
  name: 'DesignSystemSwitch',

  components: {
    DesignSystemDocumentationTable,
    AdmCheckbox,
    DesignSystemElementBlock
  },

  mixins: [mixinCheckbox],

  data () {
    return {
      basicUsage: true,
      disabled: true,
      description: true,
      checkData: {
        allChecked: false,
        indeterminate: false,
        checkedEntities: [],
      },
      options: [
        { label: 'Option', id: 1 },
        { label: 'Option 2', id: 2 },
      ],
      tableData: [
        {
          attribute: 'value / v-model',
          description: 'binding value',
          type: 'string / number / boolean',
          values: '-',
          default: '-',
        },
        {
          attribute: 'label',
          description: 'the label of Checkbox',
          type: 'string / number / boolean',
          values: '-',
          default: '-',
        },
        {
          attribute: 'caption-position',
          description: 'the position of the Checkbox label',
          type: 'string',
          values: 'left / right',
          default: 'right',
        },
        {
          attribute: 'disabled',
          description: 'whether Checkbox is disabled',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'name',
          description: 'native \'name\' attribute',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'description',
          description: 'description text below the Checkbox',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'indeterminate',
          description: 'same as indeterminate in native checkbox',
          type: 'boolean',
          values: '-',
          default: '-',
        },
      ],

      tableDataEvents: [
        {
          name: 'change',
          description: 'triggers when the bound value changes',
          parameters: 'the updated value'
        },
      ],
    }
  }
}
</script>
