class Validation {
  static fieldIsRequired (value, callback, message) {
    if (typeof value !== 'string') {
      callback(new Error('Wrong value type'))
    }

    if (value.trim() === '') {
      callback(new Error(message))
    }

    callback()
  }

  static discountOrDeductionRequired (coupon, callback, message) {
    if (coupon.discount === 0 && coupon.deduction === 0) {
      callback(new Error(message))
    } else {
      callback()
    }
  }

  static moreThanZeroRequired (value, callback, message) {
    if (value <= 0 || typeof value === 'undefined') {
      callback(new Error(message))
    } else {
      callback()
    }
  }

  static nonEmptyArrayRequired (value, callback, message) {
    if (!Array.isArray(value)) {
      callback(new Error('Wrong value type'))
    }

    if (value.length <= 0) {
      callback(new Error(message))
    } else {
      callback()
    }
  }

  static validateWebsite (value, callback, message, withProtocol = false) {
    const regexp = withProtocol
      ? /^(?:(ftp|http|https)?:\/\/)(?:[\w-]+\.)+(.*){2,255}$/gi
      : /^(?:(ftp|http|https)?:\/\/)?(?:[\w-]+\.)+(.*){2,255}$/gi

    if (!regexp.test(value) && value.length > 0) {
      return callback(new Error(message))
    }

    callback()
  }

  static isEmailValid (value) {
    const emailPattern = new RegExp([
      '^(([^<>()\\]\\\\.,;:\\s@"]+(\\.[^<>()\\]\\\\.,;:\\s@"]+)*)|(".+"))',
      '@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    ].join(''));

    return !!(value && value.match(emailPattern));
  }

  static showError (formRef, offset = 72) {
    this.getFirstError(formRef).then(rsp => {
      const field = formRef.fields.find(t => t.prop === rsp.prop)
      window.scroll(0, field.$el.getBoundingClientRect().top + window.scrollY - offset)
    })
  }

  static getFirstError (form) {
    const fields = form.fields.map(t => t.prop)

    return new Promise(resolve => {
      let flag = false

      fields.forEach(prop => {
        if (flag) return

        form.validateField(prop, (msg) => {
          flag = !!msg

          if (msg) {
            resolve({ prop: prop, msg: msg })
          }
        })
      })
    })
  }

  static validateEventTitle (value, callback, message) {
    if (value.length > 200) {
      callback(new Error(message))
    }

    callback()
  }
}

export default Validation
