export default function getTranslatedLabel (object, field, showDefault, lang, defaultLanguage) {
  if (lang === defaultLanguage) {
    return object[field]
  }
  if (!object.translations) {
    return showDefault ? object[field] : ''
  }
  if (!object.translations.hasOwnProperty(field)) {
    return showDefault ? object[field] : ''
  }

  if (!object.translations[field].hasOwnProperty(lang)) {
    return showDefault ? object[field] : ''
  }

  if (!object.translations[field][lang]) {
    return showDefault ? object[field] : ''
  }

  return object.translations[field][lang]
}
