<template>

  <!-- Design System Input Currency -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Input Number
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
    >

      <!-- Preview -->
      <template #preview>
        <adm-input-currency
          v-model="basicUsage"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputCurrencyCode({ 'v-model': 'price' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Input Number Attributes -->
    <design-system-documentation-table :table-data="tableData" />
    <!-- /Input Number Attributes -->

  </div>
  <!-- /Design System Input Currency -->

</template>

<script>
import AdmInputCurrency from '@/views/_components/input/AdmInputCurrency'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'

export default {
  name: 'DesignSystemInputCurrency',

  components: {
    AdmInputCurrency,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: 0,
      tableData: [
        {
          attribute: 'value / v-model',
          description: 'binding value',
          type: 'string / number',
          values: '-',
          default: '-',
        },
        {
          attribute: 'size',
          description: 'size of the component',
          type: 'string',
          values: 'default / medium / small',
          default: 'default',
        },
        {
          attribute: 'disabled',
          description: 'whether the component is disabled',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'max-width',
          description: 'max width of the input, e.g. \'200px\'',
          type: 'string',
          values: '-',
          default: '-',
        },
      ],
    }
  }
}
</script>
