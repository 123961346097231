<template>
  <div class="share-appointment-details-item">
    <adm-icon class="share-appointment-details-item__icon" :icon="icon" />
    <div>
      <div class="share-appointment-details-item__title">
        {{ title }}
      </div>
      <div class="share-appointment-details-item__value">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
import AdmIcon from '@/views/_components/icon/AdmIcon.vue'

export default {
  name: 'AppointmentDetailsItem',
  components: { AdmIcon },
  props: {
    title: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    }
  }
}
</script>

<style scoped lang="scss">
.share-appointment-details-item {
  display: flex;
  margin-bottom: 8px;
  flex: 100%;

  @include phone-up {
    flex: 50%;
  }

  &__icon {
    margin-right: 8px;
  }

  &__title {
    font-weight: 400;
    font-size: 13px;
    line-height: 24px;
  }

  &__value {
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }
}
</style>
