<template>

  <!-- Success Dialog -->
  <adm-modal
    top="2vh"
    :visible="isOpened"
    class="guide-success-modal"
    width="432px"
  >
    <div class="guide-success-modal__content">
      <el-col :span="5">
        <!-- Success Trophy image -->
        <div class="guide-success-modal__image">
          <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect opacity=".1" width="48" height="48" rx="24" fill="#18CA75"/><path fill-rule="evenodd" clip-rule="evenodd" d="M12.453 22.796c1.003.93 2.53 1.513 4.753 1.537.276.68.593 1.337.944 1.951.66 1.158 1.475 2.225 2.427 3.017.615.512 1.317.932 2.09 1.166v4.2h-1.334a2.667 2.667 0 00-2.666 2.667H29a2.667 2.667 0 00-2.667-2.667h-1.666v-4.05c1.039-.155 1.969-.66 2.756-1.316.952-.792 1.767-1.86 2.427-3.017.35-.614.668-1.272.944-1.951 2.223-.024 3.75-.606 4.753-1.537C36.762 21.668 37 20.221 37 19.3v-3.633a2 2 0 00-2-2h-3.006a3.2 3.2 0 00-3.194-3h-9.6a3.2 3.2 0 00-3.194 3H13a2 2 0 00-2 2V19.3c0 .921.238 2.368 1.453 3.496zM13 15.667h3v2.985c0 1.151.187 2.405.523 3.65-1.437-.119-2.249-.545-2.71-.972-.65-.604-.813-1.407-.813-2.03v-3.633zm21.187 5.663c-.461.427-1.273.854-2.71.972.336-1.245.523-2.499.523-3.65v-2.985h3V19.3c0 .623-.162 1.426-.813 2.03z" fill="#18CA75"/><path d="M26.702 15.3l-3.78 5-1.63-2.11a1.001 1.001 0 00-1.58 1.23l2.43 3.11a1 1 0 001.23.277 1 1 0 00.35-.287l4.57-6a1.006 1.006 0 10-1.6-1.22h.01z" fill="#fff"/></svg>
        </div>
        <!-- /Success Trophy image -->
      </el-col>

      <el-col :span="19">
        <!-- Success Trophy Title -->
        <div v-if="$store.state.guide.stepSuccess.currentStep" class="guide-success-modal__content__title">
          {{ data[$store.state.guide.stepSuccess.currentStep].title }}
          <adm-button
            v-if="data[$store.state.guide.stepSuccess.currentStep].isClosePresented"
            type="borderless"
            color="dark"
            size="mini"
            icon-start="close"
            @click="closeSuccessDialog"
          />
        </div>
        <!-- /Success Trophy Title -->

        <!-- Guide Content -->
        <div class="guide-success-modal__content__text">
          <p v-if="$store.state.guide.stepSuccess.currentStep">
            {{ data[$store.state.guide.stepSuccess.currentStep].description }}
          </p>
        </div>
        <!-- /Guide Content -->

        <!-- Guide Actions -->
        <div
          v-if="$store.state.guide.stepSuccess.currentStep && data[$store.state.guide.stepSuccess.currentStep].buttonText"
          class="guide-success-modal__guide-actions"
        >
          <adm-button type="filled" size="mini" color="grey" @click="openNextPopover">
            {{ data[$store.state.guide.stepSuccess.currentStep].buttonText }}
            <span class="guide-success-modal__guide-actions__icon">
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M5.625 5.976a.75.75 0 01-.167.482l-3.695 4.546a.75.75 0 01-1.166-.946l3.31-4.06L.62 2.017a.75.75 0 01.1-1.06.75.75 0 011.095.092l3.678 4.455a.75.75 0 01.133.471zM11.625 5.976a.75.75 0 01-.167.482l-3.695 4.546a.75.75 0 01-1.166-.946l3.31-4.06-3.288-3.98a.75.75 0 01.1-1.06.75.75 0 011.095.092l3.678 4.455a.75.75 0 01.133.471z" fill="#005AEE"/></svg>
            </span>
          </adm-button>
        </div>
        <!-- /Guide Actions -->

      </el-col>
    </div>
  </adm-modal>
  <!-- /Success Dialog -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmModal from '@/views/_components/modal/AdmModal'
import store from '@/store'

export default {
  name: 'GuideDialogSuccess',
  components: {
    AdmModal,
    AdmButton
  },

  props: {
    isOpened: {
      type: Boolean,
      required: true
    }
  },

  data () {
    return {
      description: '',
      buttonText: '',
      data: {
        generalSettings: {
          title: this.$t('well_done'),
          description: this.$transWithBusinessName(this.$t('general_settings_success_desc')),
          buttonText: this.$t('customize_the_booking_page'),
          nextStep: 'customize',
          isClosePresented: false,
        },
        paymentSettings: {
          title: this.$t('great_job'),
          description: this.$t('you_have_finished_important_step'),
          buttonText: this.$t('lets_create_service'),
          nextStep: 'services',
          isClosePresented: true,
        },
        services: {
          title: this.$t('great_job'),
          description: this.$t('services_success_desc'),
          buttonText: this.$t('lets_create_location'),
          nextStep: 'locations',
          isClosePresented: true,
        },
        locations: {
          title: this.$t('nice_one'),
          description: this.$t('location_success_desc'),
          buttonText: this.$t('lets_create_employee'),
          nextStep: 'employees',
          isClosePresented: true,
        },
        employees: {
          title: this.$t('awesome_work'),
          description: this.$t('employee_success_desc'),
          buttonText: null,
          nextStep: '',
          isClosePresented: true,
        },
        customize: {
          title: this.$t('hooray'),
          description: this.$t('customize_success_desc'),
          buttonText: this.$t('go_to_booking_form'),
          nextStep: 'bookingWebsite',
          isClosePresented: false,
        },
        final: {
          title: this.$t('well_done'),
          description: this.$t('you_have_finished_tutorial'),
          buttonText: null,
          isClosePresented: true,
        }
      }
    }
  },
  methods: {
    openNextPopover () {
      if (this.data[this.$store.state.guide.stepSuccess.currentStep].nextStep) {
        this.$store.commit('guide/openItemPopover', this.data[this.$store.state.guide.stepSuccess.currentStep].nextStep)
      }
      this.$store.dispatch('guide/closeSuccessModal')
    },

    closeSuccessDialog () {
      this.$store.dispatch('guide/closeSuccessModal')
    },
  }
}
</script>

<style lang="scss">
.guide-success-modal {

  .el-dialog {

    &__header {
      display: none;
    }

    width: 485px;
    position: fixed;
    top: 26px;
    right: 40px;
    display: block;
  }

  &__image {
    display: flex;
    margin: 20px auto 20px 0;
    vertical-align: middle;
    text-align: center;
    position: relative;
  }

  &__content {
    background-color: $adm-white;
    word-break: normal;
    font-size: 18px;
    z-index: 999;
    padding-left: 0;

    &__title {
      flex-wrap: wrap;
      font-weight: 600;
      font-size: 18px;
      letter-spacing: .0125em;
      line-height: 2rem;
      padding-left: 0;
      padding-top: 1rem;
      display: flex;
      align-items: center;
      position: relative;

      button {
        position: absolute;
        right: 0;
      }
    }

    &__text {
      font-size: 14px;
      line-height: 22px;
      padding: .5rem 0 1rem;

      p {
        padding-bottom: 0;
      }
    }
  }

  &__guide-actions {
    margin-bottom: 16px;
    padding-left: 0;
    padding-top: 0;
    &__icon {
      vertical-align: middle;
      height: 18px;
      margin-left: 10px;
      path {
        fill: $shade-900;
      }
    }
  }
}
</style>
