<template>
  <div class="share-appointment-entities-slots">
    <adm-date-picker
      ref="datePicker"
      :value="queryParams.timeslots[index]['date']"
      class="share-appointment-entities-slots__date"
      :format="datePickerFormat"
      :picker-options="pickerOptions"
      :append-to-body="false"
      size="small"
      :placeholder="$t('date')"
      :shortcuts="{ onClick: () => selectDate(val) }"
      value-format="yyyy-MM-dd"
      :clearable="false"
      @focus="onDatePickerFocus"
      @change="selectDate"
      @input="selectDate"
    />

    <adm-select
      :value="queryParams.timeslots[index]['time']"
      class="share-appointment-entities-slots__time"
      :disabled="!selectedDate"
      :picker-options="getTimeSelectOptionsWithLimits()"
      :placeholder="$t('time')"
      :clearable="false"
      size="small"
      icon-start="hours"
      @input="selectTime"
    >
      <adm-option
        v-for="time in slotsTimes"
        :key="time"
        :label="formatTime(time)"
        :value="time"
      />
    </adm-select>
    <adm-button
      v-if="isDeleteOptionVisible"
      type="borderless"
      size="micro"
      icon-start="trash"
      @click="$emit('remove')"
    />
  </div>
</template>

<script>
import datetime from '@/mixins/common/datetime'
import duration from '@/mixins/common/duration'
import AdmDatePicker from '@/views/_components/datePicker/AdmDatePicker.vue'
import { _ } from 'vue-underscore'
import AdmSelect from '@/views/_components/select/AdmSelect.vue'
import AdmOption from '@/views/_components/select/AdmOption.vue'
import AdmButton from '@/views/_components/button/AdmButton.vue'
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'TimeSlotItem',

  components: {
    AdmButton,
    AdmOption,
    AdmSelect,
    AdmDatePicker
  },

  mixins: [datetime, duration],

  props: {
    isDeleteOptionVisible: {
      type: Boolean,
    },
    index: {
      type: Number,
      default: 0
    }
  },

  data () {
    return {
      slots: {},
      slotsDates: [],
      isDatePickerOpenedOnce: false,
      loadingTimeSlots: false,
      cancelController: new AbortController(),
      periodBookingAdvance: this.$store.state.settings.general.periodBookingAdvance,
      minimumTimeBeforeBooking: this.$store.state.settings.general.minimumTimeBeforeBooking,
    }
  },

  computed: {
    ...mapState({
      queryParams: state => state.shareBookingForm.queryParams,
    }),

    selectedDate () {
      return this.$store.state.shareBookingForm.queryParams.timeslots[this.index]['date']
    },

    pickerOptions () {
      const $this = this
      const currentDatePickerView = this.currentDatePickerView

      return {
        disabledDate (date) {
          // Disabled all dates in the past except last year && disable all dates in the future after "Period available for booking in advance"
          if (
            $this.$moment(date) < $this.$moment().subtract(1, 'y') ||
            $this.$moment(date) > $this.$moment().add($this.periodBookingAdvance, 'd') ||
            $this.$moment(date) < $this.$moment().add($this.minimumTimeBeforeBooking - 86400, 's')
          ) {
            return true
          }

          // Disable all dates that are not in the time slots response
          if (currentDatePickerView === 'date') {
            return !$this.slotsDates.includes($this.$moment(date).format('YYYY-MM-DD'))
          }
        },
        firstDayOfWeek: this.getElementFirstDayOfWeek(),
        cellClassName () {
          return $this.loadingTimeSlots ? 'skeleton-element-datepicker' : ''
        }
      }
    },

    currentDatePickerMonthAndYear () {
      if (this.isDatePickerOpenedOnce === false) {
        let month = this.$moment().month()
        let year = this.$moment().year()

        return [month, year]
      }

      return [this.$refs.datePicker.$refs.elDatePicker.picker.month, this.$refs.datePicker.$refs.elDatePicker.picker.year]
    },

    currentDatePickerView () {
      if (this.isDatePickerOpenedOnce === false) {
        return 'date'
      }

      return this.$refs.datePicker.$refs.elDatePicker.picker.currentView
    },

    slotsTimes () {
      if (this.selectedDate) {
        const slots = this.slots[this.selectedDate]

        return _.keys(slots)
      }

      return []
    },
  },

  watch: {
    currentDatePickerView (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.datePickerCurrentViewChanged = true
      }
    },

    // Watch for calendar navigation and load new time slots
    currentDatePickerMonthAndYear (newValue, oldValue) {
      if ((newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1]) || this.datePickerCurrentViewChanged) {
        this.datePickerCurrentViewChanged = false

        const currentDatePickerMoment = this.$moment(
          { y: this.currentDatePickerMonthAndYear[1], M: this.currentDatePickerMonthAndYear[0] }
        )

        // Load time slots only if date picker date is in the past not more than 1 year
        // or date picker date is in the future not more than "Period available for booking in advance"
        if (currentDatePickerMoment.endOf('month') > this.$moment().subtract(1, 'y') &&
          currentDatePickerMoment.startOf('month') <= this.$moment().add(this.$store.state.settings.general.periodBookingAdvance, 'd') &&
          this.currentDatePickerView === 'date'
        ) {
          this.loadTimeSlots()
        }
      }
    },
  },

  methods: {
    onDatePickerFocus () {
      this.$nextTick(_ => {
        this.isDatePickerOpenedOnce = true
      })
      this.onActionsDropdownToggle()
      this.loadTimeSlots()
    },

    selectDate (date) {
      if (this.loadingTimeSlots) {
        return
      }

      if (!date || this.slots[date] === undefined || this.slots[date][this.startTime] === undefined) {
        this.$store.commit('shareBookingForm/setTimeslotDate', {
          index: this.index, value: date
        })
      }
    },

    selectTime (time) {
      this.$store.commit('shareBookingForm/setTimeslotTime', {
        index: this.index, value: time
      })
    },

    async loadTimeSlots () {
      // Abort previous request if not finished
      if (this.loadingTimeSlots) {
        this.loadingTimeSlots = false
        this.cancelController.abort()
      }
      this.cancelController = new AbortController()

      this.loadingTimeSlots = true
      try {
        const calendarParams = {
          calendarStartDate: this.$moment(
            { y: this.currentDatePickerMonthAndYear[1], M: this.currentDatePickerMonthAndYear[0] }
          ).startOf('month').format('YYYY-MM-DD'),
          calendarEndDate: this.$moment(
            { y: this.currentDatePickerMonthAndYear[1], M: this.currentDatePickerMonthAndYear[0] }
          ).endOf('month').add(15, 'days').format('YYYY-MM-DD')
        }

        const response = await this.$http.get(
          '/api/v1/public/booking/steps/date-time',
          {
            params: {
              ...calendarParams,
              service: this.queryParams?.service?.id,
              serviceCategory: this.queryParams?.serviceCategory?.id,
              serviceWasPredefined: !!this.queryParams.service,
              employee: this.queryParams?.employee?.id,
              location: this.queryParams?.location?.id,
              extras: this.getExtras(),
              isShareBookingForm: true,
            },
            signal: this.cancelController.signal,
          }
        )

        this.slots = response.data
        this.slotsDates = _.keys(this.slots)
        this.loadingTimeSlots = false
      } catch (e) {
        if (axios.isCancel(e)) return

        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        this.loadingTimeSlots = false
      }
    },

    getExtras () {
      if (!this.queryParams.extras) {
        return []
      }

      let extras = []
      for (let i = 0; i < this.queryParams.extras.length; i++) {
        extras.push(JSON.stringify({
          id: this.queryParams.extras[i].id,
          quantity: this.queryParams.extras[i].quantity,
          numberOfPersons: 1 + this.queryParams.extras[i].additionalPersons
        }))
      }

      return extras
    },

    async onActionsDropdownToggle () {
      this.loadingTimeSlots = true
      try {
        const requestParams = {
          serviceCategory: this.queryParams.serviceCategory?.id,
          service: this.queryParams.service?.id,
        }
        const response = await this.$http.get('/api/v1/booking-links/time-before-and-advanced-booking',
          {
            params: requestParams
          })
        this.periodBookingAdvance = response.data.periodBookingAdvance
        this.minimumTimeBeforeBooking = response.data.minimumTimeBeforeBooking

        this.loadingTimeSlots = false
      } catch {
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        this.loadingTimeSlots = false
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.share-appointment-entities-slots {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
  gap: 4px;

  @include phone-up {
    gap: 18px;
  }

  &__date {
    margin-right: 6px;
  }

  &__time {
    max-width: 132px;
    width: 100%;

    @include phone-up {
      max-width: 100%;
      width: 100%
    }
  }
}
</style>
