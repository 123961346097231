<template>
  <div v-if="showBanner" class="app-announcement-banner">
    <div class="app-announcement-banner__close-btn" @click="closeBanner">
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M12 3a9 9 0 100 18 9 9 0 000-18zm2.439 10.161a.9.9 0 010 1.278.9.9 0 01-1.278 0L12 13.269l-1.161 1.17a.9.9 0 01-1.278 0 .9.9 0 010-1.278L10.731 12l-1.17-1.161a.904.904 0 011.278-1.278L12 10.731l1.161-1.17a.904.904 0 111.278 1.278L13.269 12l1.17 1.161z" fill="#005AEE"/></svg>
    </div>

    <div class="app-announcement-banner__content">
      <div class="app-announcement-banner__content__app">
        <img svg-inline :src="qrCodeScanApp" alt="">
      </div>
      <div class="app-announcement-banner__content__get-app">
        <div class="app-announcement-banner__content__get-app__text">
          <span>{{ $t('download_mobile_app') }}</span>
          <p>{{ $t('now_you_can_use_mobile_app') }}</p>
        </div>
        <img svg-inline src="@/assets/img/trafft-app-store.png" @click="openApp('apple')">
        <img svg-inline src="@/assets/img/app-google-play.png" @click="openApp('google')">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AppAnnouncementBanner',

  data () {
    return {
      qrCodeScanApp: null
    }
  },

  computed: {
    canShowMobileAppBanner () {
      return !this.isBannerClosed && this.loginCount && !this.$store.getters['settings/isAgency']
    },

    isBannerClosed () {
      return this.$store.state.settings.mobileAppBanner.isMobileAppBannerClosed
    },

    showBanner () {
      return this.$store.state.mobileAppBanner.showBanner
    },

    loginCount () {
      return this.$store.state.settings.mobileAppBanner.loginCount >= 2
    }

  },

  created () {
    if (this.canShowMobileAppBanner) {
      this.generateQrCode()
      setTimeout ( () => {
        this.$store.commit('mobileAppBanner/setShowBanner')
      }, 10000)
    }
  },

  methods: {
    async generateQrCode () {
      await this.$http.get('/api/v1/mobile-app-banner/generate-qr-code', {}).then((response) => {
        this.qrCodeScanApp = response.data.uri
      })
    },

    closeBanner () {
      this.$store.dispatch('mobileAppBanner/saveMobileBannerClosed')
      this.$store.commit('settings/setSetting', {
        category: 'mobileAppBanner',
        name: 'isMobileAppBannerClosed',
        value: true
      })
    },

    openApp (app) {
      if (app === 'apple') {
        window.open('https://apps.apple.com/us/app/trafft/id6470921575', '_blank')
      }

      if (app === 'google') {
        window.open('https://play.google.com/store/apps/details?id=com.trafft.dashboard', '_blank')
      }
    }
  }
}
</script>

<style scoped lang="scss">
.app-announcement-banner {
  margin: 0 auto 24px;
  position: relative;
  height: 212px;
  max-width: 360px;
  background: $white;
  border-radius: 12px;
  border: 1px solid $shade-250;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.07), 0px 16px 45px 0px rgba(0, 0, 0, 0.12);

  @include phone-up {
    position: fixed;
    z-index: 999;
    left: 24px;
    bottom: 66px;
    width: 360px;
    max-width: 360px;
  }

  @include tablet-up {
    left: 108px;
    bottom: 0;
  }

  &__close-btn {
    position: absolute;
    right: -14px;
    top: -14px;

    svg {
      width: 28px;
      height: 28px;
      cursor: pointer;
    }

    svg path {
      fill: $shade-800;
    }
  }

  &__content {
    width: 100%;
    height: 100%;
    display: flex;

    &__app {
      position: relative;
      display: flex;
      justify-content: center;
      flex-basis: 50%;
      border-radius: 12px 0 0 12px;
      background: url("~@/assets/img/phone-qr-code.png") no-repeat center;
      background-size: contain;

      @media screen and (max-width: 389px){
        background-size: cover;
      }

      img {
        width: 104px;
        height: 104px;
        margin-top: 48px;

        @media screen and (min-width: 2048px){
          width: 124px;
          height: 124px;
        }
      }
    }

    &__get-app {
      display: flex;
      flex-basis: 50%;
      height: 100%;
      padding: 16px;
      align-items: center;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;

      &__text {
        display: flex;
        flex-direction: column;

        @include tablet-up {
          gap: 4px;
        }

        span {
          color: $shade-900;
          font-size: 13px;
          font-weight: 600;
          line-height: 16px;

          @include tablet-up {
            font-size: 14px;
          }
        }

        p {
          color: $shade-800;
          font-size: 11px;
          line-height: 14px;

          @include tablet-up {
            font-size: 12px;
          }
        }
      }

      img {
        cursor: pointer;
      }
    }
  }
}
</style>
