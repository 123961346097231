export function getBookingUrl (domainData) {
  if (window.location.host.indexOf('localhost:8080') !== -1) {
    return location.protocol + '//' + 'localhost:3000'
  }

  if (domainData.customDomain) {
    return location.protocol + '//' + domainData.customDomain
  }

  return domainData.defaultDomain
}

export function getIframeCode (path = '', domainData, language, employeeSlug = '', showServicesFirst = false) {
  const url = `${getBookingUrl(domainData)}`
  const showservices = showServicesFirst ? ' data-showservices="1"' : ''

  return `<!-- Embedded booking begin -->
<div class="embedded-booking" data-url="${url}" ${path ? `data-query="${path.replace('?', '&')}" ` : ''}${employeeSlug ? `data-employee="${employeeSlug}" ` : ''}${language ? `data-lang="${language}" ` : ''}data-autoresize="1" data-showsidebar="1" style="min-width: 320px; height: 768px;"${ showservices }></div>
<script type="text/javascript" src="${getBookingUrl(domainData)}/embed.js" async></script>
<!-- Embedded booking end -->`
}

export function copyContent (id) {
  const copyText = document.getElementById(id)

  copyText.select()
  copyText.setSelectionRange(0, 99999)

  document.execCommand('copy')
  copyText.blur()
  window.getSelection().empty()
}
