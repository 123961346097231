<template>
  <div class="recurring-item-card">
    <div class="recurring-item-card__data">
      <div class="recurring-item-card__info">
        <div
          class="recurring-item-card__info__date"
          :class="{'recurring-item-card__info__date--warning': booking.isChanged}"
        >
          {{ formatDate(booking.date) }}
        </div>
        <div
          class="recurring-item-card__info__time"
          :class="{'recurring-item-card__info__time--warning': booking.isChanged}"
        >
          {{ booking.timeStart }} - {{ booking.timeEnd }}
        </div>

        <adm-tooltip
          :disabled="!$screen.aboveTablet"
          placement="top"
        >
          <template #content>
            {{ $t('time_slot_from_recurring_not_available') }} <br>
            {{ $t('closest_available_time_assigned') }}
          </template>
          <adm-icon v-if="booking.isChanged" icon="info-filled" size="big" type="warning" />
        </adm-tooltip>
      </div>

      <!-- Actions -->
      <div v-if="!isFirstBooking" class="recurring-item-card__actions-mobile" :class="{'recurring-item-card__actions-mobile--edit': isInEditMode}">
        <adm-actions-dropdown
          :entity="booking"
          :actions="mobileActions"
        />
      </div>

      <div
        v-if="!isFirstBooking"
        class="recurring-item-card__actions"
        :class="{'recurring-item-card__actions--edit': isInEditMode}"
      >
        <template v-if="!isInEditMode">
          <adm-button
            type="borderless"
            icon-start="trash"
            color="red"
            size="mini"
            :disabled="isMinLimitReached"
            @click="deleteBooking(booking)"
          />
          <adm-button type="borderless" size="mini" @click="editBooking()">
            {{ $t('change') }}
          </adm-button>
        </template>

        <template v-else>
          <adm-button type="borderless" color="dark" size="mini" @click="discardEdit()">
            {{ $t('discard') }}
          </adm-button>
          <adm-button type="borderless" size="mini" :disabled="!selectedDate || !startTime" @click="saveEdit">
            {{ $t('save') }}
          </adm-button>
        </template>

      </div>
      <!-- /Actions -->
    </div>

    <div v-if="isInEditMode" class="recurring-item-card__edit">
      <adm-date-picker
        ref="datePicker"
        v-model="selectedDate"
        :format="datePickerFormat"
        :picker-options="pickerOptions"
        size="small"
        :placeholder="$t('select_date')"
        :shortcuts="{ onClick: () => selectDate(val) }"
        value-format="yyyy-MM-dd"
        @focus="onDatePickerFocus"
        @change="selectDate"
      />
      <adm-select
        v-model="startTime"
        :clearable="true"
        :disabled="!selectedDate"
        :picker-options="getTimeSelectOptionsWithLimits()"
        :placeholder="$t('select_time')"
        size="small"
        icon-start="hours"
      >
        <adm-option
          v-for="time in slotsTimes"
          :key="time"
          :label="formatTime(time)"
          :value="time"
        />
      </adm-select>
    </div>
  </div>
</template>

<script>
import datetime from '@/mixins/common/datetime'
import duration from '@/mixins/common/duration'
import AdmTooltip from '@/views/_components/tooltip/AdmTooltip.vue'
import AdmActionsDropdown from '@/views/_components/dropdown/AdmActionsDropdown.vue'
import AdmButton from '@/views/_components/button/AdmButton.vue'
import AdmDatePicker from '@/views/_components/datePicker/AdmDatePicker.vue'
import AdmSelect from '@/views/_components/select/AdmSelect.vue'
import AdmIcon from '@/views/_components/icon/AdmIcon.vue'
import AppointmentUtils from '@/utils/appointment'
import { _ } from 'vue-underscore'
import axios from 'axios'
import AdmOption from '@/views/_components/select/AdmOption.vue'

export default {
  name: 'RecurringItemCard',
  components: { AdmOption, AdmIcon, AdmSelect, AdmDatePicker, AdmButton, AdmActionsDropdown, AdmTooltip },
  mixins: [datetime, duration],
  props: {
    isFirstBooking: {
      type: Boolean,
      required: true,
    },
    booking: {
      type: Object,
      required: true,
    },
    isMinLimitReached: {
      type: Boolean,
      required: true,
    },
    appointmentProp: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      slots: {},
      slotsDates: [],
      isInEditMode: false,
      loadingTimeSlots: false,
      isDatePickerOpenedOnce: false,
      mobileActions: [
        {
          label: this.$t('delete'),
          action: this.deleteBooking,
          icon: 'trash',
          type: 'danger',
          visible: !this.isMinLimitReached
        },
        {
          label: this.$t('change'),
          action: this.editBooking,
          icon: 'time-back',
        }
      ],
      selectedDate: null,
      startTime: null,
      datePickerCurrentViewChanged: false,
      cancelController: new AbortController()
    }
  },
  computed: {
    pickerOptions () {
      const $this = this
      const currentDatePickerView = this.currentDatePickerView

      return {
        disabledDate (date) {
          // Disabled all dates in the past except last year
          if ($this.$moment(date) < $this.$moment().subtract(1, 'y')) {
            return true
          }

          // Disable all dates that are not in the time slots response
          if (currentDatePickerView === 'date') {
            return !$this.slotsDates.includes($this.$moment(date).format('YYYY-MM-DD'))
          }
        },
        firstDayOfWeek: this.getElementFirstDayOfWeek(),
        cellClassName () {
          return $this.loadingTimeSlots ? 'skeleton-element-datepicker' : ''
        }
      }
    },

    currentDatePickerMonthAndYear () {
      if (this.isDatePickerOpenedOnce === false) {
        let month = this.$moment().month()
        let year = this.$moment().year()

        return [month, year]
      }

      return [this.$refs.datePicker.$refs.elDatePicker.picker.month, this.$refs.datePicker.$refs.elDatePicker.picker.year]
    },

    currentDatePickerView () {
      if (this.isDatePickerOpenedOnce === false) {
        return 'date'
      }

      return this.$refs.datePicker.$refs.elDatePicker.picker.currentView
    },

    slotsTimes () {
      if (this.selectedDate) {
        const slots = this.slots[this.selectedDate]

        return _.keys(slots)
      }

      return []
    },
  },

  watch: {
    currentDatePickerView (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.datePickerCurrentViewChanged = true
      }
    },

    // Watch for calendar navigation and load new time slots
    currentDatePickerMonthAndYear (newValue, oldValue) {
      if ((newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1]) || this.datePickerCurrentViewChanged) {
        this.datePickerCurrentViewChanged = false

        const currentDatePickerMoment = this.$moment(
          { y: this.currentDatePickerMonthAndYear[1], M: this.currentDatePickerMonthAndYear[0] }
        )

        // Load time slots only if date picker date is in the past not more than 1 year
        // or date picker date is in the future not more than "Period available for booking in advance"
        if (currentDatePickerMoment.endOf('month') > this.$moment().subtract(1, 'y') &&
          currentDatePickerMoment.startOf('month') <= this.$moment().add(this.$store.state.settings.general.periodBookingAdvance, 'd') &&
          this.currentDatePickerView === 'date'
        ) {
          this.loadTimeSlots()
        }
      }
    },
  },

  methods: {
    editBooking () {
      this.selectedDate = null
      this.startTime = null
      this.isInEditMode = true
    },

    discardEdit() {
      this.isInEditMode = false
    },

    saveEdit(booking) {
      this.discardEdit()
      this.$set(this.booking, 'selectedDate', this.selectedDate)
      this.$set(this.booking, 'date', this.selectedDate)
      this.$set(this.booking, 'selectedTime', this.startTime)
      this.$set(this.booking, 'isChanged', false)
      this.$set(this.booking, 'timeStart', this.formatTime(this.startTime))
      this.$set(this.booking, 'timeEnd', this.formatTime(this.$moment(this.startTime, 'HH:mm:ss').add(booking.duration, 'seconds')))
    },

    deleteBooking (booking) {
      this.$emit('delete', booking)
    },

    onDatePickerFocus () {
      this.$nextTick(_ => {
        this.isDatePickerOpenedOnce = true
      })

      this.loadTimeSlots()
    },

    selectDate (date) {
      if (this.loadingTimeSlots) {
        return
      }

      if (!date || this.slots[date] === undefined || this.slots[date][this.startTime] === undefined) {
        this.startTime = null
      }
    },

    async loadTimeSlots () {
      // Abort previous request if not finished
      if (this.loadingTimeSlots) {
        this.loadingTimeSlots = false
        this.cancelController.abort()
      }
      this.cancelController = new AbortController()

      this.loadingTimeSlots = true
      try {
        const calendarParams = {
          calendarStartDate: this.$moment(
            { y: this.currentDatePickerMonthAndYear[1], M: this.currentDatePickerMonthAndYear[0] }
          ).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
          calendarEndDate: this.$moment(
            { y: this.currentDatePickerMonthAndYear[1], M: this.currentDatePickerMonthAndYear[0] }
          ).endOf('month').add(15, 'days').format('YYYY-MM-DD')
        }

        const response = await this.$http.get(
          '/api/v1/appointments/entities/date-time',
          {
            params: {
              ...calendarParams,
              ...this.$store.state.manageAppointment.queryParams,
              'extras': AppointmentUtils.getExtrasParams(this.appointmentProp),
            },
            signal: this.cancelController.signal,
          }
        )

        this.slots = response.data.payload
        this.slotsDates = _.keys(this.slots)
        this.loadingTimeSlots = false
      } catch (e) {
        if (axios.isCancel(e)) return

        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        this.loadingTimeSlots = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.recurring-item-card {
  font-weight: 500;
  position: relative;
  padding-left: 8px;
  padding-right: 8px;

  &--edit {
    background-color: $shade-150;
  }

  &:not(:first-child) {
    box-shadow: inset 0 1px 0 0 $shade-250;
  }

  &:hover {
    background-color: $shade-150;
    border-radius: 6px;
    box-shadow: none;

    &+div {
      box-shadow: none;
    }

    @include tablet-up {
      .recurring-item-card__actions {
        display: flex;
        align-items: center;
      }
    }
  }

  &__data {
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: 40px;
  }

  &__info {
    display: flex;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-top: 8px;
    padding-bottom: 8px;

    &__date {
      display: inline-block;
      min-width: 100px;
      color: $shade-900;
      margin-right: 8px;

      &--warning {
        color: $yellow-1000;
      }
    }

    &__time {
      margin-right: 12.5px;
      color: $shade-600;

      &--warning {
        color: $yellow-1000;
      }
    }
  }

  &__actions {
    display: none;
    gap: 4px;
    justify-content: end;

    @include phone-up {
      gap: 8px;
    }

    &--edit {
      display: flex;
      flex-wrap: wrap;
    }

    &-mobile {
      margin-right: 4px;

      @include tablet-up {
        display: none;
      }

      &--edit {
        display: none;
      }
    }
  }

  &__edit {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    padding-bottom: 8px;

    @include tablet-up {
      flex-wrap: nowrap;
    }

    & > div {
      flex-basis: 100%;
    }
  }
}
</style>
