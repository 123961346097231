<template>

  <!-- Add New Customer Dialog -->
  <adm-modal
    id="createCustomer"
    :visible="true"
    :title="stepContent[step].title"
    modal-append-to-body
    :width="step === 'create' ? '484px' : '525px'"
    @close="onClose"
  >
    <!-- Manage Customer Form -->
    <el-form
      v-if="step === 'create'"
      ref="customer"
      :model="customer"
      :rules="rulesCustomer"
    >

      <!-- First Name -->
      <el-row :gutter="16">
        <el-col :sm="24">
          <adm-form-item :label="$t('first_name')" prop="firstName">
            <adm-input
              v-if="!$store.state.view.loading"
              v-model="customer.firstName"
              :placeholder="$t('enter_first_name')"
              :maxlength="100"
              autocomplete="nope"
            />
            <adm-skeleton-element v-else :height="40" />
          </adm-form-item>
        </el-col>
      </el-row>
      <!-- /First Name -->

      <!-- Last Name -->
      <el-row :gutter="16">
        <el-col :sm="24">
          <adm-form-item :label="$t('last_name')" prop="lastName">
            <adm-input
              v-if="!$store.state.view.loading"
              v-model="customer.lastName"
              :placeholder="$t('enter_last_name')"
              :maxlength="100"
              autocomplete="nope"
            />
            <adm-skeleton-element v-else :height="40" />
          </adm-form-item>
        </el-col>
      </el-row>
      <!-- /Last Name -->

      <!-- Email -->
      <el-row :gutter="16">
        <el-col :sm="24">
          <adm-form-item :label="$t('email')" prop="email">
            <adm-input
              v-if="!$store.state.view.loading"
              v-model="customer.email"
              :placeholder="$t('email_placeholder')"
              autocomplete="nope"
            />
            <adm-skeleton-element v-else :height="40" />
          </adm-form-item>
        </el-col>
      </el-row>
      <!-- /Email -->

      <!-- Phone -->
      <el-row :gutter="16">
        <el-col :sm="24">
          <adm-form-item :label="$t('phone')" prop="phone">
            <adm-phone-input
              v-if="!$store.state.view.loading"
              :passed-phone-country-code="customer.phoneCountryCode"
              :passed-phone-number="customer.phoneNumber"
              @update="onPhoneUpdate"
            />
            <adm-skeleton-element v-else :height="40" />
          </adm-form-item>
        </el-col>
      </el-row>
      <!-- /Phone -->

      <!-- Send notification to customers -->
      <adm-checkbox
        :disabled="!customer.email"
        :value="customer.sendNotification"
        :description="$t('option_requires_email_address')"
        @change="(value) => $set(customer, 'sendNotification', value)"
      >
        {{ $t('send_an_email_with_customer_login_details') }}
      </adm-checkbox>
      <!-- Send notification to customers -->

    </el-form>
    <!-- /Manage Customer Form -->

    <!-- Successfully created content -->
    <div v-else class="successfully-created">
      <div class="successfully-created__icon">
        <img svg-inline :src="$getDashboardImageLink('illustrations', 'customer-created.svg')">
      </div>

      <div class="successfully-created__description">
        <span class="capitalize">{{ $t('customer') }}</span>
        {{ ' ' }}
        <span class="ultra-bold">{{ customer.firstName }}</span>
        {{ ' ' }}
        {{ $t('has_been_added_successfully') }}
        <br>
        {{ $t('customer_has_been_added_successfully_description') }}
      </div>
    </div>
    <!-- /Successfully created content -->

    <!-- Save & Cancel Buttons -->
    <template #footer>

      <!-- Cancel Button -->
      <adm-button size="medium" color="grey" @click="onClose">
        {{ stepContent[step].close_button_text }}
      </adm-button>
      <!-- /Cancel Button -->

      <!-- Save Button -->
      <adm-button
        size="medium"
        :loading="saveButtonLoading"
        @click="stepContent[step].confirm_button_action"
      >
        {{ stepContent[step].confirm_button_text }}
      </adm-button>
      <!-- /Save Button -->

    </template>
    <!-- /Save & Cancel Buttons -->

  </adm-modal>
  <!-- /Add New Customer Dialog -->

</template>

<script>
import AdmModal from '@/views/_components/modal/AdmModal.vue'
import AdmInput from '@/views/_components/input/AdmInput.vue'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement.vue'
import AdmPhoneInput from '@/views/_components/input/AdmPhoneInput.vue'
import AdmFormItem from '@/views/_components/form/AdmFormItem.vue'
import AdmButton from '@/views/_components/button/AdmButton.vue'
import mixinCustomer from '@/mixins/page/customer'
import mixinValidationPhone from '@/mixins/validation/phone'
import mixinValidationUser from '@/mixins/validation/user'
import { getAbortController } from '@/utils/api'
import Validation from '@/utils/validation'
import AdmCheckbox from '@/views/_components/checkbox/AdmCheckbox.vue'

export default {
  name: 'CreateCustomerDialog',

  components: {
    AdmCheckbox,
    AdmButton,
    AdmFormItem,
    AdmPhoneInput,
    AdmSkeletonElement,
    AdmInput,
    AdmModal,
  },

  mixins: [
    mixinCustomer,
    mixinValidationPhone,
    mixinValidationUser
  ],

  props: {
    type: {
      type: String,
      default: 'default'
    }
  },

  data () {
    return {
      cancelToken: getAbortController(),
      saveButtonLoading: false,
      deleteButtonLoading: false,
      step: 'create',
      customer: {
        id: null,
        customerName: this.$t('new_customer'),
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        phoneCountryCode: '',
        permittedActions: [],
        sendNotification: false,
      },
      rawPhoneNumber: '',
      phoneValid: false,
      rulesCustomer: {
        firstName: [
          { required: true, message: this.$t('enter_first_name_error'), trigger: 'submit' },
          {
            validator: (rule, value, callback) =>
              Validation.fieldIsRequired(value, callback, this.$t('enter_first_name_error')),
            trigger: 'submit'
          },
        ],
        lastName: [
          { required: true, message: this.$t('enter_last_name_error'), trigger: 'submit' },
          {
            validator: (rule, value, callback) =>
              Validation.fieldIsRequired(value, callback, this.$t('enter_last_name_error')),
            trigger: 'submit'
          }
        ],
        email: [
          {
            required: this.$store.state.settings.customize.isEmailMandatory,
            message: this.$t('enter_email_error'),
            trigger: 'submit'
          },
          { type: 'email', message: this.$t('email_not_valid'), trigger: 'submit' },
          {
            validator: (rule, value, callback) => this.validateUserEmailAsync(value, callback, this.customer, true),
            trigger: 'change'
          }
        ],
        phone: [
          {
            required: this.$store.state.settings.customize.isPhoneMandatory,
            validator: (rule, value, callback) =>
              this.$store.state.settings.customize.isPhoneMandatory ?
                this.validatePhoneRequired(callback, this.rawPhoneNumber, this.$t('enter_phone_error')): callback(),
            trigger: 'submit'
          },
          {
            validator: (rule, value, callback) =>
              this.$store.state.settings.customize.isPhoneMandatory ?
                this.validatePhone(callback, this.phoneValid && this.rawPhoneNumber, this.$t('phone_validation')) :
                this.validatePhone(callback, this.phoneValid || !this.rawPhoneNumber, this.$t('phone_validation')),
            trigger: 'submit'
          }
        ]
      },
      stepContent: {
        create: {
          title: this.$t('add_customer'),
          close_button_text: this.$t('cancel'),
          confirm_button_text: this.$t('add_customer'),
          confirm_button_action: this.addCustomer
        },
        success: {
          title: this.$t('customer_successfully_created'),
          close_button_text: this.$t('close'),
          confirm_button_text: this.$t('go_to_customer'),
          confirm_button_action: this.goToCustomer
        }
      }
    }
  },

  methods: {
    onPhoneUpdate (data) {
      this.customer.phoneNumber = data.formattedNumber
      this.customer.phoneCountryCode = data.countryCode
      this.rawPhoneNumber = data.phoneNumber
      this.phoneValid = data.isValid
    },

    addCustomer() {
      this.saveButtonLoading = true

      this.$refs['customer'].validate(async (valid) => {
        if (valid) {
          const customer = this.customer

          this.$http.post(
            '/api/v1/users/customers',
            customer,
          ).then((response) => {
            this.$emit('customer-created', response.data.customer)
            this.customer.id = response.data.id
            if (this.type === 'default') {
              this.step = 'success'
            } else {
              this.onClose()
            }
          }).catch(e => {
            if (e.response.status === 500) {
              this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
            } else if (e.response.data.message) {
              this.$message({ message: e.response.data.message, type: 'error', showClose: true })
            }
            this.saveButtonLoading = false
          }).finally(() => {
            this.saveButtonLoading = false
          })
        } else {
          this.saveButtonLoading = false
        }
      })
    },

    onClose () {
      this.$emit('dialog-close');
    },

    async goToCustomer () {
      await this.onClose();

      await this.$router.push({ path: `customers/${this.customer.id}` })
    },
  }
}
</script>

<style lang="scss">
#createCustomer {
  .successfully-created {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: auto;
    align-items: center;
    text-align: center;
  }
}
</style>
