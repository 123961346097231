const getDefaultQueryParams = () => {
  return {
    serviceCategory: null,
    employee: null,
    location: null,
    dateRange: null,
    service: null,
    timeslots: [
      {date: null, time: null}
    ],
    extras: [],
  }}

const getDefaultState = () => {
  return {
    currentSection: 'shareOptions', // shareOptions, shareParameters, entities, share, socialMediaImages
    visible: false,
    entityType: null,
    entity: null,
    queryParams: getDefaultQueryParams(),
    selectedDateOption: 'daterange', // timeslots
    selectedShareOption: null, // link, embed, qrCode, socialMedia, email objects
    selectedOptionTitle: '',
    showDescription: true,
    selectedSocialMediaImage: null,
    selectedParametersOption: 'withParameters',
  }
}

const state = getDefaultState()

const getters = {
  getTimeslotDates: state => () => {
    let slots = {};

    state.queryParams?.timeslots?.forEach((val) => {
      if (!val.date || !val.time) {
        return
      }
      if (!slots.hasOwnProperty(val.date)) {
        slots[val.date] = []
      }
      slots[val.date].push(val.time)
    })

    return slots
  },
}

const mutations = {
  show (state, payload) {
    state.visible = true
    if (payload && payload.hasOwnProperty('entityType')) {
      state.queryParams[payload.entityType] = payload.entity
    }
  },

  hide (state) {
    Object.assign(state, getDefaultState())
  },

  changeSection (state, payload) {
    state.currentSection = payload
  },

  setQueryParams (state, payload) {
    if (state.queryParams.hasOwnProperty(payload.entityType)) {
      state.queryParams[payload.entityType] = payload.value
    }
  },

  setTimeslotDate (state, payload) {
    state.queryParams['timeslots'][payload.index]['date'] = payload.value
  },

  setTimeslotTime (state, payload) {
    state.queryParams['timeslots'][payload.index]['time'] = payload.value
  },

  setExtraQuantity (state, payload) {
    state.queryParams['extras'][payload.index]['quantity'] = payload.value
  },

  resetQueryParams () {
    state.queryParams = getDefaultQueryParams()
  },

  setParametersOption (state, payload) {
    state.selectedParametersOption = payload
  },

  selectedShareOption (state, payload) {
    state.selectedShareOption = payload
  },

  setOptionTitle (state, payload) {
    state.selectedOptionTitle = payload
  },

  setShowDescription (state, payload) {
    state.showDescription = payload
  },

  setSelectedDateRangeOption (state, payload) {
    state.selectedDateOption = payload
  },

  setSelectedSocialMediaImage (state, payload) {
    state.selectedSocialMediaImage = payload
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
