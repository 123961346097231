const state = {
  'adm-black': '#00040D',
  'adm-white': '#FFFFFF',
  'shade-1000': '#000D27',
  'shade-900': '#00143C',
  'shade-800': '#334363',
  'shade-700': '#66728A',
  'shade-600': '#808A9E',
  'shade-500': '#99A1B1',
  'shade-450': '#B3B9C5',
  'shade-400': '#CCD0D8',
  'shade-300': '#DEE1E6',
  'shade-250': '#EDEFF3',
  'shade-200': '#F4F6FA',
  'shade-150': '#F7F8F9',
  '--primary-1000': '#064EC6',
  '--primary-900': '#005AEE',
  '--primary-800': '#4083F2',
  '--primary-700': '#669CF5',
  '--primary-600': '#8CB4F7',
  '--primary-500': '#CCDEFC',
  '--primary-400': '#E6EFFE',
  '--primary-300': '#F2F6FE',
  '--primary-200': '#F9FBFF',
  'green-1000': '#16A862',
  'green-900': '#18CA75',
  'green-800': '#52D797',
  'green-700': '#80E2B3',
  'green-600': '#AFEDCF',
  'green-500': '#D1F4E3',
  'green-400': '#E8FAF2',
  'green-300': '#F3FCF8',
  'red-1000': '#CF074E',
  'red-900': '#E90E5C',
  'red-800': '#EF4A85',
  'red-700': '#F487AE',
  'red-600': '#F7ABC6',
  'red-500': '#FBCFDE',
  'red-400': '#FDE7EF',
  'red-300': '#FEF3F7',
  'yellow-1000': '#E4A30B',
  'yellow-900': '#FAB005',
  'yellow-800': '#FBC444',
  'yellow-700': '#FCD476',
  'yellow-600': '#FDE4A8',
  'yellow-500': '#FEEFCD',
  'yellow-400': '#FFF8E6',
  'yellow-300': '#FEFBF2',
}

const mutations = {
  setPrimaryColors (state, payload) {
    const isAgency = payload.find(item => item.name === 'agencyUuid').value
    if (isAgency) {
      const dashboardColorPalette = payload.find(item => item.name === 'dashboardColorPalette').value
      Object.assign(state, dashboardColorPalette)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations
}
