<template>
  <div class="adm-tabs">
    <el-tabs
      v-model="model"
      :stretch="stretchTabs"
      v-bind="{...$attrs, ...$props}"
      :before-leave="beforeTabLeave"
      @input="(eventValue) => $emit('input', eventValue)"
      @change="(eventValue) => $emit('change', eventValue)"
    >
      <slot />
    </el-tabs>
  </div>
</template>

<script>
export default {
  name: 'AdmTabs',

  props: {
    value: {
      type: String,
      default: '',
      required: false
    },
    stretchTabs: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selfModel: ''
    }
  },

  computed: {
    model: {
      get () {
        return this.value !== undefined ? this.value : this.selfModel
      },
      set (val) {
        this.selfModel = val
      }
    }
  },

  methods: {
    beforeTabLeave (activeName) {
      this.$emit('before-tab-leave', activeName)

      return !this.$store.state.floatingSave.showContinueButton
    }
  }
}
</script>

<style lang="scss">
// Tabs
.adm-tabs {

  // Tabs Nav
  .el-tabs__nav {
    display: flex;
    margin-bottom: 1px;

    // Item
    .el-tabs__item {
      height: 44px;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0;

      // Adm Tab Item
      .adm-tab-item {
        padding: 0 16px;
        color: $shade-900;
      }

      // Active
      &.is-active {

        // Adm Tab Item
        .adm-tab-item {
          color: var(--primary-900);
        }
      }

      // Focused
      &:focus.is-focus.is-active:not(:active) {
        box-shadow: inset 0 0 0 1px var(--primary-900);
        border-radius: 0;
      }

      // Hover
      &:hover:not(.is-active) {
        -webkit-transition: background 0.15s;
        transition: background 0.15s;
        background: var(--primary-400);
      }
    }

    // Tabs Border
    &-wrap:after {
      background-color: $shade-300;
      height: 1px;
    }
  }

  // Active Bar
  .el-tabs__active-bar {
    background-color: var(--primary-900);
    height: 3px;
    bottom: -1px;
  }
}
</style>
