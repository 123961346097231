<template>

  <!-- Customer Card -->
  <div class="customer-card">
    <!-- Name & Email -->
    <div class="customer-card__info">
      <div
        :title="booking.firstName + ' ' + booking.lastName"
        class="customer-card__info__name overflow-ellipsis"
      >
        {{ booking.firstName + ' ' + booking.lastName }}
      </div>

      <!-- Remove Booking -->
      <adm-button type="borderless" color="dark" size="mini" icon-start="close" @click="removeBooking">
        {{ $t('remove') }}
      </adm-button>
      <!-- /Remove Booking -->
    </div>
    <!-- /Name & Email -->

    <!-- Status, Additional Number Of Persons, Add Extra & Close -->
    <div class="customer-card__actions">
      <div
        :title="booking.email"
        class="customer-card__actions__email overflow-ellipsis"
      >
        {{ booking.email ? booking.email : '' }}
      </div>

      <!-- Status -->
      <div class="customer-card__actions__select-boxes">
        <adm-select
          v-model="booking.status" size="small"
          class="customer-card__actions__select-boxes__status"
          @change="changeStatus(booking.status)"
        >
          <adm-option
            v-for="status in appointmentStatuses"
            :key="status.value"
            :label="status.label"
            :value="status.value"
          />
        </adm-select>

        <!-- Additional Number Of Persons -->
        <adm-select
          v-if="capacityMaximum > 1 || booking.additionalPersons !== 0"
          v-model="booking.additionalPersons"
          class="customer-card__actions__select-boxes__persons ml-8"
          size="small"
          icon-start="customers"
          @change="$emit('number-of-persons-updated')"
        >
          <adm-option
            v-for="number in capacityMaximum"
            :key="number"
            :label="'+' + (number - 1).toString()"
            :value="number - 1"
          />
        </adm-select>
        <!-- /Additional Number Of Persons -->
      </div>
      <!-- /Status -->
    </div>
    <!-- /Status, Additional Number Of Persons, Add Extra & Close -->

    <!-- Selected Extras -->
    <div
      v-if="$store.getters['features/isFeatureEnabled']('extras')
        && appointmentProp.service
        && appointmentProp.service.extras
        && appointmentProp.service.extras.length > 0"
      class="customer-card__extras"
    >
      <div
        v-if="booking.extras && booking.extras.length > 0"
        class="customer-card__extras__selected-extras"
      >
        <!-- Extra Items -->
        <extra-card
          v-for="extra in booking.extras"
          :key="extra.id"
          :extra-prop="extra"
          :remove-extra="removeExtra"
          :set-changed-extra="setChangedExtra"
        />
        <!-- /Extra Items -->
      </div>

      <!-- Add Extra -->
      <div class="customer-card__extras__add-extras">
        <adm-checkbox-dropdown
          :labels="appointmentProp.service.extras.map(extra => extra.name)"
          :values="appointmentProp.service.extras.map(extra => extra.id)"
          :values-checked="booking.extras ? booking.extras.map(extra => extra.id) : []"
          :placeholder="$t('search')"
          @checkbox-toggled="updateExtras"
        >
          <template #action>
            <adm-button type="borderless" size="small" :icon-start="'extras'">
              {{ $t('add_extra') }}
            </adm-button>
          </template>
        </adm-checkbox-dropdown>
      </div>
      <!-- /Add Extra -->
    </div>
    <!-- /Selected Extras -->

    <!-- Custom Fields -->
    <el-form
      v-if="showCustomFields"
      ref="form"
      :model="booking"
      :rules="rulesCustomFields"
      class="customer-card__custom-fields"
    >

      <!-- Header -->
      <el-row type="flex" :class="{ 'mb-20': customFieldsVisible }">

        <!-- Title -->
        <el-row type="flex" align="middle">
          <span class="semi-bold">{{ $t('custom_fields_information') }}</span>
        </el-row>
        <!-- /Title -->

        <!-- Collapse -->
        <adm-button
          class="ml-auto"
          type="borderless"
          color="dark"
          size="micro"
          :icon-start="customFieldsVisible ? 'minus' : 'plus'"
          @click="customFieldsVisible = !customFieldsVisible"
        />
        <!-- /Collapse -->

      </el-row>
      <!-- /Header -->

      <!-- Body -->
      <el-row v-show="customFieldsVisible">
        <el-col
          v-for="customField in customFieldsToDisplay"
          :key="customField.id"
          :sm="24"
        >
          <el-col :sm="['text', 'textArea', 'selectbox', 'datePicker'].includes(customField.type) ? 12 : 24">
            <adm-form-item
              :label="customField.label"
              :prop="'customFields.' + customField.id"
              class="mb-20"
            >

              <!-- Text -->
              <adm-input
                v-if="customField.type === 'text'"
                v-model="booking.customFields[customField.id]"
                size="small"
                autocomplete="nope"
                :placeholder="customField.placeholder"
                :maxlength="255"
              />
              <!-- /Text -->

              <!-- Textarea -->
              <adm-input
                v-if="customField.type === 'textArea'"
                v-model="booking.customFields[customField.id]"
                autocomplete="nope"
                :placeholder="customField.placeholder"
                type="textarea"
              />
              <!-- /Textarea -->

              <!-- Selectbox -->
              <adm-select
                v-if="customField.type === 'selectbox'"
                v-model="booking.customFields[customField.id]"
                size="small"
                value-key="id"
                :placeholder="customField.placeholder"
                :clearable="true"
              >
                <adm-option
                  v-for="option in customField.options"
                  :key="option.id"
                  :label="option.label"
                  :value="option.id"
                />
              </adm-select>
              <!-- /Selectbox -->

              <!-- Checkbox -->
              <template v-if="customField.type === 'checkbox'">
                <adm-checkbox-group v-model="booking.customFields[customField.id]">
                  <adm-checkbox
                    v-for="option in customField.options"
                    :key="option.id"
                    :label="option.id"
                  >
                    {{ option.label }}
                  </adm-checkbox>
                </adm-checkbox-group>
              </template>
              <!-- /Checkbox -->

              <!-- Radio Buttons -->
              <template v-if="customField.type === 'radioButtons'">
                <adm-radio
                  v-for="option in customField.options"
                  :key="option.id"
                  v-model="booking.customFields[customField.id]"
                  :label="option.id"
                >
                  {{ option.label }}
                </adm-radio>
              </template>
              <!-- /Radio Buttons -->

              <!-- Date Picker -->
              <adm-date-picker
                v-if="customField.type === 'datePicker'"
                v-model="booking.customFields[customField.id]"
                size="small"
                :format="datePickerFormat"
                :placeholder="customField.placeholder"
                :clearable="true"
              />
              <!-- /Date Picker -->

              <!-- Address -->
              <template v-if="customField.type === 'address'">
                <adm-input
                  :id="'address-autocomplete-'+ addressCustomFieldKey + '-' + customField.id"
                  v-model="autocompleteAddress[customField.id]"
                  type="text"
                  :placeholder="customField.placeholder"
                  size="small"
                  @focus="addressAutocomplete('address-autocomplete-'+ addressCustomFieldKey + '-' + customField.id, booking.customFields)"
                  @blur="getAddressValueOnBlur('address-autocomplete-'+ addressCustomFieldKey + '-' + customField.id, booking.customFields)"
                />
              </template>
              <!-- /Address -->

              <!-- Attachment -->
              <template v-if="customField.type === 'attachment'">
                <adm-uploader
                  :id="customField.id"
                  action=""
                  drag
                  :multiple="false"
                  :accepted-type="'image/*, application/*, text/plain, officedocument.presentationml.presentation, officedocument.wordprocessingml.document, .rar, .7z'"
                  :auto-upload="false"
                  :show-file-list="true"
                  :file-list="files[customField.id] ? files[customField.id] : []"
                  @on-change="(file) => onFileUpload(file, customField.id, booking.customFields)"
                  @on-remove="onFileRemove(customField.id, booking.customFields, $refs.form)"
                  @exceed="onFileExceed(customField.id, booking.customFields, $refs.form)"
                >
                  <!-- Upload Icon -->
                  <i class="el-icon-upload" />
                  <!-- /Upload Icon -->

                  <!-- Upload Instructions -->
                  <div v-html="$t('drop_file_here')" />
                  <!-- /Upload Instructions -->
                </adm-uploader>
              </template>
              <!-- /Attachment -->

            </adm-form-item>
          </el-col>
        </el-col>
      </el-row>
      <!-- /Body -->

    </el-form>
    <!-- /Custom Fields -->

    <!-- Recurring -->
    <recurring
      v-if="isRecurringAvailable"
      :appointment-prop="appointmentProp"
      :booking-prop="bookingProp"
    />
    <!-- /Recurring -->

    <!-- Coupon -->
    <div
      v-if="$store.getters['features/isFeatureEnabled']('coupons') && appointmentProp.service"
      class="customer-card__coupon"
    >
      <div
        class="customer-card__coupon__container"
        :class="{'customer-card__coupon__container--info-presented': couponErrorMessage || isCouponAppliedNow }"
      >
        <adm-form-item
          class="customer-card__coupon__container__form-item"
          :label="$t('enter_coupon')"
          :error="couponErrorMessage"
          :description="isCouponAppliedNow ? $t('coupon_successfully_applied') : ''"
        >
          <adm-tooltip
            :content="$t('coupon_disabled_for_multiple_appointments_in_invoice')"
            placement="top"
            :disabled="!bookingProp.isCouponDisabled"
          >
            <adm-input
              v-model="couponCode"
              :disabled="loadingCoupon || isCouponApplied || bookingProp.isCouponDisabled"
              size="small"
              :placeholder="$t('enter_coupon_here')"
            />
          </adm-tooltip>
        </adm-form-item>
        <adm-button
          v-if="!isCouponApplied"
          :disabled="!couponCode || bookingProp.isCouponDisabled"
          size="small"
          :loading="loadingCoupon"
          @click="applyCoupon()"
        >
          {{ $t('apply') }}
        </adm-button>
        <adm-button
          v-if="isCouponApplied"
          icon-start="close"
          size="small"
          :loading="loadingCoupon"
          color="red"
          :disabled="bookingProp.isCouponDisabled"
          @click="clearCoupon()"
        >
          {{ $t('clear_coupon') }}
        </adm-button>
      </div>

      <div
        v-if="isCouponLimitedInfoPresented"
        class="customer-card__coupon__info"
      >
        {{ $t('coupon_limited_recurring') }} {{ bookingProp.couponData.limit }}.
      </div>
    </div>
    <!-- /Coupon -->


    <!-- Reason for canceling appointment modal -->
    <adm-modal
      class="adm-appointments-date-rows__canceling-modal"
      width="484px"
      :visible="openReasonForCancelingAppointmentModal"
      @close="close"
    >
      <template #title>
        <span class="adm-modal__title">{{ $t('reason_for_canceling') }}</span>
      </template>
      <div class="adm-appointments-date-rows__canceling-modal__content">
        <p>{{ $t('add_reason_for_cancelling') }}</p>
        <el-form
          ref="reasonForCancelingAppointmentForm"
          :rules="rulesCancelingAppointment"
          :model="cancelingAppointment"
        >
          <el-form-item prop="reason">
            <adm-input
              v-model="cancelingAppointment.reason"
              class="mt-8"
              :placeholder="$t('enter_reason_here')"
              :maxlength="255"
              resize="none"
              type="textarea"
              :rows="3"
              @blur="cancelingAppointment.reason = cancelingAppointment.reason.trim()"
            />
          </el-form-item>
        </el-form>
      </div>
      <template #footer>
        <!-- Cancel Button -->
        <adm-button size="medium" color="grey" @click="close()">
          {{ $t('cancel') }}
        </adm-button>
        <!-- /Cancel Button -->
        <!-- Save Button -->
        <adm-button size="medium" @click="enterReasonForCancelingAppointment">
          {{ $t('done') }}
        </adm-button>
        <!-- /Save Button -->
      </template>
    </adm-modal>
    <!-- /Reason for canceling appointment modal -->
  </div>
  <!-- /Customer Card -->
</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmCheckbox from '@/views/_components/checkbox/AdmCheckbox'
import AdmCheckboxDropdown from '@/views/_components/dropdown/AdmCheckboxDropdown'
import AdmCheckboxGroup from '@/views/_components/checkbox/AdmCheckboxGroup'
import AdmDatePicker from '@/views/_components/datePicker/AdmDatePicker'
import AdmFormItem from '@/views/_components/form/AdmFormItem'
import AdmInput from '@/views/_components/input/AdmInput'
import AdmOption from '@/views/_components/select/AdmOption'
import AdmRadio from '@/views/_components/radio/AdmRadio'
import AdmSelect from '@/views/_components/select/AdmSelect'
import mixinDateTime from '@/mixins/common/datetime'
import mixinDuration from '@/mixins/common/duration'
import mixinStatus from '@/mixins/common/status'
import ExtraCard from './ExtraCard/ExtraCard'
import AdmModal from '@/views/_components/modal/AdmModal'
import AppointmentUtils from '@/utils/appointment'
import tax from '@/mixins/common/tax'
import mixinLocation from '@/mixins/page/location'
import mixinCustomFields from '@/mixins/customFields/customFields'
import Recurring
  from './Recurring/Recurring.vue'
import AdmTooltip from '@/views/_components/tooltip/AdmTooltip.vue'
import AdmUploader from '@/views/_components/uploader/AdmUploader.vue'
import mixinPrice from '@/mixins/common/price'

export default {
  name: 'CustomerCard',

  components: {
    AdmUploader,
    AdmTooltip,
    Recurring,
    ExtraCard,
    AdmDatePicker,
    AdmRadio,
    AdmCheckbox,
    AdmCheckboxGroup,
    AdmInput,
    AdmFormItem,
    AdmButton,
    AdmCheckboxDropdown,
    AdmOption,
    AdmSelect,
    AdmModal
  },

  mixins: [
    mixinDuration,
    mixinStatus,
    tax,
    mixinCustomFields,
    mixinPrice,
  ],

  props: {
    appointmentProp: {
      type: Object,
      required: true,
      default: () => ({})
    },
    bookingProp: {
      type: Object,
      required: true,
      default: () => ({})
    },
    capacityMaximum: {
      type: Number,
      required: true,
      default: 1
    },
    customFields: {
      type: Array,
      default: () => ([])
    },
    appliedCoupons: {
      type: Object,
      required: true,
    },
    googleMapsLoader: {
      type: Object,
      default: () => {}
    },
  },

  data () {
    return {
      loadingCoupon: false,
      couponCode: '',
      isCouponApplied: false,
      isCouponAppliedNow: false,
      couponErrorMessage: '',
      cancelingAppointment: {
        reason: ''
      },
      rulesCancelingAppointment: {
        'reason': [
          {
            required: this.$store.state.settings.customize.isReasonForCancelingMandatory,
            message: this.$t('enter_reason_for_canceling'),
            trigger: 'submit'
          }
        ]
      },
      reasonForCancelingAppointmentModal: false,
    }
  },

  computed: {
    booking: {
      get () { return this.bookingProp },
      set (booking) { this.$set(this, 'booking', booking) }
    },

    openReasonForCancelingAppointmentModal () {
      return this.reasonForCancelingAppointmentModal && this.$store.state.settings.customize.showInputForCancelingAppointment
    },

    isRecurringAvailable () {
      return !this.appointmentProp.id
        && this.appointmentProp.service
        && this.appointmentProp.employee
        && this.appointmentProp.startTime
        && this.appointmentProp.service.recurringOptions !== null
    },

    isCouponLimitedInfoPresented () {
      if (!this.couponCode || !this.bookingProp.couponData) {
        return false
      }

      if (this.bookingProp.couponData.limit === false || this.bookingProp.couponData.limit === null) {
        return false
      }

      return this.isRecurringAvailable === true
        && this.bookingProp.recurringData
        && this.bookingProp.recurringData.bookings.length > 1
        && this.appointmentProp.service.recurringOptions.recurringAppointmentsPayment === 'first_appointment_only'
        && this.bookingProp.couponData.limit < this.bookingProp.recurringData.bookings.length
    },

    customFieldsToDisplay () {
      // If Booking is already saved, show only custom fields that were available in time of booking
      if (this.bookingProp.bookingId) {
        return this.customFields.filter(item => item.type !== 'textContent' && Object.keys(this.bookingProp.customFields).includes(item.id.toString()))
      }

      return this.customFields.filter(item => item.type !== 'textContent' && item.deleted === false && (item.services.length === 0 || item.services.includes(this.appointmentProp.service ? this.appointmentProp.service.id : null)))
    },

    showCustomFields () {
      return this.appointmentProp.service &&
        Object.keys(this.booking.customFields).length !== 0 &&
        this.customFieldsToDisplay.length > 0 &&
        (this.$store.getters['features/isFeatureEnabled']('custom_fields') || Object.keys(this.booking.customFields).length)
    },
  },

  created () {
    this.couponCode = this.booking.couponCode
    if (this.couponCode) {
      this.isCouponApplied = true
    }

    this.setCustomFieldsOptions(this.booking.customFields, this.bookingProp.bookingId)
    this.setCustomFieldsValue(this.booking.customFields)
  },

  methods: {
    close () {
      this.reasonForCancelingAppointmentModal = false
    },

    changeStatus (status) {
      if (status === 3) {
        this.reasonForCancelingAppointmentModal = true
      }
    },

    removeBooking () {
      this.setChangedExtra({})
      this.$emit('booking-removed', this.booking)
    },

    updateExtras (extraId) {
      if (typeof this.booking.extras.find(extra => extra.id === extraId) === 'undefined') {
        this.booking.extras.push(Object.assign({}, {
          ...this.appointmentProp.service.extras.find(extra => extra.id === extraId),
          quantity: 1
        }))
      } else {
        this.booking.extras.splice(this.booking.extras.findIndex(extra => extra.id === extraId), 1)
      }

      const changedExtra = this.booking.extras.find(extra => extra.id === extraId)

      this.setChangedExtra(changedExtra)
      this.$emit('extra-updated')
    },

    removeExtra (extra) {
      this.setChangedExtra({})
      this.$emit('extra-removed', this.booking, extra)
    },

    setChangedExtra (extra) {
      this.$set(this.appointmentProp, 'changedExtra', extra)
    },

    async applyCoupon () {
      this.loadingCoupon = true

      let appliedCount = 0
      if (this.appliedCoupons.hasOwnProperty(this.couponCode)) {
        appliedCount = this.appliedCoupons[this.couponCode]
      }

      try {
        const response = await this.$http.post(
          '/api/v1/appointments/coupon/validate',
          {
            code: this.couponCode,
            email: this.booking.email,
            service: this.appointmentProp.service.id,
            appliedCount,
            bookingId: this.booking.bookingId,
            price: this.booking.price
          }
        )

        const coupon = response.data.coupon
        this.booking.couponCode = this.couponCode

        let discount = 0
        if (coupon.type === 'deduction') {
          discount = coupon.discountValue
        } else {
          let price = AppointmentUtils.getPriceForBookingWithoutDiscount(this.booking)
          if (this.$store.state.settings.payments.taxDisplaySettings === 'exclusive' && !this.booking.bookingId) {
            price += this.calculatePriceDetailsTax(this.booking, this.appointmentProp)
          }

          discount = price * coupon.discountValue / 100
        }

        this.$set(this.bookingProp, 'discount', discount)
        this.$set(this.bookingProp, 'couponId', coupon.id)
        this.$set(this.bookingProp, 'couponData', coupon)

        this.couponErrorMessage = null
        this.isCouponApplied = true
        this.isCouponAppliedNow = true
        this.$emit('added-coupon', this.couponCode)
      } catch (e) {
        const errorMessage = e?.response?.data?.message

        if (errorMessage) {
          this.couponErrorMessage = this.$t(`${errorMessage}`)
        }

        if (e?.response?.data?.amount) {
          this.couponErrorMessage += ` ${this.formatPrice(e?.response?.data?.amount)}`
        }
      } finally {
        this.loadingCoupon = false
      }
    },

    clearCoupon () {
      if (this.isCouponAppliedNow) {
        this.$emit('removed-coupon', this.couponCode)
      }
      this.$set(this.bookingProp, 'discount', 0)
      this.$set(this.bookingProp, 'couponId', null)
      this.$set(this.bookingProp, 'couponData', null)
      this.booking.couponCode = null
      this.couponCode = ''
      this.isCouponApplied = false
      this.isCouponAppliedNow = false
    },

    enterReasonForCancelingAppointment () {
      this.$refs.reasonForCancelingAppointmentForm.validate((valid) => {
        if (valid) {
          this.booking.reasonForCanceling = this.cancelingAppointment.reason
          this.reasonForCancelingAppointmentModal = false
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
// Customer Card
.customer-card {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.15);
  background: $adm-white;
  border-radius: 5px;
  margin-top: 0.75rem;

  // Info
  &__info {
    padding: 13px 1rem;
    background: $shade-200;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    // Name
    &__name {
      font-weight: 600;
    }
  }

  // Actions
  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 12px 16px;
    border-bottom: 1px solid $shade-300;
    flex-wrap: wrap;
    gap: 8px;

    @include phone-down {
      flex-direction: column;
      align-items: flex-start;
    }

    &__email {
      flex-grow: 1;
    }

    &__select-boxes {
      display: flex;
      justify-content: flex-end;
      flex: 1;

      &__status {
        width: 125px;
      }

      &__persons {
        width: 100px;
      }
    }
  }

  &__extras {
    padding: 12px 16px;

    &__selected-extras {
      display: flex;
      flex-direction: column;
      gap: 16px;
      margin-bottom: 8px;
    }
  }

  // Custom Fields
  &__custom-fields {
    border-top: 1px solid $shade-300;
    padding: 0.75rem 1.25rem;

    .adm-file-uploader {
      margin-bottom: 35px;
    }

    ::v-deep .adm-file-uploader__image {
      width: 246px;
      height: 108px;
      margin-bottom: 20px;
    }

    ::v-deep .el-upload-list__item {
      max-width: 246px;
      width: 246px;
      margin-top: 20px;
      left: -10px;
    }
  }

  // Coupon
  &__coupon {
    border-top: 1px solid $shade-300;
    padding: 0.75rem 1.25rem;

    &__container {
      display: flex;
      align-items: flex-end;
      gap: 16px;

      &--info-presented {
        align-items: center;
      }

      &__form-item {
        width: 100%;
        margin-bottom: 0;

        ::v-deep .adm-form-item__description {
          color: $green-900;
        }
      }
    }

    &__info {
      font-size: 12px;
      font-weight: 400;
      line-height: 14px;
      margin-top: 4px;
    }
  }
}
</style>
