<template>
  <div class="adm-file-uploader">

    <div class="adm-file-uploader__image">
      <!-- Uploader & Preview -->
      <el-upload
        ref="adm-file-uploader"
        drag
        action=""
        :limit="1"
        :multiple="false"
        :auto-upload="false"
        :accept="acceptedType"
        :show-file-list="showFileList"
        :file-list="fileList"
        :on-change="onFileChange"
        :on-remove="onFileRemove"
        :on-exceed="handleExceed"
      >

        <!-- Uploader -->
        <div v-if="!file" class="el-upload-dragger__upload">

          <div class="flex-center flex-column">
            <!-- Upload Icon -->
            <adm-icon v-if="!$slots.icon" size="extraLarge" icon="arrow-circle-top" />
            <slot v-else name="icon" />
            <!-- /Upload Icon -->

            <!-- Upload Instructions -->
            <div class="el-upload__text" v-html="getUploadInstruction()" />
            <!-- Upload Instructions -->
          </div>

        </div>
        <!-- /Uploader -->

        <!-- Preview -->
        <div v-else class="uploaded-photo-preview">
          <span>{{ file.name }}</span>
        </div>
        <!-- /Preview -->

      </el-upload>
      <!-- /Uploader & Preview -->
    </div>

    <div v-if="file" class="adm-file-uploader__actions">
      <!-- Change Image Action -->
      <div>
        <adm-button
          size="mini"
          type="ghost"
          color="dark"
          @click="openFileDialog"
        >
          {{ $t('change') }}
        </adm-button>
      </div>
      <!-- /Change Image Action -->

      <!-- Delete Image Action -->
      <adm-button
        class="ml-8"
        size="mini"
        color="red"
        type="ghost"
        icon-start="trash"
        @click="onImageDelete"
      >
        {{ $t('delete') }}
      </adm-button>
      <!-- /Delete Image Action -->
    </div>

  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import AdmButton from '@/views/_components/button/AdmButton'
import AdmIcon from '@/views/_components/icon/AdmIcon'

export default {
  name: 'AdmUploader',

  components: {
    AdmIcon,
    AdmButton,
  },

  props: {
    value: {
      type: Object,
      default: null,
    },
    acceptedType: {
      type: String,
      default: null,
    },
    showFileList: {
      type: Boolean,
      default: false
    },
    fileList: {
      type: Array,
      default() {
        return [];
      }
    },
  },

  computed: {
    file: {
      get () {
        return this.value !== undefined ? this.value : this.selfModel
      },
      set (val) {
        this.file = val
      }
    },
  },

  methods: {
    onFileChange (file) {
      this.selfModel = file
      this.$emit('input', file)
      this.$emit('on-change', file)
    },

    onFileRemove (file) {
      this.$emit('on-remove')
    },

    handleExceed (file) {
      this.$refs['adm-file-uploader'].clearFiles()
      this.$emit('exceed')
      setTimeout (() => {
        this.$refs['adm-file-uploader'].handleStart(file[0])
      }, 1000)
    },

    onImageDelete () {
      this.$emit('input', '')
    },

    getUploadInstruction () {
      return this.instructions ? this.instructions : this.$t('drop_zone');
    },

    openFileDialog () {
      const input = this.$refs['adm-file-uploader'].$refs['upload-inner'].$refs.input

      if (input) {
        input.click();
      }
    }
  }
}
</script>

<style lang="scss">
.adm-file-uploader {
  &__image {
    border: 1px dashed var(--primary-900);
    border-radius: 5px;
    padding: 8px;
    height: 132px;

    & > div {
      height: 100%;
    }

    .el-upload {
      display: flex;
      height: 100%;

      &__text {
        line-height: 20px;
        margin-top: 8px;
      }

      &-dragger {
        border-radius: 5px;
        border: none;
        background-color: var(--primary-300);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .uploaded-photo-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  &__actions {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
