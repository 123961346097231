import Cookies from 'js-cookie'
import { isLocalStorageAvailable } from '@/utils/localStorage'
import { ADMIN_SLUG, SUPER_ADMIN_SLUG } from '@/mixins/security/security'

export const EMPLOYEE = 1
export const CUSTOMER = 2

const getDefaultState = () => {
  return {
    id: 0,
    email: '',
    firstName: '',
    lastName: '',
    mediaUrl: '',
    userType: null,
    phoneNumber: null,
    phoneCountryCode: null,
    timezone: null,
    roles: [],
    permissions: [],
    allEntitiesPermissions: []
  }
}

const state = getDefaultState()

const getters = {
  isLoggedIn: state => !!state.email && (!!Cookies.get('access_token') || (isLocalStorageAvailable() && !!localStorage.getItem('access_token'))),

  isEmployee: state => state.userType === EMPLOYEE,

  isSuperAdmin: state => {
    if (state?.roles) {
      return state.roles.some(role => role.code === SUPER_ADMIN_SLUG)
    }

    return false
  },

  isAdmin: state => {
    if (state?.roles) {
      return state.roles.some(role => role.code === ADMIN_SLUG)
    }

    return false
  },

  fullName: function (state) {
    return state.firstName + ' ' + state.lastName
  },

  email: function (state) {
    return state.email
  }
}

const mutations = {
  setUser (state, payload) {
    const allowedUpdates = Object.keys(state)
    Object.keys(payload).forEach((property) => {
      if (allowedUpdates.includes(property)) {
        state[property] = payload[property]
      }
    })
  },

  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setId (state, payload) {
    state.id = payload.value
  },

  setEmail (state, payload) {
    state.email = payload.value
  },

  setType (state, payload) {
    state.type = payload.value
  },

  setPermissions (state, payload) {
    state.permissions = payload
  },

  setAllEntitiesPermissions (state, payload) {
    state.allEntitiesPermissions = payload
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
