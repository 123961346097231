<template>

  <!-- Design System Button -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Button
    </h2>
    <!-- /Title -->

    <!-- Filled -->
    <design-system-element-block
      title="Filled"
      description="Available colors are: blue, grey, red and green."
      code-description="Use attribute type to set filled button. Use attribute color to set different colors."
      class="design-buttons-wrap"
    >

      <!-- Preview -->
      <template #preview>
        <adm-button>
          Blue
        </adm-button>
        <adm-button color="grey">
          Grey
        </adm-button>
        <adm-button color="red">
          Red
        </adm-button>
        <adm-button color="green">
          Green
        </adm-button>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getButtonCode({}, 'Blue') }}
        <br>
        {{ getButtonCode({ color: 'grey' }, 'Grey') }}
        <br>
        {{ getButtonCode({ color: 'red' }, 'Red') }}
        <br>
        {{ getButtonCode({ color: 'green' }, 'Green') }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Filled -->

    <!-- Ghost -->
    <design-system-element-block
      title="Ghost"
      description="Available colors are: blue, dark and red."
      code-description="Use attribute type to set ghost button. Use attribute color to set different colors."
      class="design-buttons-wrap"
    >

      <!-- Preview -->
      <template #preview>
        <adm-button type="ghost">
          Blue
        </adm-button>
        <adm-button type="ghost" color="dark">
          Dark
        </adm-button>
        <adm-button type="ghost" color="red" icon-start="trash">
          Red
        </adm-button>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getButtonCode({ type: 'ghost' }, 'Blue') }}
        <br>
        {{ getButtonCode({ type: 'ghost', color: 'dark' }, 'Dark') }}
        <br>
        {{ getButtonCode({ type: 'ghost', color: 'red', 'icon-start': 'trash' }, 'Red') }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Ghost -->

    <!-- Borderless -->
    <design-system-element-block
      title="Borderless"
      description="Available colors are: blue, dark and light."
      code-description="Use attribute type to set borderless button. Use attribute color to set different colors."
      class="design-buttons-wrap"
    >

      <!-- Preview -->
      <template #preview>
        <adm-button type="borderless">
          Blue
        </adm-button>
        <adm-button type="borderless" color="dark">
          Dark
        </adm-button>
        <adm-button type="borderless" color="light">
          Light
        </adm-button>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getButtonCode({ type: 'borderless' }, 'Blue') }}
        <br>
        {{ getButtonCode({ type: 'borderless', color: 'dark' }, 'Dark') }}
        <br>
        {{ getButtonCode({ type: 'borderless', color: 'light' }, 'Light') }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Borderless -->

    <!-- Disabled -->
    <design-system-element-block
      title="Disabled"
      description="The disabled attribute determines if the button is disabled."
      code-description="Use disabled attribute to determine whether a button is disabled. It accepts a Boolean value."
      class="design-buttons-wrap"
    >

      <!-- Preview -->
      <template #preview>
        <adm-button :disabled="true">
          Blue
        </adm-button>
        <adm-button color="grey" :disabled="true">
          Grey
        </adm-button>
        <adm-button color="red" :disabled="true">
          Red
        </adm-button>
        <adm-button color="green" :disabled="true">
          Green
        </adm-button>
        <adm-button type="ghost" :disabled="true">
          Blue
        </adm-button>
        <adm-button type="ghost" color="dark" :disabled="true">
          Dark
        </adm-button>
        <adm-button type="ghost" color="red" icon-start="trash" :disabled="true">
          Red
        </adm-button>
        <adm-button type="borderless" :disabled="true">
          Blue
        </adm-button>
        <adm-button type="borderless" color="dark" :disabled="true">
          Dark
        </adm-button>
        <adm-button type="borderless" color="light" :disabled="true">
          Light
        </adm-button>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getButtonCode({ disabled: true }, 'Blue') }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Disabled -->

    <!-- Size -->
    <design-system-element-block
      title="Size"
      description="Besides default size, Button component provides three additional sizes for you to choose among different scenarios."
      code-description="Use attribute size to set additional sizes with medium, small or mini."
      class="design-buttons-wrap"
    >

      <!-- Preview -->
      <template #preview>
        <adm-button>
          Default
        </adm-button>
        <adm-button size="medium">
          Medium
        </adm-button>
        <adm-button size="small">
          Small
        </adm-button>
        <adm-button size="mini">
          Mini
        </adm-button>
        <adm-button size="micro">
          Micro
        </adm-button>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getButtonCode({}, 'Default') }}
        <br>
        {{ getButtonCode({ size: 'medium' }, 'Medium') }}
        <br>
        {{ getButtonCode({ size: 'small' }, 'Small') }}
        <br>
        {{ getButtonCode({ size: 'mini' }, 'Mini') }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Size -->

    <!-- Icon -->
    <design-system-element-block
      title="Icon"
      description="Use icons to add more meaning to Button. You can use icon alone to save some space, or use it with text."
      code-description="Use the icon-start and icon-end attributes to add icon before or after text. You can find the icon list in Element icon component."
      class="design-buttons-wrap"
    >

      <!-- Preview -->
      <template #preview>
        <adm-button icon-start="plus">
          Add Appointment
        </adm-button>
        <adm-button icon-start="arrow-right">
          Continue
        </adm-button>
        <adm-button color="grey" icon-start="plus" />
        <adm-button color="red" icon-start="trash" />
        <adm-button color="green" icon-start="save" />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getButtonCode({ 'icon-start': 'plus' }, 'Add Appointment') }}
        <br>
        {{ getButtonCode({ 'icon-start': 'arrow-right' }, 'Continue') }}
        <br>
        {{ getButtonCode({ 'color': 'grey', 'icon-start': 'plus' }, '') }}
        <br>
        {{ getButtonCode({ 'color': 'red', 'icon-start': 'trash' }, '') }}
        <br>
        {{ getButtonCode({ 'color': 'green', 'icon-start': 'save' }, '') }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Icon -->

    <!-- Loading -->
    <design-system-element-block
      title="Loading"
      description="Click the button to load data, then the button displays a loading state."
      code-description="Set loading attribute to true to display loading state."
      class="design-buttons-wrap"
    >

      <!-- Preview -->
      <template #preview>
        <adm-button :loading="true">
          Loading
        </adm-button>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getButtonCode({ loading: true }, 'Loading') }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Loading -->

    <!-- Group -->
    <design-system-element-block
      title="Group"
      description="Displayed as a button group, can be used to group a series of similar operations."
      code-description="Use tag <adm-button-group> to group your buttons."
    >

      <!-- Preview -->
      <template #preview>
        <adm-button-group>
          <adm-button size="medium">
            Button text
          </adm-button>
          <adm-button size="medium" icon-start="caret-down-small" />
        </adm-button-group>
        <adm-button-group>
          <adm-button color="grey" size="medium">
            Button text
          </adm-button>
          <adm-button color="grey" size="medium" icon-start="caret-down-small" />
        </adm-button-group>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getButtonGroupCodeFirst() }}
        <br>
        {{ getButtonGroupCodeSecond() }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Group -->

    <!-- Button Attributes -->
    <design-system-documentation-table :table-data="tableData" />
    <!-- /Button Attributes -->

  </div>
  <!-- /Design System Button -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmButtonGroup from '@/views/_components/button/AdmButtonGroup'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'

export default {
  name: 'DesignSystemButton',

  components: {
    AdmButton,
    AdmButtonGroup,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      tableData: [
        {
          attribute: 'type',
          description: 'button type',
          type: 'string',
          values: 'filled / ghost / borderless',
          default: 'filled',
        },
        {
          attribute: 'size',
          description: 'button size',
          type: 'string',
          values: 'default / medium / small / mini',
          default: 'default',
        },
        {
          attribute: 'color',
          description: 'button color',
          type: 'string',
          values: 'blue / grey (only filled) / red (only filled & ghost) / green (only  filled) / dark (only filled & borderless) / light (only borderless)',
          default: 'blue',
        },
        {
          attribute: 'icon-start',
          description: 'button start icon',
          type: 'string',
          values: 'all available icons',
          default: '-',
        },
        {
          attribute: 'icon-end',
          description: 'button end icon',
          type: 'string',
          values: 'all available icons',
          default: '-',
        },
        {
          attribute: 'loading',
          description: 'determine whether it\'s loading',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'disabled',
          description: 'disable the button',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'native-type',
          description: 'same as native button\'s type',
          type: 'string',
          values: 'button / submit / reset',
          default: 'button',
        },
        {
          attribute: 'autofocus',
          description: 'same as native button\'s autofocus\t',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'full-width',
          description: 'is button full width',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
      ]
    }
  },

  methods: {
    getButtonGroupCodeFirst () {
      return this.getButtonGroupCode(
        [
          { size: 'medium' },
          { size: 'medium', 'icon-start': 'caret-down-small' }
        ],
        ['Button text', '']
      )
    },

    getButtonGroupCodeSecond () {
      return this.getButtonGroupCode(
        [
          { color: 'grey', size: 'medium' },
          { color: 'grey', size: 'medium', 'icon-start': 'caret-down-small' }
        ],
        ['Button text', '']
      )
    }
  }
}
</script>
<style lang="scss">
.design-buttons-wrap {
  .adm-button {
    margin-left: 12px;
  }
}
</style>
