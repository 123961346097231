<template>
  <div class="section select-datetime">
    <!-- Date & Time Pickers -->
    <el-row :gutter="16" class="select-datetime__row">

      <!-- Date Picker -->
      <el-col :xs="24" :sm="12" class="select-datetime__row__col">
        <adm-form-item :label="$t('date')" class="mb-0">
          <adm-date-picker
            ref="datePicker"
            v-model="selectedDate"
            :format="datePickerFormat"
            :picker-options="pickerOptions"
            :placeholder="$t('select_date')"
            :shortcuts="{ onClick: () => selectDate(a) }"
            value-format="yyyy-MM-dd"
            @focus="onDatePickerFocus"
            @change="selectDate"
          />
        </adm-form-item>
      </el-col>
      <!-- /Date Picker -->

      <!-- Time Picker -->
      <el-col :xs="24" :sm="12" class="select-datetime__row__col">
        <adm-form-item :label="$t('time')" class="mb-0">
          <adm-popover
            v-if="!loadingTimeSlots"
            :disabled="appointmentProp.startDate !== null"
            effect="dark"
            placement="top"
            popper-class="dark tooltip-replace"
            trigger="focus"
          >
            {{ $t('select_date_first') }}

            <template #reference>
              <adm-select
                :value="appointmentProp.startTime"
                :clearable="true"
                :disabled="appointmentProp.startDate === null"
                :picker-options="getTimeSelectOptionsWithLimits()"
                :placeholder="$t('select_time')"
                icon-start="hours"
                @change="selectTime"
              >
                <adm-option
                  v-for="(slot, time) in slotsTimes"
                  :key="time"
                  :value="time"
                  :label="formatTime(time)"
                  class="justify-between gap-12"
                >
                  <span>{{ formatTime(time) }}</span>
                  <span>{{ getTimeSlotStatusLabel(slot) }}</span>

                </adm-option>
              </adm-select>
            </template>

          </adm-popover>
          <adm-skeleton-element v-else :height="40" />
        </adm-form-item>
      </el-col>
      <!-- /Time Picker -->

    </el-row>
    <!-- /Date & Time Pickers -->

  </div>
</template>

<script>
import AdmDatePicker from '@/views/_components/datePicker/AdmDatePicker'
import AdmFormItem from '@/views/_components/form/AdmFormItem'
import AdmOption from '@/views/_components/select/AdmOption'
import AdmSelect from '@/views/_components/select/AdmSelect'
import AppointmentUtils from '@/utils/appointment'
import axios from 'axios'
import mixinDateTime from '@/mixins/common/datetime'
import mixinDuration from '@/mixins/common/duration'
import mixinSecurity from '@/mixins/security/security'
import AdmPopover from '@/views/_components/popover/AdmPopover.vue'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement.vue'

let cancel

export default {
  name: 'DateTimeSection',

  components: {
    AdmSkeletonElement,
    AdmPopover,
    AdmDatePicker,
    AdmFormItem,
    AdmOption,
    AdmSelect,
  },

  mixins: [
    mixinDateTime,
    mixinDuration,
    mixinSecurity
  ],

  props: {
    appointmentProp: {
      type: Object,
      default: () => {},
      required: true
    }
  },

  data: function () {
    return {
      loadingTimeSlots: false,
      slots: {},
      isDatePickerOpenedOnce: false,
      datePickerCurrentViewChanged: false,
      cancelController: new AbortController()
    }
  },

  computed: {
    selectedDate: {
      get: function () {
        return this.appointmentProp.startDate
      },

      set: function (date) {
        this.selectDate(date)
      }
    },

    currentDatePickerMonthAndYear () {
      if (this.isDatePickerOpenedOnce === false) {
        let month = this.$moment().month()
        let year = this.$moment().year()

        if (this.appointmentProp.startDate) {
          month = this.$moment(this.appointmentProp.startDate).month()
          year = this.$moment(this.appointmentProp.startDate).year()
        }

        return [month, year]
      }

      return [this.$refs.datePicker.$refs.elDatePicker.picker.month, this.$refs.datePicker.$refs.elDatePicker.picker.year]
    },

    currentDatePickerView () {
      if (this.isDatePickerOpenedOnce === false) {
        return 'date'
      }

      return this.$refs.datePicker.$refs.elDatePicker.picker.currentView
    },

    pickerOptions () {
      const $this = this
      const currentDatePickerView = this.currentDatePickerView

      return {
        disabledDate (date) {
          // Disabled all dates in the past except last year
          if ($this.$moment(date) < $this.$moment().subtract(1, 'y')) {
            return true
          }

          // Disable all dates that are not in the time slots response
          if (currentDatePickerView === 'date') {
            return !$this.$store.state.manageAppointment.slotsDates.includes($this.$moment(date).format('YYYY-MM-DD'))
          }
        },
        firstDayOfWeek: this.getElementFirstDayOfWeek(),
        cellClassName () {
          return $this.loadingTimeSlots ? 'skeleton-element-datepicker' : ''
        }
      }
    },

    slotsTimes () {
      if (this.appointmentProp.startDate) {
        return this.slots[this.appointmentProp.startDate]
      }

      return []
    },
  },

  watch: {
    // Watch for changes of service or employee
    'appointmentProp.service': function (newService, oldService) {
      this.processOtherParamsChanges()
    },

    'appointmentProp.employee': function (newEmployee, oldEmployee) {
      this.processOtherParamsChanges()
    },

    'appointmentProp.location': function (newLocation, oldLocation) {
      this.processOtherParamsChanges()
    },

    'appointmentProp.changedExtra': {
      deep: true,
      handler: function () {
        this.processOtherParamsChanges('extra')
      }
    },

    // Watch for calendar navigation and load new time slots
    currentDatePickerMonthAndYear (newValue, oldValue) {
      if ((newValue[0] !== oldValue[0] || newValue[1] !== oldValue[1]) || this.datePickerCurrentViewChanged) {
        this.datePickerCurrentViewChanged = false

        const currentDatePickerMoment = this.$moment(
          { y: this.currentDatePickerMonthAndYear[1], M: this.currentDatePickerMonthAndYear[0] }
        )

        // Load time slots only if date picker date is in the past not more than 1 year
        if (currentDatePickerMoment.endOf('month') > this.$moment().subtract(1, 'y') &&
          this.currentDatePickerView === 'date'
        ) {
          this.loadTimeSlots()
        }
      }
    },

    currentDatePickerView (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.datePickerCurrentViewChanged = true
      }
    }
  },

  created () {
    if (this.selectedDate) {
      this.loadTimeSlots()
    }
  },

  methods: {
    onDatePickerFocus () {
      this.$nextTick(_ => {
        this.isDatePickerOpenedOnce = true
      })

      this.loadTimeSlots()
    },

    selectDate (date) {
      if (this.loadingTimeSlots) {
        return
      }

      this.$set(this.appointmentProp, 'startDate', date)
      this.$set(this.appointmentProp, 'startDateTime', this.appointmentProp.startDate + ' ' + this.appointmentProp.startTime)

      if (
        !this.appointmentProp.startDate ||
        this.slots[this.appointmentProp.startDate] === undefined ||
        this.slots[this.appointmentProp.startDate][this.appointmentProp.startTime] === undefined
      ) {
        this.resetTimeState()
      }
    },

    selectTime (time) {
      this.$set(this.appointmentProp, 'startTime', time)
      this.$set(this.appointmentProp, 'startDateTime', this.appointmentProp.startDate + ' ' + this.appointmentProp.startTime)
      this.$set(this.appointmentProp, 'selectedSlot', this.slots[this.appointmentProp.startDate][time])
    },

    // Load slots if employee, service, extra were changed
    processOtherParamsChanges: async function (type) {
      if (!this.appointmentProp.startDate) return

      if (type === 'extra') {
        const changedExtra = this.appointmentProp.changedExtra

        if (changedExtra && !changedExtra.duration && Object.keys(changedExtra).length > 0) return
      }

      await this.loadTimeSlots()

      if (!this.$store.state.manageAppointment.slotsDates.includes(this.appointmentProp.startDate)) {
        this.$set(this.appointmentProp, 'startDate', null)
      }

      if (!Object.keys(this.slotsTimes).includes(this.appointmentProp.startTime)) {
        this.resetTimeState()
      }
    },

    resetTimeState () {
      this.$set(this.appointmentProp, 'startTime', '')
      this.$set(this.appointmentProp, 'selectedSlot', null)
      this.$set(this.appointmentProp, 'startDateTime', '')
    },

    async loadTimeSlots () {
      // Abort previous request if not finished
      if (this.loadingTimeSlots) {
        this.loadingTimeSlots = false
        this.cancelController.abort()
      }
      this.cancelController = new AbortController()

      this.loadingTimeSlots = true
      try {
        const calendarParams = {
          calendarStartDate: this.$moment(
            { y: this.currentDatePickerMonthAndYear[1], M: this.currentDatePickerMonthAndYear[0] }
          ).startOf('month').subtract(7, 'days').format('YYYY-MM-DD'),
          calendarEndDate: this.$moment(
            { y: this.currentDatePickerMonthAndYear[1], M: this.currentDatePickerMonthAndYear[0] }
          ).endOf('month').add(15, 'days').format('YYYY-MM-DD')
        }

        const response = await this.$http.get(
          '/api/v1/appointments/entities/date-time',
          {
            params: {
              ...calendarParams,
              ...this.$store.state.manageAppointment.queryParams,
              'extras': AppointmentUtils.getExtrasParams(this.appointmentProp),
              'excludeAppointmentId': this.$store.state.manageAppointment.manageDialog.appointmentId,
            },
            signal: this.cancelController.signal,
          }
        )

        this.slots = response.data.payload
        this.slotsDates = Object.keys(this.slots)

        if (this.appointmentProp.startTime
          && this.appointmentProp.startDate in this.slots
          && this.appointmentProp.startTime in this.slots[this.appointmentProp.startDate]
        ) {
          this.$set(this.appointmentProp, 'selectedSlot', this.slots[this.appointmentProp.startDate][this.appointmentProp.startTime])
        }

        this.$store.commit('manageAppointment/setSlotDates', this.slotsDates)
      } catch (e) {
        if (axios.isCancel(e)) return

        if (e.response.data.code === 401) {
          this.$store.commit('settings/setSetting', {
            category: 'globalErrorMessages',
            name: 'globalErrorMessages',
            value: ['apple-calendar']
          })
        }
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      } finally {
        this.loadingTimeSlots = false
      }
    },

    getTimeSlotStatusLabel (slot) {
      let label = ''

      if (slot?.o === true) {
        label = this.$t('outside_of_working_hours')
      }

      if (slot?.b === 0) {
        label = this.$t('already_booked')
      }

      return label
    }
  }
}
</script>

<style lang="scss" scoped>
.select-datetime {
  &__row {
    margin-top: -8px;

    &__col {
      margin-top: 8px;
    }
  }
}
</style>

