<template>

  <!-- Service Card -->
  <div :class="{'selected': selected}" class="service-card" @click="$emit('card-selected', service)">

    <!-- Image, Name, Duration & Price -->

    <!-- Image & Name -->
    <p
      class="service-card-name"
      :title="service.name"
    >

      <!-- Avatar -->
      <adm-avatar
        :background-color="service.color"
        :margin-right="10"
        :name="service.name"
        :size="34"
        :src="service.mediaUrl"
      />
      <!-- /Avatar -->

      <span class="overflow-ellipsis">{{ service.name }}</span>
    </p>
    <!-- /Image & Name -->

    <div class="service-card-details">
      <!-- Duration -->
      <span v-if="showDuration">{{ secondsToNiceDuration(service.duration) }}</span>
      <!-- /Duration -->

      <!-- Price -->
      <span v-if="showPrice">{{ formatPrice(service.servicePrice) }}</span>
      <!-- /Price -->

      <!-- Remove button -->
      <div v-if="showRemove" class="service-card-close">
        <i class="el-icon-close" @click="$emit('card-removed')" />
      </div>
      <!-- /Remove button -->
    </div>

    <!-- /Image, Name, Duration & Price -->

  </div>
  <!-- /Service Card -->

</template>

<script>
import mixinDuration from '@/mixins/common/duration'
import mixinPrice from '@/mixins/common/price'
import AdmAvatar from '@/views/_components/avatar/AdmAvatar'

export default {
  name: 'ServiceCard',

  components: {
    AdmAvatar,
  },

  mixins: [
    mixinDuration,
    mixinPrice
  ],

  props: {
    service: {
      type: Object,
      default: () => {},
      required: true
    },
    selected: {
      type: Boolean,
      default: false,
      required: false
    },
    showDuration: {
      type: Boolean,
      default: true,
      required: false
    },
    showPrice: {
      type: Boolean,
      default: true,
      required: false
    },
    showRemove: {
      type: Boolean,
      default: false,
      required: false
    },
    clickCard: {
      type: Function,
      default: () => ({}),
      required: false
    },
    clickRemove: {
      type: Function,
      default: () => ({}),
      required: false
    }
  }
}
</script>

<style lang="scss" scoped>
.service-card {
  padding: 0.5rem;
  border: 1px solid transparent;
  box-shadow: $box-shadow-small;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  gap: 8px;

  &:hover {
    border-color: var(--primary-900);
  }

  &.selected {
    border-color: transparent;
    cursor: auto;
  }

  .service-card-name {
    font-weight: 600;
    margin: 0;
    display: flex;
    align-items: center;
    color: $shade-900;
    overflow: hidden;
  }

  .service-card-details {
    text-align: right;
    display: flex;
    gap: 8px;
    margin: 0 0 0 auto;

    span {
      font-size: 12px;
      color: $shade-900;
      opacity: 0.8;
      text-align: right;
    }
  }

  .service-card-close {
    text-align: right;

    i {
      cursor: pointer;
      font-weight: 700;
      color: $shade-500;
    }
  }
}
</style>
