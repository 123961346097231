<template>

  <!-- Switch -->
  <div
    class="adm-switch-wrapper"
    :class="[
      captionPosition === 'left' && !description ? 'caption-left' : 'caption-right',
      {
        'without-caption': !description && !label,
        'is-full-width': fullWidth,
        'is-disabled': disabled,
      }
    ]"
  >
    <el-switch
      :id="id"
      ref="admSwitch"
      v-bind="$props"
      v-model="model"
      :active-text="activeText"
      :inactive-text="inactiveText"
      :class="['adm-switch', { 'disabled': disabled }]"
      @input="(eventValue) => $emit('input', eventValue)"
      @change="(eventValue) => $emit('change', eventValue)"
    />
    <div class="adm-switch-wrapper__caption">
      <div class="adm-switch-wrapper__caption__label" @click="$refs.admSwitch.switchValue()">
        {{ label }}
      </div>

      <!-- Description -->
      <div
        v-if="description"
        class="adm-switch-wrapper__caption__description"
        v-html="description"
      />
      <!-- /Description -->

      <!-- Description Slot -->
      <div v-if="$slots.description" class="adm-switch-wrapper__caption__description">
        <slot name="description">
          {{ description }}
        </slot>
      </div>
      <!-- /Description Slot -->
    </div>
  </div>
  <!-- /Switch -->

</template>

<script>
export default {
  name: 'AdmSwitch',

  props: {
    id: {
      type: [String, Object],
      default: null
    },
    value: {
      type: [String, Number, Boolean],
      default: '',
      required: false
    },
    activeValue: {
      type: [Boolean, String, Number],
      default: true
    },
    inactiveValue: {
      type: [Boolean, String, Number],
      default: false
    },
    activeText: {
      type: String,
      default: ''
    },
    inactiveText: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    captionPosition: {
      type: String,
      default: 'right',
      validator (value) {
        return ['left', 'right'].indexOf(value) !== -1
      }
    },
    description: {
      type: String,
      default: null
    },
    label: {
      type: String,
      default: ''
    },
    width: {
      type: Number,
      default: 36
    },
    fullWidth: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      selfModel: ''
    }
  },

  computed: {
    model: {
      get () {
        return this.value !== undefined ? this.value : this.selfModel
      },
      set (val) {
        this.selfModel = val
      }
    }
  },
}
</script>

<style lang="scss">
// Switch Wrapper
.adm-switch-wrapper {
  display: flex;
  $caption: "adm-switch-wrapper__caption";

  &.is-disabled {
    .#{$caption} {
      &__label, &__description {
        color: $shade-700;
      }
    }
  }

  // Caption
  .#{$caption} {

    // Label
    &__label {
      display: inline-block;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
      cursor: default;
    }

    // Description
    &__description {
      font-size: 13px;
      line-height: 20px;
      color: $shade-700;
      font-weight: 400;
    }
  }

  // Right Side Caption
  &.caption-right {
    flex-direction: row;
    justify-content: flex-start;

    // Caption
    .#{$caption} {
      margin-left: 12px;
    }
  }

  // Left Side Caption
  &.caption-left {
    flex-direction: row-reverse;
    justify-content: flex-end;

    // Caption
    .#{$caption} {
      margin-right: 12px;
    }
  }

  &.without-caption {
    .#{$caption} {
      margin: 0;
    }
  }

  // Full Width
  &.is-full-width {

    // Caption Left
    &.caption-left {

      // Element Switch
      .el-switch {
        margin-left: auto;
      }
    }

    // Caption Right
    &.caption-right {

      // Caption Label
      .#{$caption} {
        margin-left: auto;
      }
    }
  }

  // Override Default CSS
  .adm-switch {
    height: 24px;

    // Element Switch
    .el-switch__core {
      height: 18px;
      background-color: $adm-white;
      border: 1px solid $shade-450;
      box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);

      // Hover
      &:hover {
        border: 1px solid $shade-500;
        box-shadow: inset 0 2px 2px rgba(20, 35, 61, 0.11);
      }

      // Focus
      &:focus {
        border: 1px solid var(--primary-900);
        box-shadow: 0 0 0 2px var(--primary-500);
      }

      // After
      &::after {
        width: 14px;
        height: 14px;
        background-color: $shade-500;
      }
    }

    // Checked
    &.is-checked {

      // Element Switch
      .el-switch__core {
        border: 1px solid var(--primary-900);
        background-color: var(--primary-900);

        // Hover
        &:hover {
          border: 1px solid var(--primary-800);
          background-color: var(--primary-800);
        }

        // After
        &::after {
          width: 14px;
          height: 14px;
          background-color: $adm-white;
          left: 100%;
          margin-left: -15px;
          box-shadow: 0 1px 2px rgba(0, 0, 0, 0.25);
        }
      }

      // Checked Disabled
      &.disabled {

        // Element Switch
        .el-switch__core {
          border: 1px solid var(--primary-600);
          background-color: var(--primary-600);

          // After
          &::after {
            background-color: $adm-white;
            opacity: 1;
          }

          // Hover & Focus
          &:hover, &:focus {
            border: 1px solid var(--primary-600);
          }
        }
      }
    }

    // Disabled
    &.disabled {

      // Element Switch
      .el-switch__core {
        border: 1px solid $shade-400;
        background-color: $shade-150;

        // Hover & Focus
        &:hover, &:focus {
          border: 1px solid $shade-400;
          box-shadow: none;
        }

        // After
        &::after {
          opacity: 0.5;
        }
      }
    }
  }
}
</style>
