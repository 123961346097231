var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'main-nav-bar-mobile__more-item',
    {
      'active': _vm.isActive(),
      'is-red': _vm.red,
      'is-disabled': _vm.disabled
    }
  ]},[_c('router-link',{attrs:{"to":{ name: _vm.routeName }},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.$emit('drawer-item-clicked', _vm.action)}}},[(_vm.icon)?_c('i',{class:'tz-' + _vm.icon}):_vm._e(),_c('span',[_vm._v(_vm._s(_vm.label))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }