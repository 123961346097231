const isLocalStorageAvailable = () => {
  try {
    localStorage.setItem('trafft', 'trafft');
    localStorage.removeItem('trafft');
    return true;
  } catch (e) {
    return false;
  }
}

export { isLocalStorageAvailable }
