import { render, staticRenderFns } from "./RateTrafftDialog.vue?vue&type=template&id=358734de&scoped=true&"
import script from "./RateTrafftDialog.vue?vue&type=script&lang=js&"
export * from "./RateTrafftDialog.vue?vue&type=script&lang=js&"
import style0 from "./RateTrafftDialog.vue?vue&type=style&index=0&id=358734de&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "358734de",
  null
  
)

export default component.exports