<template>

  <!-- Manage Appointment Info -->
  <div class="manage-appointment-info">

    <!-- Price Details -->
    <price-details
      v-if="isPriceDetailsVisible"
      :hide-price-details="hidePriceDetails"
      :appointment-prop="appointmentProp"
    />
    <!-- /Price Details -->

    <!-- Appointment Info -->
    <div v-if="!isPriceDetailsVisible && $screen.aboveMobile">

      <!-- Service Info -->
      <service-info
        v-if="appointmentProp.service || loading"
        :appointment-prop="appointmentProp"
      />
      <!-- /Service Info -->

      <!-- Employee Info -->
      <employee-info
        v-if="appointmentProp.employee || loading"
        :appointment-prop="appointmentProp"
      />
      <!-- /Employee Info -->

      <!-- Date & Time Info -->
      <date-time-info
        v-if="(appointmentProp.startDate || appointmentProp.startTime) || loading"
        :date="appointmentProp.startDate"
        :time="appointmentProp.startTime"
      />
      <!-- /Date & Time Info -->

      <!-- Location Info -->
      <location-info
        v-if="appointmentProp.location || loading"
        :location="appointmentProp.location"
      />
      <!-- /Location Info -->

      <!-- Customer Info -->
      <customer-info
        v-if="appointmentProp.bookings.length > 0 || loading"
        :bookings="appointmentProp.bookings"
      />
      <!-- /Customer Info -->

      <!-- Add Appointment Image -->
      <div v-if="!isPropsNotEmpty && !loading" class="placeholder">
        <div>
          <img
            class="max-width-100" svg-inline
            :src="$getDashboardImageLink('illustrations', 'create-appointment.svg')"
          >
          <div class="placeholder__text">
            {{ $t('lets_create_appointment') }}
          </div>
          <p>{{ $t('start_creating_appointment') }}</p>
        </div>
      </div>
      <!--  /Add Appointment Image -->

    </div>
    <!-- /Appointment Info -->

    <!-- Bottom Block -->
    <div class="bottom-block-container">

      <!-- Price -->
      <price
        v-if="!isPriceDetailsVisible"
        :appointment="appointmentProp"
        :service-price="servicePrice"
        :disabled="createAppointmentDisabled"
        :show-price-details="showPriceDetails"
        :service="appointmentProp.service"
      />
      <!-- /Price -->

    </div>
    <!-- /Bottom Block -->

  </div>
  <!-- /Manage Appointment Info -->

</template>

<script>
import CustomerInfo from './CustomerInfo/CustomerInfo'
import DateTimeInfo from './DateTimeInfo/DateTimeInfo'
import EmployeeInfo from './EmployeeInfo/EmployeeInfo'
import LocationInfo from './LocationInfo/LocationInfo'
import Price from './Price/Price'
import PriceDetails from '@/views/Appointments/_components/PriceDetails/PriceDetails'
import ServiceInfo from './ServiceInfo/ServiceInfo'
import { mapState } from 'vuex'

export default {
  name: 'ManageAppointmentInfo',

  components: {
    CustomerInfo,
    DateTimeInfo,
    EmployeeInfo,
    LocationInfo,
    Price,
    PriceDetails,
    ServiceInfo
  },

  props: {
    appointmentProp: {
      type: Object,
      default: () => ({}),
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: true
    },
    isPropsNotEmpty: {
      type: Boolean,
      default: false
    },
    existingPrices: {
      type: Object,
      default: () => ({}),
      required: true
    },
    createAppointmentDisabled: {
      type: Boolean,
      default: true,
    },
    servicePrice: {
      type: Number,
      default: 0,
      required: true
    },
  },

  data () {
    return {
      isPriceDetailsVisible: false
    }
  },

  computed: {
    ...mapState({
      entities: state => state.entities
    }),
  },

  methods: {
    showPriceDetails () {
      this.isPriceDetailsVisible = true
    },

    hidePriceDetails () {
      this.isPriceDetailsVisible = false
    },
  }
}
</script>

<style lang="scss">
.manage-appointment-info {
  .section {
    padding: 0 1rem 0 2rem;
    margin-bottom: 1rem;

    &:not(:first-child) {
      border-top: 1px solid $shade-300;
      padding-top: 1rem;
    }

    .section-text {
      color: $shade-900;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      opacity: 0.8;
    }
  }

  .section-title {
    margin-bottom: 0.5rem;

    span {
      color: $shade-900;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      opacity: 0.8;
    }

    i {
      margin-left: -2rem;
      margin-right: 8px;
    }
  }

  .create-appointment {
    position: absolute;
    width: calc(100% - 2rem);
    bottom: 1.5rem;
    right: 0;

    .el-button {
      width: 100%;
    }
  }

  .placeholder {
    display: flex;
    justify-content: center;
    padding-top: 16px;

    > div {
      max-width: 252px;
    }

    img {
      display: flex;
      justify-content: center;
      width: 240px;
    }

    &__text {
      text-align: center;
      margin-top: 1rem;
      color: $shade-900;
      font-weight: 600;
      font-size: 18px;
      line-height: 28px;
    }

    p {
      margin-top: 0.5rem;
      color: $shade-700;
      text-align: center;
    }
  }

  .bottom-block-container {
    margin: auto 0 0;
    width: 100%;
    display: grid;
  }
}
</style>
