<template>
  <div class="app-survey__footer">
    <adm-button
      v-if="!endOfSurvey()"
      color="grey"
      size="medium"
      class="app-survey__footer__button"
      @click="getCurrentStep() === 0 ? hideSurvey() : previousStep()"
    >
      {{ getCurrentStep() === 0 ? $t('not_interested') : $t('back') }}
    </adm-button>
    <adm-button
      v-if="!endOfSurvey()"
      size="medium"
      class="app-survey__footer__button"
      :disabled="!isSelectedSomeAnswer"
      @click="$emit('nextStep')"
    >
      {{ getNextStepButtonText() }}
    </adm-button>
    <adm-button
      v-if="endOfSurvey()"
      color="grey"
      size="medium"
      class="app-survey__footer__done-button"
      @click="closeAppSurvey()"
    >
      {{ $t('done') }}
    </adm-button>
  </div>
</template>
<script>
import AdmButton from '@/views/_components/button/AdmButton'

export default {
  name: 'SurveyFooter',
  components: { AdmButton },
  props: {
    steps: {
      type: Array,
      required: true
    },
    isSelectedSomeAnswer: {
      type: Boolean,
      required: true,
    }
  },
  methods: {

    getNextStepButtonText () {
      if(this.getCurrentStep() === 0) {
        return this.$t('sure')
      }
      if(this.endOfSurveyAnswers()) {
        return this.$t('submit')
      }
      return this.$t('next')
    },

    closeAppSurvey () {
      if(this.endOfSurvey()) {
        this.$store.commit('survey/hideSurveyButton')
      }
      this.$store.commit('survey/setSurveyOpenOrClosed', false)
    },

    getCurrentStep () {
      return this.$store.state.survey.currentStep
    },

    previousStep () {
      this.$store.commit('survey/decrementStep');
    },

    endOfSurveyAnswers () {
      return this.$store.state.survey.currentStep === this.steps.length - 2
    },

    endOfSurvey () {
      return this.$store.state.survey.currentStep === this.steps.length - 1
    },

    hideSurvey () {
      this.$store.commit('survey/setSurveyOpenOrClosed', false)
      this.$store.commit('survey/hideSurveyButton')
      this.$http.post('/api/v1/survey/hide-survey')
      this.$emit('hide')
    }
  },
}
</script>

<style lang="scss" scoped>
.app-survey__footer {
  background: #FFFFFF;
  height: 64px;
  border-radius: 0px 0px 8px 8px;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  align-items: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  border-top: 1px solid #DEE1E6;

  &__button {
    width: 169px;
    font-weight: 500;
  }

  &__done-button {
    width: 100%;
  }
}
</style>
