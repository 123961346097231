<template>

  <!-- Checkbox Dropdown -->
  <adm-dropdown
    ref="checkbox-dropdown"
    class="checkbox-dropdown"
    :hide-on-click="false"
    trigger="click"
    placement="bottom-start"
    @visible-change="visibleChange"
    @command="toggleCheckbox"
  >

    <!-- Action Slot -->
    <slot name="action" />
    <!-- /Action Slot -->

    <!-- Dropdown Menu -->
    <adm-dropdown-menu
      slot="dropdown"
      :append-to-body="appendToBody"
      class="checkbox-dropdown-menu"
    >

      <!-- Filter Search -->
      <adm-input
        v-if="showSearch"
        ref="search"
        v-model="searchValue"
        :placeholder="placeholder"
        icon-start="search"
        class="checkbox-dropdown__search"
        size="small"
        @input="eventValue => $emit('search', eventValue)"
      />
      <!-- /Filter Search -->

      <!-- Dropdown Items -->
      <div
        v-if="!loading"
        class="checkbox-dropdown-menu__items custom-scrollbar"
        :class="{ 'checkbox-dropdown-menu__items--no-search': !showSearch}"
      >
        <adm-dropdown-item
          v-for="(label, index) in labelsFiltered"
          :key="index"
          :command="valuesFiltered[index]"
        >
          <adm-checkbox
            :value="valuesChecked.includes(valuesFiltered[index])"
            @click.prevent.native
          >
            {{ label }}
          </adm-checkbox>
        </adm-dropdown-item>
        <div v-if="!loading && labelsFiltered.length === 0">
          {{ $t('no_results') }}
        </div>
      </div>
      <div
        v-else
        class="checkbox-dropdown-menu__items custom-scrollbar"
        :class="{ 'checkbox-dropdown-menu__items--no-search': !showSearch}"
      >
        <adm-skeleton-element v-for="n in 5" :key="n" :height="26" :margin-top="3" :margin-bottom="3" />
      </div>
      <!-- /Dropdown Items -->

    </adm-dropdown-menu>
    <!-- /Dropdown Menu -->

  </adm-dropdown>
  <!-- /Checkbox Dropdown -->

</template>

<script>
import AdmDropdown from '@/views/_components/dropdown/AdmDropdown'
import AdmDropdownItem from '@/views/_components/dropdown/AdmDropdownItem'
import AdmDropdownMenu from '@/views/_components/dropdown/AdmDropdownMenu'
import AdmInput from '@/views/_components/input/AdmInput'
import AdmCheckbox from '@/views/_components/checkbox/AdmCheckbox'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'

export default {
  name: 'AdmCheckboxDropdown',

  components: {
    AdmSkeletonElement,
    AdmCheckbox,
    AdmDropdown,
    AdmDropdownItem,
    AdmDropdownMenu,
    AdmInput,
  },

  props: {
    labels: {
      type: Array,
      required: true,
      default: () => ([])
    },
    values: {
      type: Array,
      required: true,
      default: () => ([])
    },
    valuesChecked: {
      type: Array,
      required: true,
      default: () => ([])
    },
    showSearch: {
      type: Boolean,
      required: false,
      default: true
    },
    appendToBody: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data () {
    return {
      searchValue: ''
    }
  },

  computed: {
    valuesFiltered () {
      const filteredLabelsKeys = []
      for (let i = 0; i < this.labels.length; i++) {
        if (this.labels[i].toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0) {
          filteredLabelsKeys.push(i)
        }
      }

      const valuesFiltered = []
      for (let j = 0; j < this.values.length; j++) {
        if (filteredLabelsKeys.includes(j)) {
          valuesFiltered.push(this.values[j])
        }
      }

      return valuesFiltered
    },

    labelsFiltered () {
      return this.labels.filter(item => item.toLowerCase().indexOf(this.searchValue.toLowerCase()) >= 0)
    }
  },

  methods: {
    closeDropdown () {
      this.$refs['checkbox-dropdown'].$refs.dropdown.hide()
    },

    visibleChange (appears) {
      if (appears) {
        if (this.showSearch) {
          this.searchValue = ''
          this.$nextTick(() => {
            this.$refs.search.focus()
          })
        }

        this.$emit('dropdown-appears')
        return
      }

      this.$emit('dropdown-disappears')
    },

    toggleCheckbox (value) {
      this.$emit('checkbox-toggled', value)
    }
  }
}
</script>

<style scoped lang="scss">
.checkbox-dropdown {
  max-width: 100%;

  &__search {
    font-size: 14px;
    margin-bottom: 0.5rem;

    input {
      font-weight: 500;
      line-height: 24px;
    }
  }

  .el-button {
    &:hover, &:focus {
      color: unset;
      border-color: #C0C4CC;
      background-color: $adm-white;
    }
  }
}

.checkbox-dropdown-menu {
  &.el-dropdown-menu {
    padding: 6px 8px;
    max-width: 90%;
  }
}

.checkbox-dropdown-menu__items {
  max-height: 172px;
  overflow: auto;

  @include phone-up {
    width: 270px;
  }

  &--no-search {
    width: 100%;
  }
}
</style>
