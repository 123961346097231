<template>

  <!-- Billing Dialog Deactivate -->
  <div class="ad-billing-dialog-deactivate">

    <!-- Features -->
    <billing-dialog-deactivate-features
      v-if="step === 'features'"
      :max-features="selectedPlan.maxNumberOfCustomFeatures"
      :items="featuresList"
      :loading-continue="loadingContinue"
      @change-step="(dialogStep) => $emit('change-step', dialogStep)"
      @next-deactivate-step="goToNextStep"
    />
    <!-- /Features -->

    <!-- Employees -->
    <billing-dialog-deactivate-employees
      v-if="step === 'employees'"
      :max-employees="selectedPlan.maxNumberOfEmployees"
      :loading-continue="loadingContinue"
      @change-step="(dialogStep) => $emit('change-step', dialogStep)"
      @next-deactivate-step="goToNextStep"
    />
    <!-- /Employees -->

    <!-- Locations -->
    <billing-dialog-deactivate-locations
      v-if="step === 'locations'"
      :items="locations"
      :max-locations="1"
      :loading-continue="loadingContinue"
      @change-step="(dialogStep) => $emit('change-step', dialogStep)"
      @next-deactivate-step="goToNextStep"
    />
    <!-- /Locations -->

  </div>
  <!-- /Billing Dialog Deactivate -->

</template>

<script>
import BillingDialogDeactivateEmployees
  from '@/views/Dashboard/BillingDialog/Deactivate/Employees/BillingDialogDeactivateEmployees'
import BillingDialogDeactivateFeatures
  from '@/views/Dashboard/BillingDialog/Deactivate/Features/BillingDialogDeactivateFeatures'
import BillingDialogDeactivateLocations
  from '@/views/Dashboard/BillingDialog/Deactivate/Locations/BillingDialogDeactivateLocations'
import mixinFeatures from '@/mixins/features/features'
import mixinPlans from '@/mixins/plans/plans'
import { mapState } from 'vuex'
import { getAbortController, isRequestCanceled } from '@/utils/api'

export default {
  name: 'BillingDialogDeactivate',

  components: {
    BillingDialogDeactivateLocations,
    BillingDialogDeactivateEmployees,
    BillingDialogDeactivateFeatures
  },

  mixins: [
    mixinFeatures,
    mixinPlans
  ],

  props: {
    itemsToDeactivate: {
      type: Object,
      default: () => {}
    },
    updateDialog: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cancelToken: getAbortController(),
      loadingContinue: false,
      locations: [],
      featuresList: [],
      step: '',
      stepIndex: 0,
      steps: []
    }
  },

  computed: {
    ...mapState({
      deactivateFeatureDialog: state => state.billing.deactivateFeatureDialog,
      featureToDeactivateCode: state => state.billing.featureToDeactivateCode,
      selectedPlan: state => state.billing.selectedPlan,
      tenantInfo: state => state.billing.tenantInfo,
      visibleDialogBilling: state => state.billing.visibleDialogBilling
    })
  },

  async created () {
    await this.getFeatures()
    await this.getLocations()

    // If dialog is open just for deactivating feature that requires deactivating entities, else downgrading plan
    if (this.deactivateFeatureDialog) {
      if (this.featureToDeactivateCode === 'multiple_locations') {
        this.step = 'locations'
      }
    } else {
      this.populateSteps()
    }
  },

  methods: {
    populateSteps () {
      const statistics = this.tenantInfo.statistics

      if (this.selectedPlan.maxNumberOfCustomFeatures && this.featuresList.filter(item => item.value === true).length > this.selectedPlan.maxNumberOfCustomFeatures) {
        this.steps.push('features')
      }

      if (
        this.deactivateFeatureDialog === false
        && this.selectedPlan.name === 'free_v2'
        && this.locations.filter(location => location.value === true).length > 1
      ) {
        this.steps.push('locations')
      }

      if (this.selectedPlan.maxNumberOfEmployees && statistics.employees.value > this.selectedPlan.maxNumberOfEmployees) {
        this.steps.push('employees')
      }

      if (this.steps.length === 0 && this.selectedPlan.name === 'free_v2') {
        this.saveSelectedPlan()
        return
      }

      this.step = this.steps[0]
    },

    async goToNextStep (fromStep, items) {
      this.$emit('update-deactivation-items', fromStep, items)
      const index = this.steps.findIndex(step => step === this.step)

      // Add locations step if "Multiple Locations" features is turned off & number of locations is more than 1
      if (this.itemsToDeactivate.features) {
        const multipleLocationsFeature = this.itemsToDeactivate.features.find(feature => feature.code === 'multiple_locations')
        const enabledLocations = this.locations.filter(location => location.value === true)
        if (this.steps.includes('locations') === false && multipleLocationsFeature.value === false && enabledLocations.length > 1) {
          this.steps.push('locations')
        }
      }

      const nextStep = this.steps[index + 1]

      // If there are not more steps for deactivation
      if (nextStep === undefined) {
        this.loadingContinue = true

        // If selecting plan
        if (this.deactivateFeatureDialog === false) {
          // If selected plan is free
          if (this.selectedPlan.name === 'free_v2') {
            await this.saveSelectedPlan()
            this.$store.commit('billing/setCanCloseBillingDialog', true)
          }

          // If choosing the plan for the first time and the plan is paid
          else if (this.tenantInfo.tenant.isPlanChosen === false && this.selectedPlan.name !== 'free_v2') {
            this.$emit('change-step', 'checkout')
          }

          // If plan is chosen, but you have more items than allowed in plan (Possible only when downgrading license from AppSumo Tier 2 to AppSumo Tier 1)
          else {
            await this.saveSelectedPlan()
            this.$store.commit('billing/setVisibleDialogBilling', false)
            this.$store.commit('billing/setReloadPlan', true)
          }
        }

        // If deactivating feature that requires deactivating entities
        if (this.deactivateFeatureDialog === true) {
          this.$store.commit('billing/setVisibleDialogBilling', false)
          this.saveDeactivateFeature(this.featureToDeactivateCode)
        }

        this.loadingContinue = false
      }

      this.step = nextStep
    },

    async getLocations () {
      try {
        this.locations = []
        this.loading = true

        const response = await this.$http.get(
          '/api/v1/locations',
          { signal: this.cancelToken.signal }
        )

        const locations = response.data.locations

        for (let i = 0; i < locations.length; i++) {
          if (locations[i].permittedActions.canEdit === false || locations[i].isOnlineLocation) {
            continue
          }

          this.locations.push({
            id: locations[i].id,
            name: locations[i].name,
            value: locations[i].status === 1,
            icon: locations[i].mediaUrl
          })
        }
      } catch (e) {
        if (isRequestCanceled(e)) return
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      } finally {
        this.loading = false
      }
    },

    async getFeatures () {
      try {
        this.loading = true

        const response = await this.$http.get(
          '/api/v1/settings/features',
          { signal: this.cancelToken.signal }
        )

        const features = response.data.features
        const planAvailableFeatures = this.selectedPlan.planAvailableFeatures

        for (let i = 0; i < features.length; i++) {
          if (features[i].isFree) {
            continue
          }

          const isFeatureAvailableInPlan = this.$store.getters['features/isFeatureAvailableInPlan'](features[i].code, planAvailableFeatures)

          this.featuresList.push({
            code: features[i].code,
            name: features[i].name,
            value: !isFeatureAvailableInPlan ? false : Boolean(features[i].isEnabled),
            disabled: !isFeatureAvailableInPlan,
            icon: this.$getDashboardImageLink('features', features[i].code + '.svg')
          })
        }
      } catch (e) {
        if (isRequestCanceled(e)) return
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
