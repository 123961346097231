<template>

  <!-- Administration Limit Reached Dialog -->
  <adm-modal
    width="530px"
    :visible="$store.state.billing.visibleLimitReachedDialog"
    :close-visible="false"
    class="adm-limit-reached"
    @close="$store.commit('billing/setVisibleLimitReachedDialog', false)"
  >

    <!-- Dialog Title -->
    <template #title>
      <span class="adm-modal__title">
        <adm-icon icon="warning-filled" :color="$colors['red-900']" size="big" />
        <span class="ml-10">
          {{ $store.state.billing.limitReachedDialogTitle }}
        </span>
      </span>
      <adm-button
        class="adm-modal__close"
        type="borderless"
        color="dark"
        aria-label="Close"
        size="micro"
        icon-start="close"
        @click="$store.commit('billing/setVisibleLimitReachedDialog', false)"
      />
    </template>
    <!-- Dialog Title -->

    <!-- Dialog Body -->
    <span>
      {{ $store.state.billing.limitReachedDialogText }}
    </span>
    <!-- /Dialog Body -->

    <!-- Actions -->
    <template #footer>

      <!-- Cancel Button -->
      <adm-button
        size="medium"
        color="grey"
        @click="$store.commit('billing/setVisibleLimitReachedDialog', false)"
      >
        {{ $t('cancel') }}
      </adm-button>
      <!-- /Cancel Button -->

      <!-- Upgrade Button -->
      <adm-button
        v-if="$store.state.billing.limitReachedDialogEntity !== 'location' && !$store.getters['settings/isAgency']"
        size="medium"
        @click="$store.commit('billing/setVisibleDialogBilling', true)"
      >
        {{ $t('upgrade_plan') }}
      </adm-button>
      <!-- /Upgrade Button -->

      <!-- View Custom Features -->
      <adm-button
        v-if="showViewCustomFeatureButton"
        size="medium"
        class="ml-12"
        @click="$router.push({ name: 'features', hash: '#multiple_locations' })"
      >
        {{ $t('view_custom_features') }}
      </adm-button>
      <!-- /View Custom Features -->

    </template>
    <!-- /Actions -->

  </adm-modal>
  <!-- /Administration Limit Reached Dialog -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmModal from '@/views/_components/modal/AdmModal'
import AdmIcon from '@/views/_components/icon/AdmIcon'
import mixinSecurity from '@/mixins/security/security'

export default {
  name: 'AdmLimitReachedDialog',

  components: {
    AdmIcon,
    AdmModal,
    AdmButton,
  },

  mixins:[mixinSecurity],

  computed: {
    showViewCustomFeatureButton () {
      return this.$store.state.billing.limitReachedDialogEntity === 'location' &&
        this.hasPermission('edit_settings') &&
        this.$store.getters['features/isFeatureVisible']('multiple_locations')
    }
  },
}
</script>

<style lang="scss">
.adm-limit-reached {
  .adm-modal__title {
    display: flex;
    align-items: center;
  }
}
</style>
