<template>
  <adm-alert
    :title="$t('demo_version')"
    :description="$t('demo_version_desc')"
    class="mb-24 w1200"
  />
</template>

<script>

import AdmAlert from '@/views/_components/alert/AdmAlert.vue'

export default {
  name: 'AgencyDemoAlert',
  components: { AdmAlert },
}
</script>