export const SUPER_ADMIN_SLUG = 'super_administrator'
export const ADMIN_SLUG = 'administrator'
export const EMPLOYEE_SLUG = 'employee'

export default {
  data: function () {
    return {
      systemRolesData: {
        super_administrator: { backgroundColor: this.$colors['--primary-900'], textColor: this.$colors['adm-white'], label: this.$t('superadmin') },
        administrator: { backgroundColor: this.$colors['yellow-700'], textColor: this.$colors['shade-900'], label: this.$t('administrator') },
        employee: { backgroundColor: this.$colors['green-700'], textColor: this.$colors['shade-900'], label: this.$t('employee') },
      },
      SUPER_ADMIN_SLUG: SUPER_ADMIN_SLUG,
      EMPLOYEE_SLUG: EMPLOYEE_SLUG
    }
  },

  methods: {
    isSuperAdmin (user = null) {
      if (!user) {
        user = this.$store.state.user
      }

      if (user?.roles) {
        return user.roles.some(role => role.code === SUPER_ADMIN_SLUG)
      }

      return false
    },

    isAdmin (user = null) {
      if (!user) {
        user = this.$store.state.user
      }

      if (user?.roles) {
        return user.roles.some(role => [SUPER_ADMIN_SLUG, ADMIN_SLUG].includes(role.code))
      }

      return false
    },

    isEmployee (user = null) {
      if (!user) {
        user = this.$store.state.user
      }

      if (this.isSuperAdmin(user)) {
        return false
      }

      if (user.roles) {
        return user.roles.some(role => role.code === EMPLOYEE_SLUG)
      }

      return false
    },

    hasPermission (requiredPermission, user = null) {
      if (!user) {
        user = this.$store.state.user
      }

      if (user.permissions) {
        return user.permissions.some(userPermission => userPermission === requiredPermission)
      }

      return false
    },

    hasPermissionForAllEntities (requiredPermission, user = null) {
      if (!user) {
        user = this.$store.state.user
      }

      if (user.allEntitiesPermissions) {
        return user.allEntitiesPermissions.some(allEntitiesPermission => allEntitiesPermission === requiredPermission)
      }

      return false
    },

    isActionDisabled (entity, action) {
      return entity?.permittedActions?.[action] === false
    },

    isAvailableBookingOverMaxCapacity () {
      return this.hasPermission('book_outside_working_hours') ||
        this.hasPermission('book_over_existing_appointment')
    }
  }
}
