<template>

  <!-- Impersonate Ribbon -->
  <div class="back-ribbon">

    <adm-button
      color="light"
      icon-start="arrow-left"
      size="micro"
      type="borderless"
      @click="back()"
    >

      {{ $cookies.get('impersonatePage') === 'agency' ? $t('back_to_agency_dashboard') : $t('back_to_backoffice') }}
    </adm-button>


    <!-- Close -->
    <div class="back-ribbon__info">
      <!-- Logo -->
      <img
        v-if="$store.getters['settings/isLogoSquareVisible'] && $store.getters['settings/isAgency']"
        :src="$store.state.settings.agency.agencyLogoSquareUrl"
        alt="logo"
      >
      <!-- /Logo -->
      <span v-if="$store.getters['settings/isAgency']" v-text="$transWithBusinessName('{{ BUSINESS_NAME }}')" />
    </div>
    <!-- /Close -->

  </div>
  <!-- /Impersonate Ribbon -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import mixinLogout from '@/mixins/logout/logout'

export default {
  name: 'ImpersonateRibbon',

  components: {
    AdmButton
  },

  mixins: [mixinLogout],

  methods: {
    back () {
      this.logout()
      window.location.href = this.$cookies.get('impersonateReturnUrl')
    }
  }
}
</script>

<style lang="scss">
.back-ribbon {
  padding: 8px 24px 8px 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $shade-1000;

  // Close
  &__info {
    display: none;
    align-items: center;
    order: 3;
    gap: 12px;
    color: $shade-250;
    font-size: 13px;
    font-weight: 500;
    line-height: 20px;

    @include phone-up {
      display: flex;
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
