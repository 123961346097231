<template>
  <table
    style="box-sizing: inherit; border-collapse: collapse; border-spacing: 0; margin-top: 8px; width: 362px;"
  >
    <tbody style="box-sizing: inherit;">
      <tr style="box-sizing: inherit;">
        <td style="box-sizing: inherit; padding: 0;">
          <table style="box-sizing: inherit; border-spacing: 0 4px;">
            <tbody style="box-sizing: inherit;">
              <tr style="box-sizing: inherit; height: 25px;">
                <email-slot-link :label="label" :link="link" />
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import EmailSlotLink from '@/views/Dashboard/ShareBookingFormDialog/LinkSection/_components/EmailSlotLink.vue'

export default {
  name: 'EmailButtonLink',
  components: { EmailSlotLink },
  props: {
    label: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    }
  }
}
</script>
