<template>

  <!-- Option -->
  <el-option
    class="adm-select-option"
    :class="{ 'is-with-icon': icon }"
    :value="value"
    :label="label"
    :disabled="disabled"
  >
    <template v-if="icon">
      <adm-icon :icon="icon" :size="iconSize" :color="iconColor" class="mr-4" />
    </template>
    <slot />
  </el-option>
  <!-- /Option -->

</template>

<script>
import AdmIcon from '@/views/_components/icon/AdmIcon'

export default {
  name: 'AdmOption',

  components: {
    AdmIcon
  },

  props: {
    value: {
      type: [String, Number, Object],
      required: true
    },
    label: {
      type: [String, Number],
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: String,
      default: 'medium'
    },
    iconColor: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss">
// Select Option
.adm-select-option, .time-select-item {
  padding: 4px 8px;
  margin: 0;
  overflow: hidden;
  border-radius: 5px;
  display: flex;
  align-items: center;
  height: 32px;
  line-height: 32px;
  border: 1px solid $adm-white;
  font-weight: 500;

  // Hover
  &:hover {
    background-color: $shade-200;
  }

  // Active
  &:active {
    background-color: $shade-250;
  }

  // Disabled
  &.is-disabled {
    cursor: not-allowed;
    color: $shade-600;
  }

  // Selected
  &.selected {
    background-color: var(--primary-300);
    color: var(--primary-900);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
  }

  // With Icon
  &.is-with-icon {
    padding: 4px 8px 4px 4px;
  }
}
</style>
