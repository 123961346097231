<template>

  <!-- Service Info -->
  <div class="section">

    <!-- Title -->
    <div class="section-title flex-center">
      <adm-icon icon="services" />
      <span>{{ $t('service') }}</span>
    </div>
    <!-- /Title -->

    <!-- Service -->
    <div class="flex-center">

      <!-- Avatar -->
      <adm-avatar
        v-if="showData"
        :background-color="service.color"
        :margin-right="10"
        :name="service.name"
        :size="34"
        :src="service.mediaUrl"
      />
      <adm-skeleton-element v-else :border-radius="17" :height="34" :margin-right="10" :width="34" />
      <!-- /Avatar -->

      <!-- Name, Duration & Price -->
      <div>

        <!-- Name -->
        <h6>
          <span v-if="showData">{{ service.name }}</span>
          <adm-skeleton-element v-else :height="16" :margin-bottom="8" :width="120" />
        </h6>
        <!-- /Name -->

        <!-- Duration -->
        <span v-if="showData" class="mr-16 section-text">
          {{ secondsToNiceDuration(service.duration) }}
        </span>
        <adm-skeleton-element v-if="showData === false" :height="14" :width="80" />
        <!-- /Duration -->

        <!-- Price -->
        <span v-if="showData" class="section-text">
          {{ formatPrice(servicePrice) }}
        </span>
        <!-- /Price -->

      </div>
      <!-- /Name, Duration & Price -->

    </div>
    <!-- /Service -->

  </div>
  <!-- /Service Info -->

</template>

<script>
import AdmAvatar from '@/views/_components/avatar/AdmAvatar'
import AdmIcon from '@/views/_components/icon/AdmIcon'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import mixinDuration from '@/mixins/common/duration'
import mixinPrice from '@/mixins/common/price'

export default {
  name: 'ServiceInfo',

  components: {
    AdmIcon,
    AdmAvatar,
    AdmSkeletonElement,
  },

  mixins: [
    mixinDuration,
    mixinPrice
  ],

  props: {
    appointmentProp: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    showData () {
      return this.service
    },

    service () {
      return this.appointmentProp.service
    },

    servicePrice () {
      const appointment = this.appointmentProp
      let servicePrice = appointment.service.servicePrice

      if (appointment.id) {
        servicePrice = appointment.price - appointment.employeeFee
      }

      return servicePrice
    }
  }
}
</script>
