<template>

  <!-- Customer Info -->
  <div class="customer-info section section-divided">

    <!-- Title -->
    <div class="section-title flex-center">
      <adm-icon icon="customers" />
      <span>{{ $t('customers') }} {{ bookings.length > 1 ? `(${bookings.length})` : '' }}</span>
    </div>
    <!-- /Title -->

    <!-- Customers -->
    <div v-for="booking in bookings" :key="booking.id" class="mb-8">

      <!-- Name & Additional Persons -->
      <div class="flex-center">

        <!-- Name -->
        <h6>
          {{ booking.firstName + ' ' + booking.lastName }}
        </h6>
        <!-- /Name -->

        <!-- Additional Persons -->
        <more-items :greater-than="0" :items-length="booking.additionalPersons" />
        <!-- /Additional Persons -->

      </div>
      <!-- /Name & Additional Persons -->

      <!-- Extra Details -->
      <extras-details :booking="booking" />
      <!-- /Extra Details -->

    </div>
    <!-- /Customers -->

    <!-- Customers Skeleton -->
    <adm-skeleton-element v-if="!showData" :height="16" :width="120" />
    <!-- /Customers Skeleton -->

  </div>
  <!-- /Customer Info -->

</template>

<script>
import AdmIcon from '@/views/_components/icon/AdmIcon'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import ExtrasDetails from '@/components/dashboard/extrasDetails/ExtrasDetails'
import MoreItems from '@/components/dashboard/moreItems/MoreItems'

export default {
  name: 'CustomerInfo',

  components: {
    AdmIcon,
    AdmSkeletonElement,
    ExtrasDetails,
    MoreItems,
  },

  props: {
    bookings: {
      type: Array,
      required: true,
      default: () => ([])
    }
  },

  computed: {
    showData () {
      return this.bookings.length > 0
    }
  }
}
</script>
