export default {
  methods: {
    calculateTax (price, taxRate) {
      return this.$store.state.settings.payments.taxDisplaySettings === 'exclusive'
        ? price * taxRate / 100
        : (price * taxRate / 100) / (1 + taxRate / 100)
    },

    calculateBookingTotalTax (booking) {
      let result = 0
      const bookingTaxData = booking.taxData

      if (bookingTaxData !== null) {
        if (bookingTaxData.serviceTaxes) {
          for (let i = 0; i < bookingTaxData.serviceTaxes.length; i++) {
            result += bookingTaxData.serviceTaxes[i].value
          }
        }

        for (let i = 0; i < booking.extras.length; i++) {
          const extra = booking.extras[i]

          const extraTaxValue = this.getExtraTaxValue(extra, bookingTaxData, booking)

          result += extraTaxValue
        }
      }

      return result
    },

    calculatePriceDetailsTax (booking = null, appointment) {
      let price = 0

      if (appointment.id && booking.bookingId) {
        return this.calculateBookingTotalTax(booking)
      }

      price += this.calculateTax(booking.price * (booking.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1), appointment.service.taxRate)

      for (let i = 0; i < booking.extras.length; i++) {
        const extra = booking.extras[i]
        price += this.calculateTax(extra.price * extra.quantity * (extra.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1), extra.taxRate)
      }

      return price
    },

    getExtraTaxValue (extra, bookingTaxData, booking) {
      let taxRate = null

      if (bookingTaxData.extrasTaxes) {
        for (let j = 0; j < bookingTaxData.extrasTaxes.length; j++) {
          let extraTaxes = bookingTaxData.extrasTaxes[j]

          for (let k = 0; k < extraTaxes.length; k++) {
            if (extraTaxes[k].extraId === extra.id) {
              taxRate = extraTaxes[k].rate
            }
          }
        }
      }

      if (!taxRate && extra.taxRate) {
        taxRate = extra.taxRate
      }

      if (taxRate) {
        return this.calculateTax(
          extra.price * extra.quantity * (extra.multiplyPriceWithNumberOfPeople
            ? (1 + booking.additionalPersons) : 1), taxRate
        )
      }

      return 0
    },
  }
}
