<template>

  <!-- Design System Element Block -->
  <div class="adm-design-system-element-block">

    <!-- Title & Description -->
    <div class="adm-design-system-element-block__title-wrapper">

      <!-- Title -->
      <h3 class="adm-design-system-element-block__title">
        {{ title }}
      </h3>
      <!-- /Title -->

      <!-- Description -->
      <span v-if="description" class="adm-design-system-element-block__description">
        {{ description }}
      </span>
      <!-- /Description -->

    </div>
    <!-- /Title & Description -->

    <!-- Container -->
    <div class="adm-design-system-element-block__container">

      <!-- Preview -->
      <div class="adm-design-system-element-block__preview" :style="{ display }">
        <slot name="preview" />
        <adm-button
          class="ml-auto"
          type="borderless"
          :icon-end="expanded ? 'arrow-up' : 'arrow-down'"
          @click="expanded = !expanded"
        >
          {{ expanded ? 'Hide' : 'Expand' }}
        </adm-button>
      </div>
      <!-- /Preview -->

      <!-- Code -->
      <div v-show="expanded" class="adm-design-system-element-block__code">
        <div v-if="codeDescription" class="adm-design-system-element-block__code__description">
          {{ codeDescription }}
        </div>
        <template>
          <!-- eslint-disable -->
          <prism v-for="(code, index) in $slots.code" :key="index" v-if="code.text" language="markup">{{ code.text.trim() }}</prism>
          <!-- eslint-enable -->
        </template>
      </div>
      <!-- /Code -->

    </div>
    <!-- /Container -->

  </div>
  <!-- /Design System Element Block -->

</template>

<script>
import 'prismjs'
import 'prismjs/themes/prism-coy.css'
import AdmButton from '@/views/_components/button/AdmButton'
import Prism from 'vue-prism-component'

export default {
  name: 'DesignSystemElementBlock',

  components: {
    AdmButton,
    Prism
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    codeDescription: {
      type: String,
      default: ''
    },
    display: {
      type: String,
      default: 'flex'
    }
  },

  data () {
    return {
      expanded: false
    }
  }
}
</script>

<style lang="scss">
// Design System Element Block
.adm-design-system-element-block {
  margin-bottom: 2rem;

  // Last Element
  &:last-of-type {
    margin-bottom: 0;
  }

  // Title Wrapper
  &__title-wrapper {
    margin-bottom: 1rem;
  }

  // Title
  &__title {
    margin-bottom: 0.5rem;
  }

  // Container
  &__container {
    border-radius: 4px;
    box-shadow: $box-shadow-large;
  }

  // Preview
  &__preview {
    display: flex;
    align-items: center;
    padding: 1.5rem;
    border-bottom: 1px solid $shade-300;
  }

  // Code
  &__code {
    padding: 1.5rem;

    // Description
    &__description {
      border: 1px solid $shade-300;
      border-radius: 4px;
      padding: 1rem;
      margin-bottom: 1rem;
    }

    // Preformatted Text
    pre.language-markup {
      &:before, &:after {
        content: none;
      }

      code {
        box-shadow: none;
        padding: 8px;
        border-left: 3px solid $red-900;
        background: #f4f4f4;
      }
    }
  }
}
</style>
