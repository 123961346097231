<template>
  <div class="promo-countdown">
    <div class="promo-countdown__holder">
      {{ days }}
    </div>
    <div class="promo-countdown__separator">
      :
    </div>
    <div class="promo-countdown__holder">
      {{ hours }}
    </div>
    <div class="promo-countdown__separator">
      :
    </div>
    <div class="promo-countdown__holder">
      {{ minutes }}
    </div>
    <div class="promo-countdown__separator">
      :
    </div>
    <div class="promo-countdown__holder">
      {{ seconds }}
    </div>
  </div>
</template>
<script>

export default {
  name: 'PromoCountdown',

  data () {
    return {
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0
    }
  },

  created () {
    this.initializeClock()
  },

  methods: {
    initializeClock() {
      let endTime = new Date("Mar 18, 2024 00:00:00").getTime();
      const countdownRepeatInterval = 7 * 24 * 60 * 60 * 1000; // 7 days

      if (new Date().getTime() > endTime) {
        endTime += countdownRepeatInterval;
      }

      while (endTime < new Date().getTime()) {
        endTime += countdownRepeatInterval;
      }

      const updateClock = () => {
        const now = new Date().getTime()
        const total = endTime - now;

        this.days = Math.floor(total / (1000 * 60 * 60 * 24));
        this.hours = Math.floor((total % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((total % (1000 * 60 * 60)) / (1000 * 60));
        this.seconds = Math.floor((total % (1000 * 60)) / 1000);

        if (total < 1) {
          endTime += countdownRepeatInterval;
          clearInterval(updateClock);
          setTimeout(() => {
            this.initializeClock();
          }, countdownRepeatInterval);
        }
      }

      updateClock()
      setInterval(updateClock, 1000)
    }
  }
}
</script>

<style lang="scss">
.promo-countdown {
  display: flex;
  padding: 0 10px;
  gap: 4px;

  &__holder {
    display: flex;
    width: 30px;
    height: 30px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--primary-400);
    font-size: 14px;
    line-height: 24px;
    font-weight: 600;
    letter-spacing: 0.54px;
    border-radius: 6px;
    background: var(--primary-900);
  }

  &__separator {
    font-weight: 600;
    color: var(--primary-900);
    font-size: 14px;
  }
}
</style>
