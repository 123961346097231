<template>
  <table style="box-sizing: inherit; border-collapse: collapse; border-spacing: 0; margin-top: 8px; width: 362px;">
    <tbody style="box-sizing: inherit;">
      <tr style="box-sizing: inherit;">
        <td style="box-sizing: inherit; padding: 0; margin-top: 16px;">
          <span style="box-sizing: inherit; font-size: 14px; line-height: 20px;color: #000000; padding-bottom: 8px; font-weight: 600;">
            {{ formatDate(date) }}
          </span>
        </td>
      </tr>
      <tr style="box-sizing: inherit;">
        <td style="box-sizing: inherit; padding: 0;">
          <table style="box-sizing: inherit; border-spacing: 0 4px;">
            <tbody style="box-sizing: inherit;">
              <tr style="box-sizing: inherit; height: 25px;">
                <email-slot-link
                  v-for="time in times"
                  :key="time"
                  :label="formatTime(time)"
                  :link="getLinkForSlot(time)"
                  size="medium"
                />
              </tr>
            </tbody>
          </table>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import EmailSlotLink
  from '@/views/Dashboard/ShareBookingFormDialog/LinkSection/_components/EmailSlotLink.vue'
import datetime from '@/mixins/common/datetime'

export default {
  name: 'EmailSlots',
  components: { EmailSlotLink },
  mixins: [datetime],
  props: {
    times: {
      type: Array,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    }
  },
  methods: {
    getLinkForSlot (slotIndex) {
      return `${this.link}&slot=${this.date} ${slotIndex}`
    }
  }
}
</script>
