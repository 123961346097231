<template>
  <div>
    <adm-modal
      :visible="true"
      width="850px"
      @close="onClose"
    >
      <!-- Login video -->
      <iframe width="100%" height="400px" :src="`${ link }?autoplay=1`" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
      <!-- /Login video -->

      <template #footer>

        <!-- Guide Actions -->
        <adm-button
          type="filled"
          color="blue"
          @click="onClose"
        >
          {{ $t('close') }}
        </adm-button>
        <!-- /Guide Actions -->

      </template>
    </adm-modal>
  </div>
</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmModal from '@/views/_components/modal/AdmModal'

export default {
  name: 'TutorialVideoModal',

  components: {
    AdmModal,
    AdmButton
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    link: {
      type: String,
      required: true
    }
  },

  methods: {
    onClose () {
      this.$emit('close')
    },
  }
}
</script>

