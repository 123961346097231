<template>
  <div class="adm-checkbox-group-wrapper">
    <el-checkbox-group
      v-model="model"
      v-bind="$props"
      @input="(eventValue) => $emit('input', eventValue)"
      @change="(eventLabel) => $emit('change', eventLabel)"
    >
      <slot />
    </el-checkbox-group>
  </div>
</template>

<script>
  export default {
    name: 'AdmCheckboxGroup',

    props: {
      value: {
        type: Array,
        default: () => []
      },
    },

    data () {
      return {
        selfModel: []
      }
    },

    computed: {
      model: {
        get () {
          return this.value !== undefined ? this.value : this.selfModel
        },
        set (val) {
          this.selfModel = val
        }
      }
    },
  };
</script>

<style lang="scss">
  .adm-checkbox-group-wrapper {
    .adm-checkbox-wrapper {
      margin-bottom: 4px;
    }
  }
</style>
