<template>

  <!-- Phone Input -->
  <vue-phone-number-input
    ref="VuePhoneNumberInput"
    v-model="phoneNumber"
    class="adm-phone-input"
    :disabled="disabled"
    :color="'#00143C'"
    :valid-color="'#20C997'"
    :error-color="'#F03E3E'"
    :default-country-code="phoneCountryCode"
    :translations="translations"
    :no-validator-state="true"
    :ignored-countries="ignoredCountries"
    @input="onUpdate"
  >
    <adm-icon
      slot="arrow"
      class="adm-phone-input__arrow"
      icon="arrow-down"
      size="small"
      :color="!disabled ? $colors['shade-900'] : $colors['shade-600']"
    />
  </vue-phone-number-input>
  <!-- /Phone Input -->

</template>

<script>
import VuePhoneNumberInput from 'vue-phone-number-input'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import AdmIcon from '@/views/_components/icon/AdmIcon'

export default {
  name: 'AdmPhoneInput',

  components: {
    AdmIcon,
    VuePhoneNumberInput
  },

  props: {
    passedPhoneNumber: {
      default: '',
      type: String
    },
    passedPhoneCountryCode: {
      default: '',
      type: String
    },
    disabled: {
      default: false,
      type: Boolean
    }
  },

  data () {
    return {
      phoneNumber: '',
      phoneCountryCode: null,
      translations: {
        countrySelectorLabel: this.$t('country_code'),
        countrySelectorError: this.$t('choose_country'),
        phoneNumberLabel: this.$t('phone_number'),
        example: this.$t('example')
      },
      ignoredCountries: ['IO', 'BQ', 'CX', 'CC', 'GD', 'MF', 'PM', 'SJ', 'WF', 'EH', 'SX']
    }
  },

  mounted () {
    if (this.passedPhoneNumber) {
      this.phoneNumber = this.passedPhoneNumber
    }

    if (this.passedPhoneCountryCode) {
      this.phoneCountryCode = this.passedPhoneCountryCode.toUpperCase()
    } else if (this.$store.state.settings.general.countryCode) {
      this.phoneCountryCode = this.$store.state.settings.general.countryCode.toUpperCase()
    }
  },

  methods: {
    onUpdate () {
      const results = this.$refs.VuePhoneNumberInput.results

      if (results.countryCode === 'AU' && results.nationalNumber) {
        if (results.nationalNumber.length === 9 && parseInt(results.nationalNumber) > 493699999) {
          this.$refs.VuePhoneNumberInput.results.isValid = true
        }
      }

      this.$emit('update', this.$refs.VuePhoneNumberInput.results)
    }
  }
}
</script>

<style lang="scss">
.el-form-item {
  .adm-phone-input {
    .select-country-container {
      flex: 0 0 100px;
      width: 100px;
      min-width: 100px;
      max-width: 100px;

      &:hover {
        .country-selector__input {
          border: 1px solid $shade-300 !important;
          box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
        }
      }

      .country-selector__input {
        text-align: center;
        border-top-left-radius: 7px !important;
        border-bottom-left-radius: 7px !important;
        padding-top: 0;
        color: $shade-900;
        background-color: $adm-white;
        padding-left: 32px !important;
      }

      .input-country-selector {
        &.is-disabled {
          input {
            background-color: $shade-150;
            color: $shade-600;
            font-size: 15px;
            line-height: 24px;
          }
        }
        .country-selector__country-flag {
          height: 100%;
          top: 0;
          display: flex;
          align-items: center;
          left: 8px !important;

          .iti-flag {
            border-radius: 3px;
          }
        }

        .country-selector__label {
          display: none;
        }

        &.is-focused {
          .country-selector__input {
            box-shadow: none !important;
          }
        }

        .country-selector__toggle {
          top: 9px !important;
          right: 4px !important;
        }

        .country-selector__list {
          border: 1px solid $shade-200;
          border-radius: 4px;
          background-color: $adm-white;
          box-shadow: 0 1px 1px rgba(0, 20, 60, 0.24), 0 3px 7px rgba(0, 20, 60, 0.17);
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          margin: 4px 0;
          padding: 8px 6px;

          &::-webkit-scrollbar {
            z-index: 11;
            width: 6px;
          }

          &::-webkit-scrollbar:horizontal {
            height: 6px;
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            width: 6px;
            background-color: rgba(144, 147, 153, 0.3);
          }

          &::-webkit-scrollbar-corner {
            background: #fff;
          }

          &::-webkit-scrollbar-track {
            background: #fff;
          }

          &::-webkit-scrollbar-track-piece {
            background: #fff;
            width: 6px;
          }

          .vue-recycle-scroller__item-view {
            border-radius: 5px !important;

            &:hover {
              background-color: $shade-200;
            }

            &:active {
              background-color: $shade-250;
            }

            .iti-flag {
              border-radius: 3px;
            }

            .dots-text {
              font-weight: 500;
              font-size: 14px;
              line-height: 24px;
            }

            .country-selector__list__item.selected {
              border-radius: 5px !important;
              color: var(--primary-900);
              font-weight: 500;
              background-color: var(--primary-300) !important;
            }
          }
        }
      }
    }

    .input-phone-number {

      &.is-focused {
        .input-tel__input {
          box-shadow: none !important;
        }
      }

      &.is-disabled {
        input {
          background-color: $shade-150;
          color: $shade-600;
          padding-top: 0;
        }

        .input-tel__label {
          display: none;
        }
      }

      &.has-value:not(.is-disabled) {
        .input-tel__input {
          padding-top: 17px;
        }

        .input-tel__label {
          top: 0;
        }
      }

      input::placeholder {
        color: $shade-600 !important;
      }

      .input-tel {
        &__label {
          color: $shade-600 !important;
          cursor: text !important;
        }

        &__input {
          cursor: text !important;
          border-top-right-radius: 7px !important;
          border-bottom-right-radius: 7px !important;
        }
      }
    }

    .country-selector__input, .input-tel__input {
      border: 1px solid $shade-300;

      &:hover {
        border: 1px solid $shade-300 !important;
        box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
      }
    }
  }

  &.is-error {
    .adm-phone-input {
      .select-country-container {
        &:hover {
          .country-selector__input {
            border: 1px solid $red-900 !important;
          }
        }

        .input-country-selector {
          &.is-focused {
            .country-selector__input {
              border: 1px solid $red-900 !important;
              box-shadow: none !important;
            }
          }
        }
      }

      .input-phone-number {
        &.is-focused {
          .input-tel__input {
            border: 1px solid $red-900 !important;
            box-shadow: none !important;
          }
        }
      }

      .country-selector__input, .input-tel__input {
        border: 1px solid $red-900 !important;

        &:hover {
          border: 1px solid $red-900 !important;
        }
      }
    }
  }
}

.adm-phone-input {
  .select-country-container {
    .input-country-selector {
      &.is-focused {
        .country-selector__input {
          border: 1px solid var(--primary-900) !important;
        }
      }
    }
  }

  .input-phone-number {
    &.is-focused {
      .input-tel__input {
        border: 1px solid var(--primary-900) !important;
      }
    }
  }
}
</style>
