<template>
  <div class="appointment-extra-card">
    <div class="appointment-extra-card__header">
      <adm-avatar
        :is-human="true"
        :margin-right="8"
        :name="extra.name"
        :size="24"
        :src="extra.mediaUrl"
      />
      <span class="overflow-ellipsis mr-8" :title="extra.name">{{ extra.name }}</span>

      <adm-button
        icon-start="close"
        type="borderless"
        size="small"
        color="grey"
        @click="removeExtra(extra)"
      />
    </div>

    <div class="appointment-extra-card__body">

      <!-- Quantity -->
      <div class="appointment-extra-card__body__block">
        <div class="appointment-extra-card__body__block__label">
          {{ $t('quantity') }}
        </div>

        <div class="appointment-extra-card__body__block__content">
          <adm-input-number
            v-model="extra.quantity"
            :max="extra.maxQuantity"
            :min="1"
            size="small"
            @change="setChangedExtra(extra)"
          />
        </div>
      </div>
      <!-- /Quantity -->

      <!-- Time -->
      <div v-if="extra.duration" class="appointment-extra-card__body__block">
        <div class="appointment-extra-card__body__block__label">
          {{ $t('duration') }}
        </div>

        <div class="appointment-extra-card__body__block__content">
          {{ typeof extra.duration !== 'undefined' ? secondsToNiceDuration(extra.duration) : '' }}
        </div>
      </div>
      <!-- /Time -->

      <!-- Price -->
      <div class="appointment-extra-card__body__block">
        <div class="appointment-extra-card__body__block__label">
          {{ $t('price') }}
        </div>

        <div class="appointment-extra-card__body__block__content">
          {{ formatPrice(extra.price) }}
        </div>
      </div>
      <!-- /Price -->
    </div>
  </div>
</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmInputNumber from '@/views/_components/input/AdmInputNumber'
import mixinDuration from '@/mixins/common/duration'
import mixinPrice from '@/mixins/common/price'
import AdmAvatar from '@/views/_components/avatar/AdmAvatar'

export default {
  name: 'ExtraCard',

  components: {
    AdmAvatar,
    AdmInputNumber,
    AdmButton
  },

  mixins: [
    mixinDuration,
    mixinPrice,
  ],

  props: {
    extraProp: {
      type: Object,
      default: () => {}
    },

    removeExtra: {
      type: Function,
      default: () => {}
    },

    setChangedExtra: {
      type: Function,
      default: () => {}
    }
  },

  data: function () {
    return {
      extra: this.extraProp
    }
  }
}
</script>

<style lang="scss" scoped>
.appointment-extra-card {
  border: 1px solid $shade-300;
  border-radius: 7px;

  &__header {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    margin: 8px 8px 0;
    padding-bottom: 8px;
    border-bottom: 1px solid $shade-300;
  }

  &__body {
    padding: 8px;
    display: flex;
    gap: 24px;

    &__block {
      max-width: 100px;

      &__label {
        font-size: 12px;
        line-height: 16px;
        margin-bottom: 4px;
      }

      &__content {
        font-size: 14px;
        line-height: 24px;
      }
    }
  }
}
</style>
