<template>

  <!-- Design System Tag -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Tag
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
      code-description="Tag provides 6 types of themes defined by type (default, primary, positive, danger, warning, success, success-secondary)."
      display="block"
    >

      <!-- Preview -->
      <template #preview>
        <div class="mb-16">
          <adm-tag class="mr-16">
            Default
          </adm-tag>
          <adm-tag class="mr-16" type="primary">
            Primary
          </adm-tag>
          <adm-tag class="mr-16" type="positive">
            Positive
          </adm-tag>
          <adm-tag class="mr-16" type="danger">
            Danger
          </adm-tag>
          <adm-tag class="mr-16" type="warning">
            Warning
          </adm-tag>
          <adm-tag class="mr-16" type="success">
            Success
          </adm-tag>
          <adm-tag class="mr-16" type="success-secondary">
            Success Secondary
          </adm-tag>
        </div>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getTagCode({ }, 'Default') }}
        <br>
        {{ getTagCode({ type: 'primary' }, 'Primary') }}
        <br>
        {{ getTagCode({ type: 'positive' }, 'Positive') }}
        <br>
        {{ getTagCode({ type: 'danger' }, 'Danger') }}
        <br>
        {{ getTagCode({ type: 'warning' }, 'Warning') }}
        <br>
        {{ getTagCode({ type: 'success' }, 'Success') }}
        <br>
        {{ getTagCode({ type: 'success-secondary' }, 'Success Secondary') }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Tag Attributes -->
    <design-system-documentation-table :table-data="tableDataAttributes" />
    <!-- /Tag Attributes -->

    <!-- Tag Slots -->
    <design-system-documentation-table title="Slots" :table-data="tableDataSlots" type="slots" />
    <!-- /Tag Slots -->

  </div>
  <!-- /Design System Tag -->

</template>

<script>
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'
import AdmTag from '@/views/_components/tag/AdmTag'

export default {
  name: 'DesignSystemTag',

  components: {
    AdmTag,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      tableDataAttributes: [
        {
          attribute: 'type',
          description: 'Tag type',
          type: 'string',
          values: 'default / primary / positive / danger / warning  / success / success-secondary',
          default: 'default',
        },
        {
          attribute: 'text-color',
          description: 'Color of the text',
          type: 'string',
          values: '-',
          default: '#00143c',
        },
        {
          attribute: 'background-color',
          description: 'Color of the background',
          type: 'string',
          values: '-',
          default: '#EDEFF3',
        },
        {
          attribute: 'border-color',
          description: 'Color of the border',
          type: 'string',
          values: '-',
          default: '#DEE1E6',
        },
        {
          attribute: 'class',
          description: 'Element class',
          type: 'string',
          values: '-',
          default: '-',
        },
      ],
      tableDataSlots: [
        {
          name: '-',
          description: 'Text of the label'
        },
      ]
    }
  },
}
</script>
