const getDefaultState = () => {
  return {
    loading: false,
    loadingFiltering: false,
    loadingOptions: false,
    count: 0,
    countFiltered: 0,
    params: {
      page: 1,
      search: '',
      services: [],
      customers: [],
      locations: [],
      employees: [],
      statuses: [],
      roles: [],
      dates: [],
      sort: ''
    },
    showFilter: false,
    routerViewKey: 1,
    // TODO - Remove from view.js than add to component itself
    visibleCategoryModal: false,
    isAssignedServicesWarningMessagePresented: true,
    upgradePopupDialogVisible: false,
    upgradePopupDialogTitle: '',
    upgradePopupDialogDescription: '',
  }
}

const state = getDefaultState()

const getters = {
  paramsFiltered (state) {
    const params = Object.assign({}, state.params)

    // Remove all empty params
    Object.keys(params).forEach((key) => (!params[key] || (params[key].length === 0)) && delete params[key])

    return params
  },

  activeFiltersCount (state) {
    let result = 0

    for (const key in state.params) {
      if (key !== 'dates' && Object.prototype.hasOwnProperty.call(state.params, key)) {
        if (Array.isArray(state.params[key]) && state.params[key].length > 0) {
          result++
        }
      }
    }

    return result
  }
}

const mutations = {
  resetState (state, route = null) {
    if (route && route.params.resetState) {
      return
    }

    Object.assign(state, getDefaultState())
  },

  showLoading (state) {
    state.loading = true
  },

  hideLoading (state) {
    state.loading = false
  },

  showLoadingFiltering (state) {
    state.loadingFiltering = true
  },

  hideLoadingFiltering (state) {
    state.loadingFiltering = false
  },

  setCount (state, count) {
    state.count = count
  },

  setCountFiltered (state, countFiltered) {
    state.countFiltered = countFiltered
  },

  decrementCount (state, number = null) {
    state.count = number ? state.count - number : state.count - 1
  },

  decrementCountFiltered (state, number = null) {
    state.countFiltered = number ? state.countFiltered - number : state.countFiltered - 1
  },

  setSearch (state, search) {
    state.params.search = search
  },

  setPage (state, page) {
    state.params.page = page
  },

  setServices (state, services) {
    state.params.services = [...services]
  },

  setLocations (state, locations) {
    state.params.locations = [...locations]
  },

  setEmployees (state, employees) {
    state.params.employees = [...employees]
  },

  setCustomers (state, customers) {
    state.params.customers = [...customers]
  },

  setStatuses (state, statuses) {
    state.params.statuses = [...statuses]
  },

  setRoles (state, roles) {
    state.params.roles = [...roles]
  },

  setDates (state, dates) {
    state.params.dates = dates
  },

  toggleFilter () {
    state.showFilter = !state.showFilter
  },

  showCategoryModal (state) {
    state.visibleCategoryModal = true
  },

  hideCategoryModal (state) {
    state.visibleCategoryModal = false
  },

  increaseRouterViewKey (state) {
    state.routerViewKey++
  },

  setSort (state, value) {
    state.params.sort = value
  },

  showAssignedServicesWarningMessage (state) {
    state.isAssignedServicesWarningMessagePresented = true
  },

  hideAssignedServicesWarningMessage (state) {
    state.isAssignedServicesWarningMessagePresented = false
  },

  toggleUpgradePlanPopup (state, value) {
    state.upgradePopupDialogVisible = value
  },

  setUpgradePlanPopupTitle (state, value) {
    state.upgradePopupDialogTitle = value
  },

  setUpgradePlanPopupDescription (state, value) {
    state.upgradePopupDialogDescription = value
  }
}

const actions = {
  hideLoading ({ commit }) {
    commit('hideLoading')
  },

  hideLoadingFiltering ({ commit }) {
    commit('hideLoadingFiltering')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
