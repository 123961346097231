export default {
  methods: {
    downloadFile (name) {
      const link = document.createElement('a')
      link.href =  `${ process.env.VUE_APP_API_URL }/assets/files/${ name }`
      link.download = name
      link.click()
    }
  }
}
