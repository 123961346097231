<template>

  <!-- Alert -->
  <div
    class="adm-alert"
    :class="[
      'adm-alert' + '__' + type,
      {
        'is-full-width': fullWidth,
        'is-without-icon': !showIcon,
        'is-without-description': isWithoutDescription,
      }
    ]"
    role="alert"
  >

    <!-- Content -->
    <div class="adm-alert__content">

      <!-- Icon -->
      <div v-if="showIcon" class="adm-alert__icon-wrapper">
        <i :class="'tz-' + getIcon()" />
      </div>
      <!-- /Icon -->

      <!-- Title & Description -->
      <span class="adm-alert__text-wrapper">

        <!-- Title -->
        <span
          v-if="title"
          class="adm-alert__text-wrapper__title"
          :style="{ fontFamily }"
          v-html="title"
        />
        <!-- /Title -->

        <!-- Title Slot -->
        <span v-if="$slots.title" class="adm-alert__text-wrapper__title">
          <slot name="title">
            {{ title }}
          </slot>
        </span>
        <!-- /Title Slot -->

        <!-- Description -->
        <span
          v-if="description"
          class="adm-alert__text-wrapper__description"
          v-html="description"
        />
        <!-- /Description -->

        <!-- Description Slot -->
        <span v-if="$slots.description" class="adm-alert__text-wrapper__description">
          <slot name="description">
            {{ description }}
          </slot>
        </span>
        <!-- /Description Slot -->

        <!-- Below Slot -->
        <div v-if="$slots.below" class="adm-alert__text-wrapper__below">
          <slot name="below" />
        </div>
        <!-- /Below Slot -->

      </span>
      <!-- /Title & Description -->

    </div>
    <!-- /Content -->

    <!-- Right Slot -->
    <div v-if="$slots.right" class="adm-alert__right">
      <slot name="right" />
    </div>
    <!-- /Right Slot -->

  </div>
  <!-- /Alert -->

</template>

<script>
export default {
  name: 'AdmAlert',

  props: {
    type: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'positive', 'warning', 'danger'].indexOf(value) !== -1
      }
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    fullWidth: {
      type: Boolean,
      default: true
    },
    icon: {
      type: String,
      default: null
    },
    showIcon: {
      type: Boolean,
      default: true
    },
    fontFamily: {
      type: String,
      default: 'Inter',
      required: false
    }
  },

  computed: {
    isWithoutDescription () {
      return !this.description && (Array.isArray(this.$slots.description) ? this.$slots.description.length === 0 : !this.$slots.description)
    }
  },

  methods: {
    getIcon () {
      if (this.icon) {
        return this.icon;
      }

      if (this.type === 'default')
        return 'info'
      else if (this.type === 'positive')
        return 'check-filled'
      else if (this.type === 'warning')
        return 'info-filled'
      else
        return 'warning-filled'
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
// Alert
.adm-alert {
  border-radius: 8px;
  padding: 0.5rem 1.25rem 0.5rem 0.5rem;
  display: flex;
  align-items: center;
  gap: 10px;

  @include phone-down {
    flex-direction: column;
    padding: 0.5rem
  }

  // Default
  &__default {
    background-color: $shade-200;
  }

  // Positive
  &__positive {
    background-color: var(--primary-400);

    i:before {
      color: var(--primary-900);
    }
  }

  // Warning
  &__warning {
    background-color: $yellow-800;
  }

  // Danger
  &__danger {
    background-color: $red-900;
    color: $adm-white;

    a {
      color: $adm-white;
    }

    i:before {
      color: $adm-white;
    }
  }

  // Content
  &__content {
    display: flex;
    align-items: flex-start;
    margin-right: auto;
  }

  // Icon Wrapper
  &__icon-wrapper {
    margin-right: 0.25rem;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 20px;
      display: block;
    }
  }

  // Text Wrapper
  &__text-wrapper {
    display: flex;
    flex-direction: column;
    align-self: center;

    // Title
    &__title {
      text-align: left;
      font-size: 14px;
      font-weight: 600;
    }

    // Description
    &__description {
      font-weight: normal;
      font-size: 13px;
      line-height: 20px;
      word-break: break-word;

      // All Child
      > * {
        font-weight: normal;
        font-size: 13px;
        line-height: 20px;
      }

      // Anchor
      a {
        text-decoration: underline;
        font-size: 13px;
        line-height: 24px;
      }
    }

    // Below Slot
    &__below {
      margin-top: 0.5rem;
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }

  // Right Slot
  &__right {
    display: flex;
    align-items: center;

    @include phone-down {
      width: 100%;

      button {
        width: 100%;
      }
    }
  }

  // Full Width
  &.is-full-width {
    display: flex;
  }

  // Without Icon
  &.is-without-icon {
    padding: 0.5rem;
  }

  // Without Description
  &.is-without-description {
    .adm-alert__text-wrapper__title {
      font-weight: 500;
    }
  }
}
</style>
