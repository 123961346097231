export const isPwa = () => {
  if (typeof window !== 'undefined') {
    return window.matchMedia('(display-mode: standalone)').matches ||
      (window.navigator.standalone && window.navigator.userAgent === 'Mozilla/5.0 (iPhone; CPU iPhone OS 15_0 like Mac OS X) AppleWebKit/605.1.15 (KHTML, like Gecko) Version/15.0 Mobile/15E148 Safari/604.1') ||
      document.referrer.includes('android-app://')
  }

  return false
}

export const isPwaOnAppleDevices = () => {
  if (typeof window !== 'undefined') {
    return (window.matchMedia('(display-mode: standalone)').matches || window.navigator.standalone) &&
      (['MacIntel', 'iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(window.navigator.platform) ||
        (navigator.platform === 'Mac' && navigator.maxTouchPoints > 1))
  }

  return false
}
