var render = function render(){var _vm=this,_c=_vm._self._c;return _c('button',{staticClass:"adm-button",class:[
    'adm-button__' + _vm.type + '__' + _vm.color,
    'adm-button__size__' + _vm.size,
    {
      'is-disabled': _vm.disabled,
      'is-loading': _vm.loading,
      'is-icon-start': _vm.iconStart && _vm.$slots.default && !_vm.iconOnly,
      'is-icon-end': _vm.iconEnd && _vm.$slots.default && !_vm.iconOnly,
      'is-icon-only': ((_vm.iconStart || _vm.iconEnd) && !_vm.$slots.default) || _vm.iconOnly
    }
  ],style:({ width: _vm.fullWidth ? '100%' : null}),attrs:{"disabled":_vm.disabled || _vm.loading,"autofocus":_vm.autofocus,"type":_vm.nativeType},on:{"click":_vm.handleClick,"mousedown":_vm.handleMouseDown}},[(_vm.loading)?_c('span',{staticClass:"adm-button-spinner-container"},[_c('svg',{staticClass:"adm-button-spinner",attrs:{"viewBox":"0 0 50 50","width":"50px","height":"50px"}},[_c('circle',{staticClass:"adm-button-spinner__ring",attrs:{"cx":"25","cy":"25","r":"22.5"}}),_c('circle',{staticClass:"adm-button-spinner__line",attrs:{"cx":"25","cy":"25","r":"22.5"}})])]):_vm._e(),(_vm.iconStart && !_vm.loading)?_c('span',{staticClass:"adm-button__icon-wrapper"},[_vm._t("icon-start",function(){return [_c('i',{class:'tz-' + _vm.iconStart,on:{"click":function($event){return _vm.$emit('icon-start-click')}}})]})],2):_vm._e(),(!_vm.iconOnly)?_c('span',[_vm._t("default")],2):_vm._e(),(_vm.iconEnd)?_c('i',{class:'tz-' + _vm.iconEnd,on:{"click":(e) => _vm.$emit('icon-end-click', e)}}):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }