<template>

  <!-- Design System Input -->
  <div class="adm-design-system-input">

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Input
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
    >

      <!-- Preview -->
      <template #preview>
        <adm-input
          v-model="basicUsage"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputCode({ 'v-model': 'input' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Disabled -->
    <design-system-element-block
      title="Disabled"
      code-description="Disable the Input with the disabled attribute."
    >

      <!-- Preview -->
      <template #preview>
        <adm-input
          v-model="disabled"
          disabled
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputCode({ 'v-model': 'input', disabled: true }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Disabled -->

    <!-- Sizes -->
    <design-system-element-block
      title="Sizes"
      code-description="Add size attribute to change the size of Input. In addition to the default size, there are three other options: medium, small and mini."
    >

      <!-- Preview -->
      <template #preview>
        <adm-input
          v-model="sizes.default"
          max-width="200px"
        />
        <adm-input
          v-model="sizes.medium"
          class="ml-16"
          size="medium"
          max-width="200px"
        />
        <adm-input
          v-model="sizes.small"
          class="ml-16"
          size="small"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputCode({ 'v-model': 'input' }) }}
        <br>
        {{ getInputCode({ 'v-model': 'input', 'size': 'medium' }) }}
        <br>
        {{ getInputCode({ 'v-model': 'input', 'size': 'small' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Sizes -->

    <!-- Input with icon -->
    <design-system-element-block
      title="Input with icon"
      description="Add an icon to indicate input type."
      code-description="To add icons in Input, you can simply use icon-start and icon-end attributes."
    >

      <!-- Preview -->
      <template #preview>
        <adm-input
          v-model="icon.start"
          icon-start="email"
          max-width="200px"
        />
        <adm-input
          v-model="icon.end"
          class="ml-16"
          icon-end="home"
          max-width="200px"
        />
        <adm-input
          v-model="icon.both"
          class="ml-16"
          icon-start="clock"
          icon-end="info-filled"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputCode({ 'v-model': 'input', 'icon-start': 'email' }) }}
        <br>
        {{ getInputCode({ 'v-model': 'input', 'icon-end': 'home' }) }}
        <br>
        {{ getInputCode({ 'v-model': 'input', 'icon-start': 'clock', 'icon-end': 'info-filled' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Input with icon -->

    <!-- Textarea -->
    <design-system-element-block
      title="Textarea"
      description="Resizable for entering multiple lines of text information. Add attribute type=&quot;textarea&quot; to change input into native textarea."
      code-description="Control the height by setting the rows prop."
    >

      <!-- Preview -->
      <template #preview>
        <adm-input
          v-model="textarea"
          class="mr-16"
          size="medium"
          type="textarea"
          :autosize="{ minRows: 2, maxRows: 6 }"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputCode({ 'v-model': 'textarea', 'type': 'textarea', 'size': 'medium', 'autosize': '{ minRows: 2, maxRows: 6 }' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Textarea -->

    <!-- Input Attributes -->
    <design-system-documentation-table :table-data="tableData" />
    <!-- /Input Attributes -->

    <!-- Input Methods -->
    <design-system-documentation-table :table-data="tableDataMethods" type="events" title="Methods" />
    <!-- /Input Methods -->

    <!-- Input Events -->
    <design-system-documentation-table :table-data="tableDataEvents" type="events" title="Events" />
    <!-- /Input Events -->

    <!-- Input Slots -->
    <design-system-documentation-table title="Slots" :table-data="tableDataSlots" type="slots" />
    <!-- /Input Slots -->

  </div>
  <!-- /Design System Input -->

</template>

<script>
import AdmInput from '@/views/_components/input/AdmInput'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'

export default {
  name: 'DesignSystemInput',

  components: {
    AdmInput,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: '',
      disabled: '',
      sizes: {
        default: '',
        medium: '',
        small: ''
      },
      icon: {
        start: '',
        end: '',
        both: ''
      },
      textarea: '',
      tableData: [
        {
          attribute: 'type',
          description: 'type of input',
          type: 'string',
          values: 'text / textarea',
          default: 'text',
        },
        {
          attribute: 'value / v-model',
          description: 'binding value',
          type: 'string / number',
          values: '',
          default: '',
        },
        {
          attribute: 'size',
          description: 'size of input',
          type: 'string',
          values: 'default / medium / small',
          default: 'default',
        },
        {
          attribute: 'disabled',
          description: 'whether input is disabled',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'readonly',
          description: 'same as readonly in native input',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'placeholder',
          description: 'the placeholder of autocomplete',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'maxlength',
          description: 'same as maxlength in native input',
          type: 'number',
          values: '-',
          default: '-',
        },
        {
          attribute: 'icon-start',
          description: 'prefix icon name',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'icon-end',
          description: 'suffix icon name',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'icon-handler',
          description: 'A handler for a suffix icon click event',
          type: 'function',
          values: '-',
          default: '-',
        },
        {
          attribute: 'rows',
          description: 'number of rows of textarea, only works when type is \'textarea\'',
          type: 'number',
          values: '-',
          default: '2',
        },
        {
          attribute: 'autosize',
          description: 'whether textarea has an adaptive height, only works when type is \'textarea\'. Can accept an object, e.g. { minRows: 2, maxRows: 6 }',
          type: 'boolean / object',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'name',
          description: 'same as name in native input',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'autofocus',
          description: 'same as autofocus in native input',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'tabindex',
          description: 'input tabindex',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'validate-event',
          description: 'whether to trigger form validation',
          type: 'boolean',
          values: '-',
          default: 'true',
        },
        {
          attribute: 'max-width',
          description: 'max width of the input, e.g. \'200px\'',
          type: 'string',
          values: '-',
          default: '-',
        },
      ],
      tableDataMethods: [
        {
          name: 'focus',
          description: 'focus the input element',
          parameters: '-'
        },
        {
          name: 'blur',
          description: 'blur the input element',
          parameters: '-'
        },
        {
          name: 'select',
          description: 'select the text in input element',
          parameters: '-'
        },
      ],
      tableDataEvents: [
        {
          name: 'blur',
          description: 'triggers when Input blurs',
          parameters: '(event: Event)'
        },
        {
          name: 'focus',
          description: 'triggers when Input focuses',
          parameters: '(event: Event)'
        },
        {
          name: 'change',
          description: 'triggers only when the input box loses focus or the user presses Enter',
          parameters: '(value: string | number)'
        },
        {
          name: 'input',
          description: 'triggers when the Input value change',
          parameters: '(value: string | number)'
        },
      ],
      tableDataSlots: [
        {
          name: 'prefix',
          description: 'prefix icon on input'
        },
        {
          name: 'suffix',
          description: 'suffix icon on input',
        },
      ]
    }
  },

  methods: {
    onBlur (e) {
      console.log('blur')
      console.log(e)
    },

    onFocus (e) {
      console.log('focus')
      console.log(e)
    },

    onChange (value) {
      console.log('change')
      console.log(value)
    },

    onInput (value) {
      console.log('input')
      console.log(value)
    },
  }
}
</script>

