export default [
  {
    path: '/login',
    name: 'login',
    meta: {
      title: 'login',
      guest: true
    },
    component: () => import(/* webpackChunkName: "login" */ '@/views/Authentication/Login/Login').then(value => value.default)
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    meta: {
      title: 'reset_password',
      guest: true
    },
    component: () => import(/* webpackChunkName: "reset-password" */ '@/views/Authentication/ResetPassword/ResetPassword').then(value => value.default)
  },
]
