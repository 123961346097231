<template>
  <div
    v-if="$store.getters['billing/getMaxEmployeesNumber'] !== 1"
    class="section select-employee"
  >

    <!-- Title -->
    <h6 class="mb-4">
      {{ $t('employees') }}
    </h6>
    <!-- /Title -->

    <!-- Select Employee Dropdown -->
    <template v-if="!showSkeletonLoader">
      <adm-select
        :value="appointmentProp.employee"
        :placeholder="$t('select_employee')"
        :clearable="!appointmentProp.id"
        :loading="loading"
        filterable
        value-key="id"
        @focus="getEmployees"
        @change="selectEmployee"
      >
        <adm-option
          v-for="employee in employees"
          :key="employee.id"
          :label="employee.firstName + ' ' + employee.lastName"
          :value="employee"
        >
          <adm-avatar
            :is-human="true"
            :margin-right="10"
            :name="`${ employee.firstName } ${ employee.lastName }`"
            :size="24"
            :src="employee.mediaUrl"
          />
          {{ employee.firstName + ' ' + employee.lastName }}
        </adm-option>
      </adm-select>
    </template>

    <adm-skeleton-element
      v-if="showSkeletonLoader"
      :height="36"
    />
    <!-- /Select Employee Dropdown -->

  </div>
</template>

<script>
import AdmOption from '@/views/_components/select/AdmOption'
import AdmSelect from '@/views/_components/select/AdmSelect'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import AdmAvatar from '@/views/_components/avatar/AdmAvatar'

export default {
  name: 'EmployeeSection',

  components: {
    AdmAvatar,
    AdmOption,
    AdmSelect,
    AdmSkeletonElement,
  },

  props: {
    appointmentProp: {
      type: Object,
      default: () => {},
      required: true
    },
  },

  data: function () {
    return {
      loading: false,
      showSkeletonLoader: false,
      employees: []
    }
  },

  computed: {
    isEditAppointment () {
      return this.$store.getters['manageAppointment/getManageDialogAction']() !== 'add'
    }
  },

  async created () {
    if (this.isEditAppointment ||
      this.$store.getters['billing/getMaxEmployeesNumber'] === 1 ||
      this.appointmentProp.employee
    ) {
      this.showSkeletonLoader = true
      await this.getEmployees()
      this.showSkeletonLoader = false
    }
  },

  methods: {
    getEmployees () {
      this.loading = true

      return this.$http.get(
        '/api/v1/appointments/entities/employees',
        { params: this.$store.state.manageAppointment.queryParams }
      ).then(response => {
        this.employees = response.data.payload

        if (this.appointmentProp.employee && !this.employees.find(employee => employee.id === this.appointmentProp.employee.id)) {
          this.employees.push(this.appointmentProp.employee)
        }

        if (this.$store.getters['billing/getMaxEmployeesNumber'] === 1 &&
          this.employees.length === 1
        ) {
          this.selectEmployee(this.employees[0])
        }
      }).finally(() => {
        this.loading = false
      })
    },

    selectEmployee (employee) {
      this.$set(this.appointmentProp, 'employee', employee)
    },

    removeService () {
      this.$set(this.appointmentProp, 'employee', null)
    }
  }
}
</script>
