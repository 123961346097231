<template>

  <!-- Form Item -->
  <div
    class="adm-form-item"
    :class="[
      {
        'is-with-message': description || isError,
        'is-error': isError,
      }
    ]"
  >
    <el-form-item
      ref="elFormItem"
      :prop="prop"
      :error="error"
    >
      <slot />
      <template v-if="label" #label>
        <slot name="label">
          {{ label }}
        </slot>
        <div v-if="isTranslatable" class="adm-form-item__translate" @click="$emit('translate')">
          <adm-icon size="small" icon="globe-outline" />
          {{ $t('translate') }}
        </div>
      </template>
    </el-form-item>
    <div v-if="(description || $slots.description) && !isError" class="adm-form-item__description">
      {{ description }}
      <slot name="description" />
    </div>
    <div v-if="isError" class="adm-form-item__error">
      {{ errorMessage }}
    </div>
  </div>
  <!-- /Form Item -->

</template>

<script>

import AdmIcon from '@/views/_components/icon/AdmIcon.vue'

export default {
  name: 'AdmFormItem',
  components: { AdmIcon },

  props: {
    prop: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    isTranslatable: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      isMounted: false
    }
  },

  computed: {
    isError () {
      if (!this.isMounted) {
        return false
      }

      return this.$refs.elFormItem.validateState === 'error'
    },

    errorMessage () {
      if (!this.isMounted && this.$refs.elFormItem.validateState !== 'error' && !this.$refs.elFormItem.validateMessage) {
        return ''
      }

      return this.$refs.elFormItem.validateMessage
    }
  },

  mounted () {
    this.isMounted = true
  }
}
</script>

<style lang="scss">
// Form Item
.adm-form-item {
  margin-bottom: 1.5rem;

  // Element Form Item
  .el-form-item {
    margin-bottom: 0;
    position: relative;
  }

  // Label
  .el-form-item__label {
    text-align: left;
    display: inline-flex;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    padding-bottom: 0.25rem;
    color: $shade-900;
    float: none;
    vertical-align: top;
  }

  // Required
  .is-required:not(.is-no-asterisk) {
    .el-form-item__label {
      &::before {
        content: '';
        margin-right: 0;
        vertical-align: unset;
      }

      &::after {
        content: '*';
        color: $red-900;
        margin-left: 1px;
        vertical-align: top;
      }
    }
  }

  &__translate {
    user-select: none;
    display: flex;
    align-items: center;
    position: absolute;
    right: 0;
    color: var(--primary-900);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    cursor: pointer;
  }

  // With Message
  &.is-with-message {

    // Element Form Item
    .el-form-item {
      margin-bottom: 0.25rem;
    }
  }

  // Description
  &__description {
    color: $shade-700;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    word-break: break-word;

    a {
      font-size: 13px;
    }
  }

  // Error
  &__error {
    color: $red-1000;
    font-weight: normal;
    font-size: 13px;
    line-height: 20px;
    word-break: break-word;
  }

  // Error
  .el-form-item.is-error {

    // Input
    .el-input__inner, .adm-currency-input {
      border: 1px solid $red-900;
    }

    .adm-select-wrapper .adm-select:hover:not(:focus) .el-input:not(.is-disabled) .el-input__inner:not(.is-focus):not(:focus) {
      border: 1px solid $red-900;
    }

    // Message
    .el-form-item__error {
      display: none;
    }

    // Date Picker Clear Icon
    .adm-date-picker-wrapper {
      .el-input__validateIcon {
        display: none;
      }
    }
  }
}
</style>
