export function copy (text) {
  const input = document.createElement('input')

  input.setAttribute('value', text)
  document.body.appendChild(input)

  input.select()
  input.setSelectionRange(0, 99999)

  navigator.clipboard.writeText(input.value);

  document.body.removeChild(input)
}

export function copyElementToClipboard(element) {
  window.getSelection().removeAllRanges();
  let range = document.createRange();
  range.selectNode(typeof element === 'string' ? document.getElementById(element) : element);
  window.getSelection().addRange(range);
  document.execCommand('copy');

  window.getSelection().removeAllRanges();
}
