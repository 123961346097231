var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"skeleton-element",style:({
    height: _vm.height ? _vm.height + 'px' : null,
    width: _vm.width ? _vm.width + 'px' : null,
    margin: _vm.margin ? _vm.margin + 'px' : null,
    marginTop: _vm.marginTop ? _vm.marginTop + 'px' : null,
    marginRight: _vm.marginRight ? _vm.marginRight + 'px' : null,
    marginBottom: _vm.marginBottom ? _vm.marginBottom + 'px' : null,
    marginLeft: _vm.marginLeft ? _vm.marginLeft + 'px' : null,
    borderRadius: _vm.borderRadius + 'px'
  })})
}
var staticRenderFns = []

export { render, staticRenderFns }