<template>

  <!-- Design System Menu -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Menu
    </h2>
    <!-- /Title -->

    <!-- Top Bar -->
    <design-system-element-block
      title="Top bar"
      code-description="By default Menu is vertical, but you can change it to horizontal by setting the mode prop to 'horizontal'."
    >

      <!-- Preview -->
      <template #preview>
        <adm-menu default-active="1" mode="horizontal">
          <adm-menu-item index="1">
            Item 1
          </adm-menu-item>
          <adm-menu-item index="2">
            Item 2
          </adm-menu-item>
          <adm-menu-item index="3">
            Item 3
          </adm-menu-item>
        </adm-menu>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Top Bar -->

    <!-- Side bar -->
    <design-system-element-block
      title="Side bar"
      code-description="By default Menu is vertical, but you can change it to horizontal by setting the mode prop to 'horizontal'."
    >

      <!-- Preview -->
      <template #preview>
        <adm-menu default-active="1">
          <adm-menu-item index="1">
            Item 1
          </adm-menu-item>
          <adm-menu-item index="2">
            Item 2
          </adm-menu-item>
          <adm-menu-item index="3">
            Item 3
          </adm-menu-item>
        </adm-menu>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Side bar -->

    <!-- Menu Attributes -->
    <design-system-documentation-table :table-data="tableDataAttributes" />
    <!-- /Menu Attributes -->

  </div>
  <!-- /Design System Menu -->

</template>

<script>
import AdmMenu from '@/views/_components/menu/AdmMenu'
import AdmMenuItem from '@/views/_components/menu/AdmMenuItem'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'

export default {
  name: 'DesignSystemMenu',

  components: {
    AdmMenuItem,
    AdmMenu,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: 0,
      tableDataAttributes: [],
      tableDataSlots: [],
      tableDataEvents: [
        {
          name: 'open',
          description: 'triggers when the Menu opens',
          parameters: '-'
        },
        {
          name: 'opened',
          description: 'triggers when the Menu opening animation ends',
          parameters: '-'
        },
        {
          name: 'close',
          description: 'triggers when the Menu closes',
          parameters: '-'
        },
        {
          name: 'closed',
          description: 'triggers when the Menu closing animation ends',
          parameters: '-'
        }
      ],
      firstMenuVisible: false
    }
  },
}
</script>
