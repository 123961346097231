<template>
  <div v-if="!noLocations" class="section select-location">

    <!-- Title -->
    <h6 class="mb-4">
      {{ $t('location') }}
    </h6>
    <!-- /Title -->

    <!-- Select Location Dropdown -->
    <template v-if="!showSkeletonLoader">
      <adm-select
        :value="appointmentProp.location"
        :placeholder="$t('select_location')"
        :loading="loading"
        clearable
        value-key="id"
        icon-start="locations"
        @change="selectLocation"
        @focus="getLocations"
        @clear="removeLocation"
      >
        <adm-option
          v-for="location in locations"
          :key="location.id"
          :label="location.name"
          :value="location"
        />
      </adm-select>
    </template>
    <adm-skeleton-element
      v-if="showSkeletonLoader"
      :height="36"
    />
    <!-- /Select Location Dropdown -->

  </div>
</template>

<script>
import AdmOption from '@/views/_components/select/AdmOption'
import AdmSelect from '@/views/_components/select/AdmSelect'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'

export default {
  name: 'LocationSection',

  components: {
    AdmOption,
    AdmSelect,
    AdmSkeletonElement,
  },

  props: {
    appointmentProp: {
      type: Object,
      default: () => {},
      required: true
    },
  },

  data: function () {
    return {
      loading: false,
      showSkeletonLoader: false,
      locations: [],
      noLocations: false,
    }
  },

  async created () {
    this.showSkeletonLoader = true
    await this.getLocations()
    this.showSkeletonLoader = false

    if (this.locations.length === 0) {
      this.noLocations = true
      this.locations.push({})
      this.$emit('empty-location')
    }
  },

  methods: {
    getLocations () {
      this.loading = true

      return this.$http.get(
        '/api/v1/appointments/entities/locations',
        { params: this.$store.state.manageAppointment.queryParams }
      ).then(response => {
        this.locations = response.data.payload

        if (this.appointmentProp.location && !this.locations.find(location => location.id === this.appointmentProp.location.id)) {
          this.locations.push(this.appointmentProp.location)
        }
      }).finally(() => {
        this.loading = false
      })
    },

    selectLocation (location) {
      this.$set(this.appointmentProp, 'location', location)
    },

    removeLocation () {
      this.$set(this.appointmentProp, 'location', null)
    }
  }
}
</script>

