var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"survey"},[(_vm.showSurveyButton)?_c('adm-button',{staticClass:"survey__button",on:{"click":function($event){!_vm.isSurveyVisible ? _vm.openAppSurvey() : _vm.closeAppSurvey()}}},[_c('transition',{attrs:{"name":"bounce","mode":"out-in"}},[(!_vm.isSurveyVisible)?_c('svg',{key:'1',attrs:{"width":"20","height":"26","viewBox":"0 0 20 26","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M6.143 3.572H3.57A2.571 2.571 0 001 6.144v15.428a2.571 2.571 0 002.571 2.572H16.43A2.57 2.57 0 0019 21.572V6.144a2.571 2.571 0 00-2.571-2.572h-2.572","stroke":"#fff","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M11.286 1H8.714a2.571 2.571 0 000 5.143h2.572a2.571 2.571 0 000-5.143zM6.143 12.572h.013M11.286 12.572h2.571M6.143 17.715h.013M11.286 17.715h2.571","stroke":"#fff","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})]):_c('svg',{key:'2',class:'svg-close',attrs:{"width":"12","height":"12","viewBox":"0 0 12 12","fill":"none","xmlns":"http://www.w3.org/2000/svg","svg-inline":'',"role":'presentation',"focusable":'false',"tabindex":'-1'}},[_c('path',{attrs:{"d":"M7.175 6l3.583-3.575A.837.837 0 009.575 1.24L6 4.824 2.425 1.241a.837.837 0 00-1.183 1.183L4.825 6 1.242 9.575a.833.833 0 000 1.183.833.833 0 001.183 0L6 7.175l3.575 3.583a.833.833 0 001.183 0 .833.833 0 000-1.183L7.175 6z","fill":"#fff"}})])])],1):_vm._e(),_c('transition',{attrs:{"name":"bounce"}},[(_vm.isSurveyVisible)?_c('div',{staticClass:"app-survey"},[_c('survey-header'),_c('el-progress',{class:{'done-progress': _vm.calculateProgress === 100,
                 'app-survey__content__progress': _vm.calculateProgress !== 100},attrs:{"stroke-width":3,"show-text":false,"percentage":_vm.calculateProgress}}),_c('div',{staticClass:"app-survey__content"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getCurrentStep === 0),expression:"getCurrentStep === 0"}],staticClass:"app-survey__content__image"},[_c('img',{attrs:{"alt":"survey beacon home","src":require("@/assets/img/survey-home.png")}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.getCurrentStep === _vm.steps.length-1),expression:"getCurrentStep === steps.length-1"}],staticClass:"app-survey__content__image"},[_c('img',{attrs:{"alt":"survey beacon home","src":require("@/assets/img/survey-end.png")}})]),_vm._l((_vm.steps),function(step,stepIndex){return _c('div',{key:stepIndex},[_c('p',{class:{'active': stepIndex === _vm.getCurrentStep,
                      'app-survey__content__text': true,
                      'is-question': _vm.getCurrentStep !== 0,
                      'text-center': _vm.getCurrentStep === _vm.steps.length-1}},[_vm._v(" "+_vm._s(step.question)+" ")]),(step.type === 'checkbox')?_c('div',{class:{'active': stepIndex === _vm.getCurrentStep,
                     'app-survey__content__answers': true}},[_c('adm-checkbox-group',{model:{value:(_vm.answers[step.key]),callback:function ($$v) {_vm.$set(_vm.answers, step.key, $$v)},expression:"answers[step.key]"}},_vm._l((step.options),function(option,optionIndex){return _c('adm-checkbox',{key:optionIndex,attrs:{"label":option.option}},[_vm._v(" "+_vm._s(option.option)+" "),(_vm.answers[step.key] && _vm.answers[step.key].includes(option.option) && option.showOther)?_c('adm-input',{attrs:{"type":"textarea","placeholder":"Please enter"},model:{value:(_vm.answersOther[step.key][option.option]),callback:function ($$v) {_vm.$set(_vm.answersOther[step.key], option.option, $$v)},expression:"answersOther[step.key][option.option]"}}):_vm._e()],1)}),1)],1):_vm._e(),(step.type === 'textarea')?_c('div',{class:{'active': stepIndex === _vm.getCurrentStep,
                     'app-survey__content__textarea': true}},[_c('adm-input',{attrs:{"type":"textarea","placeholder":"Answer here (optional)","rows":4},model:{value:(_vm.answers[step.key]),callback:function ($$v) {_vm.$set(_vm.answers, step.key, $$v)},expression:"answers[step.key]"}})],1):_vm._e(),(step.description)?_c('div',{class:{'active': stepIndex === _vm.getCurrentStep,
                     'app-survey__content__description': true}},[_c('p',[_vm._v(" "+_vm._s(step.description)+" ")])]):_vm._e()])})],2),_c('survey-footer',{attrs:{"steps":_vm.steps,"is-selected-some-answer":_vm.isSelectedSomeAnswer},on:{"nextStep":_vm.nextStep,"hide":_vm.hideBeacon}})],1):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }