<template>
  <div class="adm-square-uploader">

    <!-- Uploader & Preview -->
    <div
      class="adm-square-uploader__image"
      :style="{ 'height': height ? `${ height }px` : undefined, 'width': width ? `${ width }px` : undefined }"
    >
      <el-upload
        ref="adm-square-uploader"
        drag
        action=""
        :multiple="false"
        :auto-upload="false"
        list-type="picture"
        accept="image/gif, image/jpeg, image/png, image/webp, image/svg+xml"
        :show-file-list="false"
        :on-change="onImageChange"
      >

        <!-- Uploader -->
        <div v-if="!mediaUrl && !defaultImage" class="el-upload-dragger__upload">

          <div class="flex-center flex-column">
            <!-- Upload Icon -->
            <adm-icon v-if="!$slots.icon" size="extraLarge" icon="cloud-upload-outline" :color="$colors['shade-500']" />
            <slot v-else name="icon" />
            <!-- /Upload Icon -->

            <!-- Upload Instructions -->
            <div class="el-upload__text" v-html="getUploadInstruction()" />
            <!-- Upload Instructions -->
          </div>

        </div>
        <!-- /Uploader -->

        <div v-if="!mediaUrl && defaultImage" class="uploaded-photo-preview">
          <img :src="defaultImage" alt="">
        </div>

        <!-- Preview -->
        <div v-if="mediaUrl" class="uploaded-photo-preview">
          <img :src="mediaUrl" alt="">
        </div>
        <!-- /Preview -->

      </el-upload>
    </div>
    <!-- /Uploader & Preview -->

    <div v-if="mediaUrl || defaultImage" class="adm-square-uploader__actions">
      <!-- Change Image Action -->
      <div>
        <adm-button
          size="mini"
          type="ghost"
          color="dark"
          @click="openFileDialog"
        >
          {{ $t('change') }}
        </adm-button>
      </div>
      <!-- /Change Image Action -->

      <!-- Delete Image Action -->
      <div v-if="!defaultImage">
        <adm-button
          class="ml-8"
          size="mini"
          color="red"
          type="ghost"
          icon-start="trash"
          @click="onImageDelete"
        >
          {{ $t('delete') }}
        </adm-button>
      </div>
      <!-- /Delete Image Action -->
    </div>

  </div>
</template>

<script>
import 'cropperjs/dist/cropper.css'
import AdmButton from '@/views/_components/button/AdmButton'
import AdmIcon from '@/views/_components/icon/AdmIcon'

export default {
  name: 'AdmSquareUploader',

  components: {
    AdmIcon,
    AdmButton,
  },

  props: {
    value: {
      type: [String, Object],
      default: null,
    },
    height: {
      type: Number,
      default: null
    },
    width: {
      type: Number,
      default: null
    },
    isPreviewEnabled: {
      type: Boolean,
      default: true
    },
    defaultImage: {
      type: [String, Object],
      default: null,
    }
  },

  data () {
    return {
      mediaUrl: this.value,
    }
  },

  methods: {
    onImageChange (file) {
      if (this.isPreviewEnabled) {
        this.mediaUrl = file.url
      }
      this.$emit('input', file)
    },

    onImageDelete () {
      this.mediaUrl = ''
      this.$emit('input', null)
      this.$emit('delete')
    },

    getUploadInstruction () {
      return this.instructions ? this.instructions : this.$t('drop_zone')
    },

    openFileDialog () {
      const input = this.$refs['adm-square-uploader'].$refs['upload-inner'].$refs.input

      if (input) {
        input.click()
      }
    }
  }
}
</script>

<style lang="scss">
.adm-square-uploader {
  &__image {
    border: 1px dashed $shade-500;
    border-radius: 8px;
    padding: 8px;
    height: 132px;
    max-width: 100%;

    &:hover {
      border-color: var(--primary-900);

      .tz-cloud-upload-outline {
        &::before {
          color: var(--primary-900);
        }
      }
    }

    & > div {
      height: 100%;
    }

    .el-upload {
      display: flex;
      height: 100%;

      &__text {
        line-height: 20px;
        margin-top: 8px;
        padding: 0 10px;

        em {
          font-weight: 500;
        }
      }

      .el-upload-dragger {
        border-radius: 5px;
        border: none;
        background-color: var(--primary-300);
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .el-upload__text {
          em {
            color: var(--primary-900);
          }
        }
      }
    }

    .uploaded-photo-preview {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
  }

  &__actions {
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
  }
}
</style>
