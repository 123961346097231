<template>

  <!-- Price -->
  <div v-if="!disabled" class="total-price">

    <!-- Price Block Title -->
    <div class="price-title-block">
      <span class="price-title-block__text">{{ $t('pricing') }}</span>
      <a v-if="totalPrice > 0 || totalDiscount > 0" @click="showPriceDetails">
        {{ $t('view_detailed_pricing') }}
      </a>
    </div>
    <!-- /Price Block Title -->

    <!-- Service Price -->
    <div class="price-block">
      <span>{{ $t('service_price') }}</span>
      <span class="price-amount">{{ formatPrice(totalServicePrice) }}</span>
    </div>
    <!-- /Service Price -->

    <!-- Extras Price -->
    <div v-if="$store.getters['features/isFeatureEnabled']('extras')" class="price-block">
      <span>{{ $t('extras') }}</span>
      <span class="price-amount">{{ formatPrice(totalExtrasPrice) }}</span>
    </div>
    <!-- /Extras Price -->

    <!-- Taxes Price -->
    <div v-if="totalTaxesPrice > 0" class="price-block">
      <span>{{ $t('taxes') }}</span>
      <span class="price-amount">{{ formatPrice(totalTaxesPrice) }}</span>
    </div>
    <!-- /Taxes Price -->

    <!-- Subtotal Value -->
    <div v-if="$store.getters['features/isFeatureEnabled']('coupons') && totalDiscount > 0" class="price-block">
      <span>{{ $t('subtotal') }}</span>
      <span class="price-amount">{{ formatPrice(totalPrice + totalDiscount) }}</span>
    </div>
    <!-- /Subtotal Price -->

    <!-- Discount Value -->
    <div v-if="$store.getters['features/isFeatureEnabled']('coupons')" class="price-block">
      <span>{{ $t('coupon') }}</span>
      <span class="price-amount">{{ formatPrice(totalDiscount) }}</span>
    </div>
    <!-- /Discount Price -->

    <!-- Deposit -->
    <div v-if="totalDeposit > 0" class="price-block">
      <span>{{ $t('deposit_amount') }}</span>
      <span class="price-amount">{{ formatPrice(totalDeposit) }}</span>
    </div>
    <!-- /Deposit -->

    <!-- Total -->
    <div class="price-block price-block-last">
      <span>{{ $t('total') }}</span>
      <span class="price-amount">{{ formatPrice(totalPrice) }}</span>
    </div>
    <!-- /Total -->

  </div>
  <!-- /Price -->

</template>

<script>
import mixinPrice from '@/mixins/common/price'
import mixinTax from '@/mixins/common/tax'
import AppointmentUtils from '@/utils/appointment'

export default {
  name: 'Price',

  mixins: [
    mixinPrice,
    mixinTax,
  ],

  props: {
    appointment: {
      type: Object,
      default: () => ({}),
      required: true
    },
    disabled: {
      type: Boolean,
      default: false,
      required: true
    },
    servicePrice: {
      type: Number,
      default: 0,
      required: true
    },
    showPriceDetails: {
      type: Function,
      default: () => {}
    },
    service: {
      type: Object,
      default: () => ({})
    }
  },

  data () {
    return {
      loadingButton: false
    }
  },

  computed: {
    totalPrice () {
      let totalPrice = AppointmentUtils.calculateAppointmentPrice(this.appointment)
      if (this.appointment.id) {
        return totalPrice > 0 ? totalPrice : 0
      }

      if (this.$store.state.settings.payments.taxDisplaySettings === 'exclusive') {
        for (let i = 0; i < this.appointment.bookings.length; i++) {
          let booking = this.appointment.bookings[i]
          let taxPriceForBooking = this.getTaxPriceForBooking(booking)
          let bookingPriceWithTax = AppointmentUtils.getPriceForBookingWithDiscount(booking) + taxPriceForBooking
          if (bookingPriceWithTax < 0) {
            continue
          }
          if (booking.recurringData && booking.recurringData.bookings.length > 1) {
            taxPriceForBooking *= booking.recurringData.bookings.length
          }
          totalPrice += taxPriceForBooking
        }
      }

      return totalPrice > 0 ? totalPrice : 0
    },

    totalServicePrice () {
      let price = 0

      for (let i = 0; i < this.appointment.bookings.length; i++) {
        let bookingPrice = 0
        const booking = this.appointment.bookings[i]

        bookingPrice += booking.price * (booking.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1)

        if (booking.recurringData && booking.recurringData.bookings.length > 1) {
          bookingPrice *= booking.recurringData.bookings.length
        }

        price += bookingPrice
      }

      return price
    },

    totalExtrasPrice () {
      let price = 0
      for (let i = 0; i < this.appointment.bookings.length; i++) {
        let extrasPrice = 0
        const booking = this.appointment.bookings[i]

        for (let j = 0; j < booking.extras.length; j++) {
          extrasPrice += booking.extras[j].price * booking.extras[j].quantity *
            (booking.extras[j].multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1)
        }

        if (booking.recurringData && booking.recurringData.bookings.length > 1) {
          extrasPrice *= booking.recurringData.bookings.length
        }

        price += extrasPrice
      }

      return price
    },

    totalDiscount () {
      let discount = 0

      for (let i = 0; i < this.appointment.bookings.length; i++) {
        if (this.appointment.bookings[i].discount !== undefined) {
          discount += AppointmentUtils.getDiscountForBooking(this.appointment.bookings[i], this.appointment.service)
        }
      }

      return discount
    },

    totalTaxesPrice () {
      let price = 0

      for (let i = 0; i < this.appointment.bookings.length; i++) {
        let taxPriceForBooking = 0
        const booking = this.appointment.bookings[i]

        taxPriceForBooking = this.getTaxPriceForBooking(booking)

        if (booking.recurringData && booking.recurringData.bookings.length > 1) {
          taxPriceForBooking *= booking.recurringData.bookings.length
        }

        price += taxPriceForBooking
      }

      return price
    },

    totalDeposit () {
      let deposit = 0

      for (let i = 0; i < this.appointment.bookings.length; i++) {
        if (this.appointment.bookings[i].deposit !== undefined) {
          deposit += this.appointment.bookings[i].deposit
        }
      }

      return deposit
    }
  },

  methods: {
    getTaxPriceForBooking (booking) {
      let price = 0
      if (this.appointment.id) {
        if (booking.bookingId) {
          return this.calculateBookingTotalTax(booking)
        }

        return this.calculateTax(booking.price * (booking.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1), this.service.taxRate)
      }

      if (this.service.taxRate) {
        price += this.calculateTax(booking.price * (booking.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1), this.service.taxRate)
      }

      for (let j = 0; j < booking.extras.length; j++) {
        const extra = booking.extras[j]
        if (extra.taxRate) {
          price += this.calculateTax(
            extra.price * extra.quantity * (extra.multiplyPriceWithNumberOfPeople ? (1 + booking.additionalPersons) : 1),
            extra.taxRate
          )
        }
      }

      return price
    },
  }
}
</script>

<style lang="scss" scoped>
.total-price {
  @include phone-down() {
    padding-top: 16px;
    border-top: 1px solid $shade-300;
  }

  .price-title-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;

    &__text {
      font-weight: 600;
    }
  }

  .price-block {
    border-top: 1px solid $shade-300;
    height: 40px;
    display: flex;
    align-items: center;

    &:last-of-type {
      border-bottom: 1px solid $shade-300;
    }

    span {
      font-weight: 500;
    }

    .price-amount {
      position: absolute;
      right: 0;
    }
  }
}
</style>
