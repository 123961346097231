<template>
  <div class="app-survey__header">
    <span class="title"> {{ $t('quick_survey') }} </span>
    <adm-button icon-start="minus" type="borderless" size="mini" color="light" @click="closeAppSurvey" />
  </div>
</template>
<script>
import AdmButton from '@/views/_components/button/AdmButton'

export default {
  name: 'SurveyHeader',
  components: { AdmButton },
  methods: {
    closeAppSurvey () {
      this.$http.post('/api/v1/survey/minimized-survey', {})
      this.$store.commit('survey/setSurveyOpenOrClosed', false)
    },
  },
}
</script>

<style lang="scss" scoped>
.app-survey__header {
  width: 100%;
  height: 60px;
  background: #00143C;
  border-radius: 8px 8px 0 0;
  color: #FFFFFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 20px;

  .title {
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
  }
}
</style>
