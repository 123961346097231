import DesignSystemAlert from '@/views/DesignSystem/Alert/DesignSystemAlert'
import DesignSystemBadge from '@/views/DesignSystem/Badge/DesignSystemBadge'
import DesignSystemButton from '@/views/DesignSystem/Button/DesignSystemButton'
import DesignSystemCheckbox from '@/views/DesignSystem/Checkbox/DesignSystemCheckbox'
import DesignSystemColor from '@/views/DesignSystem/Color/DesignSystemColor'
import DesignSystemDatePicker from '@/views/DesignSystem/DatePicker/DesignSystemDatePicker'
import DesignSystemDropdown from '@/views/DesignSystem/Dropdown/DesignSystemDropdown'
import DesignSystemIcon from '@/views/DesignSystem/Icon/DesignSystemIcon'
import DesignSystemInput from '@/views/DesignSystem/Input/DesignSystemInput'
import DesignSystemInputCurrency from '@/views/DesignSystem/InputCurrency/DesignSystemInputCurrency'
import DesignSystemInputNumber from '@/views/DesignSystem/InputNumber/DesignSystemInputNumber'
import DesignSystemMenu from '@/views/DesignSystem/Menu/DesignSystemMenu'
import DesignSystemMessage from '@/views/DesignSystem/Message/DesignSystemMessage'
import DesignSystemModal from '@/views/DesignSystem/Modal/DesignSystemModal'
import DesignSystemPagination from '@/views/DesignSystem/Pagination/DesignSystemPagination'
import DesignSystemRadio from '@/views/DesignSystem/Radio/DesignSystemRadio'
import DesignSystemSelect from '@/views/DesignSystem/Select/DesignSystemSelect'
import DesignSystemSwitch from '@/views/DesignSystem/Switch/DesignSystemSwitch'
import DesignSystemTag from '@/views/DesignSystem/Tag/DesignSystemTag'
import DesignSystemTimeSelect from '@/views/DesignSystem/TimeSelect/DesignSystemTimeSelect'
import DesignSystemTooltip from '@/views/DesignSystem/Tooltip/DesignSystemTooltip'

export default [
  {
    path: '/design-system',
    name: 'design-system',
    redirect: '/design-system/alert',
    meta: {
      title: 'Design System',
      requiresAuth: true
    },
    component: () => import(/* webpackChunkName: "design-system" */ '@/views/DesignSystem/DesignSystemPage').then(value => value.default),
    children: [
      {
        path: '/design-system/alert',
        name: 'design-system-alert',
        meta: {
          title: 'Alert',
        },
        component: DesignSystemAlert
      },
      {
        path: '/design-system/button',
        name: 'design-system-button',
        meta: {
          title: 'Button',
        },
        component: DesignSystemButton
      },
      {
        path: '/design-system/badge',
        name: 'design-system-badge',
        meta: {
          title: 'Badge',
        },
        component: DesignSystemBadge
      },
      {
        path: '/design-system/checkbox',
        name: 'design-system-checkbox',
        meta: {
          title: 'Checkbox',
        },
        component: DesignSystemCheckbox
      },
      {
        path: '/design-system/color',
        name: 'design-system-color',
        meta: {
          title: 'Color',
        },
        component: DesignSystemColor
      },
      {
        path: '/design-system/date-picker',
        name: 'design-system-date-picker',
        meta: {
          title: 'Dropdown',
        },
        component: DesignSystemDatePicker
      },
      {
        path: '/design-system/dropdown',
        name: 'design-system-dropdown',
        meta: {
          title: 'Dropdown',
        },
        component: DesignSystemDropdown
      },
      {
        path: '/design-system/icon',
        name: 'design-system-icon',
        meta: {
          title: 'Icon',
        },
        component: DesignSystemIcon
      },
      {
        path: '/design-system/input',
        name: 'design-system-input',
        meta: {
          title: 'Input',
        },
        component: DesignSystemInput
      },
      {
        path: '/design-system/input-currency',
        name: 'design-system-input-currency',
        meta: {
          title: 'Input Currency',
        },
        component: DesignSystemInputCurrency
      },
      {
        path: '/design-system/input-number',
        name: 'design-system-input-number',
        meta: {
          title: 'Input Number',
        },
        component: DesignSystemInputNumber
      },
      {
        path: '/design-system/menu',
        name: 'design-system-menu',
        meta: {
          title: 'Menu',
        },
        component: DesignSystemMenu
      },
      {
        path: '/design-system/message',
        name: 'design-system-message',
        meta: {
          title: 'Message',
        },
        component: DesignSystemMessage
      },
      {
        path: '/design-system/modal',
        name: 'design-system-modal',
        meta: {
          title: 'Modal',
        },
        component: DesignSystemModal
      },
      {
        path: '/design-system/pagination',
        name: 'design-system-pagination',
        meta: {
          title: 'Pagination',
        },
        component: DesignSystemPagination
      },
      {
        path: '/design-system/radio',
        name: 'design-system-radio',
        meta: {
          title: 'Radio',
        },
        component: DesignSystemRadio
      },
      {
        path: '/design-system/select',
        name: 'design-system-select',
        meta: {
          title: 'Select',
        },
        component: DesignSystemSelect
      },
      {
        path: '/design-system/switch',
        name: 'design-system-switch',
        meta: {
          title: 'Switch',
        },
        component: DesignSystemSwitch
      },
      {
        path: '/design-system/tag',
        name: 'design-system-tag',
        meta: {
          title: 'Tag',
        },
        component: DesignSystemTag
      },
      {
        path: '/design-system/time-select',
        name: 'design-system-time-select',
        meta: {
          title: 'Time Select',
        },
        component: DesignSystemTimeSelect
      },
      {
        path: '/design-system/tooltip',
        name: 'design-system-tooltip',
        meta: {
          title: 'Tooltip',
        },
        component: DesignSystemTooltip
      },
    ]
  }
]
