<template>

  <!-- Design System Documentation Table -->
  <div class="adm-design-system-documentation-table">

    <!-- Title -->
    <h3 class="adm-design-system-documentation-table__title">
      {{ title }}
    </h3>
    <!-- /Title -->

    <!-- Default Slot -->
    <div class="mb-16">
      <slot />
    </div>
    <!-- /Default Slot -->

    <!-- Table -->
    <el-table :data="tableData" style="width: 100%">
      <template v-if="type === 'attributes'">
        <el-table-column prop="attribute" width="180" label="Attribute" />
        <el-table-column prop="description" label="Description" />
        <el-table-column prop="type" width="180" label="Type" />
        <el-table-column prop="values" label="Accepted values" />
        <el-table-column prop="default" width="120" label="Default" />
      </template>
      <template v-if="type === 'slots'">
        <el-table-column prop="name" width="180" label="Name" />
        <el-table-column prop="description" label="Description" />
      </template>
      <template v-if="type === 'events'">
        <el-table-column prop="name" width="180" label="Event Name" />
        <el-table-column prop="description" label="Description" />
        <el-table-column prop="parameters" label="Parameters" />
      </template>
      <template v-if="type === 'formats'">
        <el-table-column prop="format" width="200" label="Format" />
        <el-table-column prop="meaning" label="Meaning" />
        <el-table-column prop="note" label="Note" />
        <el-table-column prop="example" width="200" label="Example" />
      </template>
    </el-table>
    <!-- /Table -->

  </div>
  <!-- /Design System Documentation Table -->

</template>

<script>
export default {
  name: 'DesignSystemDocumentationTable',

  props: {
    title: {
      type: String,
      default: 'Attributes'
    },
    type: {
      type: String,
      default: 'attributes'
    },
    tableData: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss">
// Design System Attributes Table
.adm-design-system-documentation-table {
  margin-bottom: 2rem;

  // Title
  &__title {
    margin-bottom: 1rem;
  }

  // Cell
  .cell {
    word-break: break-word;
  }
}
</style>
