<template>

  <!-- Design System Badge -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Badge
    </h2>
    <!-- /Title -->

    <!-- Badge usage -->
    <design-system-element-block
      title="Badge usage"
      code-description="Badge provides 6 types of themes defined by type (default, primary, positive, danger, success, danger-secondary)."
      display="block"
    >

      <!-- Preview -->
      <template #preview>
        <div class="mb-16">
          <adm-badge class="mr-16">
            4
          </adm-badge>
          <adm-badge class="mr-16" type="primary">
            21
          </adm-badge>
          <adm-badge class="mr-16" type="positive">
            21
          </adm-badge>
          <adm-badge class="mr-16" type="danger">
            21
          </adm-badge>
          <adm-badge class="mr-16" type="success">
            +4
          </adm-badge>
          <adm-badge class="mr-16" type="danger-secondary">
            -12
          </adm-badge>
        </div>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getBadgeCode({ }, '4') }}
        <br>
        {{ getBadgeCode({ type: 'primary' }, '21') }}
        <br>
        {{ getBadgeCode({ type: 'positive' }, '21') }}
        <br>
        {{ getBadgeCode({ type: 'danger' }, '21') }}
        <br>
        {{ getBadgeCode({ type: 'success' }, '+4') }}
        <br>
        {{ getBadgeCode({ type: 'danger-secondary' }, '-12') }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Badge usage -->


    <!-- Badge Attributes -->
    <design-system-documentation-table :table-data="tableDataAttributes" />
    <!-- /Badge Attributes -->

    <!-- Badge Slots -->
    <design-system-documentation-table title="Slots" :table-data="tableDataSlots" type="slots" />
    <!-- /Badge Slots -->

  </div>
  <!-- /Design System Badge -->

</template>

<script>
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'
import AdmBadge from '@/views/_components/badge/AdmBadge'

export default {
  name: 'DesignSystemBadge',

  components: {
    AdmBadge,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      tableDataAttributes: [
        {
          attribute: 'type',
          description: 'Badge type',
          type: 'string',
          values: 'default / primary / positive / danger / success / danger-secondary',
          default: 'default',
        },
        {
          attribute: 'text-color',
          description: 'Color of the text',
          type: 'string',
          values: '-',
          default: '#00143c',
        },
        {
          attribute: 'background-color',
          description: 'Color of the background',
          type: 'string',
          values: '-',
          default: '#EDEFF3',
        },
        {
          attribute: 'class',
          description: 'Element class',
          type: 'string',
          values: '-',
          default: '-',
        },
      ],
      tableDataSlots: [
        {
          name: '-',
          description: 'Text of the label'
        },
      ]
    }
  },
}
</script>
