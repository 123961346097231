export default {
  methods: {
    getIcon (item) {
      if (item.showIcon !== false) {
        if (item.icon) {
          return typeof item.icon === 'function' ? item.icon(this.entity) : item.icon
        }

        if (item.type === 'delete')
          return 'trash'
        else if (item.type === 'toggle_status')
          return this.entity.status === 1 ? 'slash' : 'check-circle'
        else if (item.type === 'edit')
          return 'edit'
        else if (item.type === 'duplicate')
          return 'copy'
        else if (item.type === 'activity_log')
          return 'activity'
      }
    },

    handleItemClick (action) {
      if (action && this.entity) {
        action(this.entity)
      }

      this.$emit('close')
    }
  }
}
