<template>
  <div class="social-media-story">

    <!-- Social Media Images Description -->
    <div class="social-media-story__desc">
      {{ $t('social_media_set_custom_options') }}
    </div>
    <!-- /Social Media Images Description -->

    <!-- Social Media Images Title and Desc -->
    <adm-form-item :label="$t('title')">
      <adm-editor
        ref="admEditor"
        v-model="title"
        :min-height="40"
      />
    </adm-form-item>
    <adm-form-item :label="$t('description')">
      <adm-editor
        ref="admEditor"
        v-model="description"
        :min-height="88"
      />
    </adm-form-item>
    <!-- /Social Media Images Title and Desc -->

    <!-- Social Media Images Font -->
    <div class="social-media-story__look-fields">
      <adm-form-item :label="$t('choose_font')">
        <adm-select
          v-model="fontFamily"
        >
          <adm-option
            v-for="fontOption in getFontOptions()"
            :key="fontOption"
            :label="fontOption"
            :value="fontOption"
          />
        </adm-select>
      </adm-form-item>

      <adm-form-item :label="$t('choose_font_color')">
        <adm-input :value="fontColor" />
        <adm-color-picker
          v-model="fontColor"
          :predefine="defaultCustomizeColors"
        />
      </adm-form-item>
    </div>
    <!-- /Social Media Images Font -->

    <div class="social-media-story__preview-label">
      {{ $t('story_preview') }}
    </div>

    <!-- Social Media Images Image -->
    <div class="social-media-story__container">
      <div class="social-media-story__container__img">
        <div class="social-media-story__container__img__cover" />
        <img :src="getImageSrc()" alt="img instagram">
        <div :style="{color: fontColor}" class="social-media-story__container__img__data">
          <rich-text-wrapper>
            <div class="social-media-story__container__img__data__title" :style="{fontFamily: `${fontFamily}`}" v-html="title" />
          </rich-text-wrapper>
          <rich-text-wrapper>
            <div
              class="social-media-story__container__img__data__description"
              :style="{fontFamily: `${fontFamily}`}"
              v-html="description"
            />
          </rich-text-wrapper>

          <div v-if="dates">
            <div v-if="dates.type === 'range'">
              <div v-if="dates.values.start !== dates.values.end" class="social-media-story__container__img__data__date-item">
                {{ dates.values.start }} - {{ dates.values.end }}
              </div>
              <div v-else class="social-media-story__container__img__data__date-item">
                {{ dates.values.start }}
              </div>

            </div>

            <template v-if="dates.type === 'timeslots'">
              <div v-for="(slots, date) in dates.values" :key="date">
                <div
                  class="social-media-story__container__img__data__date"
                  :style="{color: fontColor, opacity: 0.7, fontFamily: `${fontFamily}`}"
                >
                  {{ date }}
                </div>

                <div class="social-media-story__container__img__data__slots">
                  <div
                    v-for="slot in slots" :key="slot" :style="{fontFamily: `${fontFamily}`}"
                    class="social-media-story__container__img__data__slots__item"
                  >
                    {{ slot }}
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div v-if="showWatermark"
               class="social-media-story__container__img__data__watermark"
          >
            <span :style="{fontFamily: `${fontFamily}`}">{{ $t('made_with') }}</span>
            <img src="@/assets/img/logo/logo-full.svg" alt="">
          </div>
        </div>
      </div>
    </div>
    <!-- /Social Media Images Image -->

    <div class="social-media-story__download">
      <adm-button size="medium" @click="downloadImage">
        {{ $t('download_story_image') }}
      </adm-button>

      <adm-form-item :label="$t('booking_link')">
        <adm-input :value="link">
          <div
            slot="suffix"
            class="social-media-story__download__copy"
            @click="copyLink()"
          >
            <adm-icon icon="copy" />
          </div>
        </adm-input>
      </adm-form-item>
    </div>

    <adm-alert class="mt-24 mb-8" :title="$t('social_media_add_story_desc')" />
  </div>
</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton.vue'
import AdmAlert from '@/views/_components/alert/AdmAlert.vue'
import AdmInput from '@/views/_components/input/AdmInput.vue'
import AdmFormItem from '@/views/_components/form/AdmFormItem.vue'
import AdmOption from '@/views/_components/select/AdmOption.vue'
import AdmSelect from '@/views/_components/select/AdmSelect.vue'
import { getFontOptions } from '@/utils/fontOptions'
import AdmColorPicker from '@/views/_components/colorPicker/AdmColorPicker.vue'
import AdmIcon from '@/views/_components/icon/AdmIcon.vue'
import { copy } from '@/utils/copy'
import color from '@/mixins/common/color'
import { getPublicFileLink } from '@/plugins/axios'
import AdmEditor from '@/views/_components/editor/AdmEditor.vue'
import RichTextWrapper from '@/views/_components/richTextWrapper/RichTextWrapper.vue'

export default {
  name: 'SocialMediaStory',
  components: {
    RichTextWrapper,
    AdmEditor,
    AdmIcon,
    AdmColorPicker,
    AdmSelect,
    AdmOption,
    AdmFormItem,
    AdmInput,
    AdmAlert,
    AdmButton
  },

  mixins: [color],

  props: {
    link: {
      type: String,
      required: true,
    },
    uuid: {
      type: String,
      required: true,
    }
  },
  data () {
    return {
      title: '',
      description: '',
      fontFamily: 'Inter',
      fontColor: '#FFFFFF',
      dates: null,
    }
  },

  computed: {
    showWatermark () {
      return !this.$store.getters['settings/isAgency'] && this.$store.state.settings.customize.isTrafftBrandingApplied
    }
  },

  created () {
    if (this.$store.state.shareBookingForm.queryParams.service) {
      this.title = this.$store.state.shareBookingForm.queryParams.service.name
    }
    if (!this.title && this.$store.state.shareBookingForm.queryParams.employee) {
      this.title = `${this.$store.state.shareBookingForm.queryParams.employee.firstName} ${this.$store.state.shareBookingForm.queryParams.employee.lastName}`
    }

    this.getImageData()
  },

  methods: {
    getFontOptions,

    downloadImage () {
      const params = new URLSearchParams({
        title: this.title,
        description: this.description,
        fontFamily: this.fontFamily,
        fontColor: this.fontColor,
        uuid: this.uuid,
      });

      window.location.href = `${process.env.VUE_APP_API_URL}/api/v1/booking-links/social-media-image/download?image=${this.$store.state.shareBookingForm.selectedSocialMediaImage.name}&${params.toString()}`
    },

    copyLink () {
      copy(this.link)
      this.$message({
        message: this.$t('copied_to_clipboard'),
        type: 'success',
        showClose: true
      })
    },

    getImageSrc () {
      const image = this.$store.state.shareBookingForm.selectedSocialMediaImage
      if (image.isDefault) {
        return `${process.env.VUE_APP_API_URL}/api/v1/public/booking-links/social-media-image/default/${image.name.replace('.png', '')}`
      }

      return getPublicFileLink(image.name, 'medium-max')
    },

    getImageData () {
      this.$http.get(`/api/v1/booking-links/social-media-image/data`, {
        params: {
          uuid: this.uuid,
        }
      }).then(({data})=> {
        this.description = data.description
        this.dates = data.dates
      }).finally(() => {
      })
    }
  }
}
</script>

<style lang="scss">
@import "~@/assets/font/archivo/archivo.scss";
@import "~@/assets/font/maven-pro/maven-pro.scss";
@import "~@/assets/font/montserrat/montserrat.scss";
@import "~@/assets/font/poppins/poppins.scss";
@import "~@/assets/font/roboto/roboto.scss";
@import "~@/assets/font/roboto-slab/roboto-slab.scss";

.social-media-story {
  &__desc {
    color: $shade-900;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 24px;
  }

  &__look-fields {
    display: flex;
    justify-content: space-between;
    gap: 24px;

    & > div {
      width: 100%;
    }
  }

  &__preview-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: $shade-900;
    margin-bottom: 4px;
  }

  &__container {
    border: 1px solid $shade-300;
    border-radius: 7px;
    box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
    padding: 20px;
    margin-bottom: 16px;

    &__img {
      width: 207px;
      display: flex;
      justify-content: center;
      position: relative;
      margin: 0 auto;
      overflow: hidden;

      &__cover {
        background: linear-gradient(180deg, rgba(0, 13, 39, 0.4) 0%, rgba(0, 13, 39, 0.1) 100%);
        height: 100%;
        height: -moz-available;
        height: -webkit-fill-available;
        height: stretch;
        position: absolute;
        width: 207px;
      }

      img {
        width: 207px;
        border-radius: 4px;
      }

      &__data {
        padding: 8px 13px;
        border-radius: 4px;
        position: absolute;
        margin-top: 7%;
        height: 100%;
        left: 0;
        right: 0;
        margin-left: auto;
        margin-right: auto;
        color: $white;

        &__title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          text-align: center;
        }

        &__description {
          font-weight: 500;
          text-align: center;
          font-size: 8px;
          line-height: 12px;
          margin-bottom: 6px;
          position: relative;
          z-index: 1;
        }

        &__watermark {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 2px 3px;
          gap: 3px;
          background: rgba(255, 255, 255, 0.8);
          border-radius: 2px;
          position: absolute;
          z-index: 0;
          right: 18px;
          bottom: 53px;

          span {
            font-size: 5px;
            color: #00143C;
            line-height: 11px;
          }

          img {
            width: 20px;
          }
        }

        &__date-item {
          font-size: 8px;
          font-weight: 700;
          line-height: 12px;
          text-align: center;
          margin-top: 30px;
          position: relative;
          z-index: 1;
        }

        &__date {
          font-weight: 500;
          text-align: center;
          font-size: 8px;
          line-height: 12px;
          position: relative;
          z-index: 1;
        }

        &__slots {
          margin-bottom: 4px;
          position: relative;
          z-index: 1;

          &__item {
            font-size: 8px;
            font-weight: 700;
            line-height: 12px;
            text-align: center;
          }
        }
      }
    }
  }

  &__download {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 24px;

    .adm-button {
      margin-top: 24px
    }

    .adm-form-item {
      width: 100%;
      max-width: 384px;
      margin-bottom: 0;
    }

    &__copy {
      cursor: pointer;
    }
  }
}
</style>
