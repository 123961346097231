<template>

  <!-- Billing Dialog Deactivate Employees -->
  <billing-dialog-deactivate-wrapper
    :items="items"
    :loading="loading"
    :max-items="maxEmployees"
    :loading-continue="loadingContinue"
    @change-step="(step) => $emit('change-step', step)"
    @next-deactivate-step="$emit('next-deactivate-step', 'employees', items)"
  >

    <!-- Title -->
    <template #title>
      {{ $t('max_number_of_employees_in_plan') }} {{ maxEmployees }}.
    </template>
    <!-- /Title -->

    <!-- Subtitle -->
    <template #subtitle>
      {{ $t('max_number_of_employees_in_plan_disable') }}
    </template>
    <!-- /Subtitle -->

    <!-- Headline -->
    <template #headline>
      {{ $t('currently_active_employees') }}
    </template>
    <!-- /Headline -->

  </billing-dialog-deactivate-wrapper>
  <!-- /Billing Dialog Deactivate Employees -->

</template>

<script>
import BillingDialogDeactivateWrapper
  from '@/views/Dashboard/BillingDialog/Deactivate/BillingDialogDeactivateWrapper'
import { getAbortController, isRequestCanceled } from '@/utils/api'

export default {
  name: 'BillingDialogDeactivateEmployees',

  components: {
    BillingDialogDeactivateWrapper
  },

  props: {
    maxEmployees: {
      type: Number,
      default: 0
    },
    loadingContinue: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      cancelToken: getAbortController(),
      items: [],
      loading: false
    }
  },

  created () {
    this.getEmployees()
  },

  methods: {
    async getEmployees () {
      try {
        this.loading = true

        const response = await this.$http.get(
          '/api/v1/users/employees',
          { signal: this.cancelToken.signal }
        )

        const employees = response.data.employees

        for (let i = 0; i < employees.length; i++) {
          this.items.push({
            id: employees[i].id,
            name: employees[i].firstName + ' ' + employees[i].lastName,
            value: employees[i].status === 1,
            icon: employees[i].mediaUrl,
            disabled: this.$store.state.user.id === employees[i].id
          })
        }
      } catch (e) {
        if (isRequestCanceled(e)) return
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
