<template>
  <div class="share-options">
    <div v-if="$store.state.shareBookingForm.showDescription" class="share-options__desc">
      {{ $t('share_options_desc') }}
    </div>

    <div
      class="share-options__items"
      :class="{'share-options__items--two-columns': $store.state.shareBookingForm.currentSection === 'shareParameters'}"
    >
      <template v-for="option in options">
        <component
          :is="option.redirectLink ? 'a' : 'div'"
          v-if="!option.hidden"
          :key="option.type"
          class="share-options__items__card"
          :href="option.redirectLink ? option.redirectLink : 'javascript:void(0)'"
          target="_blank"
          @click="option.action(option)"
        >
          <img
            class="share-options__items__card__img"
            :src="$getDashboardImageLink('share-booking-form', option.img)"
            alt=""
          >
          <div class="share-options__items__card__title">
            {{ option.title }}
          </div>
          <div class="share-options__items__card__desc">
            {{ option.desc }}
          </div>
        </component>
      </template>
    </div>
  </div>
</template>

<script>

import mixinDownload from '@/mixins/download/download'

export default {
  name: 'ShareOptions',

  mixins: [mixinDownload],

  data: function () {
    return {
      options: [],
      defaultOptions:  [
        {
          img: 'shareLink.svg',
          title: this.$t('share_link'),
          desc: this.$t('share_link_desc'),
          type: 'link',
          modalTitle: this.$t('share_link'),
          action: this.chooseOption,
        },
        {
          img: 'embed.svg',
          title: this.$t('embed'),
          desc: this.$t('embed_desc'),
          type: 'embed',
          modalTitle: this.$t('embed_iframe'),
          action: this.chooseOption,
        },
        {
          img: 'qrcode.svg',
          title: this.$t('qr_code'),
          desc: this.$t('generate_qr_code_desc'),
          type: 'qrCode',
          modalTitle: this.$t('generate_qr_code'),
          action: this.chooseOption,
        },
        {
          img: 'email.svg',
          title: this.$t('email'),
          desc: this.$t('share_via_email_desc'),
          type: 'email',
          modalTitle: this.$t('add_dates_and_times_to_email'),
          action: this.chooseOption,
        },
        {
          img: 'instagram.svg',
          title: this.$t('social_media'),
          desc: this.$t('social_media_desc'),
          type: 'socialMedia',
          modalTitle: this.$t('generate_social_media'),
          action: this.chooseOption,
        },
        {
          img: 'wordpress.svg',
          title: this.$t('wordpress_feature_name'),
          desc: this.$t('wordpress_click_to_download'),
          type: 'wordpress',
          modalTitle: this.$t('share_link'),
          redirectLink: 'https://wordpress.org/plugins/booking-system-trafft/',
          hidden: this.$store.getters['settings/isAgency'],
          action: this.chooseOption,
        },
      ],
      selectedOption: 'shareOptions'
    }
  },

  created () {
    if (this.isShoplazza()) {
      this.chooseOption(this.defaultOptions.find(option => option.type === 'embed'))
    }

    if (this.$store.state.shareBookingForm.currentSection === 'shareParameters') {
      this.getShareParametersOptions()

      return
    }

    this.options = this.defaultOptions
  },

  methods: {
    isShoplazza () {
      return this.$store.state.settings.general.externalIntegration && this.$store.state.settings.general.externalIntegration.source === 'shoplazza'
    },

    chooseOption (option) {
      if (option.redirectLink) {
        return
      }

      this.$store.commit('shareBookingForm/setShowDescription', false)

      this.options = []
      this.$store.commit('shareBookingForm/selectedShareOption', option)
      this.$store.commit('shareBookingForm/setOptionTitle', option.modalTitle)

      if (this.$store.state.shareBookingForm.currentSection === 'shareOptions') {
        this.getShareParametersOptions()
      }
    },

    getShareParametersOptions () {
      this.$store.commit('shareBookingForm/changeSection', 'shareParameters')

      if (this.isShoplazza()) {
        this.options = [
          {
            img: this.$store.state.shareBookingForm.selectedShareOption.img,
            title: this.$t('all_services'),
            desc: this.$t('show_all_services_on_your_website_as_cards_your_customers_will_go_through_the_entire_booking_process'),
            parametersType: 'withoutParameters',
            action: this.chooseShareParametersOption
          },
          {
            img: 'appointmentParameter.svg',
            title: this.$t('selected_services'),
            desc: this.$t('select_service_employee_date_ranges_or_time_slots_to_shorten_the_booking_process_for_your_customers'),
            parametersType: 'withParameters',
            action: this.chooseShareParametersOption
          },
        ]

        this.$store.commit(
          'shareBookingForm/setOptionTitle',
          this.$t('which_services_do_you_want_to_add_to_your_website')
        )

        return
      }

      this.options = [
        {
          img: 'appointmentParameter.svg',
          title: this.$t('set_appointment_parameters'),
          desc: this.$t('set_appointment_parameters_desc'),
          parametersType: 'withParameters',
          action: this.chooseShareParametersOption
        },
        {
          img: this.$store.state.shareBookingForm.selectedShareOption.img,
          title: this.$t('get_immediately'),
          desc: this.$t('customers_will_have_to_set_parameters'),
          parametersType: 'withoutParameters',
          action: this.chooseShareParametersOption
        },
      ]
    },

    chooseShareParametersOption (option) {
      this.$store.commit('shareBookingForm/setParametersOption', option.parametersType)
      this.$store.commit('shareBookingForm/setOptionTitle', this.$store.state.shareBookingForm.selectedShareOption.modalTitle)

      if (option.parametersType === 'withParameters') {
        this.$store.commit('shareBookingForm/changeSection', 'entities')

        return
      }

      if (option.parametersType === 'withoutParameters') {
        if (this.$store.state.shareBookingForm.selectedShareOption.type === 'socialMedia') {
          this.$store.commit('shareBookingForm/changeSection', 'socialMediaImages')

          return
        }

        this.$emit('go-next')
      }
    },

    resetOptions () {
      this.$store.commit('shareBookingForm/selectedShareOption', null)
      this.options = this.defaultOptions
    },
  }
}
</script>

<style lang="scss" scoped>
.share-options {

  &__desc {
    color: $shade-900;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  &__items {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: 1fr;
    grid-column-gap: 24px;
    grid-row-gap: 24px;

    @include phone-up {
      grid-template-columns: repeat(3, 1fr);
    }

    &--two-columns {
      grid-template-columns: repeat(2, 1fr);
    }

    &__card {
      margin: 0 auto;
      align-items: center;
      padding: 24px 12px;
      background: $shade-150;
      border-radius: 4px;
      cursor: pointer;
      width: 100%;
      position: relative;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        background: linear-gradient(180deg, var(--primary-400) 0%, var(--primary-200) 100%);
        box-shadow: 0 0 0 2px var(--primary-600);
        transition: .2s linear 0s;

        .share-options__items__card__title {
          color: var(--primary-900);
        }
      }

      &__img {
        height: 64px;
        display: flex;
        margin: 0 auto 18px;
      }

      &__title {
        text-align: center;
        color: $shade-900;
        font-weight: 600;
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 4px;
      }

      &__desc {
        text-align: center;
        color: $shade-800;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
}
</style>
