import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import billing from './modules/billing'
import colors from '@/store/modules/colors'
import entities from './modules/entities'
import features from './modules/features'
import finance from './modules/finance'
import guide from '@/store/modules/guide'
import menu from './modules/menu'
import notifications from '@/store/modules/settings/notifications'
import settings from './modules/settings/settings'
import user from './modules/user'
import view from './modules/view'
import dashboardAlerts from './modules/dashboardAlerts'
import survey from '@/store/modules/survey'
import manageAppointment from './modules/manageAppointment'
import shareBookingForm from '@/store/modules/shareBookingForm'
import { isLocalStorageAvailable } from '@/utils/localStorage'
import floatingSave from './modules/floatingSave'
import mobileAppBanner from '@/store/modules/mobileAppBanner'

Vue.use(Vuex)

let vuexPlugins = []
if (isLocalStorageAvailable()) {
  vuexPlugins = [
    createPersistedState({
      key: 'persisted_state',
      paths: ['user', 'settings', 'features', 'menu', 'billing',],
    })
  ]
}

export default new Vuex.Store({
  modules: {
    billing,
    colors,
    entities,
    features,
    finance,
    guide,
    menu,
    notifications,
    settings,
    user,
    view,
    manageAppointment,
    survey,
    shareBookingForm,
    floatingSave,
    mobileAppBanner,
    dashboardAlerts,
  },

  plugins: vuexPlugins,

  strict: process.env.NODE_ENV !== 'production'
})
