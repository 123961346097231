<template>
  <div class="promo-2024">

    <div class="promo-2024__container">
      <div class="promo-2024__container__discount">
        {{ $t('limited_time_offer') }}:<span class="promo-2024__container__discount__promo"> {{ $t('up_to_50_off') }} </span> {{ $t('on_all_licenses') }}!
      </div>
      <div class="promo-2024__container__timer">
        <promo-countdown class="promo-2024__container__timer__countdown" />
        <a class="promo-2024__container__timer__learn" @click.prevent="openBilling">
          {{ $t('learn_more') }}
        </a>
      </div>
      <!-- Close banner -->
      <div class="promo-2024__container__close-btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" v-bind:svg-inline="''" @click="close" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M13.41 12l4.3-4.29a1.004 1.004 0 10-1.42-1.42L12 10.59l-4.29-4.3a1.004 1.004 0 00-1.42 1.42l4.3 4.29-4.3 4.29a1 1 0 00.325 1.639 1 1 0 001.095-.219l4.29-4.3 4.29 4.3a1.002 1.002 0 001.639-.325 1 1 0 00-.219-1.095L13.41 12z" fill="#005AEE"/></svg>
      </div>
      <!-- /Close banner -->
    </div>
  </div>
</template>

<script>
import PromoCountdown from '@/views/Dashboard/Promotion2024/Countdown/PromoCountdown.vue'

export default {
  name: 'Promotion2024',

  components: { PromoCountdown },

  methods: {
    openBilling () {
      this.$store.commit('billing/setBillingDialogStep', 'plans')
      this.$store.commit('billing/setVisibleDialogBilling', true)
    },

    close () {
      this.$http.post('/api/v1/blackFriday2023/settings',
        {'settingName': 'showChristmasPromo2023Banner'}
      ).then(() => {
        this.$store.commit('settings/setSetting',
          { category: 'christmasPromo2023', name: 'showChristmasPromo2023Banner', value: false })
      });
    },
  }
}
</script>

<style lang="scss">
.promo-2024 {
  background: $white url("@/assets/img/christmas-promo-2023/bg-mobile.png");

  @media screen and (min-width: 1025px) {
    background: url("@/assets/img/christmas-promo-2023/bg-desktop.png");
  }

  &__container {
    position: relative;
    width: 100%;
    padding: 33px;
    display: flex;
    justify-content: center;
    flex-direction: column;

    @include phone-up {
      flex-direction: row;
      padding: 16px;
    }

    @media screen and (min-width: 1025px) {
      padding-bottom: 9px;
      padding-top: 9px;
    }

    &__discount {
      font-size: 20px;
      color: var(--primary-900);
      font-weight: 600;
      line-height: 140%;
      letter-spacing: 0.6px;
      margin: 0 auto 12px;
      text-align: center;

      @include phone-up {
        margin: 0 12px 0 0;
      }

      &__promo {
        background: #FFE600;
      }
    }

    a {
      background: #FFE600;
      border-radius: 6px;
      padding: 3px 32px;
      font-size: 14px;
      font-weight: 600;
      line-height: 24px;
      color: $shade-900;
    }

    &__timer {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      align-self: center;
      gap: 12px;

      &__learn {
        display: flex;

        @media screen and (min-width: 1200px) {
          position: absolute;
          right: 50px;
        }

        @media screen and (min-width: 1400px) {
          right: 100px;
        }

        @media screen and (min-width: 1600px) {
          right: 200px;
        }
      }
    }

    &__close-btn {
      position: absolute;
      right: 0;
      top: 8px;

      svg {
        cursor: pointer;
        align-self: flex-start;
        width: 24px;
        height: 24px;
        margin-right: 4px;

        @include tablet-up {
          margin-top: 4px;
        }
      }
    }
  }
}
</style>
