<template>

  <!-- Input -->
  <div class="adm-input-wrapper" :style="{ maxWidth }">
    <el-input
      :id="id"
      v-bind="{...$attrs, ...$props}"
      ref="elInput"
      v-model="model"
      :class="[
        type === 'textarea' ? 'adm-textarea' : 'adm-input',
        size ? (type === 'textarea' ? 'adm-textarea' : 'adm-input') + '__size__' + size : '',
        {
          'is-icon-start': iconStart || $slots.prefix,
          'is-icon-end': iconEnd || $slots.suffix,
        }
      ]"
      :autocomplete="autocomplete"
      @blur="(e) => $emit('blur', e)"
      @focus="(e) => $emit('focus', e)"
      @change="(eventValue) => $emit('change', eventValue)"
      @input="(eventValue) => $emit('input', eventValue)"
    >
      <template v-if="$slots.prefix || iconStart" slot="prefix">
        <slot name="prefix">
          <i :class="'tz-' + iconStart" />
        </slot>
      </template>
      <template v-if="$slots.suffix || iconEnd" slot="suffix">
        <slot name="suffix">
          <i
            v-if="iconHandler"
            :class="['tz-' + iconEnd, 'with-handler' ]"
            @click="() => iconHandler()"
          />

          <i v-else :class="'tz-' + iconEnd" />
        </slot>
      </template>
    </el-input>
  </div>
  <!-- /Input -->

</template>

<script>
export default {
  name: 'AdmInput',

  inheritAttrs: false,

  props: {
    id: {
      type: [String, Object],
      default: null
    },
    type: {
      type: String,
      default: 'text'
    },
    value: {
      type: [String, Number],
      default: ''
    },
    size: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'medium', 'small'].indexOf(value) !== -1
      }
    },
    disabled: Boolean,
    readonly: Boolean,
    placeholder: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: null
    },
    iconStart: {
      type: String,
      default: ''
    },
    iconEnd: {
      type: String,
      default: ''
    },
    iconHandler: {
      type: Function,
      default: null
    },
    rows: {
      type: Number,
      default: 2
    },
    autosize: {
      type: [Boolean, Object],
      default: false
    },
    name: {
      type: String,
      default: ''
    },
    autofocus: {
      type: Boolean,
      default: false
    },
    tabindex: {
      type: String,
      default: ''
    },
    validateEvent: {
      type: Boolean,
      default: true
    },
    maxWidth: {
      type: String,
      default: null,
    },
    autocomplete: {
      type: String,
      default: 'off'
    }
  },

  data () {
    return {
      selfModel: '',
    }
  },

  computed: {
    model: {
      get () {
        return this.value !== undefined ? this.value : this.selfModel
      },
      set (val) {
        this.selfModel = val
      }
    }
  },

  methods: {
    focus () {
      this.$refs.elInput.focus()
    },

    blur () {
      this.$refs.elInput.blur()
    },

    select () {
      this.$refs.elInput.select()
    },
  }
}
</script>

<style lang="scss">
// Input Wrapper
.adm-input-wrapper {
  width: 100%;
  position: relative;

  // Input & Textarea
  .adm-input, .adm-textarea {

    // Native Input & Native Textarea
    input, textarea {
      border: 1px solid $shade-300;
      border-radius: 7px;
      padding: 0 12px;
      font-size: 15px;
      -webkit-transition: box-shadow 0.15s;
      transition: box-shadow 0.15s;

      // Hover
      &:hover {
        box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
      }

      // Active & Focus
      &:active, &:focus {
        border: 1px solid var(--primary-900);
        box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
      }

      // Placeholder
      &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
        color: $shade-600;
      }
    }

    // Disabled
    &.is-disabled {
      input, textarea {
        background: $shade-150;
        border-color: $shade-300;
        box-shadow: none;
        cursor: not-allowed;
        color: $shade-600;

        // Placeholder
        &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
          color: $shade-450;
        }

        // Hover
        &:hover {
          box-shadow: unset;
        }

        // Active & Focus
        &:active, &:focus {
          border: 1px solid $shade-300;
          box-shadow: unset;
        }
      }
    }
  }

  // Input
  .adm-input {

    // Size
    &__size {

      // Default
      &__default {

        // Native Input
        input {
          height: 40px;
          line-height: 40px;
        }

        // Icon
        &.is-icon-start, &.is-icon-end {
          i {
            font-size: 24px;
            height: 24px;
            width: 24px;
          }
        }

        // Icon Start
        &.is-icon-start {

          // Prefix
          .el-input__prefix {
            left: 0.5rem;
          }

          // Native Input
          input {
            padding-left: 40px;
          }
        }

        // Icon End
        &.is-icon-end {

          // Suffix
          .el-input__suffix {
            right: 0.5rem;
          }

          // Native Input
          input {
            padding-right: 40px;
          }
        }
      }

      // Medium
      &__medium {

        // Native Input
        input {
          height: 36px;
          line-height: 36px;
        }

        // Icon
        &.is-icon-start, &.is-icon-end {
          i {
            font-size: 24px;
            height: 24px;
            width: 24px;
          }
        }

        // Icon Start
        &.is-icon-start {

          // Prefix
          .el-input__prefix {
            left: 6px;
          }

          // Native Input
          input {
            padding-left: 36px;
          }
        }

        // Icon End
        &.is-icon-end {

          // Suffix
          .el-input__suffix {
            right: 6px;
          }

          // Native Input
          input {
            padding-right: 36px;
          }
        }
      }

      // Small
      &__small {

        // Native Input
        input {
          height: 32px;
          line-height: 32px;
          font-size: 14px;
        }

        // Icon
        &.is-icon-start, &.is-icon-end {
          i {
            font-size: 20px;
            height: 20px;
            width: 20px;
          }
        }

        // Icon Start
        &.is-icon-start {

          // Prefix
          .el-input__prefix {
            left: 6px;
          }

          // Native Input
          input {
            padding-left: 32px;
          }
        }

        // Icon End
        &.is-icon-end {

          // Suffix
          .el-input__suffix {
            right: 6px;
          }

          // Native Input
          input {
            padding-right: 32px;
          }
        }
      }
    }

    // Icon Start & Icon End
    &.is-icon-start, &.is-icon-end {
      display: flex;
      align-items: center;

      // Icon
      i:before {
        color: $shade-900;
      }

      // Prefix & Suffix
      .el-input__prefix, .el-input__suffix, .el-input__suffix-inner {
        align-items: center;
        display: flex;
      }

      .with-handler {
        cursor: pointer;

        // Icon
        &:before {
          color: var(--primary-900);
        }
      }
    }
  }

  // Textarea
  .adm-textarea {

    // Size
    &__size {

      // Default
      &__default {

        // Native Textarea
        textarea {
          font-size: 15px;
          line-height: 24px;
          padding: 7px 12px;
        }
      }

      // Medium
      &__medium {

        // Native Textarea
        textarea {
          font-size: 15px;
          line-height: 24px;
          padding: 5px 10px;
        }
      }

      // Small
      &__small {

        // Native Textarea
        textarea {
          font-size: 14px;
          line-height: 20px;
          padding: 5px 10px;
        }
      }
    }
  }
}
</style>
