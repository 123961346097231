const getDefaultState = () => {
  return {
    transactionToRefund: null,
    transactionToChangeStatus: null,
  }
}

const state = getDefaultState()

const getters = {
  transactionToRefund: function (state) {
    return state.transactionToRefund
  },

  transactionToChangeStatus: function (state) {
    return state.transactionToChangeStatus
  }
}

const mutations = {
  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  setTransactionToRefund (state, payload) {
    state.transactionToRefund = payload
  },

  setTransactionToChangeStatus (state, payload) {
    state.transactionToChangeStatus = payload
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
