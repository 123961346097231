<template>
  <td
    :style="{'height': getHeight()}"
    style="box-sizing: inherit; padding: 0; min-width: 64px; display: inline-block;
     margin-right: 4px;margin-bottom: 4px; float: left; border: 1px solid #064EC6; border-radius: 3px;background-color: #005AEE"
  >
    <table :style="{'height': getHeight()}" style="box-sizing: inherit; border-collapse: collapse; border-spacing: 0;">
      <tbody style="box-sizing: inherit;">
        <tr :style="{'height': getHeight()}" style="box-sizing: inherit;">
          <td style="box-sizing: inherit; padding: 0; width: 16px;" />
          <td
            style="box-sizing: inherit; padding: 0; text-align: center; margin-right: 1px;"
          >
            <a
              :href="link"
              class="gmail-spot"
              style="box-sizing: inherit; background-color: transparent; color: #FFFFFF; text-decoration-line: none; cursor: pointer; font-size: 14px;font-weight: 500; line-height: 16px;"
            >
              <span style="box-sizing: inherit;">{{ label }}</span>
            </a>
          </td>
          <td style="box-sizing: inherit; padding: 0; width: 16px;" />
        </tr>
      </tbody>
    </table>
  </td>
</template>

<script>
export default {
  name: 'EmailSlotLink',
  props: {
    label: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'medium'].indexOf(value) !== -1
      }
    },
  },
  methods: {
    getHeight () {
      if (this.size === 'medium') {
        return '32px'
      }

      return '36px'
    }
  }
}
</script>
