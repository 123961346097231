var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adm-input-number-wrapper",style:({ maxWidth: _vm.maxWidth })},[_c('div',{staticClass:"adm-input-number",class:[
      _vm.size ? 'adm-input-number__size__' + _vm.size : '',
    ]},[_c('div',{class:[
        'el-input-number',
        _vm.inputNumberSize ? 'el-input-number--' + _vm.inputNumberSize : '',
        { 'is-disabled': _vm.inputNumberDisabled },
        { 'is-controls-right': _vm.controlsAtRight }
      ],on:{"dragstart":function($event){$event.preventDefault();}}},[(_vm.controls)?[_c('span',{directives:[{name:"repeat-click",rawName:"v-repeat-click",value:(_vm.decrease),expression:"decrease"}],staticClass:"el-input-number__decrease",class:{'is-disabled': _vm.minDisabled},attrs:{"role":"button"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.decrease.apply(null, arguments)}}},[_c('i',{staticClass:"tz-minus"})]),_c('span',{directives:[{name:"repeat-click",rawName:"v-repeat-click",value:(_vm.increase),expression:"increase"}],staticClass:"el-input-number__increase",class:{'is-disabled': _vm.maxDisabled},attrs:{"role":"button"},on:{"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.increase.apply(null, arguments)}}},[_c('i',{staticClass:"tz-plus"})])]:_vm._e(),_c('el-input',{ref:"input",attrs:{"value":_vm.displayValue,"placeholder":_vm.placeholder,"disabled":_vm.inputNumberDisabled,"size":_vm.inputNumberSize,"max":_vm.max,"min":_vm.min,"name":_vm.name},on:{"blur":_vm.handleBlur,"focus":_vm.handleFocus,"input":_vm.handleInput,"change":_vm.handleInput},nativeOn:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"up",38,$event.key,["Up","ArrowUp"]))return null;$event.preventDefault();return _vm.increase.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"down",40,$event.key,["Down","ArrowDown"]))return null;$event.preventDefault();return _vm.decrease.apply(null, arguments)}]}})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }