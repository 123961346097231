// eslint-disable-next-line no-unused-vars
const tinycolor = require('tinycolor2')

export default {
  data () {
    return {
      avatarBackgroundColors: [
        '#C0EB75',
        '#FFE066',
        '#66D9E8',
        '#E599F7',
        '#FFC078',
        '#63E6BE',
        '#B197FC',
        '#FAA2C1',
        '#74C0FC',
        '#8CE99A'
      ],
      avatarFontColors: [
        '#5C940D',
        '#E67700',
        '#0B7285',
        '#862E9C',
        '#D9480F',
        '#087F5B',
        '#5F3DC4',
        '#A61E4D',
        '#1864AB',
        '#2B8A3E'
      ],
      defaultCustomizeColors: [
        '#005AEE',
        '#228BE6',
        '#CC5DE8',
        '#E64980',
        '#12B886',
        '#FAB005',
        '#F76707',
        '#15AABF'
      ]
    }
  },

  methods: {
    hexToRGB (hex, alpha) {
      return tinycolor(hex).setAlpha(alpha).toRgbString()
    },

    getTextColor (color) {
      if (tinycolor(color).isDark()) {
        return 'white'
      }

      if (tinycolor(color).isLight()) {
        return 'black'
      }
    },

    lightenDarkenColor (colorCode, amount) {
      let usePound = false

      if (colorCode[0] === '#') {
        colorCode = colorCode.slice(1)
        usePound = true
      }

      const num = parseInt(colorCode, 16)

      let r = (num >> 16) + amount

      if (r > 255) {
        r = 255
      } else if (r < 0) {
        r = 0
      }

      let b = ((num >> 8) & 0x00FF) + amount

      if (b > 255) {
        b = 255
      } else if (b < 0) {
        b = 0
      }

      let g = (num & 0x0000FF) + amount

      if (g > 255) {
        g = 255
      } else if (g < 0) {
        g = 0
      }

      return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
    },

    darken (color, amount) {
      return tinycolor(color).darken(amount).toString()
    },

    desaturate (color, amount) {
      return tinycolor(color).desaturate(amount).toString()
    },

    saturate (color, amount) {
      return tinycolor(color).saturate(amount).toString()
    },

    generatePalette (color) {
      return {
        '--primary-color': color,
        '--primary-color-005': this.hexToRGB(color, 0.05),
        '--primary-color-01': this.hexToRGB(color, 0.1),
        '--primary-color-025': this.hexToRGB(color, 0.25),
        '--primary-color-09': this.hexToRGB(color, 0.9)
      }
    }
  }
}
