<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmButtonGroup from '@/views/_components/button/AdmButtonGroup'

export default {
  name: 'AdmDropdown',

  components: {
    AdmButton,
    AdmButtonGroup
  },

  props: {
    trigger: {
      type: String,
      default: 'click',
      validator (value) {
        return ['click', 'hover'].indexOf(value) !== -1
      }
    },
    groupButton: Boolean,
    size: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'medium', 'small', 'mini'].indexOf(value) !== -1
      }
    },
    color: {
      type: String,
      default: 'blue',
      validator (value) {
        return ['blue', 'grey'].indexOf(value) !== -1
      }
    },
    iconStart: {
      type: String,
      default: ''
    },
    placement: {
      type: String,
      default: 'bottom-end',
      validator (value) {
        return ['top', 'top-start', 'top-end', 'bottom', 'bottom-start', 'bottom-end'].indexOf(value) !== -1
      }
    },
    hideOnClick: {
      type: Boolean,
      default: true
    },
  },

  data () {
    return {
      isOpened: false
    }
  },

  mounted () {
    if (!this.groupButton) {
      this.$slots.default[0].elm.addEventListener('mouseup', () => {
        this.$slots.default[0].elm.blur()
      })
    }
  },

  render () {
    let { trigger, groupButton, size, color, iconStart, placement, hideOnClick, isOpened } = this

    const handleMainButtonClick = (event) => {
      event.stopPropagation()
      this.$emit('click', event)
      this.$refs.dropdown.hide()
    }

    const handleCommand = (command) => {
      this.$emit('command', command)
      if (this.$slots.default[0].elm !== undefined && this.$slots.default[0].elm.classList) {
        this.$slots.default[0].elm.classList.remove('is-active')
      }
    }

    const handleVisibleChange = (value) => {
      if (!groupButton && this.$slots.default[0].elm !== undefined) {
        if (value) {
          this.$slots.default[0].elm.classList.add('is-active')
        } else {
          this.$slots.default[0].elm.classList.remove('is-active')
        }
      }

      this.isOpened = value
      this.$emit('visible-change', value)
    }

    let triggerElm = !groupButton
      ? this.$slots.default
      : (<adm-button-group>
        <adm-button
          color={color}
          size={size}
          icon-start={iconStart}
          onClick={handleMainButtonClick}
        >
          {this.$slots.default}
        </adm-button>
        <adm-button
          ref="trigger"
          class={isOpened ? 'is-active' : ''}
          color={color}
          icon-start="caret-down-small"
          size={size}
        />
      </adm-button-group>)

    return (
      <el-dropdown
        ref="dropdown"
        trigger={trigger}
        placement={placement}
        hide-on-click={hideOnClick}
        onCommand={handleCommand}
        v-on:visible-change={handleVisibleChange}
      >
        {triggerElm}
        {this.$slots.dropdown}
      </el-dropdown>
    )
  }
}
</script>
