<template>

  <!-- Administration Top Alert -->
  <div class="adm-alert-top" :class="[ 'adm-alert-top' + '__' + type ]">

    <div class="adm-alert-top__content">
      <div class="adm-alert-top__content__left">
        <!-- Icon -->
        <div class="adm-alert-top__content__left__icon-wrapper mr-4">
          <i :class="'tz-' + getIcon()" />
        </div>
        <!-- /Icon -->

        <!-- Title -->
        <span class="adm-alert-top__content__left__title">
          {{ title }}
        </span>
        <!-- /Title -->
      </div>

      <!-- Close -->
      <adm-button
        v-if="closable"
        class="adm-alert-top__content__close"
        size="mini"
        type="borderless"
        color="light"
        icon-start="close"
        @click="close"
      />
      <!-- /Close -->
    </div>

    <div class="adm-alert-top__right">
      <!-- Right Slot -->
      <slot name="right" />
      <!-- /Right Slot -->

      <!-- Close -->
      <adm-button
        v-if="closable"
        class="adm-alert-top__right__close"
        size="mini"
        type="borderless"
        color="light"
        icon-start="close"
        @click="close"
      />
      <!-- /Close -->
    </div>

  </div>
  <!-- /Administration Top Alert -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'

export default {
  name: 'AdmAlertTop',

  components: {
    AdmButton
  },

  props: {
    title: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'positive', 'warning', 'danger'].indexOf(value) !== -1
      }
    },
    closable: {
      type: Boolean,
      default: false
    },
  },

  methods: {
    getIcon () {
      if (this.type === 'default')
        return 'info'
      else if (this.type === 'positive')
        return 'check-filled'
      else if (this.type === 'warning')
        return 'info-filled'
      else
        return 'warning-filled'
    },

    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss">
// Dashboard Top Alert
.adm-alert-top {
  min-height: 40px;
  display: flex;
  align-items: center;
  padding: 8px;
  flex-direction: column;
  gap: 10px;

  @include phone-up {
    padding: 8px 12px;
    flex-direction: row;
    justify-content: space-between;
    gap: 20px;
  }

  &__content {
    width: 100%;
    display: flex;
    align-items: center;
    align-self: start;
    justify-content: space-between;

    &__left {
      display: flex;
      align-items: center;

      // Title
      &__title {
        margin-right: auto;
        color: $adm-white;
        font-weight: 500;
        font-size: 13px;
        line-height: 16px;
      }

      // Icon Wrapper
      &__icon-wrapper {
        width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;

        i {
          font-size: 20px;
          display: block;
        }
      }
    }

    &__close.adm-button {
      display: flex;

      @include phone-up {
        display: none;
      }
    }
  }

  // Default
  &__default {
    background-color: $shade-200;
  }

  // Positive
  &__positive {
    background-color: var(--primary-400);

    i:before {
      color: var(--primary-900);
    }
  }

  // Warning
  &__warning {
    background-color: $yellow-800;

    .adm-alert-top__content__left__title {
      color: $shade-900;
    }
  }

  // Danger
  &__danger {
    background-color: $red-900;
    color: $adm-white;

    i:before {
      color: $adm-white;
    }
  }

  &__right {
    width: 100%;
    display: flex;
    justify-content: end;

    @include phone-up {
      width: initial;
    }

    .adm-button {
      width: 100%;

      @include phone-up {
        width: initial;
      }
    }

    // Close
    &__close.adm-button {
      display: none;
      margin-left: 8px;

      @include phone-up {
        display: flex;
      }
    }
  }
}
</style>
