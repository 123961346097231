<template>
  <div>
    <adm-modal
      :visible="isDialogOpened"
      class="guide"
      :close-visible="false"
      width="850px"
    >
      <template>
        <div class="guide__content">
          <div class="guide__content__inside">
            <div class="guide__content__title">
              {{ $t('welcome_text') }}
              <span class="guide__content__title--name">
                {{ $t('welcome_text_trafft') }}
              </span>!
            </div>

            <!-- Guide Content -->
            <div class="guide__content__text">
              {{ $t('first_paragraph') }}
            </div>
            <!-- /Guide Content -->

            <!-- Login video -->
            <div v-if="isDialogOpened" class="guide__content__video">
              <iframe width="100%" height="400px" src="https://www.youtube.com/embed/DS29VMhKOOw?autoplay=1" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen />
            </div>
            <!-- /Login video -->
          </div>
        </div>
      </template>

      <template #footer>

        <!-- Guide Actions -->
        <adm-button
          type="filled"
          color="blue"
          @click="onLaunchTutorial()"
        >
          {{ $t('continue') }}
        </adm-button>
        <!-- /Guide Actions -->

      </template>
    </adm-modal>
  </div>
</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import { mapState } from 'vuex'
import AdmModal from '@/views/_components/modal/AdmModal'
import mixinSecurity from '@/mixins/security/security'

export default {
  name: 'FirstStepGuide',

  components: {
    AdmModal,
    AdmButton
  },

  mixins: [
    mixinSecurity
  ],

  computed: {
    ...mapState({
      isGuidePopupInitiallyPresented: state => state.settings.guide.guidePopupInitiallyPresented
    }),

    isDialogOpened () {
      if (this.isGuidePopupInitiallyPresented) {
        return false
      }

      if (window.innerWidth <= 991) {
        return false
      }

      if (!this.isSuperAdmin()) {
        return false
      }

      return !this.$store.getters['settings/isAgency'];
    }
  },

  methods: {
    onClose () {
      this.$store.dispatch('settings/setGuidePresentedInitially')
      this.$store.commit('guide/openGuideSteps')
    },

    onLaunchTutorial () {
      this.onClose()
    }
  }
}
</script>

<style lang="scss">
.guide {
  .el-dialog {
    width: 850px;
    display: block;

    &__header {
      display: none;
    }
  }
  &__content {
    background-color: $adm-white;
    word-break: normal;
    font-size: 18px;
    z-index: 999;
    padding-left: 0;

    &__inside {
      width: 90%;
      margin: 0 auto;
    }

    &__video {
      display: flex;
      width: 100%;
      padding-top: 24px;
      margin: 0 auto;
      video {
        width: 100%;
        margin: 0 auto;
      }
    }

    &__title {
      margin: 0 auto;
      flex-wrap: wrap;
      font-weight: 600;
      font-size: 28px;
      letter-spacing: -0.1px;
      line-height: 40px;
      padding-left: 0;
      text-align: center;
      padding-top: 36px;

      &--name {
        color: var(--primary-900);
      }
    }

    &__text {
      width: 100%;
      font-size: 15px;
      line-height: 24px;
      padding: 16px 0 4px;
      color: $shade-800;
      text-align: center;
    }
  }
  .el-dialog__footer {
    width: 90%;
    margin: 0 auto;
    border-top: none;
    padding-top: 0;
    padding-bottom: 36px;

    .guide-actions {
      display: flex;
      margin: 0 auto;
    }
  }

}
</style>
