<template>
  <adm-modal
    class="slide-guide"
    width="850px"
    top="10vh"
    :visible="isModalOpened"
    :close-visible="false"
    :title="items[currentPopoverItem] ? items[currentPopoverItem].title : ''"
  >
    <div v-if="isModalOpened">

      <!-- Guide Step Description -->
      <div class="slide-guide__content__description">
        {{ items[currentPopoverItem].text }}
      </div>
      <!-- /Guide Step Description -->

      <!-- Guide Step Tutorial -->
      <div class="slide-guide__content__tutorial">
        <img
          v-if="items[currentPopoverItem].type === 'image'"
          :src="require(`@/assets/img/guide/${items[currentPopoverItem].asset}`)"
        >

        <video
          v-if="items[currentPopoverItem].type === 'video'"
          muted
          autoplay
          controls
          loop
        >
          <source :src="require(`@/assets/video/guide/${items[currentPopoverItem].asset}`)" type="video/mp4">
        </video>
      </div>
      <!-- /Guide Step Tutorial -->
    </div>

    <!-- Guide Step Actions -->
    <template #footer>
      <template v-if="isModalOpened">
        <adm-button
          v-if="items[currentPopoverItem].isSkipPresented"
          icon-start="book-outline"
          type="borderless"
          color="dark"
          size="medium"
          @click="skipGuide"
        >
          {{ $t('skip_this_tutorial') }}
        </adm-button>
        <adm-button size="medium" class="slide-guide__footer__continue" @click="continueGuide(items[currentPopoverItem].nextPopover)">
          {{ $t('continue') }}
        </adm-button>
      </template>
    </template>
    <!-- /Guide Step Actions -->

  </adm-modal>
</template>

<script>

import AdmButton from '@/views/_components/button/AdmButton'
import AdmModal from '@/views/_components/modal/AdmModal'

export default {
  name: 'GuideModal',
  components: {
    AdmModal,
    AdmButton
  },
  data () {
    return {
      items: {
        generalSettingsModal: {
          title: this.$t('general_modal_title'),
          text: this.$t('general_modal_desc'),
          asset: 'general.gif',
          type: 'image',
          nextPopover: 'generalSettingsSaveButton',
          isSkipPresented: false,
        },
        customizeModal: {
          title: this.$t('customize_modal_title'),
          text: this.$t('customize_modal_desc'),
          asset: 'customize.gif',
          type: 'image',
          nextPopover: 'customizeSaveButton',
          isSkipPresented: false,
        },
        employeesModal: {
          title: this.$t('employee_modal_title'),
          text: this.$t('employee_modal_desc'),
          asset: 'employee.mp4',
          type: 'video',
          nextPopover: 'employeesAddEdit',
          isSkipPresented: true,
        },
        servicesModal: {
          title: this.$transWithBusinessName(this.$t('service_modal_title')),
          text: this.$t('service_modal_desc'),
          asset: 'service.mp4',
          type: 'video',
          nextPopover: 'servicesAddButton',
          isSkipPresented: true,
        },
        locationsModal: {
          title: this.$t('location_modal_title'),
          text: this.$t('location_modal_desc'),
          asset: 'location.gif',
          type: 'image',
          nextPopover: 'locationsSaveButton',
          isSkipPresented: true,
        },
        paymentSettingsModal: {
          title: this.$t('payment_modal_title'),
          text: this.$t('payment_modal_desc'),
          asset: 'payment.gif',
          type: 'image',
          nextPopover: 'paymentSettingsSaveButton',
          isSkipPresented: true,
        }
      },
    }
  },
  computed: {
    isModalOpened () {
      let activePopoverName = this.$store.getters['guide/getActivePopoverName']

      return Object.keys(this.items).includes(activePopoverName);
    },
    currentPopoverItem () {
      return this.$store.getters['guide/getActivePopoverName']
    },
  },
  methods: {
    skipGuide () {
      this.$store.dispatch('guide/skipCurrentGuide')
    },
    continueGuide (nextPopover) {
      this.$store.commit('guide/closeItemPopover')
      this.$store.commit('guide/openItemPopover', nextPopover)
    }
  }
}
</script>

<style lang="scss">
.slide-guide {

  &__content {
    background-color: $adm-white;
    width: 850px;

    &__description {
      word-break: normal;
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 14px;
    }

    &__tutorial {
      min-height: 330px;
      img, video {
        width: 100%;
      }
    }
  }

  &__footer {
    display: flex;
    width: 100%;
    justify-content: space-between;

    &__continue {
      margin-left: auto;
    }
  }
}

</style>
