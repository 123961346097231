<template>

  <!-- Design System Icon -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Icon
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
      display="block"
    >

      <!-- Preview -->
      <template #preview>
        <div class="mb-16">
          <adm-icon icon="info" size="small" class="mr-16" />
          <adm-icon icon="info" class="mr-16" />
          <adm-icon icon="info" size="big" class="mr-16" />

          <adm-icon icon="info" size="small" type="grey" class="mr-16" />
          <adm-icon icon="info" type="grey" class="mr-16" />
          <adm-icon icon="info" size="big" type="grey" class="mr-16" />

          <adm-icon icon="info" size="small" type="warning" class="mr-16" />
          <adm-icon icon="info" type="warning" class="mr-16" />
          <adm-icon icon="info" size="big" type="warning" class="mr-16" />

          <adm-icon icon="info" size="small" type="danger" class="mr-16" />
          <adm-icon icon="info" type="danger" class="mr-16" />
          <adm-icon icon="info" size="big" type="danger" class="mr-16" />
        </div>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getIconCode({ icon: 'info', size: 'small' }) }}
        <br>
        {{ getIconCode({ icon: 'info' }) }}
        <br>
        {{ getIconCode({ icon: 'info', size: 'big' }) }}
        <br>
        {{ getIconCode({ icon: 'info', size: 'small', type: 'grey' }) }}
        <br>
        {{ getIconCode({ icon: 'info', type: 'grey' }) }}
        <br>
        {{ getIconCode({ icon: 'info', size: 'big', type: 'grey' }) }}
        <br>
        {{ getIconCode({ icon: 'info', size: 'small', type: 'warning' }) }}
        <br>
        {{ getIconCode({ icon: 'info', type: 'warning' }) }}
        <br>
        {{ getIconCode({ icon: 'info', size: 'big', type: 'warning' }) }}
        <br>
        {{ getIconCode({ icon: 'info', size: 'small', type: 'danger' }) }}
        <br>
        {{ getIconCode({ icon: 'info', type: 'danger' }) }}
        <br>
        {{ getIconCode({ icon: 'info', size: 'big', type: 'danger' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Icon Attributes -->
    <design-system-documentation-table :table-data="tableDataAttributes" />
    <!-- /Icon Attributes -->

  </div>
  <!-- /Design System Icon -->

</template>

<script>
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'
import AdmIcon from '@/views/_components/icon/AdmIcon'

export default {
  name: 'DesignSystemIcon',

  components: {
    AdmIcon,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      tableDataAttributes: [
        {
          attribute: 'size',
          description: 'Size of the icon',
          type: 'string',
          values: 'small / medium / big',
          default: 'medium',
        },
        {
          attribute: 'type',
          description: 'Type of the icon which represents predefined colors',
          type: 'string',
          values: 'grey / primary / warning / danger',
          default: 'primary',
        },
        {
          attribute: 'icon',
          description: 'Icon class',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'color',
          description: 'Color of the icon',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'font-size',
          description: 'Size of the icon',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'class',
          description: 'Element class',
          type: 'string',
          values: '-',
          default: '-',
        },
      ]
    }
  },
}
</script>
