export function getFontOptions () {
  return [
    'Inter',
    'Roboto',
    'Montserrat',
    'Poppins',
    'Archivo',
    'Maven Pro',
    'Roboto Slab'
  ]
}
