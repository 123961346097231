<template>

  <!-- Billing Dialog Deactivate Locations -->
  <billing-dialog-deactivate-wrapper
    :items="items"
    :loading="loading"
    :max-items="maxLocations"
    :loading-continue="loadingContinue"
    @change-step="(step) => $emit('change-step', step)"
    @next-deactivate-step="$emit('next-deactivate-step', 'locations', items)"
  >

    <!-- Title -->
    <template #title>
      {{ $t('max_number_of_locations_in_plan') }}
    </template>
    <!-- /Title -->

    <!-- Subtitle -->
    <template #subtitle>
      {{ $t('max_number_of_locations_in_plan_disable') }}
    </template>
    <!-- /Subtitle -->

    <!-- Headline -->
    <template #headline>
      {{ $t('currently_active_locations') }}
    </template>
    <!-- /Headline -->

  </billing-dialog-deactivate-wrapper>
  <!-- /Billing Dialog Deactivate Locations -->

</template>

<script>
import BillingDialogDeactivateWrapper
  from '@/views/Dashboard/BillingDialog/Deactivate/BillingDialogDeactivateWrapper'

export default {
  name: 'BillingDialogDeactivateLocations',

  components: {
    BillingDialogDeactivateWrapper
  },

  props: {
    maxLocations: {
      type: Number,
      default: 0
    },
    loadingContinue: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    }
  },

  data () {
    return {
      loading: false
    }
  }
}
</script>
