export default {
  data () {
    return {
      timeSelectOptions: {
        start: '00:00',
        end: '23:59',
        step: this.secondsToTimeSelectStep(this.getTimeSlotLength())
      }
    }
  },

  methods: {
    calculateDuration (startDateTime, endDateTime) {
      return this.$moment.duration(this.$moment(endDateTime).diff(this.$moment(startDateTime)))
    },

    convertSecondsToDuration (seconds) {
      return this.$moment.duration(parseInt(seconds), 'seconds')
    },

    convertDurationToMinutes (duration) {
      return duration.asMilliseconds() / 1000 / 60
    },

    convertDurationToSeconds (duration) {
      return duration.asMilliseconds() / 1000
    },

    convertDurationToNiceDuration (duration) {
      const hours = Math.floor(duration.asMinutes() / 60)
      const minutes = duration.asMinutes() % 60

      return (hours ? (hours + this.$t('hour_sign') + ' ') : '') + ' ' + (minutes ? (minutes + this.$t('minute_sign')) : '')
    },

    secondsToNiceDuration (seconds, maxLevel = 'month') {
      const month = 2419200
      const week = 604800
      const day = 86400
      const hour = 3600
      const minute = 60

      const levels = {
        month: ['month'],
        week: ['month', 'week']
      }

      let niceTimeString = ''

      let months
      if (levels.month.includes(maxLevel)) {
        months = Math.floor(seconds / month)
        seconds = months ? seconds - (months * month) : seconds
      }

      let weeks
      if (levels.week.includes(maxLevel)) {
        weeks = Math.floor(seconds / week)
        seconds = weeks ? seconds - (weeks * week) : seconds
      }

      const days = Math.floor(seconds / day)
      seconds = days ? seconds - (days * day) : seconds

      const hours = Math.floor(seconds / hour)
      const minutes = Math.floor(seconds / minute % minute)

      if (months) {
        niceTimeString = months + ' ' + this.$tc('month_plural', months)
      }

      if (weeks) {
        niceTimeString =  ' ' + weeks + ' ' + this.$tc('week_plural', weeks)
      }

      if (days) {
        niceTimeString = ' ' + days + ' ' + this.$tc('day_plural', days)
      }

      if (hours) {
        niceTimeString += ' ' + hours + `${this.$t('hour_sign')} `
      }

      if (minutes) {
        niceTimeString += ' ' + minutes + `${this.$t('minute_sign')} `
      }

      return niceTimeString.trim()
    },

    secondsToTimeSelectStep (seconds) {
      const hours = Math.floor(seconds / 3600)
      const minutes = Math.floor(seconds / 60) - hours * 60

      return (hours || '00') + ':' + (minutes || '00')
    },

    getTimeSlotLength () {
      return parseInt(this.$store.state.settings.general.timeSlotStep)
    },

    getPossibleDurationsInSeconds (selected, maxDurationInSeconds = 84600) {
      const durations = []
      for (let i = this.getTimeSlotLength(); i <= maxDurationInSeconds; i += this.getTimeSlotLength()) {
        durations.push(i)
      }

      // If there is no selected value in possible durations, add it to array
      if (selected && durations.indexOf(selected) === -1) {
        durations.push(selected)
        durations.sort(function (a, b) { return a - b })
      }

      return durations
    },

    getDurationsForScheduledNotifications (selected) {
      const durations = [1800, 3600, 7200, 10800, 14400, 18000, 21600, 32400, 43200, 86400, 86400 * 2, 86400 * 3, 86400 * 4, 86400 * 5, 86400 * 6, 86400 * 7]

      // If there is no selected value in possible durations, add it to array
      if (selected && durations.indexOf(selected) === -1) {
        durations.push(selected)
        durations.sort(function (a, b) { return a - b })
      }

      return durations
    },

    getTimeSelectOptionsWithLimits (minTime = null, maxTime = null) {
      return {
        start: '00:00',
        end: '24:00',
        step: this.secondsToTimeSelectStep(this.getTimeSlotLength()),
        selectableRange: '18:30 - 20:30',
        minTime: minTime,
        maxTime: maxTime
      }
    },

    getStringTimeInSeconds (time) {
      return this.$moment(time, 'HH:mm').diff(this.$moment().startOf('day'), 'seconds')
    },
  }
}
