export default {
  paymentSettings: {
    routes: ['settings-general'],
    nextPopover: 'paymentSettingsTab',
  },
  generalSettings: {
    routes: ['settings-general'],
    nextPopover: 'generalSettingsModal',
  },
  customize: {
    routes: ['customize'],
    nextPopover: 'customizeLookAndFeelTab',
  },
  customizeLookAndFeelTab: {
    routes: ['layout-settings'],
    nextPopover: 'customizeModal',
  },
  services: {
    routes: ['services'],
    nextPopover: 'servicesModal',
  },
  locations: {
    routes: ['locations'],
    nextPopover: 'locationsAddButton',
  },
  employees: {
    routes: ['employees'],
    nextPopover: 'employeesModal',
  },
  employeesAddEdit: {
    routes: ['employees-edit'],
    nextPopover: 'employeesAssignedServiceTab',
  },
  paymentSettingsTab: {
    routes: ['settings-payments'],
    nextPopover: 'paymentSettingsModal',
  },
  generalSettingsTab:{
    routes: ['settings-general'],
    nextPopover: 'generalSettingsModal',
  },
  servicesAddButton: {
    routes: ['services-add'],
    nextPopover: 'servicesSaveButton',
  },
  locationsAddButton: {
    routes: ['locations-add'],
    nextPopover: 'locationsModal',
  },
}
