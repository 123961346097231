<template>

  <!-- Design System Input Number -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Input Number
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
    >

      <!-- Preview -->
      <template #preview>
        <adm-input-number
          v-model="basicUsage"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputNumberCode({ 'v-model': 'number' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Disabled -->
    <design-system-element-block
      title="Disabled"
    >

      <!-- Preview -->
      <template #preview>
        <adm-input-number
          v-model="disabled"
          disabled
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputNumberCode({ 'v-model': 'number', 'disabled': true }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Disabled -->

    <!-- Steps -->
    <design-system-element-block
      title="Steps"
    >

      <!-- Preview -->
      <template #preview>
        <adm-input-number
          v-model="steps"
          :step="2"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputNumberCode({ 'v-model': 'number', 'step': 2 }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Steps -->

    <!-- Steps strictly -->
    <design-system-element-block
      title="Steps strictly"
    >

      <!-- Preview -->
      <template #preview>
        <adm-input-number
          v-model="stepsStrictly"
          :step="2"
          :step-strictly="true"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputNumberCode({ 'v-model': 'number', 'step': 2, 'step-strictly': true }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Steps strictly -->

    <!-- Precision -->
    <design-system-element-block
      title="Precision"
    >

      <!-- Preview -->
      <template #preview>
        <adm-input-number
          v-model="precision"
          :precision="2"
          :step="0.1"
          :max="10"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getInputNumberCode({ 'v-model': 'number', 'precision': 2, 'step': '0.1', 'max': '10' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Precision -->

    <!-- Sizes -->
    <design-system-element-block
      title="Sizes"
    >

      <!-- Preview -->
      <template #preview>
        <adm-input-number
          v-model="sizes.default"
          max-width="200px"
        />
        <adm-input-number
          v-model="sizes.medium"
          class="ml-16"
          size="medium"
          max-width="200px"
        />
        <adm-input-number
          v-model="sizes.small"
          class="ml-16"
          size="small"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <template #code>
        {{ getInputNumberCode({ 'v-model': 'number' }) }}
        <br>
        {{ getInputNumberCode({ 'v-model': 'number', 'size': 'medium' }) }}
        <br>
        {{ getInputNumberCode({ 'v-model': 'number', 'size': 'small' }) }}
      </template>

    </design-system-element-block>
    <!-- /Sizes -->

    <!-- Input Number Attributes -->
    <design-system-documentation-table :table-data="tableData" />
    <!-- /Input Number Attributes -->

    <!-- Input Number Methods -->
    <design-system-documentation-table :table-data="tableDataMethods" type="events" title="Methods" />
    <!-- /Input Number Methods -->

    <!-- Input Number Events -->
    <design-system-documentation-table :table-data="tableDataEvents" type="events" title="Events" />
    <!-- /Input Number Events -->

  </div>
  <!-- /Design System Input Number -->

</template>

<script>
import AdmInputNumber from '@/views/_components/input/AdmInputNumber'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'

export default {
  name: 'DesignSystemInput',

  components: {
    AdmInputNumber,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: 1,
      disabled: 1,
      steps: 5,
      stepsStrictly: 2,
      precision: 1,
      sizes: {
        default: 1,
        medium: 1,
        small: 1
      },
      tableData: [
        {
          attribute: 'value / v-model',
          description: 'binding value',
          type: 'string / number',
          values: '-',
          default: '-',
        },
        {
          attribute: 'step',
          description: 'incremental step',
          type: 'number',
          values: '-',
          default: '1',
        },
        {
          attribute: 'step-strictly',
          description: 'whether input value can only be multiple of step',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'min',
          description: 'the minimum allowed value',
          type: 'number',
          values: '-',
          default: '-Infinity',
        },
        {
          attribute: 'max',
          description: 'the maximum allowed value',
          type: 'number',
          values: '-',
          default: 'Infinity',
        },
        {
          attribute: 'size',
          description: 'size of the component',
          type: 'string',
          values: 'default / medium / small',
          default: 'default',
        },
        {
          attribute: 'disabled',
          description: 'whether the component is disabled',
          type: 'boolean',
          values: 'true / false',
          default: 'false',
        },
        {
          attribute: 'placeholder',
          description: 'placeholder in input',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'name',
          description: 'same as name in native input\t',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'max-width',
          description: 'max width of the input, e.g. \'200px\'',
          type: 'string',
          values: '-',
          default: '-',
        },
      ],
      tableDataMethods: [
        {
          name: 'focus',
          description: 'focus the input element',
          parameters: '-'
        },
        {
          name: 'select',
          description: 'select the text in input element',
          parameters: '-'
        },
      ],
      tableDataEvents: [
        {
          name: 'change',
          description: 'triggers when the value changes',
          parameters: 'currentValue, oldValue'
        },
        {
          name: 'blur',
          description: 'triggers when Input blurs',
          parameters: '(event: Event)'
        },
        {
          name: 'focus',
          description: 'triggers when Input focuses',
          parameters: '(event: Event)'
        },
      ],
    }
  },
}
</script>

