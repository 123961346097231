<template>

  <!-- Billing Dialog Deactivate Wrapper -->
  <div class="ad-billing-dialog-deactivate-wrapper">

    <!-- Title -->
    <div class="ad-billing-dialog-deactivate-wrapper__title">
      <slot name="title" />
    </div>
    <!-- /Title -->

    <!-- Subtitle -->
    <div class="ad-billing-dialog-deactivate-wrapper__subtitle">
      <slot name="subtitle" />
    </div>
    <!-- /Subtitle -->

    <!-- Active Count -->
    <div class="ad-billing-dialog-deactivate-wrapper__active-count">

      <!-- Headline -->
      <div class="ad-billing-dialog-deactivate-wrapper__active-count__headline">
        <slot name="headline" />
      </div>
      <!-- /Headline -->

      <!-- Number -->
      <div
        v-if="loading === false" class="ad-billing-dialog-deactivate-wrapper__active-count__number"
        :class="{ 'error': activeItems > maxItems }"
      >
        <div v-if="activeItems > maxItems" class="ad-billing-dialog-deactivate-wrapper__active-count__number__icon">
          <adm-icon icon="danger-filled" :color="$colors['red-900']" size="big" class="mr-8" />
        </div>
        <div v-if="activeItems <= maxItems" class="ad-billing-dialog-deactivate-wrapper__active-count__number__icon">
          <adm-icon icon="check-filled" :color="$colors['green-900']" size="big" class="mr-8" />
        </div>
        <span>
          {{ activeItems }}/{{ maxItems }}
        </span>
      </div>
      <!-- /Number -->

    </div>
    <!-- /Active Count -->

    <!-- Items Container -->
    <div>

      <!-- Items -->
      <template v-if="loading === false">
        <div
          v-for="(item, index) in items"
          :key="index"
          class="ad-billing-dialog-deactivate-wrapper__item"
          :class="{ disabled: item.disabled }"
        >

          <!-- Icon -->
          <div class="ad-billing-dialog-deactivate-wrapper__item__icon">
            <img
              v-if="showAvatar === false" :src="item.icon"
              class="ad-billing-dialog-deactivate-wrapper__item__icon__image"
            >
            <adm-avatar
              v-else
              class="ad-billing-dialog-deactivate-wrapper__item__icon__avatar"
              :background-color="item.color ? item.color : null"
              :name="item.name"
              :size="40"
              :src="item.icon"
            />
          </div>
          <!-- /Icon -->

          <!-- Name -->
          <div class="ad-billing-dialog-deactivate-wrapper__item__name">
            <span>
              {{ item.name }}
            </span>
          </div>
          <!-- /Name -->

          <!-- Switch -->
          <div class="ad-billing-dialog-deactivate-wrapper__item__switch">
            <adm-switch v-model="item.value" :disabled="item.disabled" />
          </div>
          <!-- /Switch -->

        </div>

      </template>
      <!-- /Items -->

      <!-- Skeleton Elements -->
      <template v-else>
        <adm-skeleton-element v-for="n in 4" :key="n" :height="72" :margin-bottom="12" />
      </template>
      <!-- /Skeleton Elements -->

    </div>
    <!-- /Items -->

    <!-- Continue -->
    <div class="ad-billing-dialog-deactivate-wrapper__continue">
      <adm-button
        :disabled="isContinueDisabled"
        :loading="loadingContinue"
        :full-width="true"
        @click="onContinue"
      >
        {{ $t('continue') }}
      </adm-button>
    </div>
    <!-- /Continue -->

    <!-- Back -->
    <div
      v-if="tenantInfo.subscription && tenantInfo.subscription.isAppSumo === false"
      class="ad-billing-dialog-deactivate-wrapper__back"
    >
      <a @click="$emit('change-step', 'plans')">
        <svg width="14" height="13" viewBox="0 0 14 13" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:class="'back-to-calendar__icon'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M7.262 12.71l.547-.519c.136-.136.136-.355 0-.464L3.57 7.46h9.352a.333.333 0 00.328-.328v-.766a.353.353 0 00-.328-.328H3.57L7.81 1.801c.136-.11.136-.328 0-.465l-.547-.52c-.11-.136-.328-.136-.465 0L1.082 6.531a.315.315 0 000 .465l5.715 5.715c.137.137.355.137.465 0z" fill="#868E96"/></svg>
        <span>
          {{ $t('back_to_plan_selection') }}
        </span>
      </a>
    </div>
    <!-- /Back -->

  </div>
  <!-- /Billing Dialog Deactivate Wrapper -->

</template>

<script>
import AdmAvatar from '@/views/_components/avatar/AdmAvatar'
import AdmButton from '@/views/_components/button/AdmButton'
import AdmIcon from '@/views/_components/icon/AdmIcon'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import AdmSwitch from '@/views/_components/switch/AdmSwitch'
import { mapState } from 'vuex'

export default {
  name: 'BillingDialogDeactivateWrapper',

  components: {
    AdmAvatar,
    AdmButton,
    AdmIcon,
    AdmSkeletonElement,
    AdmSwitch
  },

  props: {
    items: {
      type: Array,
      default: () => ([]),
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: true
    },
    maxItems: {
      type: Number,
      default: 0,
      required: true
    },
    showAvatar: {
      type: Boolean,
      default: true
    },
    loadingContinue: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState({
      tenantInfo: state => state.billing.tenantInfo
    }),

    isContinueDisabled () {
      return this.loading === true || this.activeItems > this.maxItems
    },

    activeItems () {
      return this.items.filter(item => item.value === true).length
    }
  },

  methods: {
    onContinue () {
      this.$emit('next-deactivate-step')
    }
  }
}
</script>

<style lang="scss" scoped>
// Billing Dialog Deactivate Wrapper
.ad-billing-dialog-deactivate-wrapper {
  width: 484px;
  margin: 1.25rem auto 0;

  @include phone-down {
    width: auto;
  }

  // Title
  &__title {
    font-weight: 600;
    font-size: 24px;
    line-height: 32px;
  }

  // Subtitle
  &__subtitle {
    margin-top: 0.5rem;
    font-weight: 500;
    opacity: 0.8;
    padding-bottom: 0.75rem;
    border-bottom: 1px solid $shade-300;
    margin-bottom: 1rem;
  }

  // Active Count
  &__active-count {
    display: flex;
    align-items: center;
    margin-bottom: 0.75rem;

    // Headline
    &__headline {
      font-weight: 500;
    }

    // Number
    &__number {
      display: flex;
      align-items: center;
      margin-left: auto;
      order: 2;
      font-weight: 500;
      font-size: 18px;

      &__icon {
        display: flex;
        align-items: center;
      }

      span {
        min-width: 40px;
        text-align: right;
      }

      &.error {
        color: $red-900;
      }
    }
  }

  // Item
  &__item {
    padding: 1rem 1.25rem;
    background: $adm-white;
    box-shadow: 0 1px 1px rgba(0, 20, 60, 0.21), 0 2px 7px -1px rgba(0, 0, 0, 0.12);
    border-radius: 6px;
    margin-bottom: 0.75rem;
    display: flex;
    align-items: center;
    position: relative;

    &.disabled :before {
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background: rgba($adm-white, 0.1);
    }

    &:last-of-type {
      margin-bottom: 0;
    }

    // Icon
    &__icon {
      display: flex;
      margin-right: 1rem;

      img {
        height: 40px;
        width: 40px;
      }
    }

    // Name
    &__name {
      font-weight: 600;
      font-size: 18px;
      max-width: 333px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    // Switch
    &__switch {
      margin-left: auto;
      order: 2;
    }
  }

  // Continue
  &__continue {
    margin-top: 1.25rem;
  }

  // Back
  &__back {
    margin-top: 0.75rem;

    a {
      display: inline-flex;
      align-items: center;
    }
  }
}
</style>
