<template>

  <!-- Design System Switch -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Switch
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
    >

      <!-- Preview -->
      <template #preview>
        <adm-switch
          v-model="basicUsage"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSwitchCode({ 'v-model': 'basicUsage' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Label usage -->
    <design-system-element-block
      title="Usage with Label"
    >

      <!-- Preview -->
      <template #preview>
        <adm-switch
          v-model="labelUsage"
          label="Option"
        />

        <adm-switch
          v-model="labelUsage"
          label="Option"
          caption-position="left"
          class="ml-12"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSwitchCode({ 'v-model': 'labelUsage', 'label': 'Option' }) }}
        <br>
        {{ getSwitchCode({ 'v-model': 'labelUsage', 'label': 'Option', 'caption-position':'left' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Label usage -->

    <!-- Disabled -->
    <design-system-element-block
      title="Disabled State"
      description="Disabled state for switch."
    >

      <!-- Preview -->
      <template #preview>
        <adm-switch
          v-model="disabled"
          label="Option"
          disabled
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSwitchCode({ 'v-model': 'disabled', 'label': 'Option', 'disabled': true }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Disabled -->

    <!-- Description -->
    <design-system-element-block
      title="Description"
      description="Switch with description"
    >

      <!-- Preview -->
      <template #preview>
        <adm-switch
          v-model="description"
          label="Option"
          description="Switch with description"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSwitchCode({ 'v-model': 'description', 'label': 'Option', 'description': 'Switch with description' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Description -->

    <!-- Switch Attributes -->
    <design-system-documentation-table
      :table-data="tableData"
      title="Switch attributes"
    />
    <!-- /Switch Attributes -->

    <!-- Switch Events -->
    <design-system-documentation-table
      :table-data="tableDataEvents"
      type="events"
      title="Switch events"
    />
    <!-- /Switch Events -->

    <!-- Switch Methods -->
    <design-system-documentation-table
      :table-data="tableDataMethods"
      type="events"
      title="Switch methods"
    />
    <!-- /Switch Methods -->

  </div>
  <!-- /Design System Switch -->

</template>

<script>
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import AdmSwitch from '@/views/_components/switch/AdmSwitch'
import mixinDesignSystem from '@/mixins/page/designSystem'

export default {
  name: 'DesignSystemSwitch',

  components: {
    AdmSwitch,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: true,
      labelUsage: true,
      disabled: true,
      description: true,
      tableData: [
        {
          attribute: 'value / v-model',
          description: 'binding value',
          type: 'string / number / boolean',
          values: '-',
          default: '-',
        },
        {
          attribute: 'label',
          description: 'the label of Switch',
          type: 'string / number / boolean',
          values: '-',
          default: '-',
        },
        {
          attribute: 'caption-position',
          description: 'the position of the Switch label',
          type: 'string',
          values: 'left / right',
          default: 'right',
        },
        {
          attribute: 'disabled',
          description: 'whether Switch is disabled',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'name',
          description: 'native \'name\' attribute',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'description',
          description: 'description text below the Switch',
          type: 'string',
          values: '-',
          default: '-',
        },
      ],

      tableDataEvents: [
        {
          name: 'change',
          description: 'triggers when the bound value changes',
          parameters: 'the updated value'
        },
      ],

      tableDataMethods : [
        {
          name: 'focus',
          description: 'focus the Switch component',
          parameters: '-'
        },
      ],
    }
  }
}
</script>
