<template>
  <div class="adm-input-number-wrapper" :style="{ maxWidth }">
    <div
      class="adm-input-number"
      :class="[
        size ? 'adm-input-number__size__' + size : '',
      ]"
    >
      <div
        :class="[
          'el-input-number',
          inputNumberSize ? 'el-input-number--' + inputNumberSize : '',
          { 'is-disabled': inputNumberDisabled },
          { 'is-controls-right': controlsAtRight }
        ]"
        @dragstart.prevent
      >
        <template v-if="controls">
          <span
            v-repeat-click="decrease"
            class="el-input-number__decrease"
            role="button"
            :class="{'is-disabled': minDisabled}"
            @keydown.enter="decrease"
          >
            <i class="tz-minus" />
          </span>
          <span
            v-repeat-click="increase"
            class="el-input-number__increase"
            role="button"
            :class="{'is-disabled': maxDisabled}"
            @keydown.enter="increase"
          >
            <i class="tz-plus" />
          </span>
        </template>

        <el-input
          ref="input"
          :value="displayValue"
          :placeholder="placeholder"
          :disabled="inputNumberDisabled"
          :size="inputNumberSize"
          :max="max"
          :min="min"
          :name="name"
          @keydown.up.native.prevent="increase"
          @keydown.down.native.prevent="decrease"
          @blur="handleBlur"
          @focus="handleFocus"
          @input="handleInput"
          @change="handleInput"
        />
      </div>
    </div>
  </div>
</template>
<script>
  import ElInput from 'element-ui/packages/input';
  import Focus from 'element-ui/src/mixins/focus';
  import RepeatClick from 'element-ui/src/directives/repeat-click';

  export default {
    name: 'AdmInputNumber',
    directives: {
      repeatClick: RepeatClick
    },
    components: {
      ElInput
    },
    mixins: [Focus('input')],
    inject: {
      elForm: {
        default: ''
      },
      elFormItem: {
        default: ''
      }
    },
    props: {
      id: {
        type: String,
        default: ''
      },
      value: {
        type: [String, Number],
        default: ''
      },
      step: {
        type: Number,
        default: 1
      },
      stepStrictly: {
        type: Boolean,
        default: false
      },
      min: {
        type: Number,
        default: -Infinity
      },
      max: {
        type: Number,
        default: Infinity
      },
      size: {
        type: String,
        default: 'default',
        validator (value) {
          return ['default', 'medium', 'small'].indexOf(value) !== -1
        }
      },
      disabled: Boolean,
      placeholder: {
        type: String,
        default: ''
      },
      precision: {
        type: Number,
        validator(val) {
          return val >= 0 && val === parseInt(val, 10);
        },
        default: 0,
      },
      name: {
        type: String,
        default: ''
      },
      maxWidth: {
        type: String,
        default: null
      },
      controls: {
        type: Boolean,
        default: true,
      }
    },
    data() {
      return {
        currentValue: 0,
        cursorPosition: null,
      };
    },
    computed: {
      minDisabled() {
        return this._decrease(this.value, this.step) < this.min;
      },
      maxDisabled() {
        return this._increase(this.value, this.step) > this.max;
      },
      numPrecision() {
        const { value, step, getPrecision, precision } = this;
        const stepPrecision = getPrecision(step);
        if (precision !== undefined) {
          if (stepPrecision > precision) {
            console.warn('[Element Warn][InputNumber]precision should not be less than the decimal places of step');
          }
          return precision;
        } else {
          return Math.max(getPrecision(value), stepPrecision);
        }
      },
      controlsAtRight() {
        return this.controls && this.controlsPosition === 'right';
      },
      _elFormItemSize() {
        return (this.elFormItem || {}).elFormItemSize;
      },
      inputNumberSize() {
        return this.size || this._elFormItemSize || (this.$ELEMENT || {}).size;
      },
      inputNumberDisabled() {
        return this.disabled || !!(this.elForm || {}).disabled;
      },
      displayValue() {
        let currentValue = this.currentValue;

        if (typeof currentValue === 'number') {
          if (this.stepStrictly) {
            const stepPrecision = this.getPrecision(this.step);
            const precisionFactor = Math.pow(10, stepPrecision);
            currentValue = Math.round(currentValue / this.step) * precisionFactor * this.step / precisionFactor;
          }

          if (this.precision !== undefined) {
            currentValue = currentValue.toFixed(this.precision);
          }
        }

        return currentValue;
      }
    },
    watch: {
      value: {
        immediate: true,
        handler(value) {
          let newVal = [undefined, ''].includes(value) ? undefined : Number(value);
          if (newVal !== undefined) {
            if (isNaN(newVal)) {
              return;
            }

            if (this.stepStrictly) {
              const stepPrecision = this.getPrecision(this.step);
              const precisionFactor = Math.pow(10, stepPrecision);
              newVal = Math.round(newVal / this.step) * precisionFactor * this.step / precisionFactor;
            }

            if (this.precision !== undefined) {
              newVal = this.toPrecision(newVal, this.precision);
            }
          }
          if (newVal >= this.max) newVal = this.max;
          if (newVal <= this.min) newVal = this.min;
          this.currentValue = newVal;
          this.$emit('input', newVal);
        }
      }
    },
    mounted() {
      let innerInput = this.$refs.input.$refs.input;
      innerInput.setAttribute('role', 'spinbutton');
      innerInput.setAttribute('aria-valuemax', this.max);
      innerInput.setAttribute('aria-valuemin', this.min);
      innerInput.setAttribute('aria-valuenow', this.currentValue);
      innerInput.setAttribute('aria-disabled', this.inputNumberDisabled);
    },

    updated() {
      if (!this.$refs || !this.$refs.input) return;
      const innerInput = this.$refs.input.$refs.input;
      innerInput.setSelectionRange(this.cursorPosition, this.cursorPosition)
      innerInput.setAttribute('aria-valuenow', this.currentValue);
    },

    methods: {
      toPrecision(num, precision) {
        if (precision === undefined) precision = this.numPrecision;
        return parseFloat(Math.round(num * Math.pow(10, precision)) / Math.pow(10, precision));
      },
      getPrecision(value) {
        if (value === undefined) return 0;
        const valueString = value.toString();
        const dotPosition = valueString.indexOf('.');
        let precision = 0;
        if (dotPosition !== -1) {
          precision = valueString.length - dotPosition - 1;
        }
        return precision;
      },
      _increase(val, step) {
        if (typeof val !== 'number' && val !== undefined) return this.currentValue;

        const precisionFactor = Math.pow(10, this.numPrecision);
        // Solve the accuracy problem of JS decimal calculation by converting the value to integer.
        return this.toPrecision((precisionFactor * val + precisionFactor * step) / precisionFactor);
      },
      _decrease(val, step) {
        if (typeof val !== 'number' && val !== undefined) return this.currentValue;

        const precisionFactor = Math.pow(10, this.numPrecision);

        return this.toPrecision((precisionFactor * val - precisionFactor * step) / precisionFactor);
      },
      increase() {
        if (this.inputNumberDisabled || this.maxDisabled) return;
        const value = this.value || 0;
        const newVal = this._increase(value, this.step);
        this.setCurrentValue(newVal);
      },
      decrease() {
        if (this.inputNumberDisabled || this.minDisabled) return;
        const value = this.value || 0;
        const newVal = this._decrease(value, this.step);
        this.setCurrentValue(newVal);
      },
      handleBlur(event) {
        if (!this.currentValue) {
          this.handleInput(this.min)
        }

        this.$emit('blur', event);
      },
      handleFocus(event) {
        this.$emit('focus', event);
      },
      setCurrentValue(newVal) {
        const oldVal = this.currentValue;
        if (typeof newVal === 'number' && this.precision !== undefined) {
          newVal = this.toPrecision(newVal, this.precision);
        }
        if (newVal >= this.max) newVal = this.max;
        if (newVal <= this.min) newVal = this.min;
        if (oldVal === newVal) return;
        this.$emit('input', newVal);
        this.$emit('change', newVal, oldVal);
        this.currentValue = newVal;
      },
      handleInput(value) {
        let newVal = value === '' ? undefined : Number(value);
        if (!isNaN(newVal) || value === '') {
          this.setCurrentValue(newVal);
        }

        const innerInput = this?.$refs?.input?.$refs?.input
        this.cursorPosition = innerInput.selectionStart
      },
      select() {
        this.$refs.input.select();
      }
    }
  };
</script>

<style lang="scss">
// Input Number Wrapper
.adm-input-number-wrapper {
  width: 100%;

  // Input Number
  .adm-input-number {

    // Size
    &__size {

      // Default
      &__default {

        // Decrease & Increase
        .el-input-number__decrease, .el-input-number__increase {
          height: 28px;
          width: 28px;
        }

        // Decrease
        .el-input-number__decrease {
          top: 6px;
          left: 6px;
        }

        // Increase
        .el-input-number__increase {
          top: 6px;
          right: 6px;
        }

        // Input
        .el-input__inner {
          padding-left: 40px;
          padding-right: 40px;
          font-size: 15px;
          line-height: 24px;
          color: $shade-900;
        }
      }

      // Medium
      &__medium {

        // Decrease & Increase
        .el-input-number__decrease, .el-input-number__increase {
          height: 24px;
          width: 24px;
        }

        // Decrease
        .el-input-number__decrease {
          top: 6px;
          left: 6px;
        }

        // Increase
        .el-input-number__increase {
          top: 6px;
          right: 6px;
        }

        // Input
        .el-input__inner {
          padding-left: 36px;
          padding-right: 36px;
          font-size: 15px;
          line-height: 24px;
          color: $shade-900;
        }
      }

      // Small
      &__small {

        // Decrease & Increase
        .el-input-number__decrease, .el-input-number__increase {
          height: 24px;
          width: 24px;
        }

        // Decrease
        .el-input-number__decrease {
          top: 4px;
          left: 4px;
        }

        // Increase
        .el-input-number__increase {
          top: 4px;
          right: 4px;
        }

        // Input
        .el-input__inner {
          padding-left: 32px;
          padding-right: 32px;
          font-size: 14px;
          line-height: 20px;
          color: $shade-900;
        }
      }
    }
  }

  // Element Input Number
  .el-input-number {
    width: 100%;

    // Decrease & Increase
    &__decrease, &__increase {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $adm-white;
      border-radius: 4px;

      // Hover - Input Rules
      &:hover:not(.is-disabled) ~ .el-input .el-input__inner:not(.is-disabled):not(:focus) {
        box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
        border-color: $shade-300;
      }

      // Hover - Button Rules
      &:hover:not(.is-disabled) {
        background-color: $shade-250;
      }

      // Active
      &:active:not(.is-disabled) {
        background-color: $shade-300;
      }

      // Icon
      i {
        font-size: 20px;

        &:before {
          color: $shade-700;
        }
      }

      // Disabled
      &.is-disabled i:before {
        color: $shade-400;
      }
    }

    // Decrease
    &__decrease {
      border-right: 0;
    }

    // Increase
    &__increase {
      border-left: 0;
    }

    // Input
    .el-input__inner {
      cursor: text;
      border-radius: 7px;
      border: 1px solid $shade-300;
      -webkit-transition: box-shadow 0.15s;
      transition: box-shadow 0.15s;

      // Hover
      &:hover {
        box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
      }

      // Focus
      &:focus {
        border: 1px solid var(--primary-900);
        box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
      }
    }

    // Disabled
    &.is-disabled {

      // Decrease & Increase
      .el-input-number__decrease, .el-input-number__increase {
        background-color: $shade-150;

        // Hover
        &:hover ~ .el-input .el-input__inner {
          box-shadow: none;
          border-color: $shade-300;
        }

        // Icon
        i:before {
          color: $shade-400;
        }
      }

      // Input
      .el-input__inner {
        cursor: not-allowed;
        background-color: $shade-150;
        color: $shade-600;

        // Hover
        &:hover {
          box-shadow: none;
        }

        // Focus
        &:focus {
          border-color: $shade-300;
          box-shadow: none;
        }
      }
    }
  }
}
</style>
