<template>

  <!-- Location Info -->
  <div class="section section-divided">

    <!-- Title -->
    <div class="section-title flex-center">
      <adm-icon icon="locations" />
      <span>{{ $t('location') }}</span>
    </div>
    <!-- /Title -->

    <!-- Location -->
    <div class="flex-center">

      <!-- Name & Address -->
      <div>

        <!-- Name -->
        <h6 v-if="showData">
          {{ location.name }}
        </h6>
        <adm-skeleton-element v-else :height="16" :margin-bottom="8" :width="120" />
        <!-- /Name -->

        <!-- Address -->
        <span v-if="showData" class="mr-16 section-text">
          {{ location.address }}
        </span>
        <adm-skeleton-element v-else :height="14" :width="160" />
        <!-- /Address -->

      </div>
      <!-- /Name & Address -->

    </div>
    <!-- /Location -->

  </div>
  <!-- /Location Info -->

</template>

<script>
import AdmIcon from '@/views/_components/icon/AdmIcon'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'

export default {
  name: 'LocationInfo',

  components: {
    AdmIcon,
    AdmSkeletonElement,
  },

  props: {
    location: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    showData () {
      return !!this.location
    }
  }
}
</script>
