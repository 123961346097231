var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-dropdown-item',{staticClass:"adm-dropdown-item",class:[
    {
      'is-divided': _vm.divided || _vm.dividedDashed,
      'is-divided-dashed': _vm.dividedDashed,
    }
  ],attrs:{"command":_vm.command,"disabled":_vm.disabled,"divided":_vm.divided || _vm.dividedDashed}},[_c('div',{staticClass:"adm-dropdown-item__wrapper",class:[
      {
        'is-disabled': _vm.disabled,
        'is-red': _vm.red,
        'is-blue': _vm.blue,
        'is-with-icon': Boolean(_vm.icon),
        'is-active': _vm.isActive,
        'is-with-active-icon': _vm.isWithActiveIcon
      }
    ]},[(_vm.icon)?_c('div',{staticClass:"adm-dropdown-item__icon",class:{'is-disabled': _vm.disabled }},[_c('i',{class:'tz-' + _vm.icon})]):_vm._e(),_c('span',{staticClass:"adm-dropdown-item__label"},[_vm._t("default")],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }