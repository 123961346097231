import { isRequestCanceled } from '@/utils/api'

export default {
  methods: {
    async deleteCustomer (id, signal) {
      try {
        let responseDelete = null

        const responseEffect = await this.$http.get(
          '/api/v1/users/effect/' + id,
          { signal: signal }
        )

        if (responseEffect.status !== 200 || responseEffect.data.status === 'error') {
          return this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        }

        const message = this.generateDeleteCustomerMessage(responseEffect.data.payload.count)

        await this.$admConfirm.show(
          this.$t('delete_customer'),
          this.$t('delete_sure_question_customer') + ' ' + message,
          {
            confirmButtonText: message ? this.$t('delete_anyway') : this.$t('delete'),
          }
        ).then(async () => {
          responseDelete = await this.$http.delete(
            '/api/v1/users/' + id,
          )
        }).catch((e) => {
          if (isRequestCanceled(e)) return
          if (e.response.status !== 200 || e.response.data.status === 'error') {
            return this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
          }
        })

        return responseDelete
      } catch (e) {}
    },

    generateDeleteCustomerMessage (count) {
      if (count.future > 0 && count.past === 0) {
        return count.future + ' ' + this.$t('future') + ' ' +
          (count.future > 1 ? this.$t('bookings_l') : this.$t('booking_l')) + ' ' +
          this.$t('delete_customer_warning')
      }

      if (count.future === 0 && count.past > 0) {
        return count.past + ' ' + this.$t('past') + ' ' +
          (count.past > 1 ? this.$t('bookings_l') : this.$t('booking_l')) + ' ' +
          this.$t('delete_customer_warning')
      }

      if (count.future > 0 && count.past > 0) {
        return count.future + ' ' + this.$t('future') + ' ' + this.$t('and') + ' ' +
          count.past + ' ' + this.$t('past') + ' ' + this.$t('bookings_l') + ' ' +
          this.$t('delete_customer_warning')
      }

      return ''
    },

    toggleCustomerStatus (customer) {
      try {
        if (customer.email || customer.phone) {
          this.$admConfirm.show(
            customer.status === 'blocked' ? this.$t('unblock_customer') :this.$t('block_customer'),
            customer.status === 'blocked' ? this.$t('are_you_sure_that_you_want_to_unblock_this_customer') : this.$t('are_you_sure_that_you_want_to_block_this_customer'),
            {
              confirmButtonText: customer.status === 'blocked' ? this.$t('unblock') : this.$t('block'), actionColor: customer.status === 'blocked' ? 'blue' : 'red'
            }
          ).then(() => {
            this.$http.post('/api/v1/users/customers/status/' + customer.id)
              .then((response) => {
                this.$message({ message: response.data.message, type: 'success', showClose: true })
                this.getCustomers(true)
              })
          })
        } else {
          this.$message({ message: this.$t('this_customer_has_no_email_or_phone_and_cannot_be_blocked'), type: 'error', showClose: true, duration: 5000})
        }
      } catch (e) {
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      }
    },
  }
}
