<template>

  <!-- Billing Dialog Free Downgrade -->
  <div class="ad-billing-dialog-free-downgrade">

    <!-- Body -->
    <div class="ad-billing-dialog-free-downgrade__body">

      <!-- Warning Icon -->
      <div class="ad-billing-dialog-free-downgrade__icon">
        <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><rect opacity=".2" width="64" height="64" rx="32" fill="#FAB005"/><path d="M47.288 38.107L36.038 19.45a5.03 5.03 0 00-8.476 0l-11.25 18.656a4.4 4.4 0 00-.073 4.4A4.943 4.943 0 0020.55 45H43.05a4.942 4.942 0 004.312-2.435 4.4 4.4 0 00-.074-4.458zM31.8 39.133a1.467 1.467 0 110-2.934 1.467 1.467 0 010 2.934zm1.467-5.866a1.467 1.467 0 01-2.934 0V27.4a1.467 1.467 0 112.934 0v5.867z" fill="#FAB005"/></svg>
      </div>
      <!-- /Warning Icon -->

      <!-- Title -->
      <div class="ad-billing-dialog-free-downgrade__title">
        <span>
          {{ $t('are_you_sure_you_want_to_change_plan') }}
        </span>
      </div>
      <!-- /Title -->

      <!-- Subtitle -->
      <div class="ad-billing-dialog-free-downgrade__subtitle">
        <span>
          {{ $transWithBusinessName($t('downgrade_free_subtitle')) }}
        </span>
      </div>
      <!-- /Subtitle -->

      <!-- Cancel & Downgrade -->
      <div class="ad-billing-dialog-free-downgrade__actions">

        <!-- Cancel -->
        <adm-button @click="onCancel">
          {{ $t('cancel') }}
        </adm-button>
        <!-- /Cancel -->

        <!-- Downgrade -->
        <adm-button color="grey" :loading="loading" @click="onDowngrade">
          {{ $t('downgrade') }}
        </adm-button>
        <!-- /Downgrade -->

      </div>
      <!-- /Cancel & Downgrade -->

    </div>
    <!-- /Body -->

    <!-- Footer - TODO - Move to component (duplicate) -->
    <div class="ad-billing-dialog-free-downgrade__footer">

      <!-- Trafft -->
      <span class="ad-billing-dialog-free-downgrade__footer__trafft">
        © Trafft {{ $moment().format('Y') }}
      </span>
      <!-- /Trafft -->

      <!-- Links -->
      <span class="ad-billing-dialog-free-downgrade__footer__links">
        <a href="https://trafft.com/terms-of-service/" target="_blank">
          {{ $t('terms_of_service') }}
        </a>
        <span>
          -
        </span>
        <a @click="openBeacon()">
          {{ $t('contact_us') }}
        </a>
      </span>
      <!-- /Links -->

    </div>
    <!-- /Footer -->

  </div>
  <!-- /Billing Dialog Free Downgrade -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import mixinPlans from '@/mixins/plans/plans'
import { mapState } from 'vuex'

export default {
  name: 'BillingDialogFreeDowngrade',

  components: {
    AdmButton
  },

  mixins: [
    mixinPlans
  ],

  data () {
    return {
      loading: false
    }
  },

  computed: {
    ...mapState({
      selectedPlan: state => state.billing.selectedPlan,
      tenantInfo: state => state.billing.tenantInfo
    }),

    shouldDeactivateEntity () {
      const statistics = this.tenantInfo.statistics

      return (this.selectedPlan.maxNumberOfCustomFeatures && statistics.features.value > this.selectedPlan.maxNumberOfCustomFeatures) ||
        (this.selectedPlan.maxNumberOfEmployees && statistics.employees.value > this.selectedPlan.maxNumberOfEmployees)
    }
  },

  methods: {
    onCancel () {
      this.$emit('change-step', 'plans')
    },

    async onDowngrade () {
      this.loading = true
      await this.downgradeToFree()
      this.loading = false
    },

    openBeacon () {
      Beacon('open')
      Beacon('navigate', '/ask/')
    }
  }
}
</script>

<style lang="scss" scoped>
// Billing Dialog Free Downgrade
.ad-billing-dialog-free-downgrade {
  width: 368px;
  background: $adm-white;
  margin: 1.25rem auto 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
  border-radius: 8px;

  // Body
  &__body {
    padding: 0 48px;
  }

  // Icon
  &__icon {
    display: flex;
    justify-content: center;
    padding-top: 1.5rem;
  }

  // Title
  &__title {
    margin-top: 1rem;
    padding-bottom: 1.25rem;
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    text-align: center;
  }

  // Subtitle
  &__subtitle {
    font-weight: 500;
    display: flex;
    text-align: center;
    padding-bottom: 1.5rem;
    opacity: 0.8;
  }

  // Actions
  &__actions {
    display: flex;
    justify-content: center;
    margin-bottom: 1.5rem;
    gap: 16px;
    flex-wrap: wrap;
  }

  // Footer
  &__footer {
    border-top: 1px solid $shade-300;
    padding: 18px 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;

    // Trafft
    &__trafft {
      font-weight: normal;
      font-size: 14px;
      color: $shade-500;
      margin-bottom: 0.5rem;
    }

    // Links
    &__links {
      font-weight: normal;
      display: flex;

      span {
        text-align: center;
        color: $shade-500;
        margin: 0 0.75rem;
      }
    }
  }
}
</style>
