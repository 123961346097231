<template>

  <!-- Time Select -->
  <div>
    <adm-select
      ref="admSelect"
      slot="reference"
      :v-model="model"
      :picker-options="pickerOptions"
      v-bind="$props"
      :placeholder="$t('select_time')"
      icon-start="hours"
      @change="(eventValue) => $emit('change', eventValue)"
      @input="(eventValue) => $emit('input', eventValue)"
      @visible-change="(eventValue) => $emit('visible-change', eventValue)"
    >
      <adm-option
        v-for="item in items"
        :key="item.value"
        :ref="item.value"
        :label="item.label"
        :value="item.value"
        :disabled="item.disabled"
      >
        {{ item.label }}
      </adm-option>
    </adm-select>
  </div>
  <!-- /Time Select -->

</template>

<script>
import AdmSelect from '@/views/_components/select/AdmSelect'
import AdmOption from '@/views/_components/select/AdmOption'
import mixinDateTime from '@/mixins/common/datetime'
import { compareTime, nextTime } from '@/utils/timeSelect'

export default {
  name: 'AdmTimeSelect',

  components: { AdmOption, AdmSelect },

  mixins: [mixinDateTime],

  props: {
    pickerOptions: {
      type: Object,
      required: true,
      default: () => {}
    },

    maxWidth: {
      type: String,
      default: null
    },

    clearable: {
      type: Boolean,
      default: true
    },

    size: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'medium', 'small'].indexOf(value) !== -1
      }
    },

    value: {
      type: String,
      default: ''
    },
  },

  data: function () {
    return {
      selfModel: ''
    }
  },

  computed: {
    model: {
      get () {
        return this.value !== undefined ? this.value : this.selfModel
      },
      set (val) {
        this.selfModel = val
      }
    },

    items() {
      const start = this.pickerOptions.start;
      const end = this.pickerOptions.end;
      const step = this.pickerOptions.step;

      const result = [];

      if (start && end && step) {
        let current = start;
        while (compareTime(current, end) <= 0) {
          result.push({
            value: current,
            label: current !== '24:00' ? this.formatTime(current) : this.formatEndTime(current),
            disabled: compareTime(current, this.pickerOptions.minTime || '-1:-1') <= 0 ||
              compareTime(current, this.pickerOptions.maxTime || '100:100') >= 0
          });
          current = nextTime(current, step);
        }
      }

      return result;
    }
  }
}
</script>
