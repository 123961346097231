<template>
  <div class="social-media-images">

    <!-- Social Media Images Description -->
    <div class="social-media-images__desc">
      {{ $t('select_story_background_desc') }}
    </div>
    <!-- /Social Media Images Description -->

    <!-- Social Media Images Info -->
    <adm-alert class="social-media-images__info">
      <template #title>
        {{ $t('select_story_background_info') }}
      </template>
    </adm-alert>
    <!-- /Social Media Images Info -->

    <!-- Social Media Images List -->
    <div class="social-media-images__list">
      <template v-if="loading">
        <adm-skeleton-element v-for="i in 8" :key="i" class="social-media-images__list__item" :width="138" />
      </template>
      <template v-else>
        <template v-for="(image, index) in images">
          <social-media-image-card
            v-if="image.name"
            :key="index"
            :selected="image.name === $store.state.shareBookingForm.selectedSocialMediaImage?.name"
            :image-src="getImageSrc(image, index)"
            @click="selectImage(image)"
            @delete="deleteImage(image, index)"
          />
          <div v-else-if="!uploadProgressIndexes.includes(index)" :key="index" class="social-media-images__list__item">
            <adm-square-uploader
              :is-preview-enabled="false"
              :height="220"
              @input="(photoFile) => uploadImage(photoFile, index, image)"
            />
          </div>
          <adm-skeleton-element
            v-if="uploadProgressIndexes.includes(index)"
            :key="index"
            class="social-media-images__list__item"
            :width="138"
          />
        </template>
      </template>
    </div>
    <!-- /Social Media Images List -->

  </div>
</template>

<script>
import AdmSquareUploader from '@/views/_components/squareUploader/AdmSquareUploader.vue'
import SocialMediaImageCard
  from '@/views/Dashboard/ShareBookingFormDialog/LinkSection/SocialMedia/SocialMediaImages/_components/SocialMediaImageCard.vue'
import AdmAlert from '@/views/_components/alert/AdmAlert.vue'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement.vue'
import { getPublicFileLink } from '@/plugins/axios'

export default {
  name: 'SocialMediaImages',
  components: {
    AdmSkeletonElement,
    AdmAlert,
    SocialMediaImageCard,
    AdmSquareUploader
  },
  data: () => {
    return {
      images: [],
      loading: false,
      uploadProgressIndexes: [],
    }
  },

  created () {
    this.getImages()
  },

  methods: {
    getImages () {
      this.loading = true
      this.$http.get(`/api/v1/booking-links/social-media-image`).then(({data} )=> {
        this.images = data
      }).finally(() => {
        this.loading = false
      })
    },

    uploadImage (photoFile, index, image) {
      this.uploadProgressIndexes.push(index)
      const formData = new FormData()
      formData.append('photo', photoFile.raw)
      formData.append('index', index)

      this.$http.post(
        `/api/v1/booking-links/social-media-image`,
        formData
      ).then(({data}) => {
        image.isDefault = false
        image.name = data.name
      }).catch((e) => {
        if (e.response.data.error) {
          return this.$message({ message: e.response.data.error, type: 'error', showClose: true })
        }
      }).finally(() => {
        this.uploadProgressIndexes = this.uploadProgressIndexes.filter((i) => i !== index)
      })
    },

    deleteImage (image, index) {
      this.$http.delete(`/api/v1/booking-links/social-media-image`, {
        data: { index }
      }).then(({} )=> {
        if (this.$store.state.shareBookingForm.selectedSocialMediaImage?.name === image.name) {
          this.$store.commit('shareBookingForm/setSelectedSocialMediaImage', null)
        }
        image.name = ''
      })
    },

    getImageSrc (image, index) {
      if (image.isDefault) {
        return `${process.env.VUE_APP_API_URL}/api/v1/public/booking-links/social-media-image/default/social-media-${1+index}`
      }

      return getPublicFileLink(image.name, 'medium-max')
    },

    selectImage (image) {
      this.$store.commit('shareBookingForm/setSelectedSocialMediaImage', image)
    }
  },
}
</script>

<style lang="scss">
.social-media-images {
  &__desc {
    color: $shade-900;
    font-size: 15px;
    line-height: 24px;
    margin-bottom: 32px;
  }

  &__info {
    margin-bottom: 24px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;

    &__item {
      flex-basis: 33.33333%;
      display: flex;
      max-width: 138px;
      min-width: 138px;
      height: 220px;
    }
  }
}
</style>
