const getDefaultState = () => {
  return {
    alertHeight: 0,
  }
}

const state = getDefaultState()

const getters = {}

const mutations = {
  setAlertHeight (state, payload) {
    state.alertHeight = payload
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters
}
