<template>

  <!-- Select -->
  <div class="adm-select-wrapper" :style="{ maxWidth }">
    <el-select
      v-bind="$props"
      :id="id"
      ref="elSelect"
      v-model="model"
      class="adm-select"
      :loading-text="$t('loading')"
      :style="{ '--icon-color': iconColor }"
      :class="[
        'adm-select__size__' + size,
        { 'is-icon-start': iconStart || customIcon, 'is-borderless': borderless, 'is-icon-colored': iconColor },
        {'adm-select__selected-multiple' : multiple && model.length > 0}
      ]"
      :popper-class="'adm-select-popper' + (popperClass ? ' ' + popperClass : popperClass)"
      @change="(eventValue) => $emit('change', eventValue)"
      @visible-change="(eventValue) => $emit('visible-change', eventValue)"
      @remove-tag="(eventValue) => $emit('remove-tag', eventValue)"
      @clear="$emit('clear')"
      @blur="(e) => $emit('blur', e)"
      @focus="(e) => $emit('focus', e)"
      @input="(eventValue) => $emit('input', eventValue)"
    >
      <i v-if="iconStart" slot="prefix" :class="'tz-' + iconStart" />
      <slot />
      <template v-if="$slots.empty" #empty>
        <slot name="empty" />
      </template>
    </el-select>
  </div>
  <!-- /Select -->

</template>

<script>
export default {
  name: 'AdmSelect',

  props: {
    id: {
      type: [String, Object],
      default: null
    },
    // eslint-disable-next-line vue/require-prop-types
    value: {
      required: true
    },
    multiple: Boolean,
    disabled: Boolean,
    valueKey: {
      type: String,
      default: 'value'
    },
    size: {
      type: String,
      default: 'default',
      validator (value) {
        return ['default', 'medium', 'small'].indexOf(value) !== -1
      }
    },
    clearable: Boolean,
    collapseTags: {
      type: Boolean,
      default: true
    },
    multipleLimit: {
      type: Number,
      default: 0
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    noDataText: {
      type: String,
      default: ''
    },
    filterable: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    filterMethod: Function,
    popperClass: {
      type: String,
      default: ''
    },
    popperAppendToBody: {
      type: Boolean,
      default: true
    },
    automaticDropdown: Boolean,
    remote: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    remoteMethod: Function,
    allowCreate: Boolean,
    loading: Boolean,
    reserveKeyword: Boolean,
    defaultFirstOption: Boolean,
    iconStart: {
      type: String,
      default: ''
    },
    iconColor: {
      type: String,
      default: ''
    },
    maxWidth: {
      type: String,
      default: null
    },
    borderless: Boolean,
    customIcon: Boolean
  },

  data () {
    return {
      selfModel: ''
    }
  },

  computed: {
    model: {
      get () {
        return this.value !== undefined && this.value !== null ? this.value : this.selfModel
      },
      set (val) {
        this.selfModel = val
      }
    }
  },

  methods: {
    focus () {
      this.$refs.elSelect.focus()
    },

    blur () {
      this.$refs.elSelect.blur()
    }
  }
}
</script>

<style lang="scss">
// Select Wrapper
.adm-select-wrapper {

  // Select
  .adm-select {
    width: 100%;

    // Prefix
    .el-input__prefix {
      display: flex;
      align-items: center;

      // Icon
      i {
        display: flex;
        align-items: center;
      }
    }

    // Disabled
    .is-disabled {
      i {
        &:before {
          color: $shade-600;
        }
      }
    }

    // Suffix
    .el-input__suffix {
      right: 8px;

      // Icon
      i {
        display: flex;
        justify-content: center;

        // Before
        &:before {
          color: $shade-900;
          font-size: 24px;
          font-family: 'icomoon', serif;
        }
      }

      // Validate Icon
      i.el-input__validateIcon {
        display: none;
      }

      // Arrow Icon
      i.el-icon-arrow-up:before {
        content: "\e903";
      }

      // Clear Icon
      i.el-icon-circle-close:before {
        font-size: 20px;
        color: $shade-700;
        content: "\e95e";
      }
    }

    // Hover
    &:hover:not(:focus) .el-input:not(.is-disabled):not(.is-focus) .el-input__inner:not(.is-focus):not(:focus) {
      border: 1px solid $shade-300;
      box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
    }

    // Element Input
    .el-input {

      // Focus
      &.is-focus {

        // Native Input
        .el-input__inner {
          border-color: var(--primary-900);
        }
      }

      // Native Input
      .el-input__inner {
        border: 1px solid $shade-300;
        border-radius: 7px;
        font-size: 15px;
        -webkit-transition: box-shadow 0.15s;
        transition: box-shadow 0.15s;
        text-overflow: ellipsis;

        // Hover
        &:hover:not(:focus):not(:disabled) {
          box-shadow: inset 0 1px 2px rgba(20, 35, 61, 0.11);
        }

        // Active & Focus
        &:active, &:focus {
          border: 1px solid var(--primary-900);
          box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
        }

        // Placeholder
        &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
          color: $shade-600;
        }

        // Disabled
        &:disabled {

          // Placeholder
          &::-webkit-input-placeholder, &:-ms-input-placeholder, &::placeholder {
            color: $shade-450;
          }
        }
      }
    }

    // Disabled
    .is-disabled {

      // Suffix
      .el-input__suffix i:before {
        color: $shade-600;
      }

      // Input
      input {
        background: $shade-150;
        border-color: $shade-300;
        box-shadow: none;
        cursor: not-allowed;
        color: $shade-600;

        // Hover
        &:hover {
          box-shadow: unset;
        }

        // Active & Focus
        &:active, &:focus {
          border: 1px solid $shade-300;
          box-shadow: unset;
        }
      }
    }

    // Size
    &__size {

      // Default
      &__default {

        // Icon
        &.is-icon-start {

          // Prefix
          .el-input__prefix {
            cursor: pointer;
            display: flex;
            align-items: center;
            left: 0.5rem;
            width: 24px;

            // Icon
            i {
              color: $shade-900;
              font-size: 24px;
              font-family: 'icomoon', serif;

              // Before
              &:before {
                color: $shade-900;
              }
            }
          }

          // Colored Icon
          &.is-icon-colored {

            // Prefix
            .el-input__prefix {

              // Icon
              i {

                // Before
                &:before {
                  color: var(--icon-color);
                }
              }
            }
          }

          // Native Input
          input {
            padding-left: 40px;
          }

          // Multiple select
          .el-select__tags {

            // Native Input
            input {
              padding-left: 0;
              margin-left: 40px;
            }
          }

          // Multiple Tags
          &.adm-select__selected-multiple .el-select__tags {

            // Native Input
            input {
              margin-left: 6px;
            }
          }
        }

        // Native Input
        .el-input__inner {
          padding: 0 40px 0 14px;
        }

        // Tags
        .el-tag {
          line-height: 24px;

          // First
          &:first-of-type {
            margin: 0 0 0 8px;
          }

          // Close Icon
          i.el-icon-close {
            height: 20px;
            width: 20px;

            &:before {
              height: 20px;
              width: 20px;
            }
          }
        }
      }

      // Medium
      &__medium {

        // Native Input
        .el-select__input {
          margin-left: 12px;
        }

        // Icon
        &.is-icon-start {

          // Prefix
          .el-input__prefix {
            cursor: pointer;
            display: flex;
            align-items: center;
            left: 6px;
            width: 24px;

            // Icon
            i {
              color: $shade-900;
              font-size: 24px;
              font-family: 'icomoon', serif;

              // Before
              &:before {
                color: $shade-900;
              }
            }
          }

          // Colored Icon
          &.is-icon-colored {

            // Prefix
            .el-input__prefix {

              // Icon
              i {

                // Before
                &:before {
                  color: var(--icon-color);
                }
              }
            }
          }

          // Native Input
          input {
            padding-left: 36px;
          }

          // Multiple select
          .el-select__tags {

            // Native Input
            input {
              padding-left: 0;
              margin-left: 36px;
            }
          }

          &.adm-select__selected-multiple .el-select__tags {

            // Native Input
            input {
              margin-left: 6px;
            }
          }
        }

        // Suffix
        .el-input__suffix {
          right: 6px;
        }

        // Native Input
        .el-input__inner {
          padding: 0 36px 0 10px;
        }

        // Tags
        .el-tag {
          line-height: 24px;

          // First
          &:first-of-type {
            margin: 0 0 0 6px;
          }

          // Close Icon
          i.el-icon-close {
            height: 20px;
            width: 20px;

            &:before {
              height: 20px;
              width: 20px;
            }
          }
        }
      }

      // Small
      &__small {

        // Icon
        &.is-icon-start {

          // Prefix
          .el-input__prefix {
            cursor: pointer;
            display: flex;
            align-items: center;
            left: 6px;
            width: 20px;

            // Icon
            i {
              font-size: 20px;
              font-family: 'icomoon', serif;

              // Before
              &:before {
                color: $shade-900;
              }
            }
          }

          // Colored Icon
          &.is-icon-colored {

            // Prefix
            .el-input__prefix {

              // Icon
              i {

                // Before
                &:before {
                  color: var(--icon-color);
                }
              }
            }
          }

          // Native Input
          input {
            padding-left: 32px;
          }

          // Multiple select
          .el-select__tags {

            // Native Input
            input {
              padding-left: 0;
              margin-left: 32px;
            }
          }

          &.adm-select__selected-multiple .el-select__tags {

            // Native Input
            input {
              margin-left: 6px;
            }
          }
        }

        // Suffix
        .el-input__suffix {
          right: 6px;

          // Icon
          i:before {
            font-size: 20px;
          }
        }

        // Native Input
        .el-input__inner {
          font-size: 14px;
          padding: 0 32px 0 10px;
        }

        // Tags
        .el-tag {
          line-height: 20px;

          // First
          &:first-of-type {
            margin: 0 0 0 6px;
          }

          // Close Icon
          i.el-icon-close {
            height: 16px;
            width: 16px;

            &:before {
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }

    // Borderless
    &.is-borderless {

      // Hover
      &:hover:not(:focus) .el-input:not(.is-disabled) .el-input__inner:not(.is-focus):not(:focus) {
        box-shadow: none;
        border: none;
      }

      // Native Input
      .el-input__inner, .el-input__inner:focus {
        box-shadow: none;
        border: none;
      }
    }

    // Tags
    .el-select__tags {
      cursor: pointer;
      flex-wrap: nowrap;
    }

    // Tag
    .el-tag {
      border: none;
      border-radius: 4px;
      background-color: var(--primary-400);
      color: var(--primary-1000);
      font-weight: 500;
      font-size: 13px;
      margin: 0 0 0 4px;
      padding: 0 2px 0 4px;
      display: flex;
      align-items: center;
      max-width: 82%;

      // Text
      .el-select__tags-text {
        padding-right: 4px;
        max-width: 100%;
        overflow: hidden;
      }

      // Close Icon
      i.el-icon-close {
        margin-top: 0;
        margin-left: 0;
        position: relative;
        color: var(--primary-1000);
        background-color: unset;
        right: 0;
        top: 0;
        transform: none;

        // Before
        &:before {
          transform: none;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 18px;
          font-family: 'icomoon', serif;
          content: "\e910";
        }

        // Hover
        &:hover {

          // Before
          &:before {
            border-radius: 4px;
            background-color: var(--primary-500);
          }
        }
      }
    }
  }
}

// Select Popper
.adm-select-popper {
  box-shadow: $box-shadow-dropdown;
  border-radius: 4px;
  border: 1px solid $shade-200;
  background: $adm-white;
  padding: 5px 7px;
  min-width: 120px;
  max-width: 90%;

  // Popper Arrow
  .popper__arrow {
    display: none;
  }

  // Dropdown List
  .el-select-dropdown__list {
    padding: 0;
  }

  // Margin From Triggering Element
  &[x-placement^=bottom] {
    margin-top: 0.25rem;
  }

  // No Data
  .el-select-dropdown__empty {
    color: $shade-600;
    padding: 0;
  }

  // Multiple
  &.is-multiple {

    // Selected
    .adm-select-option.selected {
      background-color: var(--primary-300);
      color: var(--primary-900);
      font-size: 14px;
      line-height: 24px;
      padding-right: 4px;

      & > span {
        display: inline-block;
        width: 96%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      // After
      &:after {
        display: flex;
        justify-content: center;
        width: 24px;
        font-family: 'icomoon', serif;
        content: "\e961";
        right: 4px;
      }
    }
  }
}
</style>
