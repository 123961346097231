<template>

  <!-- Design System Modal -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Modal
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
    >

      <!-- Preview -->
      <template #preview>
        <adm-modal width="500px" :visible="firstModalVisible" title="Modal Title" @close="firstModalVisible = false">
          Lorem ipsum

          <template #footer>
            <adm-button size="medium" @click="firstModalVisible = false">
              Save
            </adm-button>
          </template>
        </adm-modal>
        <adm-button color="grey" @click="firstModalVisible = true">
          Show modal
        </adm-button>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{
          getModalCode(
            { ':visible': 'firstModalVisible', 'title': "Modal Title", '@close': 'firstModalVisible = false', 'width': '500px'},
            'Lorem ipsum',
            true
          )
        }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Modal Attributes -->
    <design-system-documentation-table :table-data="tableDataAttributes" />
    <!-- /Modal Attributes -->

    <!-- Modal Slots -->
    <design-system-documentation-table title="Modal Slots" :table-data="tableDataSlots" type="slots" />
    <!-- /Modal Slots -->

  </div>
  <!-- /Design System Modal -->

</template>

<script>
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'
import AdmButton from '@/views/_components/button/AdmButton'
import AdmModal from '@/views/_components/modal/AdmModal'

export default {
  name: 'DesignSystemModal',

  components: {
    AdmModal,
    AdmButton,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: 0,
      tableDataAttributes: [
        {
          attribute: 'title',
          description: 'Title of modal',
          type: 'string',
          values: '-',
          default: '""',
        },
        {
          attribute: 'visible',
          description: 'Visibility of modal',
          type: 'Boolean',
          values: 'true/false',
          default: 'false',
        },
        {
          attribute: 'fullscreen',
          description: 'whether the Modal takes up full screen',
          type: 'Boolean',
          values: 'true/false',
          default: 'false',
        },
        {
          attribute: 'top',
          description: 'value for margin-top of Modal CSS',
          type: 'string',
          values: '-',
          default: '15vh',
        },
        {
          attribute: 'append-to-body',
          description: 'whether to append modal to body element. If false, the modal will be appended to Dialog\'s parent element',
          type: 'Boolean',
          values: 'true/false',
          default: 'false',
        },
        {
          attribute: 'lock-scroll',
          description: 'whether scroll of body is disabled while Dialog is displayed',
          type: 'Boolean',
          values: 'true/false',
          default: 'true',
        },
        {
          attribute: 'close-on-press-escape',
          description: 'whether the Dialog can be closed by pressing ESC',
          type: 'Boolean',
          values: 'true/false',
          default: 'true',
        },
        {
          attribute: 'close-visible',
          description: 'whether the close button is visible',
          type: 'Boolean',
          values: 'true/false',
          default: 'true',
        },
        {
          attribute: 'destroy-on-close',
          description: 'Destroy elements in Modal when closed',
          type: 'Boolean',
          values: 'true/false',
          default: 'false',
        }
      ],
      tableDataSlots: [
        {
          name: 'footer',
          description: 'footer of Modal'
        },
        {
          name: 'title',
          description: 'title of modal. It can be passed as pros as well'
        },
      ],
      tableDataEvents: [
        {
          name: 'open',
          description: 'triggers when the Modal opens',
          parameters: '-'
        },
        {
          name: 'opened',
          description: 'triggers when the Modal opening animation ends',
          parameters: '-'
        },
        {
          name: 'close',
          description: 'triggers when the Modal closes',
          parameters: '-'
        },
        {
          name: 'closed',
          description: 'triggers when the Modal closing animation ends',
          parameters: '-'
        }
      ],
      firstModalVisible: false
    }
  },
}
</script>
