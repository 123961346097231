export default [
  {
    path: 'finance',
    name: 'finance',
    redirect:  to => {
      return { path: '/finance/coupons' }
    },
    component: () => import(/* webpackChunkName: "finance" */ '@/views/Finance/Finance').then(value => value.default),
    children: [
      {
        path: 'invoices',
        name: 'invoices',
        meta: {
          title: 'invoices',
          breadcrumb: 'invoices',
          requiresAuth: true,
          requiredPermissions: 'read_invoice_transaction',
          planFeature: 'invoice_taxes',
        },
        component: () => import(/* webpackChunkName: "finance" */ '@/views/Finance/Invoices/Invoices').then(value => value.default)
      },
      {
        path: 'invoices/settings',
        name: 'invoice-settings',
        meta: {
          title: 'invoice_settings',
          breadcrumb: 'settings',
          requiresAuth: true,
          requiredPermissions: 'edit_invoice_settings',
          planFeature: 'invoice_taxes',
        },
        component: () => import(/* webpackChunkName: "finance" */ '@/views/Finance/Invoices/InvoicesSettings/InvoicesSettings').then(value => value.default)
      },
      {
        path: 'invoices/settings/customizations',
        name: 'invoice-settings-customizations',
        meta: {
          title: 'invoice_customizations',
          breadcrumb: 'customizations',
          requiresAuth: true,
          requiredPermissions: 'edit_invoice_settings',
          planFeature: 'invoice_taxes',
        },
        component: () => import(/* webpackChunkName: "finance" */ '@/views/Finance/Invoices/InvoicesSettings/InvoicesSettingsCustomizations').then(value => value.default)
      },
      {
        path: 'transactions',
        name: 'transactions',
        meta: {
          title: 'transactions',
          breadcrumb: 'transactions',
          requiresAuth: true,
          requiredPermissions: 'read_invoice_transaction',
        },
        component: () => import(/* webpackChunkName: "finance" */ '@/views/Finance/Transactions/TransactionsPage').then(value => value.default)
      },
      {
        path: 'coupons',
        name: 'coupons',
        meta: {
          title: 'coupons',
          breadcrumb: 'coupons',
          requiresAuth: true,
          requiredPermissions: 'read_coupon'
        },
        component: () => import(/* webpackChunkName: "finance" */ '@/views/Finance/Coupons/CouponsList/CouponsList').then(value => value.default),
      },
      {
        path: 'coupons/history',
        name: 'coupons-history',
        meta: {
          title: 'coupons_history',
          breadcrumb: 'coupons_history',
          requiresAuth: true,
          feature: 'coupons',
          requiredPermissions: 'read_coupon_history'
        },
        component: () => import(/* webpackChunkName: "finance" */ '@/views/Finance/Coupons/CouponsHistory/CouponsHistory').then(value => value.default),
        children: [

        ],
      },
      {
        path: 'coupons/add',
        name: 'coupons-add',
        meta: {
          title: 'add_coupon',
          breadcrumb: 'add_coupon',
          requiresAuth: true,
          feature: 'coupons',
          requiredPermissions: 'edit_coupon'
        },
        component: () => import(/* webpackChunkName: "coupons-manage" */ '@/views/Finance/Coupons/ManageCoupon/ManageCoupon').then(value => value.default)
      },
      {
        path: 'coupons/:couponId(\\d+)',
        name: 'coupons-edit',
        meta: {
          title: 'edit_coupon',
          breadcrumb: 'edit_coupon',
          requiresAuth: true,
          feature: 'coupons',
          requiredPermissions: 'edit_coupon'
        },
        component: () => import(/* webpackChunkName: "coupons-manage" */ '@/views/Finance/Coupons/ManageCoupon/ManageCoupon').then(value => value.default)
      },
      {
        path: 'taxes',
        name: 'taxes',
        meta: {
          title: 'taxes',
          breadcrumb: 'taxes',
          requiresAuth: true,
          requiredPermissions: 'read_tax',
          planFeature: 'invoice_taxes',
        },
        component: () => import(/* webpackChunkName: "finance" */ '@/views/Finance/Taxes/Taxes').then(value => value.default)
      },
    ]
  },
]
