<template>
  <adm-modal
    width="418px"
    :visible="visible"
    :title="title"
    :lock-scroll="lockScroll"
    @close="close"
  >

    <!-- Description -->
    <span class="adm-confirm__description">{{ description }}</span>
    <!-- Description -->

    <!-- More Options -->
    <div v-if="moreOptions" class="adm-confirm__more-options">

      <!-- Checkbox -->
      <adm-checkbox
        v-if="moreOptions.type === 'checkbox'"
        :value="moreOptionsModel"
        @change="(value) => moreOptionsModel = value"
      >
        {{ moreOptions.label }}
      </adm-checkbox>
      <!-- /Checkbox -->

    </div>
    <!-- /Options -->

    <template #footer>

      <!-- Cancel Button -->
      <adm-button
        size="medium"
        color="grey"
        @click="close"
      >
        {{ getCancelLabel() }}
      </adm-button>
      <!-- /Cancel Button -->

      <!-- Action Button -->
      <adm-button
        ref="action"
        size="medium"
        :color="color"
        @click="handleActionClick()"
      >
        {{ confirmButtonText }}
      </adm-button>
      <!-- /Action Button -->

    </template>
  </adm-modal>
</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmModal from '@/views/_components/modal/AdmModal'
import i18n from '@/i18n'
import AdmCheckbox from '@/views/_components/checkbox/AdmCheckbox.vue'

export default {
  name: 'AdmConfirm',

  components: {
    AdmCheckbox,
    AdmModal,
    AdmButton
  },

  props: {

  },

  data () {
    return {
      visible: false,
      title: '',
      description: '',
      color: 'red',
      confirmButtonText: '',
      resolve: null,
      lockScroll: false,
      moreOptions: null,
      moreOptionsModel: ''
    }
  },

  methods: {
    getCancelLabel () {
      return i18n.t('cancel')
    },

    handleActionClick () {
      this.visible = false
      if (this.resolve) {
        this.resolve(this.moreOptionsModel)
      }
    },

    close () {
      this.visible = false
      this.moreOptions = null
    },

    show (title, description, options) {
      return new Promise(resolve => {
        if (!this.$parent) {
          this.$mount()
          document.body.appendChild(this.$el)
        }

        this.visible = true
        this.title = title
        this.description = description

        if (options.confirmButtonText) {
          this.confirmButtonText = options.confirmButtonText
        }
        if (options.lockScroll) {
          this.lockScroll = options.lockScroll
        }
        if (options.actionColor) {
          this.color = options.actionColor
        }
        if (options.moreOptions) {
          this.moreOptions = options.moreOptions

          if (options.moreOptions.type === 'checkbox') {
            this.moreOptionsModel = true
          }
        }
        this.resolve = resolve
      })
    }
  }
}
</script>

<style lang="scss">
.adm-confirm {
  &__description {
    white-space: pre-line;
  }

  &__more-options {
    margin-top: 8px;
  }
}
</style>
