export default {
  methods: {
    validateUserEmailAsync (value, callback, user, excludeLoggedInUser = false) {
      clearTimeout(this.timer)
      const $this = this
      this.timer = setTimeout(function () {
        if (user) {
          $this.$http.post(
            '/api/v1/users/validate',
            {
              id: user.id,
              email: user.email,
              fieldToValidate: 'email',
              excludeLoggedInUser: excludeLoggedInUser
            },
          ).then(() => {
            callback()
          }).catch(e => {
            if (e.response.data.status === 'fail') {
              return callback(new Error($this.$t('email_taken')))
            }
          })
        }
      }, 500)
    },

    validateNewPassword (value, callback, currentPassword = null) {
      if (value.length < 8) {
        return callback(new Error(' '))
      }

      if (currentPassword && currentPassword === value) {
        return callback(new Error(this.$t('new_password_different')))
      }

      callback()
    },

    validateNewPasswordConfirm (value, callback, newPassword) {
      if (value !== newPassword) {
        return callback(new Error(this.$t('password_match')))
      }

      callback()
    },

    validateName (value, callback, message) {
      if (value.trim() === '') {
        return callback(new Error(message))
      }

      callback()
    }
  }
}
