<!--suppress ALL -->
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>
<style lang="scss">
@import '@/assets/css/main.scss';
</style>
