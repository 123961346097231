<template>

  <!-- Manage Appointment Form -->
  <el-form class="manage-appointment-form">

    <!-- Employee Disconnected From Service Alert -->
    <adm-alert
      v-if="appointmentProp.employee && appointmentProp.employee.employeeDisconnected"
      class="mb-16"
      type="warning"
      :title="$t('employee_does_not_provide_service_anymore')"
      :description="$t('employee_removed_from_appointment_notice')"
    />
    <!-- /Employee Disconnected From Service Alert -->

    <!-- Select Service -->
    <service-section
      v-if="!loading"
      :appointment-prop="appointmentProp"
    />
    <adm-skeleton-element v-else :height="40" :margin-bottom="20" />
    <!-- /Select Service -->

    <!-- Select Employees -->
    <employee-section
      v-if="!loading"
      :appointment-prop="appointmentProp"
    />
    <adm-skeleton-element v-else :height="40" :margin-bottom="20" />
    <!-- /Select Employees -->

    <!-- Date & Time -->
    <date-time-section
      v-if="!loading"
      :appointment-prop="appointmentProp"
      :loading-appointment="loading"
    />
    <adm-skeleton-element v-else :height="40" :margin-bottom="20" />
    <!-- /Date & Time -->

    <!-- Select Location -->
    <location-section
      v-if="!loading"
      :appointment-prop="appointmentProp"
      @empty-location="$emit('empty-location')"
    />
    <adm-skeleton-element v-else :height="40" :margin-bottom="20" />
    <!-- /Select Location -->

    <!-- Select Customers -->
    <customer-section
      v-if="!loading"
      ref="customerSection"
      :appointment-prop="appointmentProp"
      :existing-prices="existingPrices"
      :is-employee-disconnected-from-service="isEmployeeDisconnectedFromService"
      :capacity-maximum="capacityMaximum"
      :capacity-available="capacityAvailable"
      :service-price="servicePrice"
      :custom-fields="customFields"
    />
    <adm-skeleton-element v-else :height="40" />
    <!-- /Select Customers -->

  </el-form>
  <!-- /Manage Appointment Form -->

</template>

<script>
import AdmAlert from '@/views/_components/alert/AdmAlert'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import CustomerSection from './customerSection/CustomerSection'
import DateTimeSection from './dateTimeSection/DateTimeSection'
import EmployeeSection from './employeeSection/EmployeeSection'
import LocationSection from './locationSection/LocationSection'
import ServiceSection from './serviceSection/ServiceSection'

export default {
  name: 'ManageAppointmentForm',

  components: {
    AdmAlert,
    AdmSkeletonElement,
    CustomerSection,
    DateTimeSection,
    EmployeeSection,
    LocationSection,
    ServiceSection,
  },

  props: {
    appointmentProp: {
      type: Object,
      default: () => ({}),
      required: true
    },
    loading: {
      type: Boolean,
      default: false,
      required: true
    },
    isEmployeeDisconnectedFromService: {
      type: Boolean,
      default: false,
      required: true
    },
    existingPrices: {
      type: Object,
      default: () => ({}),
      required: true
    },
    capacityMaximum: {
      type: Number,
      default: 1
    },
    capacityAvailable: {
      type: Number,
      default: 1
    },
    servicePrice: {
      type: Number,
      default: 0,
      required: true
    },
    customFields: {
      type: Array,
      default: () => ([])
    },
  }
}
</script>

<style lang="scss">
.manage-appointment-form {
  .section:not(:last-child) {
    margin-bottom: 1.5rem;

    .search {
      .el-input__inner {
        background: $shade-200;
      }
    }

    .services {
      margin-top: 0.75rem;

      .popular-services-title {
        padding-bottom: 0.25rem;
        font-size: 12px;
      }
    }
  }

  .select-datetime {
    .el-icon-loading {
      display: none;
    }

    .is-loading {
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;

      .el-icon-loading {
        display: block;
        position: absolute;
        right: 8px;
      }

      .el-input__suffix {
        display: none;
      }
    }
  }
}
</style>
