var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-menu-item',_vm._b({staticClass:"adm-menu-item",class:[{
    'is-with-icon': Boolean(_vm.icon),
    'is-active':_vm.isActive,
    'adm-menu-item__appears-active': _vm.disabled && _vm.activeDisabled,
    'is-with-floating': _vm.isWithFloating,
    'is-active-with-floating': _vm.isActiveWithFloating
  }],on:{"click":(el) => _vm.$emit('click', el)}},'el-menu-item',_vm.$props,false),[(_vm.icon)?_c('i',{staticClass:"adm-menu-item__icon",class:'tz-' + _vm.icon}):_vm._e(),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }