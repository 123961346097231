import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import httpClient, { getPublicFileLink, getDashboardImageLink, getDashboardLogoLink } from '@/plugins/axios'
import Element from 'element-ui'
import VueCurrencyInput from 'vue-currency-input'
import VueGtm from '@gtm-support/vue2-gtm'
import { VueJsonp } from 'vue-jsonp'
import VueObserveVisibility from 'vue-observe-visibility'
import AdmConfirm from '@/views/_components/modal/AdmConfirm'
import VueScreen from 'vue-screen'
import getCustomLabel from '@/utils/customLabel'
import getTranslatedLabel from '@/utils/translatedLabel'
import * as Sentry from '@sentry/vue'
import getLabelWithBusinessName from '@/utils/labelWithBusinessName'
import './registerServiceWorker'
import i18n from './i18n'
import moment from '@/plugins/momentLocales'

Vue.config.productionTip = false

// noinspection JSUnusedGlobalSymbols
Vue.prototype.$http = httpClient

// noinspection JSUnusedGlobalSymbols
Vue.prototype.$getPublicFileLink = getPublicFileLink
Vue.prototype.$getDashboardImageLink = getDashboardImageLink
Vue.prototype.$getDashboardLogoLink = getDashboardLogoLink

// Colors
// noinspection JSUnusedGlobalSymbols
Vue.prototype.$colors = store.state.colors

Vue.prototype.$transWithBusinessName = (label) => getLabelWithBusinessName(label, store)
Vue.prototype.$customLabel = (label, defaultLabel) => getCustomLabel(label, defaultLabel, store)
Vue.prototype.$translateLabel = (label, defaultLabel, showDefault = true) => getTranslatedLabel(
  label,
  defaultLabel,
  showDefault,
  store.state.settings.languagePublicSelected,
  store.state.settings.customize.languagePublic
)

// Zapier
Vue.config.ignoredElements = ['zapier-app-directory']

Vue.use(Element, { i18n: (key, value) => i18n.t(key, value) })
Vue.use(require('vue-cookies'))
Vue.use(require('vue-moment'), { moment })
Vue.use(VueCurrencyInput, { globalOptions: { distractionFree: false, valueRange: { min: 0 }, locale: 'de-DE' } })
Vue.use(VueJsonp)
Vue.use(VueObserveVisibility)

if (process.env.VUE_APP_SENTRY_DSN) {
  Sentry.init({
    Vue,
    dsn: process.env.VUE_APP_SENTRY_DSN,
    environment: process.env.VUE_APP_SENTRY_ENVIRONMENT,
  })
}

new Vue({
  router: router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

Vue.use(AdmConfirm, {transFun: i18n})

// Google Tag Manager
if (process.env.VUE_APP_GTM_ID) {
  Vue.use(VueGtm, {
    id: process.env.VUE_APP_GTM_ID,
    defer: false,
    enabled: process.env.VUE_APP_GTM_ID !== '',
    debug: false,
    loadScript: true,
    vueRouter: router
  })
}

// Breakpoints
Vue.use(VueScreen, {
  aboveMobile: 769,
  aboveTablet: 1025,
  desktop: 1201,
  breakpointsOrder: ['aboveMobile', 'aboveTablet', 'desktop']
})
