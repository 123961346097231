<template>
  <div class="section select-service">

    <!-- Service Employee Not Assigned Alert -->
    <adm-alert
      v-if="loaded && groupedServices.length === 0"
      class="mb-16"
      type="warning"
      :title="$t('please_connect_employees_with_services')"
      :description="$t('employees_services_connection_required')"
    />
    <!-- /Service Employee Not Assigned Alert -->

    <!-- Title -->
    <h6 class="mb-4">
      {{ isEditAppointment ? $t('service') : $t('services') }}
    </h6>
    <!-- /Title -->

    <!-- Service Dropdown -->
    <adm-select
      v-if="!appointmentProp.id && !isServiceSelected"
      :placeholder="$t('select_service')"
      :value="appointmentProp.service"
      :loading="loading"
      filterable
      value-key="id"
      @focus="getServices"
      @change="selectService"
    >
      <adm-option-group
        v-for="(category, index) in groupedServices"
        :key="index"
        :label="index"
      >
        <el-option
          v-for="service in category"
          :key="service.id"
          class="adm-select-option"
          :label="service.name"
          :value="service"
        />
      </adm-option-group>
    </adm-select>
    <!-- /Service Dropdown -->

    <!-- Popular Services & Selected Service -->
    <div class="services">

      <!-- Popular Services -->
      <div
        v-if="!isServiceSelected &&
          !appointmentProp.id &&
          $store.getters['manageAppointment/isFormExtended']()"
        class="popular-services"
      >
        <!-- Popular Services Title -->
        <span class="popular-services-title">{{ $t('popular_services') }}</span>
        <!-- /Popular Services Title -->

        <!-- Popular Services Cards -->
        <template v-if="!showSkeletonLoader">
          <service-card
            v-for="service in popularServices"
            :key="service.id"
            :service="service"
            @card-selected="selectService"
          />
        </template>
        <!-- /Popular Services Cards -->

        <!-- Popular Services Skeleton -->
        <template v-if="showSkeletonLoader">
          <adm-skeleton-element v-for="n in 5" :key="n" :height="52" :margin-bottom="8" />
        </template>
        <!-- /Popular Services Skeleton -->

        <!-- Services Prices Info -->
        <adm-alert
          class="mt-16"
          :title="$t('service_prices_info')"
        />
        <!-- /Services Prices Info -->

      </div>
      <!-- /Popular Services -->

      <!-- Selected Service -->
      <service-card
        v-if="isServiceSelected"
        :selected="true"
        :service="appointmentProp.service"
        :show-duration="false"
        :show-price="false"
        :show-remove="!isEditAppointment"
        @card-removed="removeService"
      />
      <!-- /Selected Service -->

      <!-- Selected Service Skeleton -->
      <adm-skeleton-element
        v-if="$store.state.view.loading === true && isEditAppointment"
        :height="52"
      />
      <!-- /Selected Service Skeleton -->

    </div>
    <!-- /Popular Services & Selected Service -->

  </div>
</template>

<script>
import AdmAlert from '@/views/_components/alert/AdmAlert'
import AdmOptionGroup from '@/views/_components/select/AdmOptionGroup'
import AdmSelect from '@/views/_components/select/AdmSelect'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import ServiceCard from './ServiceCard/ServiceCard'

export default {
  name: 'ServiceSection',

  components: {
    AdmAlert,
    AdmOptionGroup,
    AdmSelect,
    AdmSkeletonElement,
    ServiceCard,
  },

  props: {
    appointmentProp: {
      type: Object,
      default: () => {},
      required: true
    },
  },

  data: function () {
    return {
      loading: false,
      showSkeletonLoader: false,
      loaded: false,
      popularServices: [],
      groupedServices: []
    }
  },

  computed: {
    isServiceSelected () {
      return this.appointmentProp.service
    },

    isEditAppointment () {
      return this.$store.getters['manageAppointment/getManageDialogAction']() === 'edit'
    }
  },

  watch: {
    'appointmentProp.employee' () {
      if (!this.isServiceSelected) {
        this.getPopularServices()
      }
    },

    'appointmentProp.location' () {
      if (!this.isServiceSelected) {
        this.getPopularServices()
      }
    },

    'appointmentProp.selectedSlot' () {
      if (!this.isServiceSelected) {
        this.getPopularServices()
      }
    },
  },

  created () {
    this.getPopularServices()
  },

  methods: {
    getServices () {
      this.loading = true
      let queryParams = this.$store.state.manageAppointment.queryParams
      delete queryParams.service

      this.$http.get(
        '/api/v1/appointments/entities/services',
        { params: queryParams }
      ).then(response => {
        this.groupedServices = this.groupServices(response.data.payload)
        this.loaded = true
      }).finally(() => {
        this.loading = false
      })
    },

    getPopularServices () {
      if (!this.isEditAppointment || !this.isServiceSelected) {
        this.showSkeletonLoader = true
        let queryParams = this.$store.state.manageAppointment.queryParams
        delete queryParams.service

        this.$http.get(
          '/api/v1/appointments/entities/services',
          { params: { ...queryParams, limit: 3, sort: 'popular' } }
        ).then(response => {
          this.popularServices = response.data.payload
        }).finally(() => {
          this.showSkeletonLoader = false
        })
      }
    },

    groupServices (services) {
      return services.reduce((acc, curr) => {
        if (!acc[curr.category]) acc[curr.category] = []
        acc[curr.category].push(curr)

        return acc
      }, {})
    },

    selectService (service) {
      this.$set(this.appointmentProp, 'service', service)
    },

    removeService () {
      this.$set(this.appointmentProp, 'service', null)
    }
  }
}
</script>
