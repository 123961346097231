export default {
  methods: {
    getButtonCode (props, label) {
      let propsString = this.getPropsString(props)

      if (label) {
        return `<adm-button${propsString ? ' ' : ''}${propsString}>\n  ${label}\n</adm-button>`
      }

      return `<adm-button${propsString ? ' ' : ''}${propsString} />`
    },

    getButtonGroupCode (props, labels) {
      let buttonCodes = ''

      for (let i = 0; i < props.length; i++) {
        buttonCodes += '  ' + this.getButtonCode(props[i], labels[i]).replaceAll('\n', '\n  ') + (props.length - 1 === i ? '' : '\n')
      }

      return `<adm-button-group>\n${buttonCodes}\n</adm-button-group>`
    },

    getDropdownCode (props, items, element = null) {
      let propsString = this.getPropsString(props)
      let slotDefault = '  Dropdown'

      if (element) {
        slotDefault = '  ' + element.replaceAll('\n', '\n  ')
      }

      let slotDropdown = this.getDropdownMenuCode(items)

      return `<adm-dropdown${propsString ? ' ' : ''}${propsString}>\n${slotDefault}\n${slotDropdown}\n</adm-dropdown>`
    },

    getDropdownMenuCode (items) {
      let slotItems = ''

      for (let i = 0; i < items.length; i++) {
        slotItems += this.getDropdownItemCode(items[i]) + (items.length - 1 === i ? '' : '\n')
      }

      let result = `  <adm-dropdown-menu slot="dropdown">\n${slotItems}\n</adm-dropdown-menu>`

      return result.replaceAll('\n', '\n  ')
    },

    getDropdownItemCode (item) {
      let propsString = this.getPropsString(item.props)

      return `  <adm-dropdown-item${propsString ? ' ' : ''}${propsString}>\n    ${item.text}\n  </adm-dropdown-item>`
    },

    getPropsString (props) {
      let propsString = ''

      if (props) {
        for (const [propName, propValue] of Object.entries(props)) {
          if (propValue !== null) {
            propsString += `${typeof propValue === 'string' ? '' : ':'}${propName}=${'"' + propValue + '"'} `
          }
        }

        if (propsString) {
          propsString = propsString.slice(0, -1)
        }
      }

      return propsString
    },

    getAlertCode (props, slot = null) {
      let propsString = this.getPropsString(props)

      if (slot) {
        let slotCode = '  ' + this.getButtonCode(slot.props, slot.label).replaceAll('\n', '\n  ')

        return `<adm-alert${propsString ? ' ' : ''}${propsString}>\n${slotCode}\n</adm-alert>`
      }

      return `<adm-alert${propsString ? ' ' : ''}${propsString} />`
    },

    getTagCode (props, label) {
      let propsString = this.getPropsString(props)

      return `<adm-tag${propsString ? ' ' : ''}${propsString}>\n  ${label}\n</adm-tag>`
    },

    getBadgeCode (props, label) {
      let propsString = this.getPropsString(props)

      return `<adm-badge${propsString ? ' ' : ''}${propsString}>\n  ${label}\n</adm-badge>`
    },

    getIconCode (props) {
      let propsString = this.getPropsString(props)

      return `<adm-icon${propsString ? ' ' : ''}${propsString} />`
    },

    getInputCode (props) {
      let propsString = this.getPropsString(props)

      return `<adm-input${propsString ? ' ' : ''}${propsString} />`
    },

    getInputNumberCode (props) {
      let propsString = this.getPropsString(props)

      return `<adm-input-number${propsString ? ' ' : ''}${propsString} />`
    },

    getInputCurrencyCode (props) {
      let propsString = this.getPropsString(props)

      return `<adm-input-currency${propsString ? ' ' : ''}${propsString} />`
    },

    getSelectCode (props) {
      let propsString = this.getPropsString(props)

      return `<adm-select${propsString ? ' ' : ''}${propsString} />`
    },

    getDatePickerCode (props) {
      let propsString = this.getPropsString(props)

      return `<adm-date-picker${propsString ? ' ' : ''}${propsString} />`
    },

    getPaginationCode (props) {
      let propsString = this.getPropsString(props)

      return `<adm-pagination${propsString ? ' ' : ''}${propsString} />`
    },

    getTooltipCode (props) {
      let propsString = this.getPropsString(props)

      return `<adm-tooltip${propsString ? ' ' : ''}${propsString}>
      <adm-button>Tooltip Button</adm-button>
</adm-tooltip>
      `
    },

    getModalCode (props, body, isFooterVisible) {
      let propsString = this.getPropsString(props)

      let slotCode = `<adm-modal${propsString ? ' ' : ''}${propsString}>\n      ${body}`;

      if (isFooterVisible) {
        slotCode += `
    <template #footer>
       <adm-button size="medium" @click="firstModalVisible = false">
          Save
      </adm-button>
     </template>`
      }
      slotCode+=`
</adm-modal>`

      return slotCode
    },

    getSwitchCode (props) {
      let propsString = this.getPropsString(props)

      return `<adm-switch${propsString ? ' ' : ''}${propsString} />`
    },
  }
}
