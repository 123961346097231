<template>
  <div
    class="one-row-form-item"
    :class="{ 'with-border-bottom': withBottomBorder, 'with-border-top': withTopBorder }"
  >
    <!-- Left Column -->
    <div class="one-row-form-item__left">

      <!-- Label Slot -->
      <div
        v-if="$slots.label"
        class="one-row-form-item__left__label semi-bold"
      >
        <slot name="label" />
      </div>
      <!-- /Label Slot -->

      <!-- Description Slot -->
      <div
        v-if="$slots.description"
        class="one-row-form-item__left__description"
      >
        <slot name="description" />
      </div>
      <!-- /Description Slot -->

      <!-- Default Slot -->
      <slot />
      <!-- /Default Slot -->
    </div>

    <!-- Right Column -->
    <div
      v-if="$slots.input"
      class="one-row-form-item__right"
      :style="{ flexBasis: desktopRightColumnWidth ? desktopRightColumnWidth: undefined }"
    >
      <slot name="input" />
    </div>
    <!-- /Right Column -->
  </div>
</template>

<script>
export default {
  name: 'OneRowFormItem',

  props: {
    withBottomBorder: Boolean,
    withTopBorder: Boolean,
    desktopRightColumnWidth: {
      type: String,
      default: null,
    }
  }
}
</script>

<style scoped lang="scss">
.one-row-form-item {
  padding: 16px 0;
  display: flex;
  justify-content: space-between;
  gap: 22px;

  @include tablet-down {
    display: block;
  }

  &.with-border-bottom {
    border-bottom: 1px solid $shade-200;
  }

  &.with-border-top {
    border-top: 1px solid $shade-200;
  }

  &__left {
    width: 100%;

    & > * {
      margin-bottom: 0;
    }

    &__description {
      font-size: 13px;
      line-height: 20px;
      color: $shade-700;
      vertical-align: top;
      padding-bottom: 4px;

      & a {
        font-size: 13px;
      }
    }

    &__label {
      padding-bottom: 4px;
      vertical-align: top;
      display: inline-flex;
      line-height: 20px;
    }
  }

  &__right {
    display: flex;
    gap: 20px;
    flex-shrink: 0;

    & > * {
      width: 100%;
      margin-bottom: 0;
    }
  }
}
</style>
