<template>
  <table
    style="box-sizing: inherit; border-collapse: collapse; border-spacing: 0; color: rgb(26, 26, 26); font-size: 16px; width: 344px; max-width: 344px; font-family: 'Inter', sans-serif;"
  >
    <tbody style="box-sizing: inherit;">
      <tr style="box-sizing: inherit;">
        <td style="box-sizing: inherit; padding: 0;">
          <div style="box-sizing: inherit; padding-bottom: 3px; font-size: 13px; line-height: 1.4; width: 344px;color: #000000">
            <div style="box-sizing: inherit; font-weight: 600; font-size: 16px; line-height: 19px;margin-bottom: 4px">
              {{ $store.state.shareBookingForm.queryParams.service ? $store.state.shareBookingForm.queryParams.service.name : ' ' }}
              {{ $store.state.shareBookingForm.queryParams.service && $store.state.shareBookingForm.queryParams.employee ? ' - ' : '' }}
              {{
                $store.state.shareBookingForm.queryParams.employee
                  ? $store.state.shareBookingForm.queryParams.employee.firstName + ' ' + $store.state.shareBookingForm.queryParams.employee.lastName : ' '
              }}
            </div>
            <div v-if="$store.state.shareBookingForm.queryParams.service" style="box-sizing: inherit; font-size: 12px; line-height: 20px; color: #00143C;margin-bottom: 4px;">
              {{ secondsToNiceDuration($store.state.shareBookingForm.queryParams.service.duration) }}
            </div>

            <div v-if="$store.state.settings.customize.showCustomerTimezone" style="box-sizing: inherit; font-size: 12px; line-height: 16px; color: #00143C;margin-bottom: 4px;">
              {{ $t('timezone') }}: {{ $store.state.shareBookingForm.queryParams?.employee?.timezone ? $store.state.shareBookingForm.queryParams.employee.timezone : $store.state.settings.general.timezone }}
            </div>

            <div v-if="$store.state.shareBookingForm.queryParams.extras.length" style="border-top: 1px solid #DEE1E6;border-bottom: 1px solid #DEE1E6;box-sizing: inherit; margin-top: 8px;margin-bottom: 12px; width: 344px;padding-bottom: 8px; padding-top: 12px; overflow: hidden; max-width: 344px;">
              <div
                v-for="extra in $store.state.shareBookingForm.queryParams.extras"
                :key="extra.id"
                style="box-sizing: inherit; font-size: 12px; line-height: 16px; color: #00143C;margin-bottom: 4px;"
              >
                {{ extra.name }}
                <template v-if="extra.quantity > 1">
                  (x{{ extra.quantity }})
                </template>
              </div>
            </div>

            <div v-if="$store.state.shareBookingForm.queryParams.location" style="box-sizing: inherit; font-size: 12px; line-height: 16px; color: #00143C;margin-bottom: 4px;">
              {{ $store.state.shareBookingForm.queryParams.location.name }}
            </div>

            <template v-if="Object.keys($store.getters['shareBookingForm/getTimeslotDates']()).length > 0">
              <email-slots
                v-for="(times, index) in $store.getters['shareBookingForm/getTimeslotDates']()"
                :key="index"
                :date="index"
                :times="times"
                :link="link"
              />
            </template>
            <template v-else-if="$store.state.shareBookingForm.queryParams.dateRange">
              <email-button-link
                :label="getFormattedDateRangeLabel($store.state.shareBookingForm.queryParams.dateRange)"
                :link="link"
              />
            </template>
            <template v-else>
              <email-button-link :label="$t('book_now')" :link="link" />
            </template>
            <div v-if="Object.keys($store.getters['shareBookingForm/getTimeslotDates']()).length > 0 || $store.state.shareBookingForm.queryParams.dateRange" style="box-sizing: inherit; margin-top: 13px;">
              <a class="gmail-more"
                 :href="getAllAvailableTimesLink()"
                 style="box-sizing: inherit; background-color: #EDEFF3;padding: 4px 8px; font-size: 13px; font-weight: 500;color: #00143C; text-decoration-line: none; cursor: pointer; border-radius: 7px"
              >
                {{ $t('see_all_available_times') }}
              </a>
            </div>
          </div>
        </td>
      </tr>
      <tr v-if="$store.getters['settings/isAgency'] === false && $store.state.settings.customize.isTrafftBrandingApplied" style="box-sizing: inherit;">
        <td style="box-sizing: inherit; padding: 0; text-align: right;">
          <div
            style="border-top: 1px solid #DEE1E6;box-sizing: inherit; margin-top: 8px; width: 344px; padding-top: 10px; overflow: hidden; max-width: 344px;"
          >
            <div
              style="box-sizing: inherit; font-size: 12px;line-height: 16px; color: #868E96; float: right; display: inline-block;"
            >
              {{ $t('made_with') }}
              <a href="https://trafft.com/" target="_blank" rel="noopener noreferrer"
                 style="box-sizing: inherit; background-color: transparent; text-decoration-line: none; cursor: pointer; height: 16px; display: inline-block; vertical-align: bottom; padding-left: 3px;"
              >
                <img alt="trafft"
                     src="https://trafft.com/wp-content/themes/trafft-theme-blog/assets/images/madewithtrafft.svg" height="16"
                     style="box-sizing: inherit; border: 0;"
                >
              </a>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import duration from '@/mixins/common/duration'
import { getBookingUrl } from '@/utils/url'
import EmailSlots from '@/views/Dashboard/ShareBookingFormDialog/LinkSection/EmailHtmlContent/EmailSlots/EmailSlots.vue'
import EmailButtonLink
  from '@/views/Dashboard/ShareBookingFormDialog/LinkSection/EmailHtmlContent/EmailButtonLink/EmailButtonLink.vue'
import datetime from '@/mixins/common/datetime'

export default {
  name: 'EmailHtmlContent',
  components: {
    EmailButtonLink, EmailSlots
  },
  mixins: [duration, datetime],
  props: {
    link: {
      type: String,
      required: true,
    }
  },
  methods: {
    getAllAvailableTimesLink () {
      return `${this.link}&allAvailable=1`
    },

    getFormattedDateRangeLabel (range) {
      if (range[0] === range[1]) {
        return this.formatDate(range[0])
      }

      return this.formatDate(range[0]) + ' - ' + this.formatDate(range[1])
    },
  }
}
</script>
