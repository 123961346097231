<template>
  <adm-modal
    class="adm--dialog"
    visible
    width="484px"
    :title="`${ $t('change_number_of_users') }`"
    @close="onClose"
  >
    <div class="change-number-of-users">
      <div class="change-number-of-users__header">
        <!-- Number of Users Input -->
        <div class="justify-between mb-16 gap-24">
          <span class="semi-bold flex-center flex-grow">
            {{ $t('number_of_users') }}
          </span>

          <adm-input-number
            v-model="numberOfUsers"
            class="flex"
            :min="tenantInfo.plan.planNumberOfEmployees"
          />
        </div>
        <!-- /Number of Users Input -->

        <!-- Selected Plan -->
        <div class="justify-between">
          <div class="change-number-of-users__header__title flex-center">
            <img class="change-number-of-users__header__title__icon" :src="getPlanIcon(selectedPlan)">
            <span class="semi-bold ml-12">
              {{ getPlanLabel(selectedPlan) }}
            </span>
          </div>
          <div class="change-number-of-users__header__price-interval">
            <div class="change-number-of-users__header__price-interval__price semi-bold">
              {{ formatPlanPrice(pricePerUser, currencySymbol) }}
            </div>
            <div class="change-number-of-users__grey-text">
              {{ $t('per_user_per_month').toLowerCase() }}
            </div>
          </div>
        </div>
        <!-- /Selected Plan -->
      </div>

      <div v-if="numberOfAdditionalUsers !== 0" class="change-number-of-users__body">
        <div class="mt-16">
          {{ $t('additional_users') }}
        </div>
        <div class="change-number-of-users__body__row justify-between flex-center pb-16">
          <div class="change-number-of-users__body__row__title semi-bold">
            {{ `${ numberOfAdditionalUsers } (${ numberOfAdditionalUsers > 1 ? $t('users') : $t('user') })` }}
            {{ `${ selectedPlan.billingInterval === 'yearly' ? ` x 12 (${ $t('months') })` : '' }` }}
            {{ ` x ${ formatPlanPrice(pricePerUser, currencySymbol) }` }}
          </div>
          <div class="change-number-of-users__body__row__amount bold">
            {{ formatPlanPrice(totalPrice.toFixed(2), currencySymbol) }}
          </div>
        </div>

        <div class="change-number-of-users__body__row pb-16">
          <adm-alert :description="$t('change_number_of_users_desc')" />
        </div>
      </div>

    </div>

    <!-- Save & Cancel Buttons -->
    <template #footer>

      <!-- Cancel Button -->
      <adm-button
        size="medium"
        color="grey"
        @click="onClose"
      >
        {{ $t('cancel') }}
      </adm-button>
      <!-- /Cancel Button -->

      <adm-button
        size="medium"
        :disabled="numberOfUsers === tenantInfo.plan.maxNumberOfEmployees"
        :loading="updating"
        @click="changeNumberOfUsers"
      >
        {{ $t('send_request') }}
      </adm-button>
      <!-- /Save Button -->

    </template>
    <!-- /Save & Cancel Buttons -->
  </adm-modal>
</template>

<script>
import AdmModal from '@/views/_components/modal/AdmModal'
import mixinPlans from '@/mixins/plans/plans'
import mixinDateTime from '@/mixins/common/datetime'
import mixinPrice from '@/mixins/common/price'
import AdmButton from '@/views/_components/button/AdmButton'
import { mapState } from 'vuex'
import AdmInputNumber from '@/views/_components/input/AdmInputNumber'
import AdmAlert from '@/views/_components/alert/AdmAlert'

export default {
  name: 'ChangeNumberOfUsersDialog',

  components: { AdmAlert, AdmInputNumber, AdmButton, AdmModal },

  mixins: [mixinPlans, mixinDateTime, mixinPrice],

  data: function () {
    return {
      loading: false,
      updating: false,
      numberOfUsers: 0,
    }
  },

  computed: {
    ...mapState({
      selectedPlan: state => state.billing.selectedPlan,
      tenantInfo: state => state.billing.tenantInfo,
      currencySymbol: state => state.billing.currencySymbol
    }),

    pricePerUser: function () {
      return this.selectedPlan.priceForUser.price
    },

    numberOfAdditionalUsers: function () {
      return this.numberOfUsers - this.tenantInfo.plan.maxNumberOfEmployees
    },

    totalPrice: function () {
      if (this.selectedPlan.billingInterval === 'yearly') {
        return this.numberOfAdditionalUsers * this.pricePerUser * 12
      }

      return this.numberOfAdditionalUsers * this.pricePerUser
    }
  },

  created () {
    this.numberOfUsers = this.tenantInfo.plan.maxNumberOfEmployees
  },

  methods: {
    changeNumberOfUsers () {
      this.updating = true

      this.$http.patch(`/api/v1/backoffice/plans/update/users`, {
        numberOfUsers: this.numberOfUsers
      }).then(response => {
        this.$message({ message: this.$t('request_has_been_sent'), type: 'success', showClose: true })
        this.onClose()
      }).catch(() => {
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        this.onClose()
      }).finally(() => {
        this.updating = false
      })
    },

    onClose () {
      this.$emit('close')
    },
  }
}
</script>

<style scoped lang="scss">
.adm--dialog {
  .change-number-of-users {
    box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    &__header {
      padding: 12px 16px;
      background-color: var(--primary-200);

      &__title {
        font-size: 16px;

        &__icon {
          height: 32px;
          width: 32px;
        }
      }

      &__price-interval {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__price {
          font-size: 16px;
        }
      }
    }

    &__body {
      padding: 0 24px;
      overflow: hidden;

      &__row {
        &__title, &__title__text {
          font-size: 15px;
        }

        &__amount {
          font-size: 15px;
          color: var(--primary-900);
        }
      }
    }
  }
}
</style>
