<template>

  <!-- Extras Details -->
  <div class="mt-4">
    <div v-for="extra in booking.extras" :key="extra.id" class="extras-details">
      <!-- Name, Duration & Quantity -->
      <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path class="customize-fill" d="M16.5 5.25h-9A2.25 2.25 0 005.25 7.5v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25zm.75 11.25a.75.75 0 01-.75.75h-9a.75.75 0 01-.75-.75v-9a.75.75 0 01.75-.75h9a.75.75 0 01.75.75v9z" fill="#005AEE"/><path class="customize-fill" d="M14.249 11.25h-1.5v-1.5a.75.75 0 10-1.5 0v1.5h-1.5a.75.75 0 100 1.5h1.5v1.5a.75.75 0 001.5 0v-1.5h1.5a.75.75 0 100-1.5z" fill="#005AEE"/></svg>
      <span class="extras-details__name ml-4">{{ extra.name || extra.extra.name }}</span>
      <span
        v-if="extra.duration || (extra.extra && extra.extra.duration)"
        class="extras-details__duration ml-8"
      >
        {{ secondsToNiceDuration(extra.duration || extra.extra.duration) }}
      </span>
      <adm-badge v-if="calculateExtraQuantity(booking, extra) > 1" class="ml-8">
        x{{ calculateExtraQuantity(booking, extra) }}
      </adm-badge>
      <!-- /Name, Duration & Quantity -->

    </div>
  </div>
  <!-- /Extras Details -->

</template>

<script>
import AdmBadge from '@/views/_components/badge/AdmBadge'
import mixinDuration from '@/mixins/common/duration'
import mixinPrice from '@/mixins/common/price'

export default {
  name: 'ExtrasDetails',

  components: {
    AdmBadge
  },

  mixins: [
    mixinDuration,
    mixinPrice
  ],

  props: {
    booking: {
      type: Object,
      required: true,
      default: () => ({})
    }
  },

  methods: {
    calculateExtraQuantity (booking, extra) {
      return extra.quantity * (extra.multiplyPriceWithNumberOfPeople ? (booking.additionalPersons + 1) : 1)
    }
  }
}
</script>

<style scoped lang="scss">
.extras-details {
  display: flex;
  align-items: center;
  max-width: 100%;

  &:not(:first-child) {
    margin-top: 4px;
    padding-top: 4px;
    border-top: 1px dashed $shade-300;
  }

  svg {
    width: 20px;
    flex: 0 0 auto;

    path {
      fill: $shade-900;
    }
  }

  span {
    font-size: 12px;
    line-height: 18px;
  }

  &__duration {
    white-space: nowrap;
  }

  &__name {
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>
