<template>
  <div class="pb-rich-text-wrapper">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'RichTextWrapper'
}
</script>

<style lang="scss">
.pb-rich-text-wrapper {

  &.dark {
    color: $white;
  }

  .ql-align-right {
    text-align: right;
  }
  .ql-align-center {
    text-align: center;
  }
  .ql-align-justify {
    text-align: justify;
  }
  ol, ul {
    padding-left: 1.5rem;
    list-style-position: inside;
  }

  ul {
    li {
      list-style-type: disc;
    }
  }

  @for $i from 1 through 10 {
    .ql-indent-#{$i} {
      padding-left: 2rem * $i;
    }
  }
}
</style>
