const getDefaultState = () => {
  return {
    coupons: { isEnabled: false, isVisible: false },
    custom_domain: { isEnabled: false, isVisible: false },
    custom_fields: { isEnabled: false, isVisible: false },
    custom_notifications: { isEnabled: false, isVisible: false },
    custom_roles: { isEnabled: false, isVisible: false },
    extras: { isEnabled: false, isVisible: false },
    facebook_pixel: { isEnabled: false, isVisible: false },
    google_analytics: { isEnabled: false, isVisible: false },
    google_calendar: { isEnabled: false, isVisible: false },
    google_tag_manager: { isEnabled: false, isVisible: false },
    group_booking: { isEnabled: false, isVisible: false },
    mailchimp_integration: { isEnabled: false, isVisible: false },
    multiple_locations: { isEnabled: false, isVisible: false },
    online_payments: { isEnabled: false, isVisible: false },
    outlook_calendar: { isEnabled: false, isVisible: false },
    recurring_appointments: { isEnabled: false, isVisible: false },
    sendfox_integration: { isEnabled: false, isVisible: false },
    smtp_email: { isEnabled: false, isVisible: false },
    special_days: { isEnabled: false, isVisible: false },
    webhooks: { isEnabled: false, isVisible: false },
    zapier: { isEnabled: false, isVisible: false },
    zoom_integration: { isEnabled: false, isVisible: false },
    whatsapp_integration: { isEnabled: false, isVisible: false },
    apple_calendar: { isEnabled: false, isVisible: false },
    reserve_with_google: { isEnabled: false, isVisible: false },
    booking_chat_bot: { isEnabled: false, isVisible: false },
    acumba: { isEnabled: false, isVisible: false },
    multilingual_support: { isEnabled: false, isVisible: false },
    wordpress: { isEnabled: false, isVisible: false },
    rating_review: { isEnabled: false, isVisible: false },
    resources: { isEnabled: false, isVisible: false },
  }
}

const state = {
  featuresList: getDefaultState(),
  planAvailableFeatures: [],
  featuresFilters: {
    search: '',
    category: 'all'
  }
}

const getters = {
  isFeatureEnabled: state => (featureCode) => {
    return state.featuresList[featureCode]?.isEnabled
  },

  isFeatureVisible: state => (featureCode) => {
    return state.featuresList[featureCode]?.isVisible
  },

  isFeatureAvailableInPlan: state => (featureCode, externalPlanAvailableFeatures = null) => {
    let planAvailableFeatures = state.planAvailableFeatures

    if (externalPlanAvailableFeatures) {
      planAvailableFeatures = externalPlanAvailableFeatures
    }

    if (!planAvailableFeatures.length) {
      return true
    }

    const feature = planAvailableFeatures.find(feature => feature.name === featureCode)

    if (feature) {
      return feature.isAvailable
    }

    return true
  },

  getPlansFeatureAvailableIn: state => (featureCode) => {
    let plans = []

    if (state.planAvailableFeatures.length) {
      const feature = state.planAvailableFeatures.find(feature => feature.name === featureCode)

      if (feature) {
        return feature.availableInPlans
      }
    }

    return plans
  }
}

const mutations = {
  setFeatures (state, payload) {
    Object.assign(state.featuresList, getDefaultState())

    for (let i = 0; i < payload.length; i++) {
      if (Object.prototype.hasOwnProperty.call(state.featuresList, payload[i].code)) {
        state.featuresList[payload[i].code] = {
          isEnabled: payload[i].isEnabled,
          isVisible: payload[i].isVisible,
          isFree: payload[i].isFree,
        }
      }
    }
  },

  setPlanAvailableFeatures (state, payload) {
    state.planAvailableFeatures = payload
  },

  activatePlanAvailableFeature (state, featureCode) {
    const feature = state.planAvailableFeatures.find(feature => feature.name === featureCode)
    feature.isAvailable = true
  },

  resetState (state) {
    Object.assign(state.featuresList, getDefaultState())
    Object.assign(state.planAvailableFeatures, [])
  },

  setFeaturesFilters (state, filters) {
    Object.assign(state.featuresFilters, filters)
  },
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
