const getDefaultManageDialogState = () => {
  return {
    isVisible: false,
    action: 'add',
    appointmentId: null,
    extendedForm: false,
    params: {},
  }
}
const getDefaultState = () => {
  return {
    slotsDates: [],
    queryParams: {
      service: undefined,
      employee: undefined,
      location: undefined,
      selectedSlot: undefined,
      extras: [],
    },
    manageDialog: getDefaultManageDialogState()
  }
}


const state = getDefaultState()


const getters = {
  isManageDialogVisible: state => () => {
    return state.manageDialog.isVisible
  },

  isFormExtended: state => () => {
    return state.manageDialog.extendedForm
  },

  getManageDialogAppointmentId: state => () => {
    return state.manageDialog.appointmentId
  },

  getManageDialogAction: state => () => {
    return state.manageDialog.action
  },

  getManageDialogParams: state => () => {
    return state.manageDialog.params
  }
}

const mutations = {
  setSlotDates (state, payload) {
    state.slotsDates = payload
  },

  setQueryParams (state, payload) {
    state.queryParams = payload
  },

  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  toggleExtendedForm (state) {
    state.manageDialog.extendedForm = !state.manageDialog.extendedForm
  },

  openManageDialog (state, payload) {
    state.manageDialog = {
      ...state.manageDialog,
      ...payload,
      isVisible: true,
    }
  },

  closeManageDialog (state) {
    Object.assign(state.manageDialog, getDefaultManageDialogState())
  }
}

const actions = {
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
