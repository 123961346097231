<template>
  <div class="survey">
    <!-- Survey Button -->
    <adm-button
      v-if="showSurveyButton"
      class="survey__button"
      @click="!isSurveyVisible ? openAppSurvey() : closeAppSurvey()"
    >
      <transition name="bounce" mode="out-in">
        <svg width="20" height="26" viewBox="0 0 20 26" fill="none" xmlns="http://www.w3.org/2000/svg" v-if="!isSurveyVisible" v-bind:key="'1'" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M6.143 3.572H3.57A2.571 2.571 0 001 6.144v15.428a2.571 2.571 0 002.571 2.572H16.43A2.57 2.57 0 0019 21.572V6.144a2.571 2.571 0 00-2.571-2.572h-2.572" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path d="M11.286 1H8.714a2.571 2.571 0 000 5.143h2.572a2.571 2.571 0 000-5.143zM6.143 12.572h.013M11.286 12.572h2.571M6.143 17.715h.013M11.286 17.715h2.571" stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-else="" v-bind:key="'2'" v-bind:svg-inline="''" v-bind:class="'svg-close'" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path d="M7.175 6l3.583-3.575A.837.837 0 009.575 1.24L6 4.824 2.425 1.241a.837.837 0 00-1.183 1.183L4.825 6 1.242 9.575a.833.833 0 000 1.183.833.833 0 001.183 0L6 7.175l3.575 3.583a.833.833 0 001.183 0 .833.833 0 000-1.183L7.175 6z" fill="#fff"/></svg>
      </transition>
    </adm-button>
    <!-- End Survey Button -->

    <!-- Survey Body -->
    <transition name="bounce">
      <div v-if="isSurveyVisible" class="app-survey">
        <!-- Survey Header -->
        <survey-header />
        <!-- End Survey Header -->

        <!-- Survey Content -->
        <el-progress
          :stroke-width="3"
          :show-text="false"
          :percentage="calculateProgress"
          :class="{'done-progress': calculateProgress === 100,
                   'app-survey__content__progress': calculateProgress !== 100}"
        />
        <div class="app-survey__content">
          <div v-show="getCurrentStep === 0" class="app-survey__content__image">
            <img alt="survey beacon home" src="@/assets/img/survey-home.png">
          </div>
          <div v-show="getCurrentStep === steps.length-1" class="app-survey__content__image">
            <img alt="survey beacon home" src="@/assets/img/survey-end.png">
          </div>
          <div v-for="(step, stepIndex) in steps" :key="stepIndex">
            <p :class="{'active': stepIndex === getCurrentStep,
                        'app-survey__content__text': true,
                        'is-question': getCurrentStep !== 0,
                        'text-center': getCurrentStep === steps.length-1}"
            >
              {{ step.question }}
            </p>
            <div
              v-if="step.type === 'checkbox'"
              :class="{'active': stepIndex === getCurrentStep,
                       'app-survey__content__answers': true}"
            >
              <adm-checkbox-group v-model="answers[step.key]">
                <adm-checkbox
                  v-for="(option, optionIndex) in step.options"
                  :key="optionIndex"
                  :label="option.option"
                >
                  {{ option.option }}
                  <adm-input
                    v-if="answers[step.key] && answers[step.key].includes(option.option) && option.showOther" v-model="answersOther[step.key][option.option]" type="textarea" placeholder="Please enter"
                  />
                </adm-checkbox>
              </adm-checkbox-group>
            </div>
            <div
              v-if="step.type === 'textarea'"
              :class="{'active': stepIndex === getCurrentStep,
                       'app-survey__content__textarea': true}"
            >
              <adm-input v-model="answers[step.key]" type="textarea" placeholder="Answer here (optional)" :rows="4" />
            </div>
            <div
              v-if="step.description"
              :class="{'active': stepIndex === getCurrentStep,
                       'app-survey__content__description': true}"
            >
              <p>
                {{ step.description }}
              </p>
            </div>
          </div>
        </div>
        <!-- End Survey Content -->

        <!-- Survey Footer -->
        <survey-footer
          :steps="steps"
          :is-selected-some-answer="isSelectedSomeAnswer"
          @nextStep="nextStep"
          @hide="hideBeacon"
        />
        <!-- End Survey Footer -->
      </div>
    </transition>
    <!-- End Survey Body -->
  </div>
</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import SurveyHeader from '@/views/Dashboard/Survey/SurveyHeader/SurveyHeader'
import SurveyFooter from '@/views/Dashboard/Survey/SurveyFooter/SurveyFooter'
import AdmCheckbox from '@/views/_components/checkbox/AdmCheckbox'
import AdmInput from '@/views/_components/input/AdmInput'
import AdmCheckboxGroup from '@/views/_components/checkbox/AdmCheckboxGroup'

export default {
  name: 'Survey',
  components: {
    AdmCheckboxGroup,
    AdmInput,
    AdmCheckbox,
    SurveyFooter,
    SurveyHeader,
    AdmButton
  },

  data () {
    return {
      steps: [],
      answers: {},
      answersOther: {},
    }
  },

  computed: {
    isSurveyVisible () {
      return this.$store.state.survey.isSurveyVisible
    },

    getCurrentStep () {
      return this.$store.state.survey.currentStep
    },

    calculateProgress () {
      let progress = 0
      this.steps.forEach((key, index) => {
        if (index < this.$store.state.survey.currentStep) {
          progress += 15
        }
      })
      return progress > 100 ? 100 : progress
    },

    isSelectedSomeAnswer () {
      let currentStep = this.$store.state.survey.currentStep
      let haveAnswers = false;

      if(currentStep === 0 || (this.steps[currentStep] && this.steps[currentStep]['type'] === 'textarea')) {
        return true
      }
      for(let step of this.steps) {
        Object.entries(this.answers).forEach(([key, value]) => {
          if(key === this.steps[currentStep]['key'] && value.length) {
            haveAnswers = true
          }
        })
      }
      return haveAnswers
    },

    showSurveyButton () {
      if (window.language !== 'en') {
        return false
      }

      if (!this.$store.getters['user/isSuperAdmin']) {
        return false
      }

      return this.userCreatedBeforeSevenDays() &&
        !this.$store.getters['settings/isAgency'] &&
        this.$store.state.survey.showSurvey
    },
  },

  watch: {
    showSurveyButton () {
      // get data
      this.$http.get('/api/v1/survey').then(response => {
        this.setDefaultOtherFields(response.data)
        this.steps = response.data
      })

      // open survey after 15s if it's not minimized
      if(this.$store.state.settings.survey && !this.$store.state.settings.survey.isSurveyMinimized) {
        setTimeout(() => {
            this.$store.commit('survey/setSurveyOpenOrClosed', true)
        }, 15000)
      }

      this.hideBeacon()
    }
  },

  methods: {
    setDefaultOtherFields (steps) {
      let answersOtherTemp = {}

      if (steps) {
        steps.forEach(value => {
          if(value.key && value.options) {
            value.options.forEach(option => {
              if(option.showOther) {
                if(!answersOtherTemp[value.key]) {
                  answersOtherTemp[value.key] = {}
                }
              }
            })
          }
        })

        this.answersOther = answersOtherTemp
      }
    },

    openAppSurvey () {
      Beacon('close')
      this.$store.commit('survey/setSurveyOpenOrClosed', true)
    },

    closeAppSurvey () {
      this.$http.post('/api/v1/survey/minimized-survey', {})
      this.$store.commit('survey/setSurveyOpenOrClosed', false)
    },

    saveAnswers () {
      this.$http.post('/api/v1/survey/save-answers',
        {other: this.answersOther, answers: this.answers})
    },

    userCreatedBeforeSevenDays () {
      let registrationDate = Date.parse(this.$store.state.settings.general.registrationDate);
      let today = Date.now();

      return Math.round((today - registrationDate) / (1000 * 3600 * 24)) >= 7
    },

    nextStep () {
      this.$store.commit('survey/incrementStep');

      if(this.$store.state.survey.currentStep === this.steps.length - 1) {
        this.saveAnswers()
      }
    },

    hideBeacon () {
      // close survey when beacon is open
      Beacon('on', 'open', () => this.$store.commit('survey/setSurveyOpenOrClosed', false))
    }
  }
}
</script>

<style lang="scss" scoped>
.survey {
  &__button {
    background: #00143C;
    border-radius: 50%;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;

    position: fixed;
    bottom: 10px;
    right: 100px;
    z-index: 10;

    @media only screen and (max-height: 740px) {
      bottom: 10px;
      right: 100px;
    }

    &:hover {
      background: rgba(0, 20, 60, 0.9);
    }

    svg {
      margin-top: 5px;
    }

    .svg-close {
      width: 17px;
      height: 17px;
      margin-top: 7px;
    }
  }

  .app-survey {
    position: fixed;
    bottom: 86px;
    right: 100px;
    z-index: 10;
    width: 388px;
    filter: drop-shadow(0px 4px 24px rgba(0, 0, 0, 0.24));

    @media only screen and (max-height: 740px) {
      bottom: 85px;
      right: 100px;
    }

    &__content {
      height: inherit;
      background: linear-gradient(180deg, #E6EFFE 0%, #FFFFFF 100%);
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      align-items: center;
      overflow: hidden;

      &>div {
        margin: 0 auto;
        width: 90%;
      }

      p, &__answers, &__textarea, &__description {
        display: none;
      }

      .active {
        display: block;
      }

      &__text {
        font-size: 15px;
        line-height: 24px;
        width: 348px;
        margin-top: 10px;
        margin-bottom: 24px;
      }

      &__image {
        text-align: center;
      }

      .is-question {
        margin-top: 24px;
      }

      .text-center {
        text-align: center;
        margin-top: 0;
        margin-bottom: 0;
      }

      &__progress {
        width: 100%;
        display: block;
        position: absolute;
        top: 60px;
        left: 0;
        border-radius: 0 2px 2px 0;
        background: linear-gradient(270deg, #005AEE 0%, #669CF5 100%);
      }

      &__answers {
        margin-top: 20px;
        margin-bottom: 15px;

        .adm-checkbox-wrapper {
          padding-bottom: 10px;
        }

        ::v-deep textarea {
          max-height: 64px;
          width: 348px;
          margin-left: -29px;
          margin-top: 16px;
        }
      }

      &__description {
        text-align: center;
        line-height: 20px;
        color: #66728A;
        margin-bottom: 24px;

        p {
          display: block;
        }
      }

      &__textarea {
        margin-bottom: 15px;
        margin-top: 24px;

        ::v-deep textarea {
          max-height: 100px;
        }
      }
    }
  }

  @include tablet-down {
    &__button {
      right: 40px;
      bottom: 108px;
    }

    .app-survey {
      right: 40px;
      bottom: 184px;
    }
  }

  @include phone-down {
    .app-survey, &__button {
      display: none;
    }
  }

  @media all and (max-width: 1024px) and (max-height: 500px) and (orientation: landscape) {
    display: none;
  }

  .done-progress ::v-deep .el-progress-bar__inner {
    background: linear-gradient(270deg, #18CA75 0%, #52D797 100%) !important;
  }

  .bounce-enter-active {
    animation: bounce-in 0.2s;
  }
  .bounce-leave-active {
    animation: bounce-in 0.2s reverse;
  }
  @keyframes bounce-in {
    0% {
      transform: scale(0);
    }
    50% {
      transform: scale(1);
    }
    100% {
      transform: scale(1);
    }
  }
}
</style>
