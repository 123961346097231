<template>

  <!-- Email Unsubscribe Page -->
  <div id="email-unsubscribe">

    <div>
      <!-- Logo -->
      <div class="unsubscribe-logo">
        <img alt="logo" src="@/assets/img/logo/logo-full.svg">
      </div>
      <!-- /Logo -->

      <!-- Unsubscribe Form -->
      <el-form
        class="unsubscribe-form-container"
        @submit.prevent.native="handleSubmit"
      >

        <!-- Title -->
        <h1>{{ $transWithBusinessName($t('you_have_successfully_unsubscribed')) }}</h1>
        <!-- /Title -->

        <adm-form-item
          class="form-content"
          :label="$t('if_you_have_a_moment_please_let_us_know')"
        >

          <el-radio-group
            v-model="reason"
            :disabled="isSubmitted"
          >

            <!-- List Of Reasons -->
            <div v-for="(reasonItem, id) in reasons" :key="id">
              <el-radio :label="id">
                {{ reasonItem }}
              </el-radio>
            </div>
            <!-- /List Of Reasons -->

          </el-radio-group>
        </adm-form-item>

        <!-- Submit Button -->
        <adm-button
          :disabled="isSubmitDisabled()"
          native-type="submit"
        >
          {{ $t('submit') }}
        </adm-button>
        <!-- /Submit Button -->

        <!-- Success Message -->
        <transition name="fade">
          <adm-alert
            v-if="isSubmitted"
            class="mt-20"
            type="positive"
            :title="$t('thanks_for_the_feedback')"
          />
        </transition>
        <!-- /Success Message -->

      </el-form>
      <!-- /Unsubscribe Form -->

    </div>

  </div>
  <!-- /Email Unsubscribe Page -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmFormItem from '@/views/_components/form/AdmFormItem'
import AdmAlert from '@/views/_components/alert/AdmAlert'

export default {
  name: 'EmailUnsubscribe',

  components: {
    AdmAlert,
    AdmButton,
    AdmFormItem,
  },

  data () {
    return {
      reasons: [],
      reason: null,
      submitInProgress: false,
      isSubmitted: false,
    }
  },

  created () {
    this.getReasons()
    this.unsubscribe()
  },

  methods: {
    isSubmitDisabled () {
      return !this.reason || this.submitInProgress || this.isSubmitted
    },

    getReasons () {
      this.$http.get(
        '/api/v1/public/unsubscribe/reasons'
      ).then(response => {
        this.reasons = response.data
      })
    },

    unsubscribe () {
      this.$http.post(
        '/api/v1/public/unsubscribe', {
          reason: '',
          hash: this.$route.query.hash,
          email: this.$route.query.email
        },
      ).catch(() => {
        this.$router.push({ 'name': '404' })
      })
    },

    handleSubmit () {
      this.submitInProgress = true

      this.$http.post(
        '/api/v1/public/unsubscribe', {
          reason: this.reason,
          hash: this.$route.query.hash,
          email: this.$route.query.email
        },
      ).then(() => {
        this.isSubmitted = true
      }).finally(() => {
        this.submitInProgress = false
      })
    },
  }
}
</script>

<style lang="scss">
#email-unsubscribe {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  padding-top: 48px;
  height: 100vh;
  background: #F5F6FA url('~@/assets/img/hero.svg') no-repeat top;
  background-size: 100% auto;

  position: relative;

  .unsubscribe-logo {
    display: flex;
    justify-content: center;
    margin-bottom: 49px;

    img {
      height: 40px;
    }
  }

  .unsubscribe-form-container {
    margin: 0 auto;
    background: $adm-white;
    max-width: 456px;
    padding: 48px;
    @media screen and (max-width: $bp-small-min) {
      padding: 15px;
    }
    box-shadow: 0 22px 36px rgba(0, 20, 60, 0.07), 0 2.75474px 4.50776px rgba(0, 20, 60, 0.035);
    border-radius: 13px;

    .adm-form-item {
      margin-bottom: 16px;
    }

    .el-form-item__label {
      font-size: 15px;
      font-weight: normal;
      text-align: left;
      padding-left: 0;
      padding-bottom: 8px;
    }

    .el-radio {
      margin-bottom: 8px;

      &__label {
        padding-left: 8px;
        font-size: 15px;
        font-weight: normal;
      }
    }
  }

  h1 {
    font-weight: 500;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: -1px;
    margin-bottom: 24px;
  }

}
</style>
