<template>
  <adm-modal
    class="adm--dialog"
    visible
    width="484px"
    :title="`${ $t(`change`) } ${ $t('your_plan').toLowerCase() }`"
    @close="onClose"
  >
    <div class="change-plan-cards mb-24">
      <!-- Current plan includes -->
      <div class="change-plan-cards__change-plan-card current">
        <div class="change-plan-cards__change-plan-card__title semi-bold mb-16">
          {{ getPlanLabel(tenantInfo.plan) }} ({{ $t('current').toLowerCase() }})
        </div>

        <div class="change-plan-cards__change-plan-card__features-list">
          <div
            v-for="inclusion in getIncludesForPlanChange(tenantInfo.plan)"
            :key="inclusion"
            class="change-plan-cards__change-plan-card__features-list__feature"
          >
            <adm-icon icon="check" />
            <span>{{ inclusion }}</span>
          </div>
        </div>
      </div>
      <!-- /Current plan includes -->

      <adm-icon class="change-plan-cards__arrow" icon="big-arrow-right" />

      <!-- New plan includes -->
      <div class="change-plan-cards__change-plan-card new">
        <div class="change-plan-cards__change-plan-card__title semi-bold mb-16">
          {{ getPlanLabel(selectedPlan) }} ({{ $t('new').toLowerCase() }})
        </div>

        <div class="change-plan-cards__change-plan-card__features-list">
          <div
            v-for="inclusion in getIncludesForPlanChange(selectedPlan)"
            :key="inclusion"
            class="change-plan-cards__change-plan-card__features-list__feature"
          >
            <adm-icon icon="check" />
            <span>{{ inclusion }}</span>
          </div>
        </div>
      </div>
      <!-- /New plan includes -->
    </div>

    <div class="new-plan-block">
      <!-- New Plan Info -->
      <div class="new-plan-block__header justify-between">
        <div class="new-plan-block__header__title flex-center">
          <img class="new-plan-block__header__title__icon" :src="getPlanIcon(selectedPlan)">
          <span class="semi-bold ml-12">
            {{ getPlanLabel(selectedPlan) }}
          </span>
        </div>
        <div class="new-plan-block__header__price-interval">
          <div class="new-plan-block__header__price-interval__price semi-bold">
            {{ `${ selectedPlan.price.net } ${ currencySymbol }` }}
          </div>
          <div class="new-plan-block__grey-text">
            {{ (`${ $t('billed') } ${ $t(`${ selectedPlan.billingInterval }`) }`).toLowerCase() }}
          </div>
        </div>
      </div>
      <!-- /New Plan Info -->

      <div class="new-plan-block__body">
        <!-- Charged amount -->
        <div
          v-if="!loading && planImpact.immediate_payment"
          class="new-plan-block__body__row"
          :class="[ planImpact.immediate_payment.amount < 0 ? 'with-credit' : 'with-charge' ]"
        >
          <adm-alert class="mb-8">
            <template #description>
              {{ `${ $t('the_number_of_users_will_be_set_to') } ${ selectedPlan.maxNumberOfEmployees } ${ $t('according_to_the_plan_limit') }, ${ $t('however_you_can_customize_the_number') }` }}
            </template>
          </adm-alert>

          <adm-alert
            v-if="planImpact.immediate_payment.amount < 0"
            class="mb-8"
            :description="`You currently have ${ Math.abs(planImpact.immediate_payment.amount) } ${ planImpact.immediate_payment.currency } credit balance, and you will be billed 0.00 ${ planImpact.immediate_payment.currency } on ${ formatDate(planImpact.immediate_payment.date) }. Once the credit balance is used up, your recurring billing amount will be ${ planImpact.next_payment.amount } ${ planImpact.next_payment.currency } ${ $t(`${ selectedPlan.billingInterval }`).toLowerCase() }.`"
            full-width
          />

          <div v-else class="justify-between flex-center mt-16">
            <div class="new-plan-block__body__row__title semi-bold">
              {{ $t('you_will_be_charged') }}
            </div>
            <div class="new-plan-block__body__row__amount bold">
              {{ `${ planImpact.immediate_payment.amount } ${ planImpact.immediate_payment.currency }` }}
            </div>
          </div>
        </div>

        <adm-skeleton-element v-if="loading" :height="44" :margin-top="16" :margin-bottom="16" />
        <!-- /Charged amount -->

        <!-- Next payment -->
        <div
          v-if="!loading && planImpact.next_payment"
          class="new-plan-block__body__row justify-between flex-center"
        >
          <div class="new-plan-block__body__row__title">
            <div class="new-plan-block__body__next-payment__title__text semi-bold">
              {{ $t('next_payment') }}
              {{ ` (${ $t('tax_included').toLowerCase() })` }}
            </div>
            <div class="new-plan-block__grey-text">
              {{ formatDate(planImpact.next_payment.date) }}
            </div>
          </div>
          <div class="new-plan-block__body__row__amount bold">
            {{ `${ planImpact.next_payment.amount } ${ planImpact.next_payment.currency }` }}
          </div>
        </div>

        <adm-skeleton-element v-if="loading" :height="44" :margin-top="16" :margin-bottom="16" />
        <!-- /Next payment -->

        <adm-alert
          v-if="tenantInfo.subscription.status === 'trialing'"
          class="my-16"
          type="warning"
          :description="$t('plan_can_be_changed_only_to_free')"
        />
      </div>
    </div>

    <!-- Save & Cancel Buttons -->
    <template #footer>

      <!-- Cancel Button -->
      <adm-button
        size="medium"
        color="grey"
        @click="onClose"
      >
        {{ $t('cancel') }}
      </adm-button>
      <!-- /Cancel Button -->

      <adm-button
        size="medium"
        :disabled="tenantInfo.subscription.status === 'trialing'"
        :loading="updating"
        @click="updatePlan"
      >
        {{ $t('continue') }}
      </adm-button>
      <!-- /Save Button -->

    </template>
    <!-- /Save & Cancel Buttons -->
  </adm-modal>
</template>

<script>
import AdmModal from '@/views/_components/modal/AdmModal'
import AdmIcon from '@/views/_components/icon/AdmIcon'
import mixinPlans from '@/mixins/plans/plans'
import mixinDateTime from '@/mixins/common/datetime'
import mixinPrice from '@/mixins/common/price'
import AdmButton from '@/views/_components/button/AdmButton'
import AdmAlert from '@/views/_components/alert/AdmAlert'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import { mapState } from 'vuex'

export default {
  name: 'ChangePlanDialog',

  components: { AdmSkeletonElement, AdmAlert, AdmButton, AdmIcon, AdmModal },

  mixins: [mixinPlans, mixinDateTime, mixinPrice],

  data: function () {
    return {
      loading: false,
      updating: false,
      planImpact: {},
    }
  },

  computed: {
    ...mapState({
      selectedPlan: state => state.billing.selectedPlan,
      tenantInfo: state => state.billing.tenantInfo,
      currencySymbol: state => state.billing.currencySymbol
    })
  },

  created () {
    this.getPlanImpact()
  },

  methods: {
    getPlanImpact () {
      this.loading = true

      this.$http.get(`/api/v1/backoffice/plans/impact?planId=${ this.getPaddleProductId(this.selectedPlan) }`)
        .then(response => {
          this.planImpact = response.data.planImpact
        }).catch(() => {
          this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        }).finally(() => {
          this.loading = false
        })
    },

    updatePlan () {
      this.updating = true

      this.$http.patch(`/api/v1/backoffice/plans/update`, {
        planName: this.selectedPlan.name,
        billingInterval: this.selectedPlan.billingInterval,
      }).then(() => {
        this.$message({ message: this.$t('plan_has_been_updated'), type: 'success', showClose: true })
        window.location.reload()
      }).catch(() => {
        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        this.onClose()
      }).finally(() => {
        this.updating = false
      })
    },

    onClose () {
      this.$emit('close')
    },
  }
}
</script>

<style scoped lang="scss">
.adm--dialog {
  .change-plan-cards {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    @include phone-down {
      flex-direction: column;
    }

    &__change-plan-card {
      padding: 16px;
      border: 1px solid var(--primary-600);
      border-radius: 8px;
      flex: 1;

      &.new {
        border-color:  var(--primary-900);
        background-color: var(--primary-200);

        .adm-icon {
          color: $yellow-900;
        }
      }

      &__title {
        font-size: 13px;
        line-height: 16px;
      }

      &__features-list {
        display: flex;
        flex-direction: column;
        gap: 4px;

        &__feature {
          display: flex;
          align-items: flex-start;
          gap: 4px;
          font-size: 13px;
        }
      }
    }

    &__arrow {
      align-self: center;
      justify-self: center;

      @include phone-down {
        transform: rotate(90deg);
      }
    }
  }

  .new-plan-block {
    box-shadow: 0 2px 7px -1px rgba(0, 0, 0, 0.12);
    border-radius: 5px;

    &__header {
      padding: 12px 16px;
      background-color: var(--primary-200);

      &__title {
        font-size: 16px;

        &__icon {
          height: 32px;
          width: 32px;
        }
      }

      &__price-interval {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        &__price {
          font-size: 16px;
        }
      }
    }

    &__body {
      padding: 0 24px;
      overflow: hidden;

      &__row {
        padding: 16px 0;
        min-height: 76px;

        &.with-charge {
          border-bottom: 1px dashed $shade-300;
        }

        &.with-credit {
          min-height: auto;
          padding-bottom: 0;
        }

        &__title, &__title__text {
          font-size: 15px;
        }

        &__amount {
          font-size: 15px;
          color: var(--primary-900);
        }
      }
    }

    &__grey-text {
      font-size: 13px;
      line-height: 16px;
      color: $shade-500;
    }
  }
}
</style>
