<template>

  <!-- Dropdown Menu -->
  <el-dropdown-menu
    :append-to-body="appendToBody"
    class="adm-dropdown-menu"
    :visible-arrow="false"
  >
    <slot />
  </el-dropdown-menu>
  <!-- /Dropdown Menu -->

</template>

<script>
export default {
  name: 'AdmDropdownMenu',

  props: {
    appendToBody: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
// Dropdown Menu
.adm-dropdown-menu {
  box-shadow: $box-shadow-dropdown;
  border-radius: 4px;
  border: 1px solid $shade-200;
  background: $adm-white;
  padding: 5px 7px;
  min-width: 120px;

  // Margin From Triggering Element
  &[x-placement^=bottom] {
    margin-top: 0.25rem;
  }
}
</style>
