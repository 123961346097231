<template>

  <!-- Badge -->
  <adm-tag class="adm-badge" v-bind="$props">
    <slot />
  </adm-tag>
  <!-- /Badge -->

</template>

<script>
import AdmTag from '@/views/_components/tag/AdmTag'

export default {
  name: 'AdmBadge',

  components: {
    AdmTag
  },

  props: {
    type: {
      type: String,
      default: 'default',
      validator (value) {
        return [
          'default',
          'primary',
          'positive',
          'danger',
          'danger-secondary',
          'success',
        ].indexOf(value) !== -1
      }
    },
    backgroundColor: {
      type: String,
      default: null,
      required: false,
    },
    textColor: {
      type: String,
      default: null,
      required: false,
    },
  },
}
</script>

<style lang="scss" scoped>
// Badge
.adm-badge {
  justify-content: center;
  border: none;
  font-weight: 600;
  height: 1rem;
  min-width: 1rem;
}
</style>
