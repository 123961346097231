<template>
  <!-- Design System Tooltip -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Tooltip
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
    >

      <!-- Preview -->
      <template #preview>
        <adm-tooltip content="Lorem Ipsum">
          <adm-button>Tooltip Button</adm-button>
        </adm-tooltip>

        <adm-tooltip placement="top" content="Lorem Ipsum">
          <adm-button class="ml-12">
            Tooltip Button
          </adm-button>
        </adm-tooltip>

        <adm-tooltip placement="left-end" content="Lorem Ipsum">
          <adm-button class="ml-12">
            Tooltip Button
          </adm-button>
        </adm-tooltip>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getTooltipCode({content: 'Lorem Ipsum'}) }}
        <br>
        {{ getTooltipCode({content: 'Lorem Ipsum', placement: 'top'}) }}
        <br>
        {{ getTooltipCode({content: 'Lorem Ipsum', placement: 'left-end'}) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Tooltip Attributes -->
    <design-system-documentation-table :table-data="tableData" title="Tooltip attributes" />
    <!-- /Tooltip Attributes -->

    <!-- Tooltip Slots -->
    <design-system-documentation-table title="Tooltip Slots" :table-data="tableDataSlots" type="slots" />
    <!-- /Tooltip Slots -->

  </div>
  <!-- /Design System Tooltip -->

</template>

<script>
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import mixinDesignSystem from '@/mixins/page/designSystem'
import AdmButton from '@/views/_components/button/AdmButton'
import AdmTooltip from '@/views/_components/tooltip/AdmTooltip'

export default {
  name: 'DesignSystemTooltip',
  components: { AdmTooltip, AdmButton, DesignSystemDocumentationTable, DesignSystemElementBlock },
  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: '',
      sizes: {
        default: '',
        medium: '',
        small: ''
      },
      tableData: [
        {
          attribute: 'placement',
          description: 'position of Tooltip',
          type: 'string',
          values: 'top/top-start/top-end/bottom/bottom-start/bottom-end/left/left-start/left-end/right/right-start/right-end',
          default: 'bottom',
        },
        {
          attribute: 'content',
          description: 'content of tooltip',
          type: 'string',
          values: '-',
          default: '',
        },
        {
          attribute: 'open-delay',
          description: 'delay of appearance, in millisecond',
          type: 'string',
          values: '-',
          default: '0',
        },
        {
          attribute: 'disabled',
          description: 'whether Tooltip is disabled',
          type: 'Boolean',
          values: 'true/false',
          default: 'false',
        },
      ],
      tableDataSlots: [
        {
          name: 'content',
          description: 'same as prop content just allows html',
        }
      ]
    }
  },
}
</script>
