<template>

  <!-- Design System Colors -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Color
    </h2>
    <!-- /Title -->

    <!-- Rows -->
    <div class="adm-design-system-colors">
      <div v-for="(group, index) in groups" :key="index" class="adm-design-system-colors__row">
        <h3>{{ group.name }}</h3>
        <div v-for="color in group.colors" :key="color.variable">
          <span class="adm-design-system-colors__variable">
            ${{ color.variable }}
          </span>
          <span class="adm-design-system-colors__hex">
            ({{ color.hex }})
          </span>
          <div
            :style="{ backgroundColor: color.hex, border: color.outline ? '2px solid' : null }"
            class="adm-design-system-colors__block"
          />
        </div>
      </div>
    </div>
    <!-- /Rows -->

  </div>
  <!-- /Design System Colors -->

</template>

<script>
export default {
  name: 'DesignSystemColor',

  mixins: [],

  data () {
    return {
      groups: [
        {
          name: 'Black & White',
          colors: [
            {
              variable: 'adm-black',
              hex: this.$colors['adm-black']
            },
            {
              variable: 'adm-white',
              hex: this.$colors['adm-white'],
              outline: true
            }
          ]
        },
        {
          name: 'Shade',
          colors: [
            {
              variable: 'shade-1000',
              hex: this.$colors['shade-1000']
            },
            {
              variable: 'shade-900',
              hex: this.$colors['shade-900']
            },
            {
              variable: 'shade-800',
              hex: this.$colors['shade-800']
            },
            {
              variable: 'shade-700',
              hex: this.$colors['shade-700']
            },
            {
              variable: 'shade-600',
              hex: this.$colors['shade-600']
            },
            {
              variable: 'shade-500',
              hex: this.$colors['shade-500']
            },
            {
              variable: 'shade-450',
              hex: this.$colors['shade-450']
            },
            {
              variable: 'shade-400',
              hex: this.$colors['shade-400']
            },
            {
              variable: 'shade-300',
              hex: this.$colors['shade-300']
            },
            {
              variable: 'shade-250',
              hex: this.$colors['shade-250']
            },
            {
              variable: 'shade-200',
              hex: this.$colors['shade-200']
            },
            {
              variable: 'shade-150',
              hex: this.$colors['shade-150']
            }
          ]
        },
        {
          name: 'Blue',
          colors: [
            {
              variable: 'blue-1000',
              hex: this.$colors['--primary-1000']
            },
            {
              variable: 'blue-900',
              hex: this.$colors['--primary-900']
            },
            {
              variable: 'blue-800',
              hex: this.$colors['--primary-800']
            },
            {
              variable: 'blue-700',
              hex: this.$colors['--primary-700']
            },
            {
              variable: 'blue-600',
              hex: this.$colors['--primary-600']
            },
            {
              variable: 'blue-500',
              hex: this.$colors['--primary-500']
            },
            {
              variable: 'blue-400',
              hex: this.$colors['--primary-400']
            },
            {
              variable: 'blue-300',
              hex: this.$colors['--primary-300']
            },
            {
              variable: 'blue-200',
              hex: this.$colors['--primary-200']
            }
          ]
        },
        {
          name: 'Green',
          colors: [
            {
              variable: 'green-1000',
              hex: this.$colors['green-1000']
            },
            {
              variable: 'green-900',
              hex: this.$colors['green-900']
            },
            {
              variable: 'green-800',
              hex: this.$colors['green-800']
            },
            {
              variable: 'green-700',
              hex: this.$colors['green-700']
            },
            {
              variable: 'green-600',
              hex: this.$colors['green-600']
            },
            {
              variable: 'green-500',
              hex: this.$colors['green-500']
            },
            {
              variable: 'green-400',
              hex: this.$colors['green-400']
            },
            {
              variable: 'green-300',
              hex: this.$colors['green-300']
            }
          ]
        },
        {
          name: 'Red',
          colors: [
            {
              variable: 'red-1000',
              hex: this.$colors['red-1000']
            },
            {
              variable: 'red-900',
              hex: this.$colors['red-900']
            },
            {
              variable: 'red-800',
              hex: this.$colors['red-800']
            },
            {
              variable: 'red-700',
              hex: this.$colors['red-700']
            },
            {
              variable: 'red-600',
              hex: this.$colors['red-600']
            },
            {
              variable: 'red-500',
              hex: this.$colors['red-500']
            },
            {
              variable: 'red-400',
              hex: this.$colors['red-400']
            },
            {
              variable: 'red-300',
              hex: this.$colors['red-300']
            }
          ]
        },
      ]
    }
  }
}
</script>

<style lang="scss">
// Design System Colors Page
.adm-design-system-colors {
  display: flex;

  // Row
  &__row {
    display: block;

    h3 {
      margin-bottom: .5rem;
    }
  }

  // Variable
  &__variable {
    font-weight: 500;
  }


  // Block
  &__block {
    display: block;
    width: 200px;
    height: 40px;
    margin-right: 2rem;
    border-radius: 8px;
    margin-bottom: 0.25rem;
  }
}
</style>
