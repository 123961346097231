import AdmConfirmTemplate from './AdmConfirm.vue'

const AdmConfirm = {
  // every plugin for Vue.js needs install method
  // this method will run after Vue.use(<your-plugin-here>) is executed
  install(Vue, options) {
    Vue.component('AdmConfirmTemplate', AdmConfirmTemplate)

    // exposing global modal dialog object with method show()
    // method show() takes title, description and options as argument
    Vue.prototype.$admConfirm = new (Vue.extend(AdmConfirmTemplate))({propsData: options})
    Vue.admConfirm = Vue.prototype.$admConfirm
  }
}

export default AdmConfirm
