<template>

  <!-- Employee Info -->
  <div class="section section-divided">

    <!-- Title -->
    <div class="section-title flex-center">
      <adm-icon icon="employee" />
      <span>{{ $t('employee') }}</span>
    </div>
    <!-- /Title -->

    <!-- Employee -->
    <div class="flex-center">

      <!-- Avatar -->
      <adm-avatar
        v-if="showData"
        :is-human="true"
        :margin-right="10"
        :name="`${ employee.firstName } ${ employee.lastName }`"
        :size="34"
        :src="employee.mediaUrl"
      />
      <adm-skeleton-element v-else :border-radius="17" :height="34" :margin-right="10" :width="34" />
      <!-- /Avatar -->

      <!-- Name & Info -->
      <div class="flex-center">

        <!-- Name -->
        <h6 v-if="showData">
          {{ employee.firstName + ' ' + employee.lastName }}
          <span v-if="employeePriceDifference !== 0" class="ml-8 section-text">
            {{ formatPrice(employeePriceDifference) }}
          </span>
        </h6>
        <adm-skeleton-element v-else :height="16" :width="120" />
        <!-- /Name -->

      </div>
      <!-- /Name & Info -->

    </div>
    <!-- /Employee -->

  </div>
  <!-- /Employee Info -->

</template>

<script>
import AdmAvatar from '@/views/_components/avatar/AdmAvatar'
import AdmIcon from '@/views/_components/icon/AdmIcon'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import mixinPrice from '@/mixins/common/price'

export default {
  name: 'EmployeeInfo',

  components: {
    AdmIcon,
    AdmAvatar,
    AdmSkeletonElement,
  },

  mixins: [
    mixinPrice
  ],

  props: {
    appointmentProp: {
      type: Object,
      default: () => {}
    }
  },

  computed: {
    employee () {
      return this.appointmentProp.employee
    },

    employeePriceDifference () {
      const appointment = this.appointmentProp

      if (appointment.employeeFee) {
        return appointment.employeeFee
      }

      let priceDifference = 0

      if (appointment.service?.servicePrice) {
        if (appointment.employee?.price) {
          priceDifference = appointment.employee.price - appointment.service.servicePrice
        }

        if (appointment.service?.employeePrice) {
          priceDifference = appointment.service.employeePrice - appointment.service.servicePrice
        }
      }

      return priceDifference
    },

    showData () {
      return this.employee
    }
  },
}
</script>
