<template>

  <!-- Design System Time Select -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Time Select
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
    >

      <!-- Preview -->
      <template #preview>
        <adm-time-select
          v-model="basicUsage"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Size -->
    <design-system-element-block
      title="Size"
    >

      <!-- Preview -->
      <template #preview>
        <adm-time-select
          v-model="sizes.default"
          max-width="200px"
        />
        <adm-time-select
          v-model="sizes.medium"
          class="ml-16"
          size="medium"
          max-width="200px"
        />
        <adm-time-select
          v-model="sizes.small"
          class="ml-16"
          size="small"
          max-width="200px"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getSelectCode({ 'v-model': 'select' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Size -->

    <!-- Time Select Attributes -->
    <design-system-documentation-table :table-data="tableData" title="Time Select attributes" />
    <!-- /Time Select Attributes -->

  </div>
  <!-- /Design System Time Select -->

</template>

<script>
import AdmTimeSelect from '@/views/_components/timeSelect/AdmTimeSelect'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'

export default {
  name: 'DesignSystemSelect',

  components: {
    AdmTimeSelect,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      basicUsage: '',
      sizes: {
        default: '',
        medium: '',
        small: ''
      },
      tableData: [
        {
          attribute: 'value / v-model',
          description: 'binding value',
          type: 'boolean / string / number / array / object',
          values: '-',
          default: '-',
        },
      ],
    }
  },
}
</script>

