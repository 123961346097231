
export default {
  methods: {
    async toggleServiceStatus (service) {
      try {
        this.toggleStatusButtonLoading = true
        const response = await this.$http.post(
          '/api/v1/services/status/' + service.id,
          {},
          { signal: this.cancelToken.signal }
        )

        if (response.status !== 200) {
          this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
        }

        if (service.status === 1) {
          service.status = 2
          this.$emit('update-active-services', this.activeServices - 1)
        } else {
          service.status = 1
          this.$emit('update-active-services', this.activeServices + 1)
        }
        this.$message({ message: response.data.message, type: 'success', showClose: true })
      } catch (e) {
        if (e.response.status === 409) {
          this.$store.commit('billing/setVisibleLimitReachedDialog', true)
          this.$store.commit('billing/setLimitReachedDialogEntity', 'service')
          this.$store.commit('billing/setLimitReachedDialogTitle', this.$t('service_limit_reached_title'))
          this.$store.commit('billing/setLimitReachedDialogText', e.response.data.message)
          return
        }

        this.$message({ message: this.$t('something_went_wrong'), type: 'error', showClose: true })
      } finally {
        this.toggleStatusButtonLoading = false
      }
    }
  }
}
