var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adm-alert",class:[
    'adm-alert' + '__' + _vm.type,
    {
      'is-full-width': _vm.fullWidth,
      'is-without-icon': !_vm.showIcon,
      'is-without-description': _vm.isWithoutDescription,
    }
  ],attrs:{"role":"alert"}},[_c('div',{staticClass:"adm-alert__content"},[(_vm.showIcon)?_c('div',{staticClass:"adm-alert__icon-wrapper"},[_c('i',{class:'tz-' + _vm.getIcon()})]):_vm._e(),_c('span',{staticClass:"adm-alert__text-wrapper"},[(_vm.title)?_c('span',{staticClass:"adm-alert__text-wrapper__title",style:({ fontFamily: _vm.fontFamily }),domProps:{"innerHTML":_vm._s(_vm.title)}}):_vm._e(),(_vm.$slots.title)?_c('span',{staticClass:"adm-alert__text-wrapper__title"},[_vm._t("title",function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]})],2):_vm._e(),(_vm.description)?_c('span',{staticClass:"adm-alert__text-wrapper__description",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),(_vm.$slots.description)?_c('span',{staticClass:"adm-alert__text-wrapper__description"},[_vm._t("description",function(){return [_vm._v(" "+_vm._s(_vm.description)+" ")]})],2):_vm._e(),(_vm.$slots.below)?_c('div',{staticClass:"adm-alert__text-wrapper__below"},[_vm._t("below")],2):_vm._e()])]),(_vm.$slots.right)?_c('div',{staticClass:"adm-alert__right"},[_vm._t("right")],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }