<template>

  <!-- Color Picker -->
  <div class="adm-color-picker">
    <el-color-picker
      :value="value"
      :predefine="predefine"
      :show-alpha="showAlpha"
      class="adm-color-picker__element"
      :class="{'adm-color-picker__element--border': value === '#FFFFFF'}"
      @input="(eventValue) => $emit('input', eventValue)"
      @change="(eventValue) => $emit('change', eventValue)"
    />
  </div>
  <!-- Color Picker -->

</template>

<script>
export default {
  name: 'AdmColorPicker',

  props: {
    value: {
      type: String,
      default: ''
    },
    predefine: {
      type: Array,
      default: () => []
    },
    showAlpha: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    readonly: {
      type: Boolean,
      default: true
    }
  }
}
</script>

<style lang="scss">
// Color Picker
.adm-color-picker {

  // Element Picker
  &__element {
    border-radius: 4px;
    height: auto;
    line-height: 0;
    position: absolute;
    top: 8px;
    right: 8px;

    &--border {
      border: 1px solid $shade-300;
      top: 7px;
    }

    // Trigger
    .el-color-picker__trigger {
      border: none;
      height: 1.5rem;
      width: 1.5rem;
      padding: 0;

      .el-color-picker__color {
        border-radius: 4px;
        border: none;
      }

      .el-color-picker__color-inner {
        border-radius: 4px;
      }
    }
  }
}

.el-color-picker__panel {
  .el-color-dropdown__btns .el-color-dropdown__link-btn {
    display: none;
  }
}
</style>
