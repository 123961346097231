export default {
  methods: {
    validatePhone (callback, isValid, message) {
      if (!isValid) {
        callback(new Error(message))

        return
      }

      callback()
    },

    validatePhoneRequired (callback, value, message) {
      if (typeof value === 'undefined') {
        callback(new Error(message))

        return
      }

      callback()
    }
  }
}
