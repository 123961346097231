const state = {
  categories: [],
  employees: [],
  locations: [],
  services: [],
}

const getters = {}

const mutations = {
  setImmutableEntity (state, payload) {
    state[payload.option] = payload.values
  }
}

const actions = {}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
