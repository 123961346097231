export default {
  data () {
    return {
      checkData: {
        allChecked: false,
        indeterminate: false,
        checkedEntities: []
      }
    }
  },

  methods: {
    handleCheckAll (entities) {
      const $this = this
      const checkedAll = this.checkData.allChecked = !this.checkData.allChecked
      const isEntitiesArray = Array.isArray(entities)
      this.checkData.checkedEntities = []

      if (isEntitiesArray) {
        entities.forEach(entity => {
          $this.$set(entity, 'checked', checkedAll)
          $this.checkData.checkedEntities.push(entity)
        })
      }

      if (!isEntitiesArray) {
        for (const property in entities) {
          if (Object.prototype.hasOwnProperty.call(entities, property)) {
            for (let i = 0; i < entities[property].length; i++) {
              const entity = entities[property][i]
              $this.$set(entity, 'checked', checkedAll)
              $this.checkData.checkedEntities.push(entity)
            }
          }
        }
      }

      $this.checkData.allChecked = checkedAll
      $this.checkData.indeterminate = false

      if (!checkedAll) {
        $this.checkData.checkedEntities = []
      }
    },

    handleUncheckAll (entities) {
      this.checkData.allChecked = true
      this.handleCheckAll(entities)
    },

    handleCheckSingle (entity, allEntities) {
      const $this = this
      const itemIndex = $this.checkData.checkedEntities.indexOf(entity)
      const isEntityChecked = itemIndex > -1

      if (isEntityChecked) {
        $this.$set(entity, 'checked', false)
        $this.checkData.checkedEntities.splice(itemIndex, 1)
      }

      if (!isEntityChecked) {
        $this.$set(entity, 'checked', true)
        $this.checkData.checkedEntities.push(entity)
      }

      const checkedEntitiesNumber = this.checkData.checkedEntities.length
      const allEntitiesNumber = (allEntities => {
        const isEntitiesArray = Array.isArray(allEntities)
        let count = 0

        if (!isEntitiesArray) {
          for (const property in allEntities) {
            if (Object.prototype.hasOwnProperty.call(allEntities, property)) {
              count += allEntities[property].length
            }
          }
        }

        if (isEntitiesArray) {
          count += allEntities.length
        }

        return count
      })(allEntities);

      $this.checkData.allChecked = (checkedEntitiesNumber === allEntitiesNumber)
      $this.checkData.indeterminate = (checkedEntitiesNumber > 0 && checkedEntitiesNumber < allEntitiesNumber)
    },

    resetBulkData () {
      this.checkData = {
        allChecked: false,
        indeterminate: false,
        checkedEntities: []
      }
    },
  }
}
