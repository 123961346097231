<template>

  <!-- Date Time Info -->
  <div class="section section-divided">

    <!-- Title -->
    <div class="section-title flex-center">
      <adm-icon icon="date" />
      <span>{{ $t('date_and_time') }}</span>
    </div>
    <!-- /Title -->

    <!-- Date & Time -->
    <div class="flex-center">

      <!-- Date -->
      <div v-if="date" class="mr-16">
        <h6>{{ formatDate(date) }}</h6>
      </div>
      <adm-skeleton-element v-if="!showData" :height="16" :margin-right="16" :width="120" />
      <!-- /Date -->

      <!-- Time -->
      <div v-if="time">
        <h6>{{ formatTime(time + ':00') }}</h6>
      </div>
      <adm-skeleton-element v-if="!showData" :height="16" :width="60" />
      <!-- /Time -->

    </div>
    <!-- /Date & Time -->

  </div>
  <!-- /Date Time Info -->

</template>

<script>
import AdmIcon from '@/views/_components/icon/AdmIcon'
import AdmSkeletonElement from '@/views/_components/skeletonElement/AdmSkeletonElement'
import mixinDateTime from '@/mixins/common/datetime'

export default {
  name: 'DateTimeInfo',

  components: {
    AdmIcon,
    AdmSkeletonElement,
  },

  mixins: [
    mixinDateTime
  ],

  props: {
    date: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true,
      default: ''
    },
    time: {
      validator: prop => typeof prop === 'string' || prop === null,
      required: true,
      default: ''
    }
  },

  computed: {
    showData () {
      return this.date || this.time
    }
  }
}
</script>
