<template>
  <div
    :class="['adm-modal', { 'is-fullscreen': fullscreen }]"
    tabindex="0"
    @keydown.esc="escapeClicked"
  >
    <el-dialog
      :show-close="false"
      v-bind="{...$attrs, ...$props, ...{ top: !$screen.aboveMobile ? '0px' : top }}"
      :visible="isVisible"
      @close="$emit('close')"
      @open="$emit('open')"
      @closed="$emit('closed')"
      @opened="$emit('opened')"
    >
      <template #title>
        <slot name="title" />
        <span class="adm-modal__title">{{ title }}</span>
        <adm-button
          v-if="closeVisible"
          class="adm-modal__close"
          type="borderless"
          color="dark"
          aria-label="Close"
          size="mini"
          icon-start="close"
          @click="$emit('close')"
        />
      </template>
      <slot />
      <template #footer>
        <slot name="footer" />
      </template>
    </el-dialog>
  </div>
</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'

export default {
  name: 'AdmModal',

  components: {
    AdmButton
  },

  inheritAttrs: false,

  props: {
    title: {
      type: String,
      default: ''
    },
    visible: {
      type: Boolean,
      default: false
    },
    modalAppendToBody: {
      type: Boolean,
      default: false
    },
    lockScroll: {
      type: Boolean,
      default: true
    },
    closeOnClickModal: {
      type: Boolean,
      default: true
    },
    closeOnPressEscape: {
      type: Boolean,
      default: true
    },
    closeVisible: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: null
    },
    fullscreen: Boolean,
    top: {
      type: String,
      default: '70px'
    },
    beforeClose: {
      type: Function,
      default: () => {},
    },
    destroyOnClose: Boolean
  },

  data: () => {
    return {
      isVisible: false,
    }
  },

  watch: {
    visible: {
      immediate: true,
      handler: function (value) {
        if (value === true) {
          return setTimeout(
            () => {
              this.isVisible = true

              if (this.modalAppendToBody) {
                document.body.appendChild(this.$el);
              }
            },
            1)
        }

        this.isVisible = false
      }
    }
  },

  methods: {
    escapeClicked () {
      if (this.closeOnPressEscape) {
        this.$emit('close')
      }
    }
  }
}
</script>

<style lang="scss">
.adm-modal {

  .el-dialog {
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    color: $shade-900;
    max-width: 100%;

    @include tablet-up {
      width: 70%;
    }
    @include phone-down {
      width: 100% !important;
      margin-bottom: 0;
      height: calc(100% - 56px) !important;
      overflow: auto;
      display: flex;
      flex-direction: column;
      border-radius: 0;

      .el-dialog__body {
        margin-bottom: 61px;
      }

      .el-dialog__footer {
        position: fixed;
        bottom: 0;
        width: 100%;
        background: $adm-white;
        z-index: 1;

        @include phone-down {
          > * {
            flex: 1;
          }

          .el-popover__reference-wrapper {
            display: flex;

            .el-popover__reference {
              width: 100%;
            }
          }
        }
      }
    }

    &__wrapper {
      @include phone-down {
        top: calc(var(--alert-height) + 56px);
      }
    }

    &__header {
      padding: 20px 24px 24px;
    }

    &__body {
      word-break: normal;
      padding: 0 24px 24px;
    }

    &__footer {
      border-top: 1px solid $shade-300;
      padding: 12px 24px;
      display: flex;
      justify-content: flex-end;
      gap: 12px;
    }

    &.is-fullscreen {
      width: 100%;
    }
  }

  &.is-fullscreen {
    .el-dialog {
      &__wrapper {
        top: 0;
      }
    }
  }

  &__title {
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    margin-right: 30px;
    display: inline-block;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
  }
}

// Background
.v-modal {
  z-index: 995 !important;
  background: $shade-900;
  opacity: 0.1;

  @include phone-down {
    top: calc(var(--alert-height) + 56px);
  }
}
</style>
