<template>

  <!-- Design System Input Radio -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Radio
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
      description="Radio should not have too many options. Otherwise, use the Select component instead."
    >

      <!-- Preview -->
      <template #preview>
        <adm-radio v-model="basicUsage" label="1">
          Option A
        </adm-radio>
        <adm-radio v-model="basicUsage" class="ml-16" label="2">
          Option B
        </adm-radio>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- Disabled -->
    <design-system-element-block
      title="Disabled"
      description="disabled attribute is used to disable the radio."
    >

      <!-- Preview -->
      <template #preview>
        <adm-radio v-model="disabled" label="1" disabled>
          Option A
        </adm-radio>
        <adm-radio v-model="disabled" class="ml-16" label="4" disabled>
          Option D
        </adm-radio>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Disabled -->

    <!-- Description -->
    <design-system-element-block
      title="Description"
    >

      <!-- Preview -->
      <template #preview>
        <adm-radio
          v-model="description"
          label="1"
          description="This is a support text with description"
        >
          Option A
        </adm-radio>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Description -->

    <!-- Radio group -->
    <design-system-element-block
      title="Radio group"
      description="Combine adm-radio-group with adm-radio to display a radio group. Bind a variable with v-model of adm-radio-group element and set label value in adm-radio. It also provides change event with the current value as its parameter."
    >

      <!-- Preview -->
      <template #preview>
        <adm-radio-group v-model="radioGroup">
          <adm-radio label="1">
            Option A
          </adm-radio>
          <adm-radio class="ml-16" label="2">
            Option B
          </adm-radio>
        </adm-radio-group>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code />
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Radio group -->

    <!-- Radio Attributes -->
    <design-system-documentation-table :table-data="tableData" title="Radio attributes" />
    <!-- /Radio Attributes -->

    <!-- Radio Events -->
    <design-system-documentation-table :table-data="tableDataEvents" type="events" title="Radio events" />
    <!-- /Radio Events -->

    <!-- Radio Group Attributes -->
    <design-system-documentation-table :table-data="tableDataRadioGroup" title="Radio group attributes" />
    <!-- /Radio Group Attributes -->

    <!-- Radio Group Events -->
    <design-system-documentation-table :table-data="tableDataRadioGroupEvents" type="events" title="Radio group events" />
    <!-- /Radio Group Events -->


  </div>
  <!-- /Design System Input Radio -->

</template>

<script>
import AdmRadio from '@/views/_components/radio/AdmRadio'
import AdmRadioGroup from '@/views/_components/radio/AdmRadioGroup'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'

export default {
  name: 'DesignSystemRadio',

  components: {
    DesignSystemDocumentationTable,
    AdmRadioGroup,
    AdmRadio,
    DesignSystemElementBlock
  },

  data () {
    return {
      basicUsage: '1',
      disabled: '1',
      description: '1',
      radioGroup: '1',
      tableData: [
        {
          attribute: 'value / v-model',
          description: 'binding value',
          type: 'string / number / boolean',
          values: '-',
          default: '-',
        },
        {
          attribute: 'label',
          description: 'the value of Radio',
          type: 'string / number / boolean',
          values: '-',
          default: '-',
        },
        {
          attribute: 'label-position',
          description: 'the position of the radio text',
          type: 'string',
          values: 'left / right',
          default: 'left',
        },
        {
          attribute: 'disabled',
          description: 'whether Radio is disabled',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
        {
          attribute: 'name',
          description: 'native \'name\' attribute',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'description',
          description: 'description text below radio button',
          type: 'string',
          values: '-',
          default: '-',
        },
      ],
      tableDataEvents: [
        {
          name: 'change',
          description: 'triggers when the bound value changes',
          parameters: 'the label value of the chosen radio'
        },
      ],
      tableDataRadioGroup: [
        {
          attribute: 'value / v-model',
          description: 'binding value',
          type: 'string / number / boolean',
          values: '-',
          default: '-',
        },
        {
          attribute: 'disabled',
          description: 'whether the nesting radios are disabled',
          type: 'boolean',
          values: '-',
          default: 'false',
        },
      ],
      tableDataRadioGroupEvents: [
        {
          name: 'change',
          description: 'triggers when the bound value changes',
          parameters: 'the label value of the chosen radio'
        },
      ],
    }
  }
}
</script>
