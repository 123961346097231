import axios from 'axios'
import Cookies from 'js-cookie'
import { isLocalStorageAvailable } from '@/utils/localStorage'

export function getAbortController () {
  return new AbortController()
}

export function isRequestCanceled (thrown) {
  return axios.isCancel(thrown)
}

export function getAuthorization () {
  if (!Cookies.get('access_token') && (isLocalStorageAvailable() && localStorage.getItem('access_token'))) {
    return `Bearer ${localStorage.getItem('access_token')}`
  }

  if (Cookies.get('access_token')) {
    return `Bearer ${JSON.parse(Cookies.get('access_token')).token}`
  }

  return ''
}
