export default {
  methods: {
    logout () {
      this.$cookies.remove('access_token')
      localStorage.removeItem('access_token')
      this.$store.commit('user/resetState')
      this.$store.commit('settings/resetState')
      this.$store.commit('features/resetState')
      this.$store.commit('billing/resetState')
    },
  }
}
