import httpClient from '@/plugins/axios'

const getDefaultState = () => {
  return {
    loading: false,
    isLoaded: false,
    activePlan: null,
    general: {
      tenantName: '',
      defaultBookingWebsiteUrl: '',
      firstDayOfWeek: 'monday',
      registrationDate: '',
      appointmentStatus: 'approved',
      activePlan: 'free_trial',
      couponsSentForDay: { count: 0 },
      timezone: 'Europe/London',
      defaultFirstPage: 'calendar',
      externalIntegration: [],
    },
    company: {
      companyWebsite: '',
      companyLogo: '',
      companyBusinessName: '',
      phoneNumber: '',
      companyAddress: '',
    },
    schedule: {},
    payments: {
      payPalNewConnection: false,
      payPalConnected: false,
      payPalMerchantId: '',
      stripeConnected: false,
      authorizeConnected: false,
      currency: '',
      priceSymbolPosition: 'before',
      taxDisplaySettings: 'inclusive',
      onSiteEnabled: true,
      showTaxesOnSite: false,
    },
    googleCalendar: {
      googleMeetIntegration: false,
    },
    notifications: {},
    customize: {
      isTrafftBrandingApplied: true,
      pageTitle: null,
      companyFavicon: '',
      languagePublic: 'en',
      availableLanguagesPublic: [],
      isEnabledRedirectUrlSuccess: '',
      employeeSelection: 'manual',
      hideAnyEmployee: true,
      employeeSelectionLogic: 'roundRobin',
      servicesPageView: 'grid',
      servicesPageCardsImage: 'large',
      servicesPageFilters: 'vertical',
      showCustomerTimezone: false,
      customizeTheme: {
        name: 'light',
        primaryColor: '#005aee',
        font: 'Inter',
        interfaceAppearance: 'light',
        background: {
          color: '#ffffff',
          generatedPattern: '',
          pattern: '',
          patternColors: [],
          repeat: false,
          opacity: false
        }
      },
      showEmailField: true,
      isEmailMandatory: true,
      showPhoneField: true,
      isPhoneMandatory: false,
      showInputForCancelingAppointment: true,
      isReasonForCancelingMandatory: false,
      showEmployeesNearestSlots: true,
      showEmployeesPage: false,
      employeesPageView: 'grid',
      employeesPageCardsImage: 'large',
      employeesPageFilters: 'horizontal'
    },
    googleTagManager: {
      googleTagManagerId: '',
    },
    googleAnalytics: {
      googleAnalytics4MeasurementId: '',
    },
    facebookPixel: {
      facebookPixelId: '',
    },
    sms: {
      smsCredits: '',
      smsCreditsTemporary: '',
      creditRunningLowCanBePresented: true,
    },
    guide: {
      isWelcomeBannerDismissed: false,
      guidePopupInitiallyPresented: false,
      guideDismissed: false,
      guideSteps: {
        bookingWebsite: false,
        customize: false,
        employees: false,
        generalSettings: false,
        locations: false,
        paymentSettings: false,
        services: false,
        clickedAddToWebsite: false,
        copiedEmbedCode: false,
      },
      newFeaturesGuide: {
        calendarEmployeeSelection: {
          opened: false,
          dismissed: false,
        },
      },
    },
    acumba: {
      acumbaList: '',
    },
    mailchimp: {
      mailchimpList: '',
    },
    sendFox: {
      sendFoxList: '',
    },
    customDomain: {
      domain: '',
      adminDomain: '',
      agencyBookingDomain: '',
    },
    labelChanges: {
      service: '',
      services: '',
      employee: '',
      any_employee: '',
      select_employee: ''
    },
    groupBooking: {
      allowBookingBelowMinimumCapacity: true
    },
    agency: {
      agencyLogoFullUrl: window.agencyLogoFullUrl,
      agencyLogoSquareUrl: window.agencyLogoSquareUrl,
      agencyName: window.agencyName,
      agencyUuid: window.agencyUuid,
      dashboardBackground: '',
      dashboardColorPalette: {},
      isSmsNotificationsFeatureVisible: true,
      isAgencyDemo: false,
    },
    visibleSeenMSCreditRunningLowAlert: false,
    notification: {
      notificationCombinedPlaceholders: {}
    },
    mobileAppBanner: {
      isMobileAppBannerClosed: false,
      loginCount: 1
    },
    christmasPromo2023: {
      showChristmasPromo2023AgencyPopup: true,
      showChristmasPromo2023Banner: true
    },
    email: {
      isSuperAdminEmailVerified: true,
    },
    globalErrorMessages: {
      globalErrorMessages: []
    }
  }
}

const state = getDefaultState()

const getters = {
  getSettingByName: state => (name, category) => {
    if (Object.prototype.hasOwnProperty.call(state, category)) {
      if (Object.prototype.hasOwnProperty.call(state[category], name)) {
        return state[category][name]
      }
    }
  },

  getBookingWebsiteDomainData: state => () => {
    return {customDomain: state.customDomain.domain, defaultDomain: state.general.defaultBookingWebsiteUrl}
  },

  isGuideFinished: state => () => {
    if (state.guide.guideDismissed || !state.guide.guideSteps) {
      return true
    }

    let isEverythingFinished = true
    Object.keys(state.guide.guideSteps).forEach((step) => {
      if (state.guide.guideSteps[step] === false) {
        isEverythingFinished = false
      }
    })

    return isEverythingFinished
  },

  getDefaultLanguagePublic: state => () => {
    return state.customize.languagePublic
  },

  isAgency: (state) => {
    return !!state.agency.agencyUuid
  },

  isLogoSquareVisible: (state) => {
    return (!!state.agency.agencyUuid && !!state.agency.agencyLogoSquareUrl) || !!state.agency.agencyUuid === false
  },

  isLogoFullVisible: (state) => {
    return (!!state.agency.agencyUuid && !!state.agency.agencyLogoFullUrl) || !!state.agency.agencyUuid === false
  },

  isSMSCreditRunningOutAndHaveRechargedSmsCredits: state => () => {
    return state.sms.smsCredits < 2 && state.sms.smsCredits > 0
  }
}

const mutations = {
  setSettings (state, payload) {
    if (!payload) {
      return
    }

    state.isLoaded = true
    payload.forEach(setting => {
      if (!state[setting.category]) {
        state[setting.category] = {}
      }
      state[setting.category][setting.name] = setting.value
    })
  },

  setSetting (state, setting) {
    state[setting.category][setting.name] = setting.value
  },

  finishGuideStep (state, step) {
    state.guide.guideSteps[step] = true
  },

  resetState (state) {
    Object.assign(state, getDefaultState())
  },

  showLoading (state) {
    state.loading = true
  },

  hideLoading (state) {
    state.loading = false
  },

  dismissWelcomeBanner (state) {
    state.guide.isWelcomeBannerDismissed = true
  },

  setVisibleSMSCreditRunningLowAlert (state, payload) {
    if (payload === false) {
      state.sms.creditRunningLowCanBePresented = false
    }
    state.visibleSeenMSCreditRunningLowAlert = payload
  },

  dismissNewFeatureGuide (state, feature) {
    state.guide.newFeaturesGuide[feature] = {
      opened: false,
      dismissed: true,
    }
  },

  openNewFeatureGuide (state, feature) {
    state.guide.newFeaturesGuide[feature].opened = true
  },

  setSmsCreditsTemporary (state, payload) {
    state.sms.smsCreditsTemporary += payload
  }
}

const actions = {
  dismissGuide (context) {
    context.commit('setSetting', { category: 'guide', name: 'guideDismissed', value: true })
    const formData = new FormData()
    formData.append('guide', JSON.stringify(context.state.guide))

    httpClient.post(
      '/api/v1/settings', formData
    ).then(() => {})
  },

  setGuidePresentedInitially (context) {
    context.commit('setSetting', { category: 'guide', name: 'guidePopupInitiallyPresented', value: true })
    const formData = new FormData()
    formData.append('guide', JSON.stringify(context.state.guide))

    httpClient.post(
      '/api/v1/settings', formData
    ).then(() => {})
  },

  finishGuideStepAction (context, step) {
    context.commit('finishGuideStep', step)
    const formData = new FormData()
    formData.append('guide', JSON.stringify(context.state.guide))

    httpClient.post(
      '/api/v1/settings', formData
    ).then(() => {})
  },

  dismissWelcomeBannerAction (context) {
    context.commit('dismissWelcomeBanner')
    const formData = new FormData()
    formData.append('guide', JSON.stringify(context.state.guide))

    httpClient.post(
      '/api/v1/settings', formData
    ).then(() => {})
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
