<template>

  <!-- Checkbox -->
  <div
    class="adm-checkbox-wrapper"
    :class="[
      captionPosition === 'left' && !description ? 'caption-left' : 'caption-right',
      {
        'is-full-width': fullWidth
      }
    ]"
  >
    <el-checkbox
      :id="id"
      ref="admCheckbox"
      v-bind="$props"
      v-model="model"
      class="adm-checkbox"
      :class="[{ 'disabled': disabled }]"
      @input="(eventValue) => $emit('input', eventValue)"
      @change="(eventValue) => $emit('change', eventValue)"
    >
      <div
        v-if="$slots.default"
        class="adm-checkbox-wrapper__caption"
      >
        <div class="adm-checkbox-wrapper__caption__label">
          <slot />
        </div>
        <div v-if="description" class="adm-checkbox-wrapper__caption__description">
          {{ description }}
        </div>
      </div>
    </el-checkbox>
  </div>
  <!-- /Checkbox -->

</template>

<script>
export default {
  name: 'AdmCheckbox',

  props: {
    id: {
      type: [String, Object],
      default: null
    },
    value: {
      type: [String, Number, Boolean],
      default: '',
      required: false
    },
    activeValue: {
      type: [Boolean, String, Number],
      default: true
    },
    inactiveValue: {
      type: [Boolean, String, Number],
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    captionPosition: {
      type: String,
      default: 'right',
      validator (value) {
        return ['left', 'right'].indexOf(value) !== -1
      }
    },
    description: {
      type: String,
      default: null
    },
    width: {
      type: Number,
      default: 36
    },
    fullWidth: {
      type: Boolean,
      default: false
    },
    indeterminate: {
      type: Boolean,
      default: false
    },
    label: {
      type: [String, Number],
      default: ''
    },
  },

  data () {
    return {
      selfModel: ''
    }
  },

  computed: {
    model: {
      get () {
        return this.value !== undefined ? this.value : this.selfModel
      },
      set (val) {
        this.selfModel = val
      }
    }
  },
}
</script>

<style lang="scss">
// Checkbox Wrapper
.adm-checkbox-wrapper {
  display: flex;
  $caption: "adm-checkbox-wrapper__caption";

  // Caption
  .#{$caption} {
    display: inline-block;

    // Label
    &__label {
      cursor: pointer;
      color: $shade-900;
      display: inline-block;
      font-size: 15px;
      line-height: 24px;
      font-weight: 500;
      word-break: break-word;
    }

    // Description
    &__description {
      font-size: 13px;
      line-height: 20px;
      color: $shade-700;
      font-weight: 400;
    }
  }

  // Right Side Caption
  &.caption-right {
    .el-checkbox {
      flex-direction: row;
      justify-content: flex-start;
    }

    // Caption
    .#{$caption} {
      margin-left: 12px;
    }
  }

  // Left Side Caption
  &.caption-left {
    .el-checkbox {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    // Caption
    .#{$caption} {
      margin-right: 12px;
    }
  }

  // Full Width
  &.is-full-width {

    // Caption Left
    &.caption-left {

      // Element Checkbox
      .el-checkbox {
        margin-left: auto;
      }
    }

    // Caption Right
    &.caption-right {

      // Caption Label
      .#{$caption} {
        margin-left: auto;
      }
    }
  }

  // Disabled label
  .el-checkbox.is-disabled {
    .#{$caption} {
      &__label {
        cursor: not-allowed;
        color: $shade-700;
      }
    }
  }

  // Override Default CSS
  .el-checkbox {
    white-space: normal;
    display: flex;
    align-items: flex-start;

    // Label
    &__label {
      padding: 0;
    }

    // Input
    &__input {
      height: 24px;
      display: flex;
      align-items: center;
    }

    // Inner
    &__inner {
      border: 1px solid $shade-450;
      box-shadow: 0 1px 1px rgba(115, 134, 169, 0.06);
      border-radius: 4px;
      height: 16px;
      width: 16px;

      &:after, &:before {
        content: none;
        transform: none;
        border: none;
      }
    }

    // Checked
    &__input.is-checked {
      .el-checkbox__inner {
        border: 1px solid var(--primary-900);
        background-color: $adm-white;

        &:after {
          content: '';
          width: 10px;
          height: 10px;
          border-radius: 2px;
          transform: translate(-50%, -50%);
          background-color: var(--primary-900);
          top: 50%;
          left: 50%;
          border-color: var(--primary-900);
        }
      }
    }

    // Indeterminate
    &__input.is-indeterminate {
      .el-checkbox__inner {
        border: 1px solid var(--primary-900);
        background-color: $adm-white;

        &:before {
          content: '';
          width: 10px;
          height: 2px;
          border-radius: 30px;
          transform: translate(-50%, -50%);
          background-color: var(--primary-900);
          top: 50%;
          left: 50%;
        }
      }
    }

    // Hover
    &__input:hover:not(.is-disabled):not(.is-checked) {

      // Inner
      .el-checkbox__inner {
        border: 1px solid $shade-600;
        box-shadow: inset 0 1px 1px rgba(20, 35, 61, 0.11);
      }
    }

    // Focus
    &__input.is-focus:not(.is-disabled) {

      // Inner
      .el-checkbox__inner {
        border: 1px solid var(--primary-900);
        box-shadow: 0 0 0 2px var(--primary-500);
      }
    }

    // Disabled
    &__input.is-disabled {
      .el-checkbox__inner {
        border: 1px solid $shade-400;
        background-color: $shade-150;
      }

      &.is-checked {
        .el-checkbox__inner {
          border: 1px solid var(--primary-600);

          &:after {
            background-color: var(--primary-600);
          }
        }
      }
    }
  }
}
</style>
