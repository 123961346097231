<template>

  <!-- Design System Alert -->
  <div>

    <!-- Title -->
    <h2 class="adm-design-system__content__title">
      Alert
    </h2>
    <!-- /Title -->

    <!-- Basic usage -->
    <design-system-element-block
      title="Basic usage"
      description="Alert components are non-overlay elements in the page that does not disappear automatically."
      code-description="Alert provides 4 types of themes defined by type (default, positive, warning, error)."
      display="block"
    >

      <!-- Preview -->
      <template #preview>
        <adm-alert class="mb-16" title="Default alert" />
        <adm-alert class="mb-16" type="positive" title="Positive alert" />
        <adm-alert class="mb-16" type="warning" title="Warning alert" />
        <adm-alert class="mb-16" type="danger" title="Error alert" />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getAlertCode({ title: 'Default alert' }) }}
        <br>
        {{ getAlertCode({ type: 'positive', title: 'Positive alert' }) }}
        <br>
        {{ getAlertCode({ type: 'warning', title: 'Warning alert' }) }}
        <br>
        {{ getAlertCode({ type: 'danger', title: 'Error alert' }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Basic usage -->

    <!-- With description -->
    <design-system-element-block
      title="With description"
      description="Description includes a message with more detailed information."
      code-description="Besides the required title attribute, you can add a description attribute to help you describe the alert with more details. Description can only store text string, and it will word wrap automatically."
      display="block"
    >

      <!-- Preview -->
      <template #preview>
        <adm-alert
          class="mb-16"
          title="Default alert"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
        <adm-alert
          class="mb-16"
          type="positive"
          title="Positive alert"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
        <adm-alert
          class="mb-16"
          type="warning"
          title="Warning alert"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
        <adm-alert
          class="mb-16"
          type="danger"
          title="Error alert"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{
          getAlertCode({
            title: 'Default alert',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          })
        }}
        <br>
        {{
          getAlertCode({
            type: 'positive',
            title: 'Positive alert',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          })
        }}
        <br>
        {{
          getAlertCode({
            type: 'warning',
            title: 'Warning alert',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          })
        }}
        <br>
        {{
          getAlertCode({
            type: 'danger',
            title: 'Error alert',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          })
        }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /With description -->

    <!-- Show icon -->
    <design-system-element-block
      title="Show icon"
      description="Alert can be displayed without icon."
      code-description="Setting the show-icon attribute displays an icon that corresponds with the current Alert type."
      display="block"
    >

      <!-- Preview -->
      <template #preview>
        <adm-alert
          class="mb-16"
          title="Default alert"
          :show-icon="false"
        />
        <adm-alert
          class="mb-16"
          type="positive"
          title="Positive alert"
          :show-icon="false"
        />
        <adm-alert
          class="mb-16"
          type="warning"
          title="Warning alert"
          :show-icon="false"
        />
        <adm-alert
          class="mb-16"
          type="danger"
          title="Error alert"
          :show-icon="false"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getAlertCode({ title: 'Default alert', 'show-icon': false }) }}
        <br>
        {{ getAlertCode({ type: 'positive', title: 'Positive alert', 'show-icon': false }) }}
        <br>
        {{ getAlertCode({ type: 'warning', title: 'Warning alert', 'show-icon': false }) }}
        <br>
        {{ getAlertCode({ type: 'danger', title: 'Error alert', 'show-icon': false }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Show icon -->

    <!-- Full width -->
    <design-system-element-block
      title="Full width"
      description="Alert can be displayed with or without full width."
      code-description="Setting the full-width attribute displays an alert in full width."
    >

      <!-- Preview -->
      <template #preview>
        <adm-alert
          title="Default alert"
          :full-width="false"
        />
        <adm-alert
          class="ml-16"
          type="positive"
          title="Positive alert"
          :full-width="false"
        />
        <adm-alert
          class="ml-16"
          type="warning"
          title="Warning alert"
          :full-width="false"
        />
        <adm-alert
          class="ml-16"
          type="danger"
          title="Error alert"
          :full-width="false"
        />
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{ getAlertCode({ title: 'Default alert', 'full-width': false }) }}
        <br>
        {{ getAlertCode({ type: 'positive', title: 'Positive alert', 'full-width': false }) }}
        <br>
        {{ getAlertCode({ type: 'warning', title: 'Warning alert', 'full-width': false }) }}
        <br>
        {{ getAlertCode({ type: 'danger', title: 'Error alert', 'full-width': false }) }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /Full width -->

    <!-- With button right -->
    <design-system-element-block
      title="With button right"
      description="You can add content on the right side of the alert."
      code-description="Use right slot to add content on the right side of the alert."
      display="block"
    >

      <!-- Preview -->
      <template #preview>
        <adm-alert
          class="mb-16"
          title="Default alert"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        >
          <adm-button slot="right" size="small" type="ghost" color="dark">
            Button text
          </adm-button>
        </adm-alert>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{
          getAlertCode({
            title: 'Default alert',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          }, {
            type: 'right',
            props: { slot: 'right', size: 'small', type: 'ghost', color: 'dark' },
            label: 'Button text'
          })
        }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /With button right -->

    <!-- With buttons below -->
    <design-system-element-block
      title="With buttons below"
      description="You can add content on the right side of the alert."
      code-description="Use right slot to add content on the right side of the alert."
      display="block"
    >

      <!-- Preview -->
      <template #preview>
        <adm-alert
          class="mb-16"
          title="Default alert"
          description="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
        >
          <adm-button slot="below" size="mini" type="ghost" color="dark">
            Button text
          </adm-button>
        </adm-alert>
      </template>
      <!-- /Preview -->

      <!-- Code -->
      <template #code>
        {{
          getAlertCode({
            title: 'Default alert',
            description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.'
          }, {
            type: 'below',
            props: { slot: 'below', size: 'mini', type: 'ghost', color: 'dark' },
            label: 'Button text'
          })
        }}
      </template>
      <!-- /Code -->

    </design-system-element-block>
    <!-- /With buttons below -->

    <!-- Alert Attributes -->
    <design-system-documentation-table :table-data="tableDataAttributes" />
    <!-- /Alert Attributes -->

    <!-- Alert Slots -->
    <design-system-documentation-table title="Slots" :table-data="tableDataSlots" type="slots" />
    <!-- /Alert Slots -->

  </div>
  <!-- /Design System Alert -->

</template>

<script>
import AdmAlert from '@/views/_components/alert/AdmAlert'
import DesignSystemDocumentationTable from '@/views/DesignSystem/DesignSystemDocumentationTable'
import DesignSystemElementBlock from '@/views/DesignSystem/DesignSystemElementBlock'
import mixinDesignSystem from '@/mixins/page/designSystem'
import AdmButton from '@/views/_components/button/AdmButton'

export default {
  name: 'DesignSystemAlert',

  components: {
    AdmButton,
    AdmAlert,
    DesignSystemDocumentationTable,
    DesignSystemElementBlock
  },

  mixins: [
    mixinDesignSystem
  ],

  data () {
    return {
      tableDataAttributes: [
        {
          attribute: 'type',
          description: 'alert type',
          type: 'string',
          values: 'default / positive / warning / error',
          default: 'default',
        },
        {
          attribute: 'title',
          description: 'title',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'description',
          description: 'Descriptive text. Can also be passed with the description slot.',
          type: 'string',
          values: '-',
          default: '-',
        },
        {
          attribute: 'show-icon',
          description: 'If a type icon is displayed',
          type: 'boolean',
          values: 'true / false',
          default: 'true',
        },
        {
          attribute: 'full-width',
          description: 'If an alert is full width',
          type: 'boolean',
          values: 'true / false',
          default: 'true',
        },
      ],
      tableDataSlots: [
        {
          name: 'description',
          description: 'description of the alert'
        },
        {
          name: 'right',
          description: 'content of the Alert right side',
        },
        {
          name: 'below',
          description: 'content below alert ',
        }
      ]
    }
  },
}
</script>
