<template>
  <el-tooltip
    popper-class="adm-tooltip"
    :placement="placement"
    :disabled="disabled"
    :open-delay="openDelay"
    effect="dark"
  >
    <template #content>
      <slot name="content" />
      {{ content }}
    </template>
    <slot />
  </el-tooltip>
</template>

<script>
export default {
  name: 'AdmTooltip',
  props: {
    placement: {
      type: String,
      default: 'bottom'
    },
    content: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    openDelay: {
      type: Number,
      default: 0
    },
  }
}
</script>

<style lang="scss">
.adm-tooltip {
  &.el-tooltip__popper {
    text-align: center;
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    padding: 6px;
    border-radius: 6px;

    &.is-dark {
      background-color: $shade-900;
      border: none;
      box-shadow: none;
      color: $adm-white;
    }
  }

}

</style>
