<template>

  <!-- Popover Button Wrapper -->
  <el-popover
    popper-class="guide__item__popover guide__item__popover--smaller"
    :title="title"
    :width="width"
    trigger="manual"
    :value="isPopoverOpened"
    :placement="placement"
  >

    <!-- Close Popover Button -->
    <adm-button
      v-if="isClosePresented"
      class="guide__item__popover__close-button"
      type="borderless"
      color="dark"
      size="mini"
      icon-start="close"
      @click="closePopover"
    />
    <!-- /Close Popover Button -->

    <!-- Popover Description -->
    <p class="guide__item__popover__description">
      {{ description }}
    </p>
    <!-- /Popover Description -->

    <slot slot="reference" />

  </el-popover>
  <!-- /Popover Button Wrapper -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'

export default {
  name: "GuideButtonWrapper",
  components: {
    AdmButton
  },
  props: {
    isOpened: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    width: {
      type: Number,
      default: 452,
    },
    placement: {
      type: String,
      required: true,
    },
    isClosePresented: {
      type: Boolean,
      required: true,
    },
    timeout: {
      type: Number,
      default: 0,
    },
  },

  data () {
    return {
      isPopoverOpenedActive: false,
      isPopoverOpened: false,
    }
  },

  watch: {
    isOpened: {
      immediate: true,
      handler: function (value) {
        if (value === true) {
          return setTimeout(() => this.isPopoverOpened = true, this.timeout)
        }

        this.isPopoverOpened = false
      }
    }
  },

  mounted () {
    this.$nextTick(function () {
      this.isPopoverOpenedActive = true
    })
  },

  methods: {
    closePopover () {
      this.$store.commit('guide/closeItemPopover')
    }
  },
}
</script>
