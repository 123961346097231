import mixinDateTime from '@/mixins/common/datetime'
import mixinLocation from '@/mixins/page/location'

export default {
  mixins: [mixinDateTime, mixinLocation],

  data: function () {
    return {
      rulesCustomFields: {},
      customFieldsVisible: false,
      autocompleteAddress: [],
      autocomplete: null,
      addressCustomFieldKey: 1,
      files: []
    }
  },

  computed: {
    hasRequiredCustomFields () {
      for (let i = 0; i < this.customFieldsToDisplay.length; i++) {
        if (this.customFieldsToDisplay[i].required === true) {
          return true
        }
      }

      return false
    },
  },

  methods: {
    setCustomFieldsOptions (customFieldsProp, entityId) {
      for (let i = 0; i < this.customFields.length; i++) {
        const customField = this.customFields[i]
        if (customField.type !== 'textContent') {
          if (!entityId && customField.deleted !== true) {
            if (customField.type === 'checkbox') {
              this.$set(customFieldsProp, customField.id, [])
            } else if (customField.type === 'radioButtons') {
              this.$set(customFieldsProp, customField.id, customField.options[0].id)
            } else {
              this.$set(customFieldsProp, customField.id, null)
            }
          }

          if (customField.type === 'address') {
            this.rulesCustomFields['customFields.' + customField.id] = [{
              validator: (rule, value, callback) => {
                if (!customFieldsProp[customField.id] && this.autocompleteAddress[customField.id]) {
                  return callback(new Error(this.$t('please_choose_location_from_list')));
                } else {
                  callback()
                }
              },
              trigger: 'blur',
            },
              {
                message: this.getCustomFieldValidationMessage(customField.type),
                trigger: 'submit',
              }]
          }

          if (customField.type === 'attachment') {
            this.rulesCustomFields['customFields.' + customField.id] = [{
              validator: (rule, file, callback) => {
                if (rule.required && file === null) {
                  return callback(new Error(this.$t('please_upload_attachment')))
                }
                else if (file !== null) {
                  // check size
                  if (file.raw && file.raw.size / 1024 / 1024 > 20) {
                    return callback(new Error(this.$t("allowed_maximum_size_of_file_20_mb")));
                  }

                  // check type
                  let allowedExtensions = ['jpg', 'jpeg', 'gif', 'png', 'tiff', 'webp', 'svg', 'doc', 'pdf', 'ppt', 'pptx', 'docx', 'txt', 'zip', 'tar', '7z', 'rar']

                  if (file.name && !allowedExtensions.includes(file.name.slice(file.name.lastIndexOf(".") + 1))) {
                    return callback(new Error(this.$t('type_of_file_is_invalid')));
                  }

                  callback()
                } else {
                  callback()
                }
              },
              trigger: 'submit',
            }]
          }
        }
      }
    },

    setCustomFieldsValue (customFieldsProp) {
      let addressCustomField = this.customFieldsToDisplay.filter(x => x.type === 'address')
      if (addressCustomField.length) {
        addressCustomField.forEach((field) => {
          this.getAddressCustomFieldValue(field, customFieldsProp)
        })
      }
      this.addressCustomFieldKey = Math.random() * 1000

      let attachments = this.customFieldsToDisplay.filter(x => x.type === 'attachment')
      let attachmentCustomFieldsValue = []
      Object.entries(customFieldsProp).filter(([key]) => {
        attachments.filter(x => {
          if (x.id === parseInt(key)) {
            attachmentCustomFieldsValue.push(x)
          }
        })
      })

      attachmentCustomFieldsValue.forEach((value) => {
        if (customFieldsProp[value.id]) {
          let file = JSON.parse(customFieldsProp[value.id])
          this.files[value.id] = [{
            name: file.fileName,
            uid: file.uuid
          }]
        }
      })
    },

    getCustomFieldValidationMessage (type) {
      if (['text', 'textArea'].includes(type)) {
        return this.$t('please_enter')
      }

      if (type === 'attachment') {
        return this.$t('please_upload_attachment')
      }

      if (type === 'address') {
        return this.$t('please_choose_location_from_list')
      }

      return this.$t('please_select')
    },

    getAddressCustomFieldValue (customField, customFieldsProp) {
      try {
        let address = JSON.parse(customFieldsProp[customField.id])
        if (address) {
          this.autocompleteAddress[customField.id] = address.formatted_address
        }
      } catch (e) {
        this.autocompleteAddress[customField.id] = customFieldsProp[customField.id]
      }
    },

    addressAutocomplete (elementId, customFieldsProp) {
      this.googleMapsLoader.load().then(() => {
        // Get result from address input
        this.autocomplete = new google.maps.places.Autocomplete(
          document.getElementById(elementId),
          { type: "address" }
        )

        // When place is changed
        this.autocomplete.addListener("place_changed", () => {
          let place = this.autocomplete.getPlace();
          this.setValueToAddressCustomField({
            formatted_address: place.formatted_address,
            url: place.url
          }, elementId.split('-')[3], customFieldsProp)
        })
      })
    },

    getAddressValueOnBlur (elementId, customFieldsProp) {
      this.setValueToAddressCustomField(document.getElementById(elementId).value, elementId.split('-')[3], customFieldsProp)
    },

    setValueToAddressCustomField (value, addressCustomFieldId, customFieldsProp) {
      if (value === "") {
        customFieldsProp[addressCustomFieldId] = null
        return
      }

      if (value.formatted_address) {
        customFieldsProp[addressCustomFieldId] = JSON.stringify(value)
        return
      }

      customFieldsProp[addressCustomFieldId] = value
    },

    onFileUpload (file, id, customFieldsProp) {
      this.files[id] = [file]
      customFieldsProp[id] = file
    },

    onFileRemove (id, customFieldsProp, form) {
      this.files[id] = null
      customFieldsProp[id] = null
      form.clearValidate(['customFields.' + id])
    },

    onFileExceed (id, form) {
      form.clearValidate(['customFields.' + id])
    }
  }
}
