export default [
  {
    path: 'rating-review',
    name: 'rating-review',
    redirect: 'rating-review/overview',
    meta: {
      title: 'rating_review',
      requiresAuth: true,
      requiredPermissions: 'edit_settings',
      planFeature: 'rating_review',
    },
    component: () => import(/* webpackChunkName: "ratingReview" */'@/views/RatingReview/RatingReviewPage.vue').then(value => value.default),
    children: [
      {
        path: 'overview',
        name: 'rating-review-overview',
        meta: {
          title: 'rating_review',
          requiresAuth: true,
          breadcrumb: 'Overview',
          requiredPermissions: 'edit_settings',
        },
        component: () => import(/* webpackChunkName: "ratingReview" */'@/views/RatingReview/Preview/RatingReviewPreview.vue').then(value => value.default)
      },
      {
        path: 'settings',
        name: 'rating-review-settings',
        meta: {
          title: 'settings',
          requiresAuth: true,
          breadcrumb: 'Settings',
          requiredPermissions: 'edit_settings',
        },
        component: () => import(/* webpackChunkName: "ratingReview" */'@/views/RatingReview/Settings/RatingReviewSettings.vue').then(value => value.default)
      },
    ]
  },
]
