export default [
  {
    path: 'locations',
    name: 'locations',
    meta: {
      title: 'locations',
      requiresAuth: true,
      requiredPermissions: 'read_location',
    },
    component: () => import(/* webpackChunkName: "locations" */ '@/views/Locations/LocationsPage/LocationsPage').then(value => value.default)
  },
  {
    path: 'locations/:locationId(\\d+)',
    name: 'locations-edit',
    redirect: { name: 'location-info' },
    meta: {
      title: 'edit_location',
      requiresAuth: true,
      requiredPermissions: 'edit_location',
    },
    component: () => import(/* webpackChunkName: "locations-manage" */ '@/views/Locations/ManageLocation/ManageLocation').then(value => value.default),
    children: [
      {
        path: 'info',
        name: 'location-info',
        meta: {
          title: 'location_info',
          requiresAuth: true,
          requiredPermissions: 'edit_location',
        },
        component: () => import(/* webpackChunkName: "locations-manage" */ '@/views/Locations/ManageLocation/LocationInfo/LocationInfo').then(value => value.default)
      },
      {
        path: 'work-hours',
        name: 'location-work-hours',
        meta: {
          title: 'location_work_hours',
          requiresAuth: true,
          requiredPermissions: 'edit_location',
        },
        component: () => import(/* webpackChunkName: "locations-manage" */ '@/views/Locations/ManageLocation/LocationWorkHours/LocationWorkHours').then(value => value.default)
      },
      {
        path: 'gallery',
        name: 'location-gallery',
        meta: {
          title: 'location_gallery',
          requiresAuth: true,
          requiredPermissions: 'edit_location',
        },
        component: () => import(/* webpackChunkName: "locations-manage" */ '@/views/Locations/ManageLocation/LocationGallery/LocationGallery').then(value => value.default)
      },
    ]
  }
]
