<template>

  <!-- Share Booking Form Dialog -->
  <adm-modal
    :title="$store.state.shareBookingForm.selectedOptionTitle"
    :visible="true"
    destroy-on-close
    class="share-booking-form"
    width="800px"
    :class="`share-booking-form--${$store.state.shareBookingForm.currentSection}`"
    @close="closeShareBookingFormDialog"
  >

    <!-- Share Options -->
    <share-options
      v-if="['shareOptions', 'shareParameters'].includes($store.state.shareBookingForm.currentSection)"
      ref="shareOptions"
      @go-next="goNext"
    />
    <!-- /Share Options -->

    <!-- Entities Section -->
    <link-entities-section
      v-if="$store.state.shareBookingForm.currentSection === 'entities'"
      ref="linkEntitiesSection"
    />
    <!-- /Entities Section -->

    <!-- Instagram Images -->
    <social-media-images
      v-if="$store.state.shareBookingForm.currentSection === 'socialMediaImages'"
    />
    <!-- /Instagram Images -->

    <!-- Share Link Section -->
    <link-share-section
      v-if="$store.state.shareBookingForm.currentSection === 'share'"
      :uuid="uuid"
      :link="linkUuid"
      :short-link="shortLink"
      :qr-code-uri="qrCodeUri"
    />
    <!-- /Share Link Section -->

    <!-- Share Link Buttons -->
    <template v-if="showFooter" #footer>

      <!-- Back Button -->
      <adm-button
        size="medium"
        color="grey"
        :disabled="createLinkLoading"
        @click="goBack()"
      >
        {{ $t('back') }}
      </adm-button>
      <!-- /Back Button -->

      <!-- Create Link Button -->
      <adm-button
        v-if="$store.state.shareBookingForm.currentSection === 'entities'
          || $store.state.shareBookingForm.currentSection === 'socialMediaImages'"
        size="medium"
        :loading="createLinkLoading"
        :disabled="$store.state.shareBookingForm.currentSection === 'socialMediaImages' && !$store.state.shareBookingForm.selectedSocialMediaImage"
        @click="goNext"
      >
        {{ $t('next') }}
      </adm-button>
      <!-- /Create Link Button -->

      <!-- Done Button -->
      <adm-button
        v-if="$store.state.shareBookingForm.currentSection === 'share'"
        size="medium"
        @click="closeShareBookingFormDialog"
      >
        {{ $t('done') }}
      </adm-button>
      <!-- /Done Button -->

    </template>
    <!-- /Share link Buttons -->

  </adm-modal>
  <!-- /Share Booking Form Dialog -->

</template>

<script>
import AdmButton from '@/views/_components/button/AdmButton'
import AdmModal from '@/views/_components/modal/AdmModal'
import LinkEntitiesSection from './EntitiesSection/EntitiesSection'
import LinkShareSection from './LinkSection/LinkShareSection'
import { getBookingUrl } from '@/utils/url'
import mixinRouter from '@/mixins/common/router'
import ShareOptions from './ShareOptions/ShareOptions.vue'
import SocialMediaImages
  from '@/views/Dashboard/ShareBookingFormDialog/LinkSection/SocialMedia/SocialMediaImages/SocialMediaImages.vue'

export default {
  name: 'ShareBookingFormDialog',

  components: {
    SocialMediaImages,
    ShareOptions,
    LinkShareSection,
    LinkEntitiesSection,
    AdmModal,
    AdmButton,
  },

  mixins: [
    mixinRouter,
  ],

  props: {
    isOpen: {
      type: Boolean,
      default: false
    },
  },

  data: function () {
    return {
      createLinkLoading: false,
      link: '',
      shortLink: null,
      qrCodeUri: {},
      uuid: '',
      linkUuid: ''
    }
  },

  computed: {
    showFooter () {
      if (this.$store.state.settings.general.externalIntegration
        && this.$store.state.settings.general.externalIntegration.source === 'shoplazza'
        && this.$store.state.shareBookingForm.currentSection === 'shareParameters'
      ) {
        return false
      }
      return this.$store.state.shareBookingForm.currentSection !== 'shareOptions'
    },
  },

  created () {
    this.$store.commit('shareBookingForm/setOptionTitle', this.$t('share_booking'))
  },

  methods: {
    goBack () {
      const currentSection = this.$store.state.shareBookingForm.currentSection
      if (this.$store.state.shareBookingForm.selectedShareOption.type === 'socialMedia') {
        if (currentSection === 'share') {
          this.$store.commit('shareBookingForm/changeSection', 'socialMediaImages')

          return
        }
      }

      if (['share', 'socialMediaImages'].includes(currentSection)) {
        if (this.$store.state.shareBookingForm.selectedParametersOption === 'withParameters') {
          this.$store.commit('shareBookingForm/changeSection', 'entities')
        }

        if (this.$store.state.shareBookingForm.selectedParametersOption === 'withoutParameters') {
          this.$store.commit('shareBookingForm/changeSection', 'shareParameters')
        }

        return
      }

      if (currentSection === 'entities') {
        this.$store.commit('shareBookingForm/changeSection', 'shareParameters')
        this.$store.commit('shareBookingForm/resetQueryParams')

        return
      }

      this.$store.commit('shareBookingForm/changeSection', 'shareOptions')
      this.$store.commit('shareBookingForm/selectedShareOption', '')
      this.$store.commit('shareBookingForm/setOptionTitle', this.$t('share_booking'))

      if (this.$refs.shareOptions) {
        this.$refs.shareOptions.resetOptions()
        this.$store.commit('shareBookingForm/setShowDescription', true)
      }
    },

    closeShareBookingFormDialog () {
      this.$store.commit('shareBookingForm/hide')
    },

    getUrlParams () {
      let urlParams = { type: 's' }
      if (this.$refs?.linkEntitiesSection?.getUrlParams()) {
        urlParams = Object.assign(urlParams, this.$refs.linkEntitiesSection.getUrlParams())
      }

      return urlParams
    },

    async generateLink () {
      const urlParams = this.getUrlParams()

      const appointmentUrl = `${getBookingUrl(this.$store.getters['settings/getBookingWebsiteDomainData']())}/${this.bookAppointmentRoute()}`
      let paramsLink = ''

      if (this.$store.state.shareBookingForm.selectedShareOption === 'embedQrCode') {
        if (this.$store.state.shareBookingForm.entityType === 'employee') {
          paramsLink = `/e/${this.$store.state.shareBookingForm.entity.slug}`
        }

        if (this.$store.state.shareBookingForm.entityType === 'service') {
          urlParams.service = this.$store.state.shareBookingForm.entity
        }

        if (this.$store.state.shareBookingForm.entityType === 'serviceCategory') {
          urlParams.serviceCategory = this.$store.state.shareBookingForm.entity
        }
      }

      if (urlParams.hasOwnProperty('employee')) {
        paramsLink = `/e/${urlParams.employee.slug}`
        delete urlParams.employee
      }

      const queryParamsCopy = { ...this.$store.state.shareBookingForm.queryParams }
      delete queryParamsCopy['timeslots']
      delete queryParamsCopy['employee']

      const shouldGenerateLink =
        (this.$store.state.shareBookingForm.queryParams.timeslots && this.$store.state.shareBookingForm.queryParams.timeslots.length > 0) ||
        Object.keys(queryParamsCopy).length > 0

      const response = await this.$http.post('/api/v1/booking-links/generate-link', { ...this.$store.state.shareBookingForm.queryParams })
      this.uuid = response.data.bookingLinkUuid

      const linkParams = shouldGenerateLink ? `?t=s&uuid=${this.uuid}` : ''
      this.linkUuid = `${appointmentUrl}${paramsLink}${linkParams}`
      this.link = `${appointmentUrl}${paramsLink}`
    },

    async generateShortLink () {
      try {
        const { data } = await this.$http.post('/api/v1/short-url', { url: this.linkUuid })
        this.shortLink = data.url
      } catch (e) {
        this.shortLink = ''
      }
    },

    async goNext () {
      if (this.$store.state.shareBookingForm.currentSection === 'socialMediaImages') {
        if (this.$store.state.shareBookingForm.selectedParametersOption === 'withoutParameters') {
          await this.generateLink()
        }

        this.$store.commit('shareBookingForm/changeSection', 'share')
        return
      }


      if (this.$store.state.shareBookingForm.selectedShareOption.type === 'qrCode') {
        await this.generateQRCode()

        return
      }

      this.createLinkLoading = true
      await this.generateLink()

      this.shortLink = null
      if (this.$store.state.shareBookingForm.selectedShareOption.type === 'link') {
        this.generateShortLink()
      }

      this.createLinkLoading = false

      let section = 'share'
      if (this.$store.state.shareBookingForm.selectedShareOption.type === 'socialMedia' && this.$store.state.shareBookingForm.currentSection !== 'socialMediaImages') {
        section = 'socialMediaImages'
      }

      this.$store.commit('shareBookingForm/changeSection', section)
    },

    async generateQRCode () {
      this.createLinkLoading = true
      await this.generateLink()

      this.$http.post('/api/v1/booking-links/qr-code',
        { link: this.linkUuid }
      ).then((response) =>{
        this.qrCodeUri = response.data
      }).finally(() => {
        this.createLinkLoading = false
        this.$store.commit('shareBookingForm/changeSection', 'share')
      })
    }
  }
}
</script>

<style lang="scss">
.share-booking-form {
  &--shareOptions {
    .el-dialog {
      max-width: 816px;

      @include phone-up {
        width: 816px;
      }

      &__wrapper {
        height: 100vh;
      }
    }
  }

  &--entities {
    .el-dialog {
      max-width: 484px;
    }
  }

  &--socialMediaImages {
    .el-dialog {
      max-width: 648px;
    }
  }

  &--share {
    .el-dialog {
      max-width: 648px;

      @include phone-up {
        width: 816px;
      }
    }
  }
}
</style>
