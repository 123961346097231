export default [
  {
    path: 'employees',
    name: 'employees',
    meta: {
      title: 'employees',
      requiresAuth: true,
      requiredPermissions: ['read_employee', 'edit_own_employee_profile'],
    },
    component: () => import(/* webpackChunkName: "employees" */ '@/views/Employees/EmployeesPage/EmployeesPage').then(value => value.default)
  },
  {
    path: 'employees/:userId(\\d+)',
    name: 'employees-edit',
    redirect: { name: 'info' },
    meta: {
      title: 'edit_employee',
      requiresAuth: true,
      requiredPermissions: ['edit_employee', 'edit_own_employee_profile'],
    },
    component: () => import(/* webpackChunkName: "employees-manage" */ '@/views/Employees/ManageEmployee/ManageEmployee.vue').then(value => value.default),
    children: [
      {
        path: 'info',
        name: 'info',
        meta: {
          title: 'employee_info',
          requiresAuth: true,
          requiredPermissions: ['edit_employee', 'edit_own_employee_profile'],
        },
        component: () => import(/* webpackChunkName: "employees-manage" */ '@/views/Employees/ManageEmployee/EmployeeInfo/EmployeeInfo.vue').then(value => value.default)
      },
      {
        path: 'services',
        name: 'assigned-services',
        meta: {
          title: 'assigned_services',
          requiresAuth: true,
          requiredPermissions: ['edit_employee', 'edit_own_employee_profile'],
        },
        component: () => import(/* webpackChunkName: "employees-manage" */ '@/views/Employees/ManageEmployee/AssignedServices/AssignedServices.vue').then(value => value.default)
      },
      {
        path: 'work-hours',
        name: 'work-hours',
        meta: {
          title: 'working_hours',
          requiresAuth: true,
          requiredPermissions: ['edit_employee', 'edit_own_employee_profile'],
        },
        component: () => import(/* webpackChunkName: "employees-manage" */ '@/views/Employees/ManageEmployee/WorkHours/WorkHours.vue').then(value => value.default)
      },
      {
        path: 'days-off',
        name: 'days-off',
        meta: {
          title: 'days_off',
          requiresAuth: true,
          requiredPermissions: ['edit_employee', 'edit_own_employee_profile'],
        },
        component: () => import(/* webpackChunkName: "employees-manage" */ '@/views/Employees/ManageEmployee/DaysOff/AdmDaysOff.vue').then(value => value.default)
      },
      {
        path: 'special-days',
        name: 'special-days',
        meta: {
          title: 'special_days',
          requiresAuth: true,
          requiredPermissions: ['edit_employee', 'edit_own_employee_profile'],
        },
        component: () => import(/* webpackChunkName: "employees-manage" */ '@/views/Employees/ManageEmployee/SpecialDays/AdmSpecialDays.vue').then(value => value.default)
      }
    ]
  }
]
