import httpClient from '@/plugins/axios'

const getDefaultState = () => {
  return {
    showBanner: null
  }
}

const state = getDefaultState()

const mutations = {
  setShowBanner (state) {
    state.showBanner = true
  },

  setBannerToClosed (state) {
    state.showBanner = false
  },
}

const actions = {
  saveMobileBannerClosed (context) {
    context.commit('setBannerToClosed')
    httpClient.post(
      '/api/v1/mobile-app-banner/close', {}
    ).then(() => {})
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
