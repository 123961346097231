<template>

  <!-- Actions Dropdown -->
  <div v-if="hideActionsDropdown()" class="adm-actions-dropdown" @click.stop>
    <adm-dropdown
      :hide-on-click="hide"
      trigger="click"
      @command="handleItemClick"
      @visible-change="(value) => $emit('visible-change', value)"
    >

      <!-- Dots -->
      <slot name="button">
        <adm-button
          color="grey"
          icon-start="more-options"
          size="small"
          type="borderless"
        />
      </slot>
      <!-- /Dots -->

      <!-- Actions -->
      <adm-dropdown-menu slot="dropdown">
        <adm-dropdown-item
          v-for="actionItem in actions"
          :key="actionItem.type"
          :class="actionItem.class ? actionItem.class : ''"
          :command="actionItem.action"
          :disabled="actionItem.disabled ? actionItem.disabled(entity) : false"
          :hidden="actionItem.hidden ? actionItem.hidden(entity) : false"
          :red="actionItem.type === 'delete'"
          :divided="actionItem.divided === true"
          :icon="getIcon(actionItem)"
        >
          {{ typeof actionItem.label === 'function' ? actionItem.label(entity) : actionItem.label }}
        </adm-dropdown-item>
      </adm-dropdown-menu>
      <!-- /Actions -->

    </adm-dropdown>
  </div>
  <!-- /Actions Dropdown -->

</template>

<script>
import AdmDropdown from '@/views/_components/dropdown/AdmDropdown'
import AdmDropdownItem from '@/views/_components/dropdown/AdmDropdownItem'
import AdmDropdownMenu from '@/views/_components/dropdown/AdmDropdownMenu'
import AdmButton from '@/views/_components/button/AdmButton'
import mixinMenu from '@/mixins/menu/menu'

export default {
  name: 'AdmActionsDropdown',

  components: {
    AdmButton,
    AdmDropdown,
    AdmDropdownItem,
    AdmDropdownMenu
  },

  mixins: [mixinMenu],

  props: {
    entity: {
      type: Object,
      default: () => {}
    },
    actions: {
      type: Array,
      default: () => []
    },
    isSidebar: {
      type: Boolean,
      default: false
    },
    hide: {
      type: Boolean,
      default: true
    },
  },

  methods: {
    hideActionsDropdown () {
      return !this.actions.every(action => action?.hidden?.(this.entity))
    }
  }
}
</script>

<style scoped lang="scss">
// Actions Dropdown
.adm-actions-dropdown {
  cursor: pointer;

  // Three Dots
  &__dots {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 32px;
    height: 32px;
    cursor: pointer;
    background-color: #fff;
    border-radius: 50%;

    // Icon
    path {
      fill: $shade-500;
    }

    // Icon Hover
    &:hover {
      path {
        fill: var(--primary-900);
      }
    }
  }
}
</style>
