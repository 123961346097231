export default [
  {
    path: 'appointments',
    name: 'appointments',
    meta: {
      title: 'appointments',
      requiresAuth: true,
      requiredPermissions: 'read_appointment',
    },
    component: () => import(/* webpackChunkName: "appointments" */ '@/views/Appointments/AppointmentsPage/AppointmentsPage').then(value => value.default)
  }
]
