class StringUtils {
  static getAcronym (name, isHuman = false) {
    if (typeof name === 'string') {
      name = name.replace(/[&\/\\#,+()$~%.'":*?<>{}=+_\-]/g, '').trim()

      const nameArray = name.split(' ')
      let initials = nameArray.map((name) => {
        return name.charAt(0)
      })

      if (initials !== null) {
        initials = ((initials.shift() || '') + (initials.pop() || ''))

        return initials.toUpperCase()
      }

      return ''
    }
  }
}

export default StringUtils
