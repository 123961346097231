<template>

  <!-- Billing Dialog Deactivate Features -->
  <billing-dialog-deactivate-wrapper
    :items="items"
    :loading="loading"
    :max-items="maxFeatures"
    :show-avatar="false"
    :loading-continue="loadingContinue"
    @change-step="(step) => $emit('change-step', step)"
    @next-deactivate-step="$emit('next-deactivate-step', 'features', items)"
  >

    <!-- Title -->
    <template #title>
      {{ $t('max_number_of_features_in_plan') }} {{ maxFeatures }}.
    </template>
    <!-- /Title -->

    <!-- Subtitle -->
    <template #subtitle>
      {{ $t('max_number_of_features_in_plan_disable') }}
    </template>
    <!-- /Subtitle -->

    <!-- Headline -->
    <template #headline>
      {{ $t('currently_active_custom_features') }}
    </template>
    <!-- /Headline -->

  </billing-dialog-deactivate-wrapper>
  <!-- /Billing Dialog Deactivate Features -->

</template>

<script>
import BillingDialogDeactivateWrapper
  from '@/views/Dashboard/BillingDialog/Deactivate/BillingDialogDeactivateWrapper'
import mixinFeatures from '@/mixins/features/features'
import { getAbortController, isRequestCanceled } from '@/utils/api'
import { mapState } from 'vuex'

export default {
  name: 'BillingDialogDeactivateFeatures',

  components: {
    BillingDialogDeactivateWrapper
  },

  mixins: [
    mixinFeatures,
  ],

  props: {
    maxFeatures: {
      type: Number,
      default: 0
    },
    loadingContinue: {
      type: Boolean,
      default: false
    },
    items: {
      type: Array,
      default: () => ([])
    },
  },

  data () {
    return {
      cancelToken: getAbortController(),
      loading: false
    }
  },

  computed: {
    ...mapState({
      selectedPlan: state => state.billing.selectedPlan,
    }),
  },
}
</script>
