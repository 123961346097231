var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"adm-switch-wrapper",class:[
    _vm.captionPosition === 'left' && !_vm.description ? 'caption-left' : 'caption-right',
    {
      'without-caption': !_vm.description && !_vm.label,
      'is-full-width': _vm.fullWidth,
      'is-disabled': _vm.disabled,
    }
  ]},[_c('el-switch',_vm._b({ref:"admSwitch",class:['adm-switch', { 'disabled': _vm.disabled }],attrs:{"id":_vm.id,"active-text":_vm.activeText,"inactive-text":_vm.inactiveText},on:{"input":(eventValue) => _vm.$emit('input', eventValue),"change":(eventValue) => _vm.$emit('change', eventValue)},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},'el-switch',_vm.$props,false)),_c('div',{staticClass:"adm-switch-wrapper__caption"},[_c('div',{staticClass:"adm-switch-wrapper__caption__label",on:{"click":function($event){return _vm.$refs.admSwitch.switchValue()}}},[_vm._v(" "+_vm._s(_vm.label)+" ")]),(_vm.description)?_c('div',{staticClass:"adm-switch-wrapper__caption__description",domProps:{"innerHTML":_vm._s(_vm.description)}}):_vm._e(),(_vm.$slots.description)?_c('div',{staticClass:"adm-switch-wrapper__caption__description"},[_vm._t("description",function(){return [_vm._v(" "+_vm._s(_vm.description)+" ")]})],2):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }