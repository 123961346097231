<template>

  <!-- Menu Item -->
  <el-menu-item
    class="adm-menu-item"
    v-bind="$props"
    :class="[{
      'is-with-icon': Boolean(icon),
      'is-active':isActive,
      'adm-menu-item__appears-active': disabled && activeDisabled,
      'is-with-floating': isWithFloating,
      'is-active-with-floating': isActiveWithFloating
    }]"
    @click="(el) => $emit('click', el)"
  >
    <i
      v-if="icon"
      class="adm-menu-item__icon"
      :class="'tz-' + icon"
    />
    <slot />
  </el-menu-item>
  <!-- /Menu Item -->

</template>

<script>
export default {
  name: 'AdmMenuItem',

  props: {
    index: {
      default: null,
      validator: val => typeof val === 'string' || val === null
    },
    route: {
      type: [String, Object],
      default: null
    },
    disabled: Boolean,
    activeDisabled: Boolean,
    icon: {
      type: String,
      default: ''
    },
    isActive: Boolean,
    isWithFloating: Boolean,
    isActiveWithFloating:Boolean
  }
}
</script>

<style lang="scss">
// Menu Item
.adm-menu-item {

  // List Item
  &.el-menu-item {
    color: $shade-800;
    height: 48px;
    line-height: 48px;
    border-radius: 5px;
    padding-left: 8px !important;
    padding-right: 8px;
    display: flex;
    align-items: center;
    min-width: auto;
    white-space: pre-wrap;

    &.is-disabled {
      opacity: 1;
      color: $shade-500;

      .adm-menu-item__icon:before {
        color: $shade-600;
      }
    }

    &.adm-menu-item__appears-active {
      &.is-disabled {
        opacity: 1;
        color: $shade-800;
        cursor: pointer;

        .adm-menu-item__icon:before {
          color: $shade-800;
        }
      }
    }

    // Hover
    &:hover {
      background-color: $shade-200;
    }

    // Focus
    &:focus {
      background-color: initial;
    }

    // Active
    &.is-active:not(.is-disabled):not(.is-with-floating), &:active:not(.is-disabled):not(.is-with-floating) {
      background-color: var(--primary-300);

      // Icon
      .adm-menu-item__icon {
        font-size: 24px;

        // Before
        &:before {
          color: var(--primary-900);
        }
      }

      // Text
      span {
        color: var(--primary-900);
      }
    }

    &.is-active, &.is-with-floating {
      background-color: transparent;
    }

    &.is-active-with-floating {
      background-color: var(--primary-300);

      // Icon
      .adm-menu-item__icon {
        font-size: 24px;

        // Before
        &:before {
          color: var(--primary-900);
        }
      }

      // Text
      span {
        color: var(--primary-900);
      }
    }

    // Text
    span {
      font-weight: 500;
      font-size: 14px;
      min-height: 24px;
      line-height: 16px;
      display: flex;
      align-items: center;
    }
  }

  // With Icon
  &.is-with-icon {

    // Text
    span {
      padding-left: 0.5rem;
    }

    // Icon
    .adm-menu-item__icon {
      display: flex;
      font-size: 24px;

      // Before
      &:before {
        color: $shade-800;
      }
    }
  }
}
</style>
