<template>
  <div class="refer-friend-dialog">
    <adm-modal
      :visible="visible"
      :width="'648px'"
      :title="$t('love_trafft_share_joy')"
      modal-append-to-body
      @close="close"
    >
      <div class="refer-friend-dialog__content">
        <img src="@/assets/img/refer-friend.png" alt="">
        <span class="refer-friend-dialog__content__desc">{{ $t('refer_friend_desc') }}</span>

        <el-form ref="referFriend" :rules="rules" :model="referFriendModel">
          <div class="refer-friend-dialog__content__section">
            <adm-form-item :label="$t('invite_by_email')" prop="inviteEmail">
              <adm-input ref="inviteEmail" v-model="referFriendModel.inviteEmail" size="small" />
            </adm-form-item>
            <adm-button :loading="loading" size="small" :disabled="referFriendModel.inviteEmail === ''" @click="invite">
              {{ $t('invite') }}
            </adm-button>
          </div>

          <!-- Referral Link -->
          <div class="refer-friend-dialog__content__section">
            <adm-form-item :label="$t('referral_link')">
              <adm-input v-model="referFriendModel.referralLink" disabled size="small" />
            </adm-form-item>
            <adm-button size="small" @click="copyReferralLink">
              {{ $t('copy') }}
            </adm-button>
          </div>
          <!-- /Referral Link -->
        </el-form>
      </div>

      <template #footer>
        <adm-button size="medium" @click="close">
          {{ $t('done') }}
        </adm-button>
      </template>
    </adm-modal>
  </div>
</template>

<script>
import AdmModal from '@/views/_components/modal/AdmModal.vue'
import AdmButton from '@/views/_components/button/AdmButton.vue'
import AdmInput from '@/views/_components/input/AdmInput.vue'
import AdmFormItem from '@/views/_components/form/AdmFormItem.vue'
import { copy } from '@/utils/copy'

export default {
  name: 'ReferFriendDialog',
  components: {
    AdmFormItem,
    AdmInput,
    AdmButton,
    AdmModal
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    }
  },

  data () {
    return {
      referFriendModel: {
        inviteEmail: '',
        referralLink: `https://signup.trafft.com/`,
      },
      rules: {
        inviteEmail: [
          {
            type: 'email', message: this.$t('email_not_valid'), trigger: 'submit'
          }
        ]
      },
      loading: false
    }
  },

  methods: {
    invite () {

      this.$refs.referFriend.validate((valid) => {
        if (valid) {
          this.loading = true
          this.$http.post('/api/v1/refer-friend/invite',
            {inviteEmail: this.referFriendModel.inviteEmail}
          ).then(() => {
            this.$message({
              message: this.$t('invite_email_send_success', {email: this.referFriendModel.inviteEmail}),
              type: 'success',
              showClose: true,
              dangerouslyUseHTMLString: true
            })
            this.loading = false
          }).catch((e) => {
            this.$message({
              message: e.response.data.message,
              type: 'error',
              showClose: true
            })
          })
        }
      })
    },

    copyReferralLink () {
      copy(this.referFriendModel.referralLink)
      this.$message({ message: this.$t('referral_link_copy'), type: 'success', showClose: true })
    },

    close () {
      this.$emit('close')
      this.referFriendModel.inviteEmail = ""
    }
  }
}
</script>

<style scoped lang="scss">
.refer-friend-dialog {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    img {
      width: 100%;
    }

    &__desc {
      max-width: 490px;
      margin: 0 auto;
      color: $shade-800;
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
    }

    &__section {
      width: 100%;
      display: flex;
      justify-content: space-between;
      gap: 12px;
      flex-shrink: 1;
      border-bottom: 1px solid $shade-300;
      margin-bottom: 24px;

      &:last-child {
        margin-bottom: 0;
      }

      .adm-form-item {
        width: 100%;
      }

      .adm-button {
        margin-top: 23px;
      }
    }
  }
}
</style>