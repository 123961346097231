<template>
  <div class="rate-trafft-dialog">
    <adm-modal
      :visible="visible"
      :width="'484px'"
      :title="$t('help_people_decide_about_application')"
      modal-append-to-body
      @close="close"
    >
      <div class="rate-trafft-dialog__content">
        <span class="rate-trafft-dialog__content__description">
          {{ $t('rate_trafft_desc') }}
        </span>

        <div class="rate-trafft-dialog__content__rate-options">
          <span class="rate-trafft-dialog__content__description">
            {{ $t('rate_us_on') }}
          </span>
          <a
            v-for="option in rateOptions"
            v-show="shouldShow(option)"
            :key="option.name"
            class="rate-trafft-dialog__content__rate-options__card"
            :href="option.link"
            target="_blank"
          >
            <div class="rate-trafft-dialog__content__rate-options__card__option">
              <img :src="option.image" alt="">
              <span class="rate-trafft-dialog__content__rate-options__card__option__name">
                {{ option.name }}
              </span>
            </div>
            <div class="rate-trafft-dialog__content__rate-options__card__external-link">
              <adm-icon icon="external-link-outline" color="grey" />
            </div>
          </a>
        </div>
      </div>
      <template #footer>
        <div class="d-flex justify-end gap-8">
          <adm-button size="medium" :full-width="!$screen.aboveMobile" @click="close">
            {{ $t('done') }}
          </adm-button>
        </div>
      </template>
    </adm-modal>
  </div>
</template>
<script>
import AdmModal from '@/views/_components/modal/AdmModal.vue'
import GetApp from '@/assets/img/rate-trafft/GetApp.svg'
import Capterra from '@/assets/img/rate-trafft/Capterra.svg'
import G2 from '@/assets/img/rate-trafft/g2.svg'
import SoftwareAdvice from '@/assets/img/rate-trafft/software-advice.svg'
import Wordpress from '@/assets/img/rate-trafft/wordpress.svg'
import GooglePlayStore from '@/assets/img/rate-trafft/google-store.svg'
import AppStore from '@/assets/img/rate-trafft/app-store.svg'
import Trustpilot from '@/assets/img/rate-trafft/trustpilot.svg'
import AdmIcon from '@/views/_components/icon/AdmIcon.vue'
import AdmButton from '@/views/_components/button/AdmButton.vue'

export default {
  name: 'RateTrafftDialog',

  components: {
    AdmButton,
    AdmIcon,
    AdmModal
  },

  props: {
    visible: {
      type: Boolean,
      default: false
    },
  },

  data () {
    return {
      rateOptions: [
        {
          name: this.$t('google_play_store'),
          image: GooglePlayStore,
          link: "https://play.google.com/store/apps/details?id=com.trafft.dashboard&hl=en_US&pli=1",
          showOnDesktop: false
        },
        {
          name: this.$t('apple_store'),
          image: AppStore,
          link: "https://apps.apple.com/us/app/trafft/id6470921575",
          showOnDesktop: false
        },
        {
          name: this.$t('wordpress_feature_name'),
          image: Wordpress,
          link: "https://wordpress.org/plugins/booking-system-trafft/#reviews",
          showOnDesktop: true
        },
        {
          name: this.$t('capterra'),
          image: Capterra,
          link: "https://reviews.capterra.com/products/new/5ad6e50e-54a8-47a3-88dc-835663124b73",
          showOnDesktop: true
        },
        {
          name: this.$t('trustpilot'),
          image: Trustpilot,
          link: "https://www.trustpilot.com/evaluate/trafft.com",
          showOnDesktop: true
        },
        {
          name: this.$t('g2'),
          image: G2,
          link: "https://www.g2.com/products/trafft/reviews/start?return_to=https%3A%2F%2Fwww.g2.com%2Fproducts%2Ftrafft%2Ftake_survey",
          showOnDesktop: true
        },
        {
          name: this.$t('software_advice'),
          image: SoftwareAdvice,
          link: "https://reviews.softwareadvice.com/products/new/5ad6e50e-54a8-47a3-88dc-835663124b73",
          showOnDesktop: true
        },
        {
          name: this.$t('get_app'),
          image: GetApp,
          link: "https://reviews.getapp.com/products/new/5ad6e50e-54a8-47a3-88dc-835663124b73?_ga=2.68182481.1521678885.1706108588-752785257.1706108588",
          showOnDesktop: true
        },
      ]
    }
  },

  methods: {
    close () {
      this.$emit('close')
    },

    shouldShow(option) {
      if (option.showOnDesktop === false && !this.$screen.aboveTablet) {
        return true;
      }

      return option.showOnDesktop;
    },
  }
}
</script>

<style scoped lang="scss">
.rate-trafft-dialog {
  &__content {
    display: flex;
    flex-direction: column;
    gap: 24px;

    &__description {
      color: $shade-800;
      font-size: 15px;
      font-weight: 500;
      line-height: 24px;
    }

    &__rate-options {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      gap: 8px;

      &__card {
        border-radius: 4px;
        background: $shade-150;
        padding: 12px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: 2px solid transparent;
        cursor: pointer;

        @include tablet-up {
          &__external-link {
            visibility: hidden;
          }
        }

        &__option {
          display: flex;
          align-items: center;
          gap: 16px;

          img {
            width: 28px;
            height: 16px;
          }

          &__name {
            color: $shade-900;
            font-size: 18px;
            font-weight: 600;
            line-height: 28px;
          }
        }

        &:hover {
          border: 2px solid var(--primary-600);
          background: var(--visualpicker-linear, linear-gradient(180deg, var(--primary-400) 0%, var(--primary-200) 100%));
          text-decoration: none;

          span {
            color: var(--primary-900) !important;
          }

          @include tablet-up {
            div {
              visibility: visible !important;
            }
          }
        }
      }
    }
  }
}
</style>
